import { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'app/hooks/context/auth';
import { ApiConst, PageInfo, MessageConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

import {
  CorpAdminUserChangeRequest,
  CorpAdminUserChangeResponse,
} from 'app/service/customer/corporation/users/admin';

import {
  CorpUserTypeCheckRequest,
  CorpUserTypeCheckResponse,
} from 'app/service/customer/corporation/users/check';

// 企業管理者
export interface AdminUser {
  scimId: string;
  email: string;
  name: string;
  nameKana: string;
  departmentName: string;
}

/**
 * 企業管理者変更カスタムHooks
 */
export const useCorpAdminUser = () => {
  // 認証コンテキスト
  const { authInfo, setAuth } = useContext(AuthContext);

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // 企業管理者情報（変更前）
  const [currentAdminUser] =
    // 初期値設定
    useState<AdminUser>({
      scimId: authInfo?.scimId || '',
      email: authInfo?.email || '',
      name: authInfo?.name || '',
      nameKana: authInfo?.nameKana || '',
      departmentName: authInfo?.departmentName || '',
    });

  // 企業管理者情報（変更後）
  const [nextAdminUser, setNextAdminUser] = useState<AdminUser | null>(null);

  // IF-CO-041 企業管理者更新API 定義
  const {
    response: userChangeResponse,
    error: userChangeErrorResponse,
    callApi: changeApi,
  } = useApi<CorpAdminUserChangeResponse>();

  // IF-CO-041 企業管理者更新API call
  const adminUserChange = async () => {
    // リクエスト設定
    const req: CorpAdminUserChangeRequest = {
      corporationId: authInfo?.corporationId || '',
      fromScimId: authInfo?.scimId || '',
      toScimId: nextAdminUser?.scimId || '',
    };

    // メッセージ初期化
    setGlobalMessage('');

    // API呼出し
    await changeApi(ApiConst.CORP_USER_ADMIN_CHANGE, 'POST', req);
  };

  // IF-CO-041 企業管理者更新API レスポンス判定
  useEffect(() => {
    if (userChangeResponse) {
      // 更新成功
      setScreen(2);
      // 管理者更新成功後、認証情報の権限を更新
      setAuth('0', '0');
      // ブラウザバック対応 完了画面へはreplace
      history.replace(PageInfo.CORP_ADMIN_CHANGE_COMPLETE.path);
      // 二重POST防止：OFF
      processing.current = false;
      // browser.pushState(null, window.location.href);
    } else if (userChangeErrorResponse) {
      // 更新エラー
      window.scrollTo(0, 0);
      setGlobalMessage(userChangeErrorResponse.message);
      // 二重POST防止：OFF
      processing.current = false;
      history.go(-1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChangeResponse, userChangeErrorResponse]);

  // IF-CO-053 ユーザ区分チェックAPI 定義
  const {
    response: userTypeCheckResponse,
    error: userTypeCheckErrorResponse,
    callApi: userTypeCheckApi,
  } = useApi<CorpUserTypeCheckResponse>();

  // IF-CO-053 ユーザ区分チェックAPI call
  const userTypeCheck = async () => {
    // リクエスト設定
    const req: CorpUserTypeCheckRequest = {
      scimId: nextAdminUser?.scimId || '',
    };

    // メッセージ初期化
    setGlobalMessage('');

    // API呼出し
    await userTypeCheckApi(ApiConst.CORP_USER_TYPE_CHECK, 'POST', req);
  };

  // IF-CO-053 ユーザ区分チェックAPI レスポンス判定
  useEffect(() => {
    if (userTypeCheckResponse) {
      if (inputCheck()) {
        setScreen(1);
        // ブラウザバック対応 確認画面へはpush
        history.push(PageInfo.CORP_ADMIN_CHANGE_CONFIRM.path);
        // 二重POST防止：OFF
        processing.current = false;
      }
    } else if (userTypeCheckErrorResponse) {
      window.scrollTo(0, 0);
      setGlobalMessage(
        '指定されたユーザーの「お客さま種別」が個人のため管理者への変更はできません。'
      );
      // 二重POST防止：OFF
      processing.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTypeCheckResponse, userTypeCheckErrorResponse]);

  // 画面（0:入力、1:確認、2:完了）
  const [screen, setScreen] = useState(0);

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // Location
  const { pathname } = useLocation();

  // Histroy
  const history = useHistory();

  // 入力チェック
  const inputCheck = (): boolean => {
    let result = true;

    // 必須チェック（ラジオ）
    if (!nextAdminUser?.scimId) {
      // グローバルメッセージ設定
      window.scrollTo(0, 0);
      setGlobalMessage(MessageConst.NO_SELECTED_USER_ERROR);
      result = false;
    }

    return result;
  };
  // 画面遷移アクション
  const handleNext = async () => {
    // メッセージ初期化
    setGlobalMessage('');

    // pathより次pathを決定し、screenを更新
    switch (pathname) {
      case PageInfo.CORP_ADMIN_CHANGE_INPUT.path: // 入力画面
        // 二重POST防止：OFF
        processing.current = true;
        await userTypeCheck();
        break;
      case PageInfo.CORP_ADMIN_CHANGE_CONFIRM.path: // 確認画面
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        await adminUserChange();
        // IF-CO-041 企業管理者更新API レスポンス判定にて遷移
        break;
      case PageInfo.CORP_ADMIN_CHANGE_COMPLETE.path: // 完了画面
        // 二重POST防止：OFF
        processing.current = false;
        // 画面遷移（アカウント管理画面）
        history.push(PageInfo.ACCOUNT_MANAGE.path);
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        // その他は入力画面へ
        setScreen(0);
        break;
    }
  };

  // 戻るアクション
  const handleBack = () => {
    // メッセージ初期化
    setGlobalMessage('');
    history.go(-1);
  };
  useEffect(() => {
    if (screen === 1) {
      if (!inputCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, screen]);
  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen,
      setScreen,
      paths: {
        input: PageInfo.CORP_ADMIN_CHANGE_INPUT.path,
        confirm: PageInfo.CORP_ADMIN_CHANGE_CONFIRM.path,
        complete: PageInfo.CORP_ADMIN_CHANGE_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);
  return {
    screen,
    globalMessage,
    currentAdminUser,
    nextAdminUser,
    setNextAdminUser,
    handleNext,
    handleBack,
  };
};

export default useCorpAdminUser;
