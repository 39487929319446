import { FC, memo } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInfo } from 'app/style/theme';

const useStyles = makeStyles({
  messageArea: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: '30px',
    padding: '22px 25px',
    letterSpacing: '1px',
    borderRadius: '4px',
    backgroundColor: ColorInfo.red02,
    border: `1px solid ${ColorInfo.red01}`,
    '& svg': {
      fill: 'currentColor',
      color: ColorInfo.red01,
    },
  },
  errorText: {
    color: ColorInfo.red01,
    maxWidth: '900px',
    marginLeft: '10px',
    fontSize: '1.6rem',
    fontWeight: 600,
    lineheight: 1.2,
    textAlign: 'left',
  },
});

// インタフェース
interface MessageProps {
  message: string | null;
}

const GlobalErrorMessage: FC<MessageProps> = memo(({ message }) => {
  const classes = useStyles();
  return (
    <>
      {message && (
        <div className={classes.messageArea}>
          <svg
            viewBox="0 0 40 34"
            width="40"
            height="34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M39.06,33.47H.75a.73.73,0,0,1-.65-.38.72.72,0,0,1,0-.75L19.26.34a.79.79,0,0,1,1.29,0l19.16,32a.75.75,0,0,1-.65,1.13ZM2.07,32H37.74L19.91,2.18Z" />
            <path d="M19.91,23.68a.75.75,0,0,1-.75-.75v-9a.75.75,0,0,1,1.5,0v9A.76.76,0,0,1,19.91,23.68Z" />
            <path d="M19.9,27.36a1,1,0,0,1-.7-.29.91.91,0,0,1-.22-.33,1,1,0,0,1-.08-.38,1,1,0,0,1,.3-.71,1,1,0,0,1,1.41,0,1,1,0,0,1,.29.71,1,1,0,0,1-.07.38,1.19,1.19,0,0,1-.22.33,1,1,0,0,1-.32.21A1,1,0,0,1,19.9,27.36Z" />
          </svg>

          <Typography className={classes.errorText} align="center">
            {message}
          </Typography>
        </div>
      )}
    </>
  );
});

const GlobalErrorHtml: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.messageArea}>
      <svg
        viewBox="0 0 40 34"
        width="40"
        height="34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M39.06,33.47H.75a.73.73,0,0,1-.65-.38.72.72,0,0,1,0-.75L19.26.34a.79.79,0,0,1,1.29,0l19.16,32a.75.75,0,0,1-.65,1.13ZM2.07,32H37.74L19.91,2.18Z" />
        <path d="M19.91,23.68a.75.75,0,0,1-.75-.75v-9a.75.75,0,0,1,1.5,0v9A.76.76,0,0,1,19.91,23.68Z" />
        <path d="M19.9,27.36a1,1,0,0,1-.7-.29.91.91,0,0,1-.22-.33,1,1,0,0,1-.08-.38,1,1,0,0,1,.3-.71,1,1,0,0,1,1.41,0,1,1,0,0,1,.29.71,1,1,0,0,1-.07.38,1.19,1.19,0,0,1-.22.33,1,1,0,0,1-.32.21A1,1,0,0,1,19.9,27.36Z" />
      </svg>

      <Typography className={classes.errorText} align="center">
        {children}
      </Typography>
    </div>
  );
};

const GlobalMessage: FC<MessageProps> = memo(({ message }) => (
  <>
    <Typography align="center" style={{ fontSize: 20 }}>
      {message}
    </Typography>
  </>
));
export { GlobalErrorMessage, GlobalMessage, GlobalErrorHtml };
