/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import Template from 'app/views/components/template';
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBack, ButtonForward } from 'app/views/components/block/button';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import TextInput from 'app/views/components/atom/text';

import { ColorInfo } from 'app/style/theme';
import StepperContent from 'app/views/components/block/stepper';
import Divider from '@material-ui/core/Divider';
import { InputRowGridPadding } from 'app/views/components/block/layout';
import useAuthTransfer, {
  TransferDestination,
  TransferSource,
} from 'app/hooks/corporation/auth/transfer';

const useStyles = makeStyles({
  dividerMain: {
    margin: '30px 0 30px 0',
  },
  infoArea: {
    padding: '0px',
    margin: '0px',
  },
  table: {
    margin: '10px 0 10px 0',
    borderTop: `1px solid ${ColorInfo.gray03}`,
  },
  tableService: {
    margin: '10px 0 60px 0',
  },
  tableRow: {
    alignItems: 'center',
    height: 80,
    justifyContent: 'space-between',
    paddingBottom: 18,
    '& > *': {
      border: `1px solid ${ColorInfo.gray03}`,
    },
    '& td:first-child': {
      borderLeft: '0px',
    },
    '& td:last-child': {
      borderRight: '0px',
    },
  },
  tableRowList: {
    alignItems: 'center',
    height: 70,
    justifyContent: 'space-between',
    paddingBottom: 18,
    '& > *': {
      border: `1px solid ${ColorInfo.gray03}`,
    },
    '& td:first-child': {
      borderLeft: '0px',
    },
    '& td:last-child': {
      borderRight: '0px',
    },
  },
  tableUserRow: {
    '& td:first-child': {
      background: ColorInfo.gray05,
    },
  },
  tableHeadRow: {
    background: ColorInfo.gray05,
    '& td': {
      textAlign: 'center',
    },
  },
  tableMarkCell: {
    textAlign: 'center',
  },
  descArea: {
    padding: '40px 0 20px 0',
  },
  descAreaCenter: {
    padding: '40px 0 20px 0',
    textAlign: 'center',
  },
  inputDesc: {
    textAlign: 'center',
    paddingBottom: '40px',
  },
  actionDesc: {
    textAlign: 'center',
    paddingBottom: '40px',
  },
  noticeDesc: {
    fontSize: '1.3rem',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: 30,
    width: '500px',
    marginTop: 30,
  },
});

// 移譲元情報Props
interface SourceInfoProps {
  source: TransferSource;
  apiCallStatus?: number;
  title?: string;
}
// 移譲元情報
const SourceInfo: FC<SourceInfoProps> = memo(
  ({ source, title = '移譲元ユーザー' }) => {
    const classes = useStyles();
    return (
      <>
        <Box className={classes.infoArea}>
          <Typography variant="h3" style={{ marginTop: '90px' }}>
            {title}
          </Typography>

          <Table aria-label="simple table" className={classes.table}>
            <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
              <TableCell style={{ width: '320px' }}>
                <Typography>メールアドレス</Typography>
              </TableCell>
              <TableCell>
                <Typography>{source?.email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
              <TableCell>
                <Typography>氏名</Typography>
              </TableCell>
              <TableCell>
                <Typography style={{ wordBreak: 'break-word' }}>
                  {source?.name}
                </Typography>
              </TableCell>
            </TableRow>
          </Table>
        </Box>
      </>
    );
  }
);

// 移譲元情報（企業管理者）
const SourceCorpManageInfo: FC<SourceInfoProps> = memo(({ source }) => {
  const classes = useStyles();
  const isAdminCorpManager = source.administratorFlag === '1';
  return (
    <>
      {isAdminCorpManager && (
        <>
          <Box className={classes.infoArea}>
            <Typography variant="h3">企業管理者</Typography>
            <Typography>
              あなたは企業管理者です。権限の移譲を行うと、企業管理者の権限やサービスの利用・管理権限がすべて移譲されます。
            </Typography>
            <Typography>
              権限移譲後にサービスを利用する場合は、サービス利用権限を付与される必要があります。
            </Typography>

            <Typography
              className={classes.noticeDesc}
              style={{ marginTop: '20px' }}
            >
              管理者の権限：企業情報の変更申請、削除申請
            </Typography>
            <Typography
              className={classes.noticeDesc}
              style={{ paddingLeft: '90px' }}
            >
              サービスの申し込み、変更・解約申請、全サービスの利用、全サービス利用権限の付与
            </Typography>
          </Box>
        </>
      )}
    </>
  );
});

// 移譲元情報（詳細）
const SourceDetailInfo: FC<SourceInfoProps> = memo(
  ({ source, apiCallStatus }) => {
    const classes = useStyles();
    const contractServiceList = source?.contractServiceList || [];

    const Mark = ({ value }: { value: string | null }) => {
      if (value === '1') {
        return <Typography style={{ color: ColorInfo.blue02 }}>●</Typography>;
      }
      return <Typography style={{ color: ColorInfo.gray02 }}>×</Typography>;
    };

    const SeviceListContent = () => {
      if (apiCallStatus === 0) {
        return (
          <div style={{ marginTop: 30 }}>
            <></>
            <br />
          </div>
        );
      }

      if (contractServiceList.length === 0) {
        return (
          <div>
            <Typography variant="body1" className={classes.mainText2}>
              現在、ご利用可能なサービスはありません。
            </Typography>
            <br />
          </div>
        );
      }

      return contractServiceList.map((service) => (
        <Table
          aria-label="simple table"
          className={`${classes.table} ${classes.tableService}`}
        >
          <TableRow className={`${classes.tableRowList}`}>
            <TableCell colSpan={5}>
              <Typography variant="h4">
                {service?.contractServiceName}
              </Typography>
              <Typography style={{ paddingLeft: '20px' }}>
                {service?.contractName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow
            className={`${classes.tableRowList} ${classes.tableHeadRow}`}
          >
            <TableCell>
              <Typography>オプション</Typography>
            </TableCell>
            <TableCell>
              <Typography>利用権限</Typography>
            </TableCell>
            <TableCell>
              <Typography>
                利用権限
                <br />
                付与機能
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>利用権限付与者</Typography>
            </TableCell>
            <TableCell>
              <Typography>
                利用権限付与者
                <br />
                メールアドレス
              </Typography>
            </TableCell>
          </TableRow>
          {service?.contractOptionList?.map((option) => (
            <TableRow className={`${classes.tableRowList}`}>
              <TableCell>
                <Typography>{option?.contractOptionName}</Typography>
              </TableCell>
              <TableCell className={`${classes.tableMarkCell}`}>
                <Mark value={option?.contractOptionUsageFlag} />
              </TableCell>
              <TableCell className={`${classes.tableMarkCell}`}>
                <Mark value={option?.authorityFlag} />
              </TableCell>
              <TableCell>
                <Typography>{option?.issuerName}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{option?.issuerMail}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      ));
    };

    return (
      <>
        <Box className={classes.infoArea}>
          <Typography variant="h3">アカウント管理権限</Typography>

          <Table aria-label="simple table" className={classes.table}>
            <TableRow
              className={`${classes.tableRowList} ${classes.tableHeadRow}`}
            >
              <TableCell>
                <Typography>権限内容</Typography>
              </TableCell>
              <TableCell>
                <Typography>権限有無</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={`${classes.tableRowList} `}>
              <TableCell>
                <Typography>
                  企業ユーザー登録申請の承認、招待、企業ユーザー登録の解除
                </Typography>
              </TableCell>
              <TableCell className={`${classes.tableMarkCell}`}>
                <Mark value={source?.authorityApproval} />
              </TableCell>
            </TableRow>
            <TableRow className={`${classes.tableRowList} `}>
              <TableCell>
                <Typography>アカウント管理権限の付与機能</Typography>
              </TableCell>
              <TableCell className={`${classes.tableMarkCell}`}>
                <Mark value={source?.authorityAssignment} />
              </TableCell>
            </TableRow>
          </Table>
        </Box>

        <Box className={classes.infoArea}>
          <Typography variant="h3">サービス利用権限</Typography>
          {SeviceListContent()}
        </Box>
      </>
    );
  }
);

// 移譲先情報Props
interface DestinationProps {
  destination: TransferDestination;
}
// 移譲先情報
const DestinationInfo: FC<DestinationProps> = memo(({ destination }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.infoArea}>
        <Typography variant="h3">移譲先ユーザー</Typography>
        <Typography>以下のユーザーに権限を移譲します。</Typography>

        <Table aria-label="simple table" className={classes.table}>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell style={{ width: '320px' }}>
              <Typography>メールアドレス</Typography>
            </TableCell>
            <TableCell>
              <Typography>{destination?.email}</Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>氏名</Typography>
            </TableCell>
            <TableCell>
              <Typography>{destination?.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>氏名（カナ）</Typography>
            </TableCell>
            <TableCell>
              <Typography>{destination?.nameKana}</Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>部署名</Typography>
            </TableCell>
            <TableCell>
              <Typography>{destination?.departmentName}</Typography>
            </TableCell>
          </TableRow>
        </Table>
      </Box>
    </>
  );
});

// 入力情報Props
interface InputProps {
  useAuthTransferHook: ReturnType<typeof useAuthTransfer>;
}
// 入力情報
const InutInfo: FC<InputProps> = memo(({ useAuthTransferHook }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.infoArea}>
        <Typography className={classes.inputDesc}>
          上記ユーザーの全権限を移譲する相手のメールアドレスを入力してください。
        </Typography>

        <InputRowGridPadding label="メールアドレス">
          <TextInput
            name="destEmail"
            value={useAuthTransferHook?.destEmail}
            handleInputEvent={useAuthTransferHook.handleInputEvent}
            fullWidth
            required
            onBlur={useAuthTransferHook.validationCheck}
            error={!!useAuthTransferHook.validationMessage?.destEmail}
          />
          <Typography color="error">
            {useAuthTransferHook.validationMessage?.destEmail}
          </Typography>
        </InputRowGridPadding>
      </Box>
    </>
  );
});

// 説明Props
interface DescriptionProps {
  screen: number;
}
// 説明
const DescriptionInfo: FC<DescriptionProps> = memo(({ screen }) => {
  const classes = useStyles();
  const Description = () => {
    switch (screen) {
      case 0: // 入力画面
        return (
          <Typography className={classes.descArea}>
            ユーザーの所持する権限（サービスの管理・利用権限）すべてを、指定したユーザーに移譲します。
            <br />
            移譲元のユーザー情報と権限・利用サービスをご確認後、移譲先のメールアドレスをご入力の上、「確認」ボタンを押してください。
          </Typography>
        );
      case 1: // 確認画面
        return (
          <Typography className={`${classes.descAreaCenter}`}>
            ご入力内容を確認の上、「送信」ボタンを押してください。
          </Typography>
        );
      default:
        // 完了画面
        return (
          <Typography className={`${classes.descAreaCenter}`}>
            権限移譲先のユーザーにメールを送信しました。
          </Typography>
        );
    }
  };

  return (
    <>
      <Description />
    </>
  );
});

// 操作Props
interface ActionAreaProps {
  screen: number;
  useAuthTransferHook: ReturnType<typeof useAuthTransfer>;
}
// 操作
const ActionAreaInfo: FC<ActionAreaProps> = memo(
  ({ screen, useAuthTransferHook }) => {
    const classes = useStyles();
    const { handleBack, handleNext } = useAuthTransferHook;

    return (
      <>
        <Grid
          container
          direction="row"
          style={{ marginBottom: '-24px', marginTop: '-55px' }}
          alignItems="center"
        >
          {/* ボタン配置 */}
          {screen === 10 && (
            <>
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>戻る</ButtonBack>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3} />
            </>
          )}
          {screen === 0 && (
            <>
              <Grid item xs={12}>
                <Typography className={classes.actionDesc}>
                  サービス利用権限も含め、すべての権限を移譲します。ご注意ください。
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>キャンセル</ButtonBack>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ flexBasis: 'auto', padding: '0px 12px' }}
              >
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  確認
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </>
          )}

          {screen === 1 && (
            <>
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>戻る</ButtonBack>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ flexBasis: 'auto', padding: '0px 12px' }}
              >
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  送信
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </>
          )}

          {screen === 2 && (
            <>
              <Grid container item xs={12} style={{ flexBasis: 'auto' }}>
                <ButtonForward
                  onClick={handleNext}
                  style={{ width: '600px', margin: 'auto' }}
                >
                  アカウント管理ページへ
                </ButtonForward>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
);

// 画面パラメータ
interface params {
  scimId: string;
}

//  アカウント管理 利用権限移譲
const AuthTransferPage: FC = memo(() => {
  const classes = useStyles();
  const { scimId } = useParams<params>();
  // 管理権限移譲Hooks
  const useAuthTransferHook = useAuthTransfer(scimId);
  const {
    screen,
    transferSource,
    transferDestination,
    globalMessage,
    apiCallStatus,
  } = useAuthTransferHook;

  // stepper文言
  const steps = ['入力', '確認', '完了'];

  return (
    <Template title="アカウント管理 - 利用権限移譲">
      <StepperContent StepNumber={screen} Steps={steps} />

      <GlobalErrorMessage message={globalMessage} />

      <DescriptionInfo screen={screen} />

      {screen === 0 && (
        <>
          <SourceInfo source={transferSource} />
          <SourceCorpManageInfo source={transferSource} />
          <SourceDetailInfo
            source={transferSource}
            apiCallStatus={apiCallStatus}
          />
          <Divider className={classes.dividerMain} />
          <InutInfo useAuthTransferHook={useAuthTransferHook} />
          <Divider className={classes.dividerMain} />
        </>
      )}
      {screen === 1 && (
        <>
          <SourceInfo source={transferSource} />
          <DestinationInfo destination={transferDestination} />
        </>
      )}
      <ActionAreaInfo
        screen={screen}
        useAuthTransferHook={useAuthTransferHook}
      />
    </Template>
  );
});

//  アカウント管理 利用権限
export const AccountAuthPage: FC = memo(() => {
  const { scimId } = useParams<params>();
  // 管理権限移譲Hooks
  const useAuthTransferHook = useAuthTransfer(scimId);
  const { transferSource, globalMessage, apiCallStatus } = useAuthTransferHook;

  return (
    <Template title="アカウント管理 - 利用権限一覧">
      <GlobalErrorMessage message={globalMessage} />
      <SourceInfo source={transferSource} title="ログインユーザー" />
      <SourceDetailInfo source={transferSource} apiCallStatus={apiCallStatus} />
      <ActionAreaInfo screen={10} useAuthTransferHook={useAuthTransferHook} />
    </Template>
  );
});

export default AuthTransferPage;
