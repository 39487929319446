import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiConst, PageInfo } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { InquiryRequest, InquiryResponse } from 'app/service/customer/inquiry';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';
import useValidate, { validationProps } from 'app/hooks/validate';
import useRegistServiceList from 'app/hooks/corporation/regist/servicelist';

export interface checkedItem {
  serviceId: string;
  serviceName: string;
  checked: boolean;
}
// 問い合わせ情報
export interface Inquiry {
  category: string;
  service: string;
  serviceId: string;
  inquiryText: string;
  serviceStartTime: string | null;
  familyName: string;
  givenName: string;
  familyNameKana: string;
  givenNameKana: string;
  corporationName: string;
  departmentName: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  emailAddress: string;
  emailAddressConfirm: string;
}

// 問い合わせメッセージ
export interface InquiryMessage {
  category: string;
  serviceId: string;
  inquiryText: string;
  serviceStartTime: string;
  name: string;
  nameKana: string;
  corporationName: string;
  departmentName: string;
  corporationTelephoneNumber: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  emailAddress: string;
  emailAddressConfirm: string;
}

/**
 * 問い合わせ業務カスタムHooks
 */
export const useInquiry = () => {
  // 認証コンテキスト
  const { authInfo, setApplyStatus } = useContext(AuthContext);

  // 画面遷移状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);

  // ValidateHooks
  const valid = useValidate();

  // 二重POST防止：OFFで初期化 ANDO
  const processing = useRef(false);

  // 問い合わせ情報
  const [inquiry, setInquiry] =
    // 初期値設定
    useState<Inquiry>({
      category: '',
      service: '',
      serviceId: '',
      inquiryText: '',
      serviceStartTime: '',
      familyName: '',
      givenName: '',
      familyNameKana: '',
      givenNameKana: '',
      corporationName: '',
      departmentName: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      emailAddress: '',
      emailAddressConfirm: '',
    });

  const { registServiceList } = useRegistServiceList();

  // checkedItemsは初期値を空のオブジェクトにする
  const [checkedItems, setCheckedItems] = useState<checkedItem[]>([]);
  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // 問い合わせメッセージ
  const [inquiryMessage, setInquiryMessage] =
    // 初期値設定
    useState<InquiryMessage>({
      category: '',
      serviceId: '',
      inquiryText: '',
      serviceStartTime: '',
      name: '',
      nameKana: '',
      corporationName: '',
      departmentName: '',
      corporationTelephoneNumber: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      emailAddress: '',
      emailAddressConfirm: '',
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<InquiryResponse>();

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setInquiry({
      ...inquiry,
      [name]: value,
    });
  };
  // checkBox
  const handleCheckEvent = (name: string, value: boolean) => {
    setInquiry({
      ...inquiry,
      [name]: value,
    });
  };
  // radioButton
  // サービスIDを設定する
  const handleRadioEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name, value } = target;
    setInquiry({
      ...inquiry,
      [name]: value,
    });
    // 複数サービスが選択できるようになったときのためにコメントアウトで残しておく
    // if (
    //   checkedItems.length > 0 &&
    //   checkedItems.findIndex((v) => v.checked) > -1
    // ) {
    //   setCheckedItems((prev) => {
    //     prev.forEach((v, index) => {
    //       prev.splice(index, 1, {
    //         serviceId: prev[index].serviceId,
    //         serviceName: prev[index].serviceName,
    //         checked: false,
    //       });
    //     });
    //     return [...prev];
    //   });
    // }
    setInquiryMessage({
      ...inquiryMessage,
      [name]: radioInputCheck(name, { value }),
    });
  };

  const handleServiceCheckEvent = (id: string, value: boolean) => {
    setCheckedItems((prev) => {
      prev.splice(
        prev.findIndex((v) => v.serviceId === id),
        1,
        {
          serviceId: prev[prev.findIndex((v) => v.serviceId === id)].serviceId,
          serviceName:
            prev[prev.findIndex((v) => v.serviceId === id)].serviceName,
          checked: value,
        }
      );
      return [...prev];
    });
  };
  const isEditable = (...values: string[]): boolean => {
    if (!authInfo?.isAuth) return false;
    if (values) {
      return !values.every((value) => value.trim().length === 0);
    }
    return true;
  };

  const [editableFields, setEditableFields] = useState<{
    [key: string]: boolean;
  }>({});

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: PageInfo.INQUIRY_INPUT.path,
        confirm: PageInfo.INQUIRY_CONFIRM.path,
        complete: PageInfo.INQUIRY_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);
  useEffect(() => {
    // 初期画面（初回レンダリング：入力画面を設定し、history履歴を上書き）
    // browser.initReplace(0, PageInfo.INQUIRY_INPUT.path, null);

    // 初期処理
    // useState更新（問い合わせ情報をログインContextから初期設定）;
    const familyName = authInfo?.name?.split('　')[0] || '';
    const givenName = authInfo?.name?.split('　')[1] || '';
    const familyNameKana = authInfo?.nameKana?.split('　')[0] || '';
    const givenNameKana = authInfo?.nameKana?.split('　')[1] || '';
    const corporationName = authInfo?.corpName || '';
    const departmentName = authInfo?.departmentName || '';
    const corporationTelephoneNumber1 = authInfo?.telephoneNumber1 || '';
    const corporationTelephoneNumber2 = authInfo?.telephoneNumber2 || '';
    const corporationTelephoneNumber3 = authInfo?.telephoneNumber3 || '';
    const emailAddress = authInfo?.email || '';
    const emailAddressConfirm = authInfo?.email || '';

    setInquiry({
      ...inquiry,
      // 名前（漢字）姓
      familyName,
      // 名前（漢字）名
      givenName,
      // 名前（カナ）セイ
      familyNameKana,
      // 名前（カナ）メイ
      givenNameKana,
      // 会社名
      corporationName,
      // 部署名
      departmentName,
      // 電話番号
      corporationTelephoneNumber1,
      corporationTelephoneNumber2,
      corporationTelephoneNumber3,
      // メールアドレス
      emailAddress,
      emailAddressConfirm,
    });

    const editable = {};

    Object.assign(editable, {
      name: isEditable(familyName, givenName),
    });
    Object.assign(editable, {
      name_kana: isEditable(familyNameKana, givenNameKana),
    });
    Object.assign(editable, {
      corporationName: isEditable(corporationName),
    });
    Object.assign(editable, {
      departmentName: isEditable(departmentName),
    });
    Object.assign(editable, {
      telephoneNumber: isEditable(
        corporationTelephoneNumber1,
        corporationTelephoneNumber2,
        corporationTelephoneNumber3
      ),
    });
    Object.assign(editable, {
      emailAddress: isEditable(emailAddress),
    });
    Object.assign(editable, {
      emailAddressConfirm: isEditable(emailAddressConfirm),
    });

    setEditableFields(editable);

    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registServiceList && registServiceList.dataList) {
      registServiceList.dataList.forEach((service) => {
        setCheckedItems((prev) => {
          prev.push({
            serviceId: service?.contractServiceId,
            serviceName: service?.contractServiceName,
            checked: false,
          });
          return [...prev];
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registServiceList]);

  // 戻るアクション
  const history = useHistory();
  const handleBack = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (status === 1) {
      if (validateCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, status]);
  // 次処理
  const handleNext = () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力画面の場合、入力チェック実施
        if (!validateCheck()) {
          nextPage();
        }
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        // 問い合わせリクエスト呼び出し
        void inquiryRegistAPI();
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        nextPage();
        break;
    }
  };
  const serviceIdValid = (param: validationProps): string =>
    valid.allOfMultipleRequiredCheck('サービス', [param.value], '選択');
  const categoryValid = (param: validationProps): string =>
    valid.allOfMultipleRequiredCheck('カテゴリ', [param.value], '選択');
  const serviceStartTimeValid = (param: validationProps): string =>
    valid.requiredCheck('ご利用開始時期', param.value, '選択');
  const radioInputCheck = (key: string, param: validationProps): string => {
    if (key === 'serviceId') {
      return serviceIdValid(param);
    }
    if (key === 'category') {
      return categoryValid(param);
    }
    if (key === 'serviceStartTime') {
      return serviceStartTimeValid(param);
    }
    return '';
  };
  // ボタン押下時入力チェック
  const validateCheck = (): boolean => {
    let result = false;
    // サービス 必須チェック
    let serviceMessage = '';
    serviceMessage = serviceIdValid({ value: inquiry.serviceId });
    result = result || !!serviceMessage;
    // カテゴリ 必須チェック
    let categoryMessage = '';
    categoryMessage = categoryValid({ value: inquiry.category });
    result = result || !!categoryMessage;
    // お問い合わせ内容バリデーションチェック
    const inquiryTextMessage = valid.inquiryTextValidation({
      value: inquiry.inquiryText,
      require: true,
    });
    result = result || !!inquiryTextMessage;

    // ご利用開始時期 必須チェック
    let serviceStartTimeMessage = '';
    // 内容で「サービスを契約したい」を選択した場合のみチェック
    if (inquiry.category === 'サービスを契約したい') {
      serviceStartTimeMessage = serviceStartTimeValid({
        value: inquiry.serviceStartTime || '',
      });
    }
    result = result || !!serviceStartTimeMessage;

    // 姓,名 バリデーションチェック
    let nameMessage = '';
    nameMessage = valid.nameValidation(
      inquiry.familyName,
      inquiry.givenName,
      true
    );
    result = result || !!nameMessage;
    // セイ,メイバリデーションチェック

    // セイ 必須チェック
    let nameKanaMessage = '';
    nameKanaMessage = valid.nameKanaValidation(
      inquiry.familyNameKana,
      inquiry.givenNameKana,
      true
    );
    result = result || !!nameKanaMessage;
    // 企業名 バリデーションチェック
    let corporationNameMessage = '';
    corporationNameMessage = valid.corporationNameValidation({
      value: inquiry.corporationName,
      require: true,
    });

    // 部署名 バリデーションチェック
    let departmentNameMessage = '';
    departmentNameMessage = valid.departmentNameValidation({
      value: inquiry.departmentName,
      require: true,
    });
    result = result || !!departmentNameMessage;

    // 電話番号1 電話番号2 電話番号3 バリデーションチェック
    const corporationTelephoneNumberMessageList =
      valid.corporationTelephoneNumberValidation(
        inquiry.corporationTelephoneNumber1,
        inquiry.corporationTelephoneNumber2,
        inquiry.corporationTelephoneNumber3,
        true
      );
    result = result || !!corporationTelephoneNumberMessageList[0];
    result = result || !!corporationTelephoneNumberMessageList[1];
    result = result || !!corporationTelephoneNumberMessageList[2];
    result = result || !!corporationTelephoneNumberMessageList[3];

    // メールアドレス バリデーションチェック
    let emailAddressMessage = '';
    emailAddressMessage = valid.mailValidation(
      { value: inquiry.emailAddress, require: true },
      'メールアドレス'
    );
    result = result || !!emailAddressMessage;
    // メールアドレス（確認用） バリデーションチェック
    let emailAddressConfirmMessage = '';
    emailAddressConfirmMessage = valid.mailValidation(
      { value: inquiry.emailAddressConfirm, require: true },
      'メールアドレス（確認用）'
    );
    result = result || !!emailAddressConfirmMessage;

    // メールアドレス 同一チェック
    emailAddressConfirmMessage =
      emailAddressConfirmMessage ||
      valid.sameCheck(
        'メールアドレス',
        'メールアドレス（確認用）',
        inquiry.emailAddress,
        inquiry.emailAddressConfirm
      );
    result = result || !!emailAddressConfirmMessage;

    // メッセージ設定
    setInquiryMessage({
      ...inquiryMessage,
      category: categoryMessage,
      inquiryText: inquiryTextMessage,
      serviceStartTime: serviceStartTimeMessage,
      serviceId: serviceMessage,
      name: nameMessage,
      nameKana: nameKanaMessage,
      corporationName: corporationNameMessage,
      departmentName: departmentNameMessage,
      corporationTelephoneNumber: corporationTelephoneNumberMessageList[0],
      corporationTelephoneNumber1: corporationTelephoneNumberMessageList[1],
      corporationTelephoneNumber2: corporationTelephoneNumberMessageList[2],
      corporationTelephoneNumber3: corporationTelephoneNumberMessageList[3],
      emailAddress: emailAddressMessage,
      emailAddressConfirm: emailAddressConfirmMessage,
    });

    if (result) {
      // エラーメッセージ
      setGlobalMessage(
        `入力内容にエラーがあります。ご確認のうえ再度「確認」ボタンを押してください。`
      );
      window.scrollTo(0, 0);
    } else {
      setGlobalMessage('');
    }
    return result;
  };

  // 問い合わせ登録API
  const inquiryRegistAPI = () => {
    function serviceJoin() {
      const serviceList: string[] = [];
      if (inquiry.serviceId !== '') {
        serviceList.push(inquiry.serviceId);
      }
      return serviceList;
    }

    // API Request設定
    const req: InquiryRequest = {
      // APIを適宜設定
      ...{
        category: inquiry.category,
        serviceList: serviceJoin(),
        inquiryText: inquiry.inquiryText,
        familyName: inquiry.familyName,
        givenName: inquiry.givenName,
        familyNameKana: inquiry.familyNameKana,
        givenNameKana: inquiry.givenNameKana,
        corporationName: inquiry.corporationName,
        departmentName: inquiry.departmentName,
        corporationTelephoneNumber1: inquiry.corporationTelephoneNumber1,
        corporationTelephoneNumber2: inquiry.corporationTelephoneNumber2,
        corporationTelephoneNumber3: inquiry.corporationTelephoneNumber3,
        mailAddress: inquiry.emailAddress,
      },
    };

    if (inquiry?.serviceStartTime)
      req.serviceStartTime = inquiry.serviceStartTime;

    // 問い合わせAPI呼出し
    void callApi(ApiConst.INQUIRY_REGIST, 'POST', req);
    setApplyStatus('1');
  };

  // 画面遷移
  const nextPage = () => {
    // 次画面へ遷移（状態を加算）
    setStatus((preStatus) => preStatus + 1);
    switch (status) {
      case 0:
        history.push(PageInfo.INQUIRY_CONFIRM.path);
        break;
      case 1:
        history.replace(PageInfo.INQUIRY_COMPLETE.path);
        break;
      default:
        history.push(PageInfo.TOP.path);
        break;
    }
  };

  useEffect(() => {
    if (response) {
      // 正常
      // nextPage();
      setStatus((preStatus) => preStatus + 1);
      history.replace(PageInfo.INQUIRY_COMPLETE.path);
      // browser.pushState(null, window.location.href);
      // 初期処理
      // useState更新（問い合わせ情報をログインContextから初期設定）;
      const familyName = authInfo?.name?.split('　')[0] || '';
      const givenName = authInfo?.name?.split('　')[1] || '';
      const familyNameKana = authInfo?.nameKana?.split('　')[0] || '';
      const givenNameKana = authInfo?.nameKana?.split('　')[1] || '';
      const corporationName = authInfo?.corpName || '';
      const departmentName = authInfo?.departmentName || '';
      const corporationTelephoneNumber1 = authInfo?.telephoneNumber1 || '';
      const corporationTelephoneNumber2 = authInfo?.telephoneNumber2 || '';
      const corporationTelephoneNumber3 = authInfo?.telephoneNumber3 || '';
      const emailAddress = authInfo?.email || '';
      const emailAddressConfirm = authInfo?.email || '';

      setInquiry({
        ...inquiry,
        category: '',
        service: '',
        serviceId: '',
        inquiryText: '',
        serviceStartTime: '',
        // 名前（漢字）姓
        familyName,
        // 名前（漢字）名
        givenName,
        // 名前（カナ）セイ
        familyNameKana,
        // 名前（カナ）メイ
        givenNameKana,
        // 会社名
        corporationName,
        // 部署名
        departmentName,
        // 電話番号
        corporationTelephoneNumber1,
        corporationTelephoneNumber2,
        corporationTelephoneNumber3,
        // メールアドレス
        emailAddress,
        emailAddressConfirm,
      });
    } else if (error?.code) {
      // 応答電文を設定
      setGlobalMessage(error.message);
      // API入力値エラー
      // 入力画面へ戻る
      history.go(-1);
      // 二重POST防止：OFF
      processing.current = false;
    }
    // [browser]を除くため以下コメント追記
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error, history]);

  return {
    status,
    inquiry,
    inquiryMessage,
    setInquiryMessage,
    globalMessage,
    editableFields,
    registServiceList,
    checkedItems,
    handleInputEvent,
    handleCheckEvent,
    handleServiceCheckEvent,
    handleRadioEvent,
    handleNext,
    handleBack,
  };
};
export default useInquiry;
