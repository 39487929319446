import { FC } from 'react';

import { List } from '@material-ui/core';
import ServiceView from 'app/views/components/modules/service-view';

const IntroduceServiceList: FC = () => (
  <div style={{ marginTop: '-17px', marginBottom: '-90px' }}>
    <List>
      <ServiceView
        name="トヨタドライブ統計"
        href={process.env.REACT_APP_SERVICE_01_URL || ''}
        image="/image/service_img.jpg"
      >
        <p>
          全国を走るトヨタ自動車のコネクティッドカーから収集した大量の車両データを、安全な形で統計処理し、交通事故防止や走行しやすい道路維持管理、渋滞緩和など、社会課題の解決へとつなげます。
          <br />
          トヨタドライブ統計は、だれもが安全・安心で、便利・快適に生活できる街づくりへ貢献します。
        </p>
      </ServiceView>
    </List>
  </div>
);

export default IntroduceServiceList;
