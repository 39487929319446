import { FC, memo } from 'react';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
  MainButton as AtomMainButton,
  LinkMainButton,
  SubButton as AtomSubButton,
} from 'app/views/components/atom/button';
import { ColorInfo } from 'app/style/theme';

const useStyles = makeStyles(() => ({
  // パターン01
  btnPtn01: {
    height: '88px',
    width: '410px',
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '1.8rem',
    lineHeight: 1.4,
    fontWeight: 500,
    letterSpacing: '1px',
    color: ColorInfo.white01,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    border: 0,
    borderRadius: '6px',
    backgroundColor: ColorInfo.blue02,
    transition: 'all 0.3s',
    '&$disabled': {
      backgroundColor: ColorInfo.blue05,
    },
    '&:hover': {
      backgroundColor: ColorInfo.blue03,
    },
  },
  arrowforward: {
    '&::before': {
      position: 'absolute',
      top: '50%',
      right: '35px',
      content: '""',
      width: '35px',
      height: '1px',
      backgroundColor: ColorInfo.white01,
      transition: 'all 0.3s',
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      right: '20px',
      transform: 'translateY(-6px)',
      content: `""`,
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 15px 7px 0px',
      borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
      transition: 'all 0.3s',
    },
  },
  arrowback: {
    '&::after': {
      position: 'absolute',
      top: '50%',
      left: '5px',
      transform: 'translateY(-6px)',
      content: `""`,
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 0px 7px 15px',
      borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
      transition: 'all 0.3s',
    },
    '&::before': {
      position: 'absolute',
      top: '50%',
      left: '15px',
      content: `""`,
      width: '35px',
      height: '1px',
      backgroundColor: ColorInfo.blue02,
      transition: 'all 0.3s',
    },
  },
  // パターン02
  btnPtn02: {
    color: ColorInfo.blue02,
    backgroundColor: ColorInfo.blue05,
    '&$disabled': {
      backgroundColor: ColorInfo.blue05,
    },
    '&:hover': {
      color: ColorInfo.white01,
      backgroundColor: ColorInfo.blue03,
    },
  },
  btnPtn02Forward: {
    '&::before': {
      backgroundColor: ColorInfo.blue03,
    },
    '&::after': {
      borderColor: `transparent transparent ${ColorInfo.blue03} transparent`,
    },
    '&:hover::before': {
      backgroundColor: ColorInfo.white01,
    },
    '&:hover::after': {
      borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
    },
  },
  btnPtn02Back: {
    '&::before': {
      backgroundColor: ColorInfo.blue03,
    },
    '&::after': {
      borderColor: `transparent transparent ${ColorInfo.blue03} transparent`,
    },
    '&:hover::before': {
      backgroundColor: ColorInfo.white01,
    },
    '&:hover::after': {
      borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
    },
  },
  // 低強調ボタン
  btnLow: {
    position: 'relative',
    padding: '10px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    fontWeight: 500,
    border: 0,
    verticalAlign: 'middle',
    textDecoration: 'none',
    transition: 'all 0.3s',
    backgroundColor: ColorInfo.white01,
    '&$disabled': {
      color: ColorInfo.blue05,
    },
    '&:hover': {
      color: ColorInfo.blue02,
      backgroundColor: ColorInfo.white01,
    },
    '&:hover::before': {
      backgroundColor: ColorInfo.blue02,
    },
    '&:hover::after': {
      borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
    },
  },
  btnLowForward: {
    padding: '10px 105px 10px 0px',
    '&::before': {
      position: 'absolute',
      top: '50%',
      right: '15px',
      content: `""`,
      width: '70px',
      height: '1px',
      backgroundColor: 'currentColor',
      transition: 'all 0.3s',
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-6px)',
      content: `""`,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 15px 7px 0px',
      borderColor: `transparent transparent currentColor transparent`,
      transition: 'all 0.3s',
    },
  },
  btnLowBack: {
    padding: '10px 0px 10px 105px',
    '&::before': {
      position: 'absolute',
      top: '50%',
      left: '15px',
      content: `""`,
      width: '70px',
      height: '1px',
      backgroundColor: 'currentColor',
      transition: 'all 0.3s',
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-6px)',
      content: `""`,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 0px 7px 15px',
      borderColor: `transparent transparent currentColor transparent`,
      transition: 'all 0.3s',
    },
  },
  // テキストリンク
  textLink: {
    color: ColorInfo.blue02,
    backgroundColor: ColorInfo.white01,
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  // サブボタン
  btnSub: {
    width: '150px',
    height: '56px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    cursor: 'pointer',
    fontSize: '1.5rem',
    lineHeight: '1.4',
    letterSpacing: '1px',
    color: ColorInfo.white01,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    border: 0,
    borderRadius: '6px',
    backgroundColor: ColorInfo.blue02,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: ColorInfo.blue03,
    },
  },
  // 申請取消
  btnCancel: {
    margin: 'auto',
    '&::before': {
      with: '30px',
    },
  },
}));
// インタフェース
type MainProps = MuiButtonProps;

// 次フローボタン（パターン01）
const ButtonForward: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnPtn01} ${classes.arrowforward}`}
        forward
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});

// 次フローボタン（パターン02）
const ButtonSubForward: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnPtn01} ${classes.arrowforward} ${classes.btnPtn02} ${classes.btnPtn02Forward}`}
        forward
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});
// 戻るフローボタン（パターン02）
const ButtonSubBack: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnPtn01} ${classes.arrowback} ${classes.btnPtn02} ${classes.btnPtn02Back}`}
        back
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});

// フローなしボタン（パターン02）
const ButtonSub: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomSubButton
        className={`${classes.btnPtn01} ${classes.btnPtn02}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomSubButton>
    </>
  );
});

// 低強調ボタン(戻る/キャンセルボタン)
const ButtonBack: FC<MainProps> = memo(({ children, ...props }) => (
  <>
    <ButtonLowBack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </ButtonLowBack>
  </>
));

// 低強調ボタン(フローボタン)
const ButtonLowForward: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnLow} ${classes.btnLowForward}`}
        forward
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});

// 低強調ボタン(フローボタン)
const ButtonLowBack: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnLow} ${classes.btnLowBack}`}
        back
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});

// 低強調ボタン(フローなし)
const ButtonLow: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomSubButton
        className={`${classes.btnLow}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomSubButton>
    </>
  );
});

// サブボタン
const SubButton: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomSubButton
        className={classes.btnSub}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomSubButton>
    </>
  );
});
// インタフェース
interface LinkProps extends MuiButtonProps {
  to: string;
  disabled?: boolean;
}

// 次フローリンクボタン ※未使用(暫定で登録方法選択で利用）)
const LinkMainForward: FC<LinkProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <LinkMainButton
        className={classes.textLink}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </LinkMainButton>
    </>
  );
});

// 取消ボタン（キャンセル再申請用）
const ButtonCancel: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnLow} ${classes.btnLowForward} ${classes.btnCancel} `}
        forward
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});

const ButtonConfirm: FC<MainProps> = memo(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <AtomMainButton
        className={`${classes.btnSub}`}
        forward
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </AtomMainButton>
    </>
  );
});

export {
  ButtonForward,
  ButtonSubForward,
  ButtonSubBack,
  ButtonSub,
  ButtonBack,
  ButtonLowForward,
  ButtonLowBack,
  ButtonLow,
  SubButton,
  LinkMainForward,
  ButtonCancel,
  ButtonConfirm,
};
