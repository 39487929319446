/* eslint-disable max-classes-per-file */
// サンプル定数定義（参考）
export class SampleConsts {
  static readonly NAME = process.env.REACT_APP_SAMPLE_NAME;
}

export class SdpApConst {
  static readonly HOST_URL: string = process.env.REACT_APP_API_HOST_URL
    ? process.env.REACT_APP_API_HOST_URL
    : '';

  static readonly HOST_PAGE_URL: string = process.env.REACT_APP_PAGE_HOST_URL
    ? process.env.REACT_APP_PAGE_HOST_URL
    : '';
}
export class UserTypeConst {
  // ユーザ区分：「個人」
  static readonly PERSON: string = 'person';

  // ユーザ区分：「法人」
  static readonly CORP_LIGHT: string = 'corporation';

  // ID連携済みユーザ
  static readonly CORP_PREMIUM: string = 'corporation_premium';

  // ユーザ区分：「販売店」
  static readonly DEALER: string = 'dealer';

  // ユーザ情報なしの意図的データ（フロント内でのみ利用）※null、undefinedを同意味
  static readonly NOT_USER: string = 'noUser';

  // 招待されたユーザでは無い場合のエラー（フロントでのみ利用）
  static readonly NOT_INVITED_USER: string = 'notInvited';
}
export class CorporationStatusConst {
  // 企業ステータス：「企業申請中」
  static readonly APPLYING: string = '1';

  // 企業ステータス：「企業申請差戻」
  static readonly APPLY_REJECT: string = '2';

  // 企業ステータス：「登録済み」
  static readonly IN_USE: string = '4';

  // 企業ステータス：「変更申請中」
  static readonly CHANGE_REQUEST: string = '5';

  // 企業ステータス：「変更申請差戻」
  static readonly CHANGE_REJECT: string = '6';

  // 企業ステータス：「利用停止」
  static readonly SUSPENSION: string = '9';
}

export interface LinkConfig {
  path: string;
}
export class OuterLinkInfo {
  static readonly OUT_LINK_URL: LinkConfig = {
    path: process.env.REACT_APP_OUT_SERVICE_URL
      ? process.env.REACT_APP_OUT_SERVICE_URL
      : '',
  };

  static readonly CONTROLE_CENTER_LINK_URL: LinkConfig = {
    path: process.env.REACT_APP_CONTROLE_CENTER_URL
      ? process.env.REACT_APP_CONTROLE_CENTER_URL
      : '',
  };

  static readonly FAQ_LINK_URL: LinkConfig = {
    path: process.env.REACT_APP_FAQ_URL ? process.env.REACT_APP_FAQ_URL : '',
  };

  static readonly TERMS_LINK_URL: LinkConfig = {
    path: process.env.REACT_APP_TERMS_URL
      ? process.env.REACT_APP_TERMS_URL
      : '',
  };

  // サイト利用についてリンク
  static readonly SITE_ATTRIBUTE_URL: LinkConfig = {
    path: process.env.REACT_APP_ATTRIBUTE_URL
      ? process.env.REACT_APP_ATTRIBUTE_URL
      : '',
  };

  // 個人情報に関する基本方針リンク
  static readonly PRIVACY_URL: LinkConfig = {
    path: process.env.REACT_APP_PRIVACY_URL
      ? process.env.REACT_APP_PRIVACY_URL
      : '',
  };

  // TOYOTAアカウント利用規約リンク
  static readonly ID_TERM_URL: LinkConfig = {
    path: process.env.REACT_APP_ID_TERM_URL
      ? process.env.REACT_APP_ID_TERM_URL
      : '',
  };

  // 反社会的勢力に対する基本方針リンク
  static readonly ANTI_SOCIAL_URL: LinkConfig = {
    path: process.env.REACT_APP_ANTI_SOCIAL_URL
      ? process.env.REACT_APP_ANTI_SOCIAL_URL
      : '',
  };

  // 企業情報
  static readonly CORP_INFO_URL: LinkConfig = {
    path: process.env.REACT_APP_CORP_INFO_URL
      ? process.env.REACT_APP_CORP_INFO_URL
      : '',
  };

  // Center Toyota Biz Center「お客様からのお問い合わせ対応について」リンク
  static readonly TOYOTA_BIZ_CENTER_INQUIRY_URL: LinkConfig = {
    path: process.env.REACT_APP_TOYOTA_BIZ_CENTER_INQUIRY_URL
      ? process.env.REACT_APP_TOYOTA_BIZ_CENTER_INQUIRY_URL
      : '',
  };
}
export interface ApiConfig {
  url: string;
  headers?: { [key: string]: string };
  withoutAuth?: boolean;
}

export class ApiConst {
  // IF-CO-001 法人情報登録
  static readonly CORP_APPLY: ApiConfig = {
    url: '/api/v1/corporation',
    headers: { 'Content-type': 'multipart/form-data;charset=utf-8/json' },
  };

  // IF-CO-002 法人ID登録
  static readonly CORP_FEDERATE: ApiConfig = {
    url: '/api/v1/corporation/invite',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-022 ユーザ招待
  static readonly CORP_USERS_INVITE: ApiConfig = {
    url: '/api/v1/corporation/users/invite',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-021 ユーザ招待チェック
  static readonly CORP_USERS_INVITE_CHECK: ApiConfig = {
    url: '/api/v1/corporation/users/invitecheck',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-028 招待削除
  static readonly CORP_USERS_INVITE_DELETE: ApiConfig = {
    url: '/api/v1/corporation/users/invite/delete',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-027 承認リクエスト
  static readonly CORP_USERS_APPROVAL_REQUEST: ApiConfig = {
    url: '/api/v1/corporation/users/approval/request',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-008 OneID基盤認可要求
  static readonly ONEID_AUTH: ApiConfig = {
    url: '/api/v1/auth',
    headers: { 'Content-type': 'charset=UTF-8' },
    withoutAuth: true,
  };

  // IF-CO-009 アクセストークン要求
  static readonly ONEID_TOKEN: ApiConfig = {
    url: '/api/v1/token',
    headers: { 'Content-type': 'charset=UTF-8' },
    withoutAuth: true,
  };

  static readonly ONEID_USERINFO: ApiConfig = {
    url: '/api/v1/userInfo',
    headers: { 'Content-type': 'charset=UTF-8' },
    withoutAuth: true,
  };

  // IF-CO-024 ID連携
  static readonly CORP_USERS_INVITE_REGIST: ApiConfig = {
    url: '/api/v1/corporation/users/invite/regist',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-033 IDリクエスト件数取得
  static readonly CORP_USERS_APPROVAL_REQUEST_COUNT: ApiConfig = {
    url: '/api/v1/corporation/users/approval/request/account/',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-034 部署名変更
  static readonly CORP_USERS_UPDATE: ApiConfig = {
    url: '/api/v1/corporation/users/update',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-035 登録済み法人ユーザー検索
  static readonly CORP_REGISTED_USERS: ApiConfig = {
    url: '/api/v1/corporation/register/users',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-036 管理権限更新
  static readonly CORP_USER_AUTH_UPDATE: ApiConfig = {
    url: '/api/v1/corporation/users/updateAuthority',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-037 企業連携解除
  static readonly CORP_USER_DETACH: ApiConfig = {
    url: '/api/v1/corporation/users/relationDetachment',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-039 管理権限情報取得
  static readonly MANAGE_AUTH: ApiConfig = {
    url: '/api/v1/corporation/admin/auth',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-040 利用権限移譲登録
  static readonly AUTH_TRANSFER_REGIST: ApiConfig = {
    url: '/api/v1/corporation/auth/transfer/regist',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-041 企業管理者更新
  static readonly CORP_USER_ADMIN_CHANGE: ApiConfig = {
    url: '/api/v1/corporation/users/admin/update',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-042 企業情報変更申請
  static readonly CORP_USER_INFO_CHANGE: ApiConfig = {
    url: '/api/v1/corporation/info/change/request',
    headers: { 'Content-type': 'multipart/form-data;charset=utf-8/json' },
  };

  // IF-CO-054 企業情報変更申請情報取消
  static readonly CORP_USER_INFO_EDIT_CANCEL: ApiConfig = {
    url: '/api/v1/corporation/info/change/cancel',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-046 企業退会
  static readonly CORP_DELETE: ApiConfig = {
    url: '/api/v1/corporation/delete',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-IN-001 問い合わせ登録
  static readonly INQUIRY_REGIST: ApiConfig = {
    url: '/api/v1/inquiry',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    withoutAuth: true,
  };

  // IF-CG-001 ログアウト
  static readonly LOGOUT: ApiConfig = {
    url: '/api/v1/logout',
  };

  // IF-SV-001 利用契約サービス一覧取得
  static readonly CONTRACT_SERVICE_LIST: ApiConfig = {
    url: '/api/v1/usage/contract/services',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SV-002 契約情報一覧取得
  static readonly CONTRACT_LIST: ApiConfig = {
    url: '/api/v1/usage/contract/info/list',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SV-004 利用権限詳細取得
  static readonly CONTRACT_AUTH_DETAIL: ApiConfig = {
    url: '/api/v1/usage/contract/auth/detail',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SV-007 利用権限更新
  static readonly CONTRACT_AUTH_UPDATE: ApiConfig = {
    url: '/api/v1/usage/contract/auth/update',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SV-006 サービス利用ユーザー登録
  static readonly CONTRACT_SERVICES_USER_REGIST: ApiConfig = {
    url: '/api/v1/usage/contract/services/user/regist',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SV-018 ご利用中のサービス一覧取得
  static readonly CONTRACT_SERVICE_USE_LIST: ApiConfig = {
    url: '/api/v1/usage/contract/service/list',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SD-004 サービス利用画面(/service/request/screen/{requestUserId}/{serviceUsageContractId})
  static readonly SERVICE_USE: ApiConfig = {
    url: '/api/v1/service/request/screen/',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // 法人利用契約APIキー情報取得IF-SV-027
  static readonly APIKEY_LIST: ApiConfig = {
    url: '/api/v1/usage/contract/apikey/list/',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-SP-008 各種ファイルPDFダウンロード(/support/file/download)
  static readonly FILE_DOWNLOAD: ApiConfig = {
    url: '/api/v1/support/file/download',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // 登録済みサービス情報取得IF-SV-031
  static readonly REGIST_SERVICE_LIST: ApiConfig = {
    url: '/api/v1/usage/contract/regist/service/list',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // サービス解除IF-CO-050
  static readonly LINK_DETACH: ApiConfig = {
    url: '/api/v1/corporation/link/detach',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };

  // IF-CO-053 ユーザ区分チェック
  static readonly CORP_USER_TYPE_CHECK: ApiConfig = {
    url: '/api/v1/corporation/users/typecheck',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  };
}

export interface PageConfig {
  path: string;
}

export class PageInfo {
  static readonly ENTORY: PageConfig = {
    path: '/',
  };

  static readonly TOP: PageConfig = {
    path: '/top',
  };

  static readonly ERROR_404: PageConfig = {
    path: '/404error',
  };

  static readonly ERROR_500: PageConfig = {
    path: '/500error',
  };

  static readonly REGISTRATION: PageConfig = {
    path: '/registrations',
  };

  static readonly TERM_APPLY: PageConfig = {
    path: `/terms/apply`,
  };

  static readonly TERM_FEDERATE: PageConfig = {
    path: `/terms/federate`,
  };

  static readonly CORP_APPLY_BASE: PageConfig = {
    path: '/corp/apply',
  };

  static readonly CORP_APPLY_TERMS: PageConfig = {
    path: `${PageInfo.CORP_APPLY_BASE.path}/terms`,
  };

  static readonly CORP_APPLY_INPUT: PageConfig = {
    path: `${PageInfo.CORP_APPLY_BASE.path}/input`,
  };

  static readonly CORP_APPLY_CONFIRM: PageConfig = {
    path: `${PageInfo.CORP_APPLY_BASE.path}/confirm`,
  };

  static readonly CORP_APPLY_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_APPLY_BASE.path}/complete`,
  };

  // 企業情報変更 BASE
  static readonly CORP_INFO_EDIT_BASE: PageConfig = {
    path: '/corp/info/edit',
  };

  // 企業情報変更 入力
  static readonly CORP_INFO_EDIT_INPUT: PageConfig = {
    path: `${PageInfo.CORP_INFO_EDIT_BASE.path}/input`,
  };

  // 企業情報変更 確認
  static readonly CORP_INFO_EDIT_CONFIRM: PageConfig = {
    path: `${PageInfo.CORP_INFO_EDIT_BASE.path}/confirm`,
  };

  // 企業情報変更 完了
  static readonly CORP_INFO_EDIT_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_INFO_EDIT_BASE.path}/complete`,
  };

  // 企業情報申請取消 完了
  static readonly CORP_INFO_EDIT_CANCEL_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_INFO_EDIT_BASE.path}/cancel/complete`,
  };

  static readonly CORP_FEDERATE_BASE: PageConfig = {
    path: '/corp/id/federate',
  };

  static readonly CORP_RESIGN_BASE: PageConfig = {
    path: '/corporation/resign',
  };

  static readonly CORP_FEDERATE_INPUT: PageConfig = {
    path: `${PageInfo.CORP_FEDERATE_BASE.path}/input`,
  };

  static readonly CORP_FEDERATE_CONFIRM: PageConfig = {
    path: `${PageInfo.CORP_FEDERATE_BASE.path}/confirm`,
  };

  static readonly CORP_FEDERATE_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_FEDERATE_BASE.path}/complete`,
  };

  static readonly CORP_RESIGN_CONFIRM: PageConfig = {
    path: `${PageInfo.CORP_RESIGN_BASE.path}/confirm`,
  };

  static readonly CORP_RESIGN_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_RESIGN_BASE.path}/complete`,
  };

  static readonly CORP_USERS_INVITE_BASE: PageConfig = {
    path: '/corp/users/invite',
  };

  static readonly CORP_USERS_INVITE_INPUT: PageConfig = {
    path: `${PageInfo.CORP_USERS_INVITE_BASE.path}/input`,
  };

  static readonly CORP_USERS_INVITE_CONFIRM: PageConfig = {
    path: `${PageInfo.CORP_USERS_INVITE_BASE.path}/confirm`,
  };

  static readonly CORP_USERS_INVITE_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_USERS_INVITE_BASE.path}/complete`,
  };

  static readonly ACCOUNT_MANAGE: PageConfig = {
    path: '/account/manage',
  };

  static readonly CORP_USERS_INVITE_MAIL_ERROR: PageConfig = {
    path: '/corp/users/mail/error',
  };

  static readonly INQUIRY_BASE: PageConfig = {
    path: '/inquiry',
  };

  static readonly INQUIRY_INPUT: PageConfig = {
    path: `${PageInfo.INQUIRY_BASE.path}/input`,
  };

  static readonly INQUIRY_CONFIRM: PageConfig = {
    path: `${PageInfo.INQUIRY_BASE.path}/confirm`,
  };

  static readonly INQUIRY_COMPLETE: PageConfig = {
    path: `${PageInfo.INQUIRY_BASE.path}/complete`,
  };

  static readonly INQUIRY_BRANCH: PageConfig = {
    path: `${PageInfo.INQUIRY_BASE.path}/inquirybranch`,
  };

  static readonly CONTRACT_USERS_MANAGE: PageConfig = {
    path: '/contract/users/manage',
  };

  static readonly CONTRACT_USERS_MANAGE_PARAM: PageConfig = {
    path: `${PageInfo.CONTRACT_USERS_MANAGE.path}/:contractNo/:contractMenuId/:contractCourseId`,
  };

  static readonly CONTRACT_USERS_INVITE_BASE: PageConfig = {
    path: '/contract/users/invite',
  };

  static readonly CONTRACT_USERS_INVITE_INPUT: PageConfig = {
    path: `${PageInfo.CONTRACT_USERS_INVITE_BASE.path}/input`,
  };

  static readonly CONTRACT_USERS_INVITE_INPUT_PARAM: PageConfig = {
    path: `${PageInfo.CONTRACT_USERS_INVITE_INPUT.path}/:contractNo/:contractMenuId/:contractCourseId`,
  };

  static readonly CONTRACT_USERS_INVITE_CONFIRM: PageConfig = {
    path: `${PageInfo.CONTRACT_USERS_INVITE_BASE.path}/confirm`,
  };

  static readonly CONTRACT_USERS_INVITE_COMPLETE: PageConfig = {
    path: `${PageInfo.CONTRACT_USERS_INVITE_BASE.path}/complete`,
  };

  static readonly CONTRACT_TERMS: PageConfig = {
    path: `/contract/terms`,
  };

  static readonly CONTRACT: PageConfig = {
    path: '/contract',
  };

  static readonly MYPAGE: PageConfig = {
    path: '/mypage',
  };

  static readonly SERVICE_USE: PageConfig = {
    path: '/service/use',
  };

  static readonly SERVICE_DETAIL: PageConfig = {
    path: '/service/detail/',
  };

  static readonly CONTRACT_DETAIL_BASE: PageConfig = {
    path: '/contract/detail/',
  };

  static readonly CONTRACT_DETAIL_PARAM: PageConfig = {
    path: `${PageInfo.CONTRACT_DETAIL_BASE.path}:contractNo`,
  };

  static readonly CONTRACT_APIKEY: PageConfig = {
    path: `${PageInfo.CONTRACT_DETAIL_BASE.path}apikey/`,
  };

  static readonly CONTRACT_APIKEY_PARAM: PageConfig = {
    path: `${PageInfo.CONTRACT_APIKEY.path}:contractNo/:scimId`,
  };

  static readonly NOTICE_BASE: PageConfig = {
    path: '/notice',
  };

  static readonly NOTICE_PARAM: PageConfig = {
    path: `${PageInfo.NOTICE_BASE.path}/:notificationId`,
  };

  // TODO 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる
  // static readonly FAQ: PageConfig = {
  //   path: '/faq',
  // };

  // マイページ-アカウント管理 利用権限移譲 BASE
  static readonly AUTH_TRANSFER_BASE: PageConfig = {
    path: '/account/manage/auth/transfer',
  };

  // マイページ-アカウント管理 利用権限移譲 入力
  static readonly AUTH_TRANSFER_INPUT: PageConfig = {
    path: `${PageInfo.AUTH_TRANSFER_BASE.path}/input`,
  };

  // マイページ-アカウント管理 利用権限移譲 入力
  static readonly AUTH_TRANSFER_INPUT_PARAM: PageConfig = {
    path: `${PageInfo.AUTH_TRANSFER_INPUT.path}/:scimId`,
  };

  // マイページ-アカウント管理 利用権限移譲 確認
  static readonly AUTH_TRANSFER_CONFIRM: PageConfig = {
    path: `${PageInfo.AUTH_TRANSFER_BASE.path}/confirm`,
  };

  // マイページ-アカウント管理 利用権限移譲 確認
  static readonly AUTH_TRANSFER_CONFIRM_PARAM: PageConfig = {
    path: `${PageInfo.AUTH_TRANSFER_CONFIRM.path}/:scimId`,
  };

  // マイページ-アカウント管理 利用権限移譲 完了
  static readonly AUTH_TRANSFER_COMPLETE: PageConfig = {
    path: `${PageInfo.AUTH_TRANSFER_BASE.path}/complete`,
  };

  // マイページ-アカウント管理 利用権限
  static readonly ACCOUNT_AUTH: PageConfig = {
    path: `/account/manage/auth`,
  };

  // マイページ-アカウント管理 利用権限
  static readonly ACCOUNT_AUTH_PARAM: PageConfig = {
    path: `${PageInfo.ACCOUNT_AUTH.path}/:scimId`,
  };

  // 企業管理者変更
  static readonly CORP_ADMIN_CHANGE_BASE: PageConfig = {
    path: '/corp/users/admin',
  };

  // 企業管理者変更入力
  static readonly CORP_ADMIN_CHANGE_INPUT: PageConfig = {
    path: `${PageInfo.CORP_ADMIN_CHANGE_BASE.path}/input`,
  };

  // 企業管理者変更確認
  static readonly CORP_ADMIN_CHANGE_CONFIRM: PageConfig = {
    path: `${PageInfo.CORP_ADMIN_CHANGE_BASE.path}/confirm`,
  };

  // 企業管理者変更完了
  static readonly CORP_ADMIN_CHANGE_COMPLETE: PageConfig = {
    path: `${PageInfo.CORP_ADMIN_CHANGE_BASE.path}/complete`,
  };

  // サービス紹介
  static readonly INTRODUCE: PageConfig = {
    path: '/introduce',
  };

  // ご利用ガイド
  static readonly HOW_TO_GUIDE: PageConfig = {
    path: '/howtoguide',
  };

  // サイト利用について
  static readonly ATTRIBUTE: PageConfig = {
    path: '/attribute',
  };

  // Toyota Biz Centerについて
  static readonly ABOUT: PageConfig = {
    path: '/about',
  };

  // サービス解除
  static readonly LINK_DETACH: PageConfig = {
    path: '/link/detach',
  };

  // サービス解除完了
  static readonly LINK_DETACH_COMPLETE: PageConfig = {
    path: `${PageInfo.LINK_DETACH.path}/complete`,
  };
}

// メッセージ
export class MessageConst {
  static readonly NOACTION =
    'エラーが発生しました。時間をおいてから再度お試しください。';

  static readonly GLOBAL_INOUT =
    '入力内容にエラーがあります。ご確認のうえ再度「確認」ボタンを押してください。';

  static readonly ERROR_REFRESH =
    'エラーが発生しました。画面を更新のうえ再度お試しください。';

  static readonly AUTH_ERROR =
    '認証処理にてエラーが発生しました。再度ログインをしてください。';

  static readonly NO_SELECTED_USER_ERROR =
    '一覧から企業ユーザーを選択してください。';

  // リテラル定義（改行も実体として扱われる）リテラル定義しない場合は\nを記載すれよい
  static readonly INFO_ID_REQUEST_WARN = `すでに貴社もしくは他社の企業ユーザーとして登録されているため、
  この申請は無効になりました。`;
}

// MessageConstの文字列を改行表示する場合に使用する関数
export const messageConv = (message: string): any =>
  message.split('\n').map((line) => (
    <>
      {line}
      <br />
    </>
  ));

// エラーコード
export class ErrorCodeContst {
  static readonly NOACTION = 'NOACTION';

  static readonly VALIDATION = 'SDPW91001--';
}

// 項目名
export class ItemNameContst {
  static readonly MAILADDRESS = 'メールアドレス';
}

// ご利用ガイド Toyota Biz
export class GuidePathBizCenter {
  // Center Toyota Biz Centerご利用ガイド
  static readonly GUIDE_BIZ_USE: string = process.env.REACT_APP_GUIDE_BIZ_PATH_1
    ? process.env.REACT_APP_GUIDE_BIZ_PATH_1
    : '';
}

// ご利用ガイド サービス
export class GuidePathService {
  // 「トヨタドライブ統計／ドライブデータダッシュボードの使い方」
  static readonly GUIDE_SERVICE_DRIVE: string = process.env
    .REACT_APP_GUIDE_SERVICE_PATH_1
    ? process.env.REACT_APP_GUIDE_SERVICE_PATH_1
    : '';
}

// サービス全般
export class GeneralServices {
  // サービス画像ファイルパス
  static readonly SERVICE_IMAGE_FILE_PATH: string = process.env.REACT_APP_SERVICE_IMAGE_FILE_PATH
    ? process.env.REACT_APP_SERVICE_IMAGE_FILE_PATH
    : '';
}

export interface FaqInfo {
  key: number;
  label: string | undefined;
  url: string | undefined;
}

const getFaqListLabel = (i: number): string | undefined => {
  const label = process.env[`REACT_APP_FAQ_LIST_LABEL_${i}`];
  if (label) {
    return label;
  }
  return undefined;
};
const getFaqListUrl = (i: number): string | undefined => {
  const url = process.env[`REACT_APP_FAQ_LIST_URL_${i}`];
  if (url) {
    return url;
  }
  return undefined;
};

export const getFaqUrl = (i: number): string | undefined => {
  const url = process.env[`REACT_APP_FAQ_URL_${i}`];
  if (url) {
    return url;
  }
  return undefined;
};
export const getFaqInfoList = (): FaqInfo[] => {
  const FaqList: FaqInfo[] = [];
  for (let i = 1; getFaqListLabel(i) && getFaqListUrl(i); i += 1) {
    const key = i;
    const label = getFaqListLabel(i);
    const url = getFaqListUrl(i);
    const Faq: FaqInfo = { key, label, url };
    FaqList.push(Faq);
  }
  return FaqList;
};

// 改行コード
export type LineSeparator = '\r' | '\n' | '\r\n';
