import { FC, memo, useContext } from 'react';
import Template from 'app/views/components/template';
import { Container, Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { ButtonForward, ButtonBack } from 'app/views/components/block/button';
import { PageInfo } from 'app/utils/constants';
import { ColorInfo } from 'app/style/theme';
import useOneId from 'app/hooks/oneId';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';
import { AuthContext } from 'app/hooks/context/auth';

const useStyles = makeStyles(() => ({
  list: {
    // listStyle: 'disc',
    '& > li > ul > li': {
      listStyleType: 'none',
    },
    '& > li': {
      listStyleType: 'disc',
      paddingLeft: '12px',
      marginLeft: '20px',
      '& > *': {
        lineHeight: '35px',
      },
      '& span': {
        paddingLeft: 15,
      },
    },
  },
  textLink: {
    color: ColorInfo.blue02,
    fontSize: '1.5rem',
    textDecoration: 'none',
    '&:visited': {
      color: ColorInfo.blue02,
      textDecoration: 'none',
    },
    '&:hover, &:active': {
      textDecoration: 'underline',
    },
  },
}));

// 説明
const DescriptionInfo: FC = memo(() => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ margin: '90px 0px 0px 0px' }}>
        <Typography>
          「Toyota Biz
          Centerのご利用にあたって」をご確認いただき、「次へ」ボタンを押してください。
        </Typography>
      </Box>

      <Typography variant="h3" component="h3">
        Toyota Biz Centerのご利用にあたって
      </Typography>
      <Box sx={{ margin: '-10px 0px 5px 20px' }}>
        <ul className={classes.list}>
          <li>
            <Typography>
              トヨタ自動車（以下、当社といいます）の基準に沿った審査を経て企業登録が行われます。
            </Typography>
          </li>
          <li>
            <Typography>
              企業登録は、1企業につき1登録のため、事前に取得有無をご確認ください。
            </Typography>
          </li>
          <li>
            <Typography>
              審査には「登記事項証明書（現在事項証明書）」が必要となりますので、予めご用意をお願いいたします。
            </Typography>
          </li>
          <li>
            <Typography>
              登録申請時にご入力いただいた代表電話番号宛に、当社担当者よりお電話を差し上げる場合があります。
            </Typography>
          </li>
          <li>
            <Typography>
              登録申請を行ったユーザーが「企業管理者」として登録されます。
            </Typography>
            <Typography style={{ lineHeight: '20px' }}>
              -
              企業管理者の主な役割：企業情報の変更申請／各種サービスのお申し込み／企業ユーザー管理
              <span>など</span>
            </Typography>
          </li>
          <li>
            <Typography>「企業管理者」はいつでも変更可能です。</Typography>
          </li>
          <Typography style={{ paddingTop: '15px' }}>
            ※企業登録に費用は発生いたしません。各サービス利用時に費用が発生する場合があります。
          </Typography>
        </ul>
      </Box>
    </>
  );
});

const CompanyApplyTermsPage: FC = () => {
  const history = useHistory();
  const { eventApplyTerms } = useOneId();

  // 戻るアクション
  const handleBack = () => {
    history.push(PageInfo.TOP.path);
  };
  const { authInfo } = useContext(AuthContext);

  // 次処理
  const handleNext = () => {
    // GAタグ:企業申請（同意）-次へボタンクリック
    onGaClick('B-010-002-001');
    // DMPビーコンタグ：企業申請（同意）-次へボタンクリック
    OnDmpClick('B-010-002-001', authInfo?.scimId);
    // 企業申請（規約）
    eventApplyTerms();
  };

  return (
    <Template title="企業登録">
      <Container>
        <DescriptionInfo />

        <Box
          style={{
            marginTop: '80px',
            marginBottom: '60px',
            borderTop: `1px solid ${ColorInfo.gray04}`,
          }}
        />

        <Grid
          container
          direction="row"
          style={{ margin: '80px -12px 105px' }}
          alignItems="center"
        >
          <Grid item xs={3}>
            <ButtonBack onClick={handleBack}>キャンセル</ButtonBack>
          </Grid>

          <Grid container item xs={6} alignItems="center">
            <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
              次へ
            </ButtonForward>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Container>
    </Template>
  );
};

export default CompanyApplyTermsPage;
