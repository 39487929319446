import { FC, useCallback, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Template from 'app/views/components/template';
import { ButtonBack } from 'app/views/components/block/button';
import { useContractDetail } from 'app/hooks/contract/detail/index';
import { ColorInfo } from 'app/style/theme';
import { PageInfo } from 'app/utils/constants';
import { GlobalErrorMessage } from 'app/views/components/block/message';

const useStyles = makeStyles({
  tableRow: {
    height: 80,
    '& > *': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderLeft: `1px solid ${ColorInfo.gray03}`,
    },
    '& td:first-child': {
      background: ColorInfo.gray05,
      borderLeft: 'none',
    },
  },
  // メニューリンク関連
  textLink: {
    fontSize: '1.5rem',
    color: ColorInfo.blue02,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  titleCol: {
    width: '35%',
  },
});

const ContractDetailPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  interface params {
    contractNo: string;
  }
  const params = useParams<params>();
  // 契約内容照会－契約詳細業務カスタムHooks
  const { contractDetail, globalMessage } = useContractDetail(
    params.contractNo
  );

  const handleBack = () => {
    history.go(-1);
  };
  // APIで取得した契約番号をglobalのwindowに保存しておく
  useEffect(() => {
    if (contractDetail?.contractNo)
      window.sdpContractNo = contractDetail?.contractNo;
  }, [contractDetail.contractNo]);

  // レンダリング
  return (
    <Template title="契約内容照会－契約情報">
      <Typography variant="h3" style={{ marginTop: '90px' }}>
        契約情報
      </Typography>

      {globalMessage && <GlobalErrorMessage message={globalMessage} />}
      <Table aria-label="simple table">
        {!globalMessage &&
          contractDetail?.dataList.map((param) => (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.titleCol}>
                <Typography>{param.item}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{param.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
      </Table>
      <Typography>
        <Box
          sx={{ marginTop: '30px', marginBottom: '15px', textAlign: 'center' }}
        >
          契約変更、解約に関しては、
          <Link className={classes.textLink} to={PageInfo.INQUIRY_BRANCH.path}>
            お問い合わせフォーム
          </Link>
          からご連絡ください。
        </Box>
      </Typography>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: '-30px' }}
      >
        <Grid item xs={3}>
          <ButtonBack onClick={() => handleBack()}>戻る</ButtonBack>
        </Grid>
        <Grid item xs={9} />
      </Grid>
    </Template>
  );
};
export default ContractDetailPage;
