import { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ColorInfo } from 'app/style/theme';
import Help from '@material-ui/icons/Help';

const useDataGridStyles = makeStyles({
  root: {
    marginTop: 15,
  },
  columnHeader: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    backgroundColor: ColorInfo.gray05,
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  cell: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  row: {
    '&.MuiDataGrid-withBorder': {
      border: 'none',
    },
  },
  sortIcon: {
    display: 'inline-block',
    width: 24,
    padding: '6px 0',
    margin: '0 0 0 10px',
    fontSize: '1rem',
    color: ColorInfo.white01,
    borderRadius: 4,
    backgroundColor: '#1b8ac2',
    transition: 'all 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ColorInfo.blue03,
    },
  },
  helpIcon: {
    margin: '0 0 0 5px',
    fontSize: '20px',
    color: ColorInfo.gray01,
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    '&:hover': {
      color: ColorInfo.blue03,
    },
  },
});

// 昇順アイコン
const SortAscendingIcon = memo(() => {
  const classes = useDataGridStyles();
  return <span className={classes.sortIcon}>▲</span>;
});

// 降順アイコン
const SortDescendingIcon = memo(() => {
  const classes = useDataGridStyles();
  return <span className={classes.sortIcon}>▼</span>;
});

// ソート無しアイコン
const SortUnsortedIcon = memo(() => {
  const classes = useDataGridStyles();
  return <span className={classes.sortIcon}>▼</span>;
});

// ヘルプアイコン
const HelpIcon = memo(() => {
  const classes = useDataGridStyles();
  return <Help className={classes.helpIcon} />;
});

export { SortAscendingIcon, SortDescendingIcon, SortUnsortedIcon, HelpIcon };
