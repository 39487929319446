import { FC, memo, useState } from 'react';
import Template from 'app/views/components/template';
import {
  Container,
  FormControl,
  Grid,
  Typography,
  RadioGroup,
  Box,
  makeStyles,
} from '@material-ui/core';
import StepperContent from 'app/views/components/block/stepper';
import { useInquiry } from 'app/hooks/inquiry';
import TextInput from 'app/views/components/atom/text';
import { InputRowGridPadding } from 'app/views/components/block/layout';
import InputTelephone from 'app/views/components/block/telephone';
import InputCheck from 'app/views/components/block/checkbox';
import InputName from 'app/views/components/block/name';
import { ButtonBack, ButtonForward } from 'app/views/components/block/button';
import { OuterLinkInfo, PageInfo } from 'app/utils/constants';
import { useHistory } from 'react-router-dom';
import { OuterBlankLink } from 'app/views/components/atom/link';
import { ColorInfo } from 'app/style/theme';
import InputRadio from 'app/views/components/block/radio';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import useValidate from 'app/hooks/validate';

// スタイル
const useStyles = makeStyles(() => ({
  confirm: {
    marginLeft: '-50px',
  },
  caution: {
    '& li': {
      padding: '10px 0px 10px 10px',
      listStyleType: 'disc',
      lineHeight: '30px',
      '&:first-child': {
        paddingTop: 0,
      },
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
}));

interface InquiryInterface {
  inquiryHook: ReturnType<typeof useInquiry>;
  valid: ReturnType<typeof useValidate>;
}

// 必須コンポーネント
const InquiryRequiredRed: FC = memo(() => (
  <>
    <span style={{ color: ColorInfo.red01 }}>＊</span>
  </>
));

// 問い合わせ画面ヘッダコンポーネント
const InquiryPageHeader: FC<InquiryInterface> = memo(({ inquiryHook }) => {
  const { status, globalMessage } = inquiryHook;
  const classes = useStyles();
  return (
    <>
      <StepperContent StepNumber={status} />
      <Container>
        <Grid
          container
          justifyContent="center"
          direction="column"
          style={{ margin: 0, width: '100%' }}
        >
          <Grid>
            <Box sx={{ marginTop: '-25px' }}>
              {/* 問い合わせ入力画面 */}
              {status === 0 && (
                <>
                  <div
                    style={{
                      marginTop: '78px',
                      marginBottom: globalMessage ? '77px' : '',
                    }}
                  >
                    <GlobalErrorMessage message={globalMessage} />
                  </div>
                  <Typography>
                    「注意事項」と「個人情報の取扱いについて」に同意のうえ、以下のフォームに必須事項を入力し、「確認」ボタンを押してください。
                  </Typography>
                  <Typography variant="h3">注意事項</Typography>
                  <Box
                    sx={{
                      marginLeft: '45px',
                    }}
                  >
                    <ul className={classes.caution}>
                      <Typography>
                        <li>
                          当社からの回答に時間がかかる場合があります。予めご了承ください。
                        </li>
                        <li>
                          当社の回答の一部または全部を他者に転送・開示することはお控えください。
                        </li>
                      </Typography>
                    </ul>
                  </Box>
                  <Typography variant="h3">個人情報の取扱いについて</Typography>
                  <Box
                    sx={{
                      marginLeft: '45px',
                    }}
                  >
                    <ul className={classes.caution}>
                      <Typography>
                        <li>
                          当社は、お客さまからのお問い合わせに関し、ご提供いただいた情報（個人情報含む）について
                          <OuterBlankLink
                            href={OuterLinkInfo.PRIVACY_URL.path}
                            text="個人情報に関する基本方針"
                            // GAタグ:個人情報に関する基本方針押下
                            gaTagId="B-020-001-001"
                          />
                          に従い、適正かつ安全に管理・運用することに努めます。
                        </li>
                        <li>
                          「サービスに関するお問い合わせ」でご入力いただいた個人情報は以下の目的で使用いたします。
                          <br />
                          - お問い合わせへの回答
                          <br />
                          - アクセス解析
                          <br />- Toyota Biz Centerの営業活動
                        </li>
                      </Typography>
                    </ul>
                  </Box>
                </>
              )}
              {/* 問い合わせ確認画面 */}
              {status === 1 && (
                <>
                  <Typography>
                    <Box
                      sx={{
                        marginTop: '78px',
                        textAlign: 'center',
                      }}
                    >
                      ご入力内容をご確認のうえ、「送信」ボタンを押してください。
                    </Box>
                  </Typography>
                </>
              )}
              {/* 問い合わせ完了画面 */}
              {status === 2 && (
                <>
                  <Typography>
                    <Box
                      sx={{
                        marginTop: '78px',
                        marginBottom: '30px',
                        textAlign: 'center',
                      }}
                    >
                      Toyota Biz Centerへのお問い合わせありがとうございます。
                    </Box>
                    <Box
                      sx={{
                        marginTop: '5px',
                        marginBottom: '30px',
                        textAlign: 'center',
                      }}
                    >
                      3営業日以内に、担当者よりご連絡いたしますので今しばらくお待ちください。
                    </Box>
                    <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                      なお、ご入力いただいたメールアドレス宛に受付完了メールを送信しております。
                    </Box>
                    <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                      受付完了メールが届かない場合、処理が正常に行われていない可能性がありますので、
                    </Box>
                    <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                      大変お手数ですが、再度お問い合わせのお手続きをお願いいたします。
                    </Box>
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
});
// 問い合わせ内容コンポーネント
const InquiryText: FC<InquiryInterface> = memo(({ inquiryHook, valid }) => {
  const [showStartTime, setShowStartTime] = useState<boolean>(false);
  const {
    status,
    inquiry,
    inquiryMessage,
    checkedItems,
    registServiceList,
    handleInputEvent,
    handleRadioEvent,
    setInquiryMessage,
  } = inquiryHook;
  // 項目の活性（入力）、非活性（入力以外）
  const isDisabled = (): boolean => {
    switch (status) {
      case 0:
        return false;
      default:
        return true;
    }
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        style={{ margin: 0 }}
      >
        <Typography variant="h3">
          サービス選択{!isDisabled() && <InquiryRequiredRed />}
        </Typography>
      </Grid>
      <Box sx={{ marginLeft: '20px' }}>
        {!isDisabled() && (
          <>
            <RadioGroup
              aria-label="service"
              name="radio-buttons-group"
              value={inquiry?.serviceId}
              onChange={handleRadioEvent}
            >
              <Box sx={{ marginTop: '10px' }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <>
                    {checkedItems.length > 0 &&
                      registServiceList &&
                      registServiceList?.dataList &&
                      registServiceList?.dataList.map((service, index) => (
                        <>
                          <Grid item xs={6}>
                            <InputRadio
                              label={service?.contractServiceName}
                              name="serviceId"
                              value={service?.contractServiceId}
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              handleCheckEvent={() => {}}
                            />
                          </Grid>
                        </>
                      ))}
                  </>
                </Grid>
              </Box>
            </RadioGroup>
            <Typography color="error">{inquiryMessage.serviceId}</Typography>
          </>
        )}
        {isDisabled() && (
          <>
            <Box
              sx={{
                marginLeft: '10px',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              <Grid container direction="row" justifyContent="flex-start">
                <Typography>
                  {registServiceList &&
                    registServiceList.dataList &&
                    registServiceList.dataList.find(
                      (service) =>
                        service.contractServiceId === inquiry.serviceId
                    )?.contractServiceName}
                </Typography>
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        style={{
          margin: isDisabled() ? '20px 0px 0px 0px' : '10px 0px 0px 0px',
        }}
      >
        <Typography variant="h3">
          内容{!isDisabled() && <InquiryRequiredRed />}
        </Typography>
      </Grid>
      <Box sx={{ marginLeft: '20px' }}>
        {!isDisabled() && (
          <>
            <RadioGroup
              aria-label="category"
              name="radio-buttons-group"
              value={inquiry?.category}
              onChange={handleRadioEvent}
            >
              <Box sx={{ marginTop: '15px' }}>
                <Grid container direction="row" justifyContent="flex-start">
                  <Grid item xs={6}>
                    <InputRadio
                      name="category"
                      value="サービスについて知りたい（概要・料金など）"
                      label="サービスについて知りたい（概要・料金など）"
                      handleCheckEvent={() => {
                        setShowStartTime(false);
                        inquiry.serviceStartTime = '';
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputRadio
                      name="category"
                      value="サービスを契約したい"
                      label="サービスを契約したい"
                      handleCheckEvent={() => {
                        setShowStartTime(true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputRadio
                      name="category"
                      value="サービスを変更・解約したい"
                      label="サービスを変更・解約したい"
                      handleCheckEvent={() => {
                        setShowStartTime(false);
                        inquiry.serviceStartTime = '';
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputRadio
                      name="category"
                      value="その他"
                      label="その他"
                      handleCheckEvent={() => {
                        setShowStartTime(false);
                        inquiry.serviceStartTime = '';
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </RadioGroup>
            <Typography color="error">{inquiryMessage.category}</Typography>
          </>
        )}
        {isDisabled() && (
          <>
            <Box sx={{ marginLeft: '10px', marginTop: '20px' }}>
              <Grid container direction="row" justifyContent="flex-start">
                <Typography>{inquiry.category}</Typography>
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ marginLeft: '20px', marginTop: '30px' }}>
        {!isDisabled() && (
          <>
            <TextInput
              name="inquiryText"
              value={inquiry?.inquiryText}
              placeholder="詳細をご記入ください。"
              handleInputEvent={handleInputEvent}
              rows={10}
              multiline
              fullWidth
              onBlur={(event) => {
                const message = valid.inquiryTextValidation({
                  value: event.target.value,
                });
                setInquiryMessage({ ...inquiryMessage, inquiryText: message });
              }}
              style={{ marginTop: '20px' }}
              error={!!inquiryMessage.inquiryText}
            />
          </>
        )}
        <Typography color="error">{inquiryMessage.inquiryText}</Typography>
      </Box>
      <Box sx={{ marginLeft: '5px' }}>
        {isDisabled() && (
          <TextInput
            name="inquiryText"
            value={inquiry?.inquiryText}
            minRows={1}
            maxRows={10}
            multiline
            fullWidth
            disabled
          />
        )}
      </Box>

      {showStartTime && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            style={{ margin: 0 }}
          >
            <Typography variant="h3">
              ご利用開始時期{!isDisabled() && <InquiryRequiredRed />}
            </Typography>
          </Grid>
          {!isDisabled() && (
            <Box sx={{ marginLeft: '20px' }}>
              <RadioGroup
                aria-label="category"
                name="radio-buttons-group"
                value={inquiry?.serviceStartTime}
                onChange={handleRadioEvent}
              >
                <Box sx={{ marginTop: '10px' }}>
                  <Grid container direction="row" justifyContent="flex-start">
                    <Grid item xs={6}>
                      <InputRadio
                        name="serviceStartTime"
                        value="3か月以内"
                        label="3か月以内"
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        handleCheckEvent={() => {}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputRadio
                        name="serviceStartTime"
                        value="半年以内"
                        label="半年以内"
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        handleCheckEvent={() => {}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputRadio
                        name="serviceStartTime"
                        value="1年以内"
                        label="1年以内"
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        handleCheckEvent={() => {}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputRadio
                        name="serviceStartTime"
                        value="それ以降／未定"
                        label="それ以降／未定"
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        handleCheckEvent={() => {}}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </RadioGroup>
              <Typography color="error">
                {inquiryMessage.serviceStartTime}
              </Typography>
            </Box>
          )}
        </>
      )}
      {isDisabled() && (
        <>
          <Box sx={{ marginLeft: '30px', marginTop: '20px' }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              style={{ marginBottom: 12 }}
            >
              <Typography>{inquiry.serviceStartTime}</Typography>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
});

// お客様情報コンポーネント
const InquiryCustomerInfo: FC<InquiryInterface> = memo(
  ({ inquiryHook, valid }) => {
    const {
      status,
      inquiry,
      inquiryMessage,
      editableFields,
      handleInputEvent,
      setInquiryMessage,
    } = inquiryHook;

    const isDisabled = (isEditable: boolean): boolean =>
      status === 0 ? isEditable : true;
    const classes = useStyles();

    return (
      <>
        <Typography
          variant="h3"
          style={{
            marginTop: '80px',
          }}
        >
          お客さま情報
        </Typography>
        <Box sx={{ margin: '5px 0px 5px 20px' }}>
          <InputRowGridPadding
            label="氏名（漢字）"
            required={isDisabled(editableFields.name)}
          >
            <div
              className={isDisabled(editableFields.name) ? classes.confirm : ''}
            >
              <InputName
                name1="familyName"
                value1={inquiry.familyName}
                placeholder1="姓"
                name2="givenName"
                value2={inquiry.givenName}
                placeholder2="名"
                handleInputEvent={handleInputEvent}
                disabled={isDisabled(editableFields.name)}
                handleFocusEvent={() => {
                  const message = valid.nameValidation(
                    inquiry.familyName,
                    inquiry.givenName
                  );
                  setInquiryMessage({ ...inquiryMessage, name: message });
                }}
                error={!!inquiryMessage.name}
              />
              <Typography color="error">{inquiryMessage.name}</Typography>
            </div>
          </InputRowGridPadding>
        </Box>
        <Box sx={{ margin: '5px 0px 5px 20px' }}>
          <InputRowGridPadding
            label="氏名（カナ）"
            required={isDisabled(editableFields.name_kana)}
          >
            <div
              className={
                isDisabled(editableFields.name_kana) ? classes.confirm : ''
              }
            >
              <InputName
                name1="familyNameKana"
                value1={inquiry.familyNameKana}
                placeholder1="セイ"
                name2="givenNameKana"
                value2={inquiry.givenNameKana}
                placeholder2="メイ"
                handleInputEvent={handleInputEvent}
                disabled={isDisabled(editableFields.name_kana)}
                handleFocusEvent={() => {
                  const message = valid.nameKanaValidation(
                    inquiry.familyNameKana,
                    inquiry.givenNameKana
                  );
                  setInquiryMessage({ ...inquiryMessage, nameKana: message });
                }}
                error={!!inquiryMessage.nameKana}
              />
              <Typography color="error">{inquiryMessage.nameKana}</Typography>
            </div>
          </InputRowGridPadding>
        </Box>
        <Box sx={{ margin: '5px 0px 5px 20px' }}>
          <InputRowGridPadding
            label="企業名"
            required={isDisabled(editableFields.corporationName)}
          >
            <TextInput
              className={
                isDisabled(editableFields.corporationName)
                  ? classes.confirm
                  : ''
              }
              name="corporationName"
              value={inquiry.corporationName}
              placeholder="例）トヨタ自動車株式会社"
              handleInputEvent={handleInputEvent}
              fullWidth
              disabled={isDisabled(editableFields.corporationName)}
              onBlur={(event) => {
                const message = valid.corporationNameValidation({
                  value: event.target.value,
                });
                setInquiryMessage({
                  ...inquiryMessage,
                  corporationName: message,
                });
              }}
              error={!!inquiryMessage.corporationName}
            />
            <Typography color="error">
              {inquiryMessage.corporationName}
            </Typography>
          </InputRowGridPadding>
        </Box>
        <Box sx={{ margin: '5px 0px 5px 20px' }}>
          <InputRowGridPadding
            label="部署名"
            required={isDisabled(editableFields.departmentName)}
          >
            <TextInput
              className={
                isDisabled(editableFields.corporationName)
                  ? classes.confirm
                  : ''
              }
              name="departmentName"
              placeholder="例）営業部（ない場合は「なし」とご入力ください。）"
              value={inquiry.departmentName}
              handleInputEvent={handleInputEvent}
              fullWidth
              disabled={isDisabled(editableFields.departmentName)}
              onBlur={(event) => {
                const message = valid.departmentNameValidation({
                  value: event.target.value,
                });
                setInquiryMessage({
                  ...inquiryMessage,
                  departmentName: message,
                });
              }}
              error={!!inquiryMessage.departmentName}
            />
            <Typography color="error">
              {inquiryMessage.departmentName}
            </Typography>
          </InputRowGridPadding>
        </Box>
        <Box sx={{ margin: '5px 0px 5px 20px' }}>
          <InputRowGridPadding
            label="電話番号"
            required={isDisabled(editableFields.telephoneNumber)}
          >
            <div
              className={
                isDisabled(editableFields.telephoneNumber)
                  ? classes.confirm
                  : ''
              }
            >
              <InputTelephone
                name1="corporationTelephoneNumber1"
                value1={inquiry.corporationTelephoneNumber1}
                name2="corporationTelephoneNumber2"
                value2={inquiry.corporationTelephoneNumber2}
                name3="corporationTelephoneNumber3"
                value3={inquiry.corporationTelephoneNumber3}
                handleInputEvent={handleInputEvent}
                disabled={isDisabled(editableFields.telephoneNumber)}
                handleFocusEvent={() => {
                  const message = valid.corporationTelephoneNumberValidation(
                    inquiry.corporationTelephoneNumber1,
                    inquiry.corporationTelephoneNumber2,
                    inquiry.corporationTelephoneNumber3
                  );
                  setInquiryMessage({
                    ...inquiryMessage,
                    corporationTelephoneNumber: message[0],
                    corporationTelephoneNumber1: message[1],
                    corporationTelephoneNumber2: message[2],
                    corporationTelephoneNumber3: message[3],
                  });
                }}
                error={
                  !!inquiryMessage?.corporationTelephoneNumber ||
                  !!inquiryMessage?.corporationTelephoneNumber1 ||
                  !!inquiryMessage?.corporationTelephoneNumber2 ||
                  !!inquiryMessage?.corporationTelephoneNumber3
                }
              />

              <Typography color="error">
                {inquiryMessage?.corporationTelephoneNumber}
              </Typography>
              <Typography color="error">
                {inquiryMessage?.corporationTelephoneNumber1}
              </Typography>
              <Typography color="error">
                {inquiryMessage?.corporationTelephoneNumber2}
              </Typography>
              <Typography color="error">
                {inquiryMessage?.corporationTelephoneNumber3}
              </Typography>
            </div>
          </InputRowGridPadding>
        </Box>
        <Box sx={{ margin: '5px 0px 5px 20px' }}>
          <InputRowGridPadding
            label="メールアドレス"
            required={isDisabled(editableFields.emailAddress)}
          >
            <TextInput
              className={
                isDisabled(editableFields.emailAddress) ? classes.confirm : ''
              }
              name="emailAddress"
              placeholder="例）abc@toyota.jp"
              value={inquiry.emailAddress}
              handleInputEvent={handleInputEvent}
              fullWidth
              required
              disabled={isDisabled(editableFields.emailAddress)}
              onBlur={(event) => {
                const message = valid.mailValidation(
                  {
                    value: event.target.value,
                  },
                  'メールアドレス'
                );
                setInquiryMessage({
                  ...inquiryMessage,
                  emailAddress: message,
                });
              }}
              error={!!inquiryMessage.emailAddress}
            />
            <Typography color="error">{inquiryMessage.emailAddress}</Typography>
          </InputRowGridPadding>
        </Box>
        {!editableFields.emailAddressConfirm && (
          <Box sx={{ margin: '5px 0px 5px 20px' }}>
            <InputRowGridPadding
              label="メールアドレス（確認用）"
              required={isDisabled(editableFields.emailAddressConfirm)}
            >
              <TextInput
                className={
                  isDisabled(editableFields.emailAddressConfirm)
                    ? classes.confirm
                    : ''
                }
                name="emailAddressConfirm"
                placeholder="例）abc@toyota.jp"
                value={inquiry.emailAddressConfirm}
                handleInputEvent={handleInputEvent}
                fullWidth
                required
                disabled={isDisabled(editableFields.emailAddressConfirm)}
                onBlur={(event) => {
                  const message = valid.mailValidation(
                    {
                      value: event.target.value,
                    },
                    'メールアドレス（確認用）'
                  );
                  setInquiryMessage({
                    ...inquiryMessage,
                    emailAddressConfirm: message,
                  });
                }}
                error={!!inquiryMessage.emailAddressConfirm}
              />
              <Typography color="error">
                {inquiryMessage.emailAddressConfirm}
              </Typography>
            </InputRowGridPadding>
          </Box>
        )}
      </>
    );
  }
);

// ボタンコンポーネント
const InquiryButton: FC<InquiryInterface> = memo(({ inquiryHook }) => {
  const { status, handleBack, handleNext } = inquiryHook;
  // 個人情報チェック状態
  const [termCheck, setTermCheck] = useState(false);
  const handleTermCheck = () => {
    setTermCheck(!termCheck);
  };
  const history = useHistory();
  return (
    <>
      <Box sx={{ margin: '80px 0px 105px', width: '1000px' }}>
        {status === 0 && (
          <>
            <Grid>
              <Box
                style={{
                  marginTop: '80px',
                  borderTop: `1px solid ${ColorInfo.gray04}`,
                }}
              />
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ margin: '75px auto auto' }}
            >
              <Box>
                <InputCheck
                  label="「注意事項」と「個人情報の取扱い」に同意する"
                  name="termCheck"
                  value={termCheck}
                  handleCheckEvent={() => handleTermCheck()}
                  required
                />
              </Box>
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ margin: '80px -12px 105px' }}
            >
              <Box>
                <FormControl component="fieldset">
                  <ButtonForward onClick={handleNext} disabled={!termCheck}>
                    確認
                  </ButtonForward>
                </FormControl>
              </Box>
            </Grid>
          </>
        )}
        {status === 1 && (
          <>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ margin: '0px -12px' }}
              >
                <Grid item xs={3}>
                  <ButtonBack onClick={handleBack}>戻る</ButtonBack>
                </Grid>

                <Grid item xs={6} style={{ padding: '0px 12px' }}>
                  <ButtonForward
                    onClick={handleNext}
                    style={{ margin: 'auto' }}
                  >
                    送信
                  </ButtonForward>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </Box>
          </>
        )}
        {status === 2 && (
          <>
            <Box>
              <Grid container direction="row" style={{ margin: '0px -12px' }}>
                <Grid>
                  <ButtonForward
                    onClick={() => history.push(PageInfo.TOP.path)}
                  >
                    トップへ
                  </ButtonForward>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
});

// 問い合わせ画面コンポーネント
const InquiryPage: FC = memo(() => {
  const inquiryHook = useInquiry();
  const { status } = inquiryHook; // ValidateHooks
  const valid = useValidate();
  return (
    <>
      <Template title="サービスに関するお問い合わせ">
        <InquiryPageHeader inquiryHook={inquiryHook} valid={valid} />
        {status !== 2 && (
          <>
            <InquiryText inquiryHook={inquiryHook} valid={valid} />
            <InquiryCustomerInfo inquiryHook={inquiryHook} valid={valid} />
          </>
        )}
        <InquiryButton inquiryHook={inquiryHook} valid={valid} />
      </Template>
    </>
  );
});

export default InquiryPage;
