import { createHash } from 'crypto';
import { useHistory, useLocation } from 'react-router-dom';

declare global {
  interface Window {
    _dmpBeaconProperties: any;
    DMP: any;
  }
}
const SCRIPT_NAME = 'dmpScript';
const SCRIPT_HEAD = 'dmpHead';
const SCRIPT_BODY = 'dmpBody';

const OnDmpClick = (contentsID: string, scimId: string | undefined | null) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (window.DMP && window.DMP.getRequest) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.DMP.getRequest('click', {
      document_title: document.title,
      additional_fields: {
        // ユニークID（hash化後のscimID）
        field01: scimId
          ? createHash('sha256').update(scimId).digest('hex')
          : '',
        // クライアントID
        field02: process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
          ? process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
          : '',
        field03: new Date().getTime(),
        field04: contentsID,
        sub_type: 'oneid_siteclickcount',
      },
    });
  }
};

// window._dmpBeaconPropertiesにscimIdとclient_idを設定し、DMPビーコンの発火時に通知する
/** DMPビーコンタグ読み込み用関数 */
const initializeDMP = (scimId: string): void => {
  const hashedScimId: string = scimId
    ? createHash('sha256').update(scimId).digest('hex')
    : '';
  const clientId: string = process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
    ? process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
    : '';
  // 上記の方法で発火されない場合のためにわざとコメントアウトで残しておく
  // idでscriptの要素を取得する
  const beforeScript = document.getElementById(SCRIPT_NAME);
  // scimIdを埋め込んだ要素を作成
  const afterScript = document.createElement('script');
  afterScript.id = SCRIPT_NAME;
  afterScript.type = 'text/javascript';
  afterScript.text = `if(!window._dmpBeaconProperties) window._dmpBeaconProperties = {};
  if(!window._dmpBeaconProperties.optParam) window._dmpBeaconProperties.optParam = {};
  window._dmpBeaconProperties.optParam.document_title = document.title;
  window._dmpBeaconProperties.optParam.additional_fields={
    "field01": "${hashedScimId}","field02":"${clientId}"};`;
  // 要素を置き換える
  beforeScript?.replaceWith(afterScript);

  // idでscriptの要素を取得する
  const beforeHead = document.getElementById(SCRIPT_HEAD);
  const afterHead = document.createElement('script');
  afterHead.id = SCRIPT_HEAD;
  afterHead.type = 'text/javascript';
  afterHead.src = '//onetag.tws.toyota.jp/onetag/head';
  afterHead.onload = () => {
    // index.htmlでheadタグを読み込んでいても配列が存在しないため、発火はされない想定
    // 共通タグ用の配列に値をセットする
    // eslint-disable-next-line no-underscore-dangle
    if (!window._dmpBeaconProperties) window._dmpBeaconProperties = {};
    // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-member-access
    if (!window._dmpBeaconProperties.optParam) {
      // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-member-access
      window._dmpBeaconProperties.optParam = {};
    } // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-member-access
    window._dmpBeaconProperties.optParam.document_title = document.title;
    // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-unsafe-member-access
    window._dmpBeaconProperties.optParam.additional_fields = {
      field01: hashedScimId,
      field02: clientId,
    };
    // idでscriptの要素を取得する
    const beforeBody = document.getElementById(SCRIPT_BODY);
    const afterBody = document.createElement('script');
    afterBody.id = SCRIPT_BODY;
    afterBody.type = 'text/javascript';
    afterBody.src = '//onetag.tws.toyota.jp/onetag/body';
    // 要素を置き換える
    beforeBody?.replaceWith(afterBody);
  };
  // 要素を置き換える
  beforeHead?.replaceWith(afterHead);

  const teimerId = setInterval(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (window.DMP && window.DMP.getRequest) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      window.DMP.getRequest('click', {
        document_title: document.title,
        additional_fields: {
          field01: hashedScimId,
          field02: process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
            ? process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
            : '',
          field03: new Date().getTime(),
          field04: `${window.location.pathname}${window.location.search}`,
          sub_type: 'virtual_pv',
        },
      });
      clearInterval(teimerId);
    }
  }, 500);
};
// 外部リンククリック時のDMPビーコンタグ
const OnOutDmpClick = (url: string, scimId: string | undefined | null) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (window.DMP && window.DMP.getRequest) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.DMP.getRequest(
      'click',
      {
        document_title: document.title,
        additional_fields: {
          // ユニークID（hash化後のscimID）
          field01: scimId
            ? createHash('sha256').update(scimId).digest('hex')
            : '',
          // クライアントID
          field02: process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
            ? process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
            : '',
          // 現在時刻（UNIXタイムスタンプ）を設定
          field03: new Date().getTime(),
          sub_type: 'extlink_click',
        },
      },
      {
        toUrl: url,
      }
    );
  }
};
export { OnDmpClick, OnOutDmpClick, initializeDMP };
