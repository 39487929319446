import { useState, useEffect, useContext } from 'react';

import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { SelectDetailResponse } from 'app/service/customer/corporation/detail';
import { AuthContext } from 'app/hooks/context/auth';

// 企業管理
export interface AdminCorpMng {
  corporationId: string;
  corporationName: string;
  corporationNameKana: string;
  departmentName: string;
  departmentNameKana: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  corpAddressPostalCode1: string;
  corpAddressPostalCode2: string;
  corpAddressRegion: string;
  corpAddressLocality: string;
  corpAddressStreet: string;
  corpAddressBuilding: string;
  administratorName: string;
  administratorEmail: string;
  status: string;
  statusName: string;
  applyDate: string;
  note: string;
  corporationAddress: string;
}

/**
 * 登録中のファイル情報
 */
export interface FileInfo {
  seq?: number | null;
  filename?: string | null;
  filepath?: string | null;
  uploadDate?: string | null;
}

/**
 * 企業管理業務カスタムHooks
 */
export const useAdminCorpMng = () => {
  // 企業管理情報
  const [adminCorpMng, setAdminCorpMng] =
    // 初期値設定
    useState<AdminCorpMng>({
      corporationId: '',
      corporationName: '',
      corporationNameKana: '',
      departmentName: '',
      departmentNameKana: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      corpAddressPostalCode1: '',
      corpAddressPostalCode2: '',
      corpAddressRegion: '',
      corpAddressLocality: '',
      corpAddressStreet: '',
      corpAddressBuilding: '',
      administratorName: '',
      administratorEmail: '',
      status: '',
      statusName: '',
      applyDate: '',
      note: '',
      corporationAddress: '',
    });

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 企業管理詳細API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<SelectDetailResponse>();
  // 登記謄本ファイルアップロード情報
  const [uploadFileList, setUploadFileList] = useState<FileInfo[]>([]);

  // 企業情報取得API
  const adminCorpMngDetailAPI = (corporationId: string) => {
    // API呼出し
    const ADMIN_CORP_MNG_DETAIL: ApiConfig = {
      url: `/api/v1/corporation/admin/${corporationId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    if (corporationId) {
      void callApi(ADMIN_CORP_MNG_DETAIL, 'GET', null);
    }
  };

  useEffect(() => {
    // 初期処理
    setAdminCorpMng({
      corporationId: '',
      corporationName: '',
      corporationNameKana: '',
      departmentName: '',
      departmentNameKana: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      corpAddressPostalCode1: '',
      corpAddressPostalCode2: '',
      corpAddressRegion: '',
      corpAddressLocality: '',
      corpAddressStreet: '',
      corpAddressBuilding: '',
      administratorName: '',
      administratorEmail: '',
      status: '',
      statusName: '',
      applyDate: '',
      note: '',
      corporationAddress: '',
    });

    // 企業情報取得API呼び出し
    adminCorpMngDetailAPI(authInfo?.corporationId || '');
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // 住所情報結合
      const addressArray = [
        response.corpAddressRegion || '',
        response.corpAddressLocality || '',
        response.corpAddressStreet || '',
        response.corpAddressBuilding || '',
      ];
      // 招待中アカウント一覧設定
      setAdminCorpMng({
        corporationId: response.corporationId || '',
        corporationName: response.corporationName || '',
        corporationNameKana: response.corporationNameKana || '',
        departmentName: response.departmentName || '',
        departmentNameKana: response.departmentNameKana || '',
        corporationTelephoneNumber1: response.corporationTelephoneNumber1 || '',
        corporationTelephoneNumber2: response.corporationTelephoneNumber2 || '',
        corporationTelephoneNumber3: response.corporationTelephoneNumber3 || '',
        corpAddressPostalCode1: response.corpAddressPostalCode1 || '',
        corpAddressPostalCode2: response.corpAddressPostalCode2 || '',
        corpAddressRegion: response.corpAddressRegion || '',
        corpAddressLocality: response.corpAddressLocality || '',
        corpAddressStreet: response.corpAddressStreet || '',
        corpAddressBuilding: response.corpAddressBuilding || '',
        administratorName: response.administratorName || '',
        administratorEmail: response.administratorEmail || '',
        status: response.status || '',
        statusName: response.statusName || '',
        applyDate: response.applyDate || '',
        note: response.note || '',
        corporationAddress: addressArray.join(' '),
      });
      // 登録済みのファイル名を設定する
      const uploadFiles2 = (response.registrationCopyFileList ?? [])
        .filter((file) => file.seq != null && file.filename != null)
        .map((file) => ({
          seq: file.seq,
          filename: file.filename,
          uploadDate: file.uploadDate == null ? undefined : file.uploadDate,
          filepath: file.filepath == null ? undefined : file.filepath,
        }));
      setUploadFileList(uploadFiles2);
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return { adminCorpMng, uploadFileList };
};
export default useAdminCorpMng;
