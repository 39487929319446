/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ChangeEvent,
  FC,
  memo,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import Template from 'app/views/components/template';
import {
  Box,
  Typography,
  Grid,
  Toolbar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Link,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonForward,
  SubButton,
  ButtonLowForward,
  ButtonLowBack,
} from 'app/views/components/block/button';
import Button from '@material-ui/core/Button';
import TextInput from 'app/views/components/atom/text';
import Select from 'app/views/components/block/select';
import {
  PageInfo,
  UserTypeConst,
  ApiConfig,
  ApiConst,
  MessageConst,
} from 'app/utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import useAdminCorpMng, { AdminCorpMng } from 'app/hooks/corporation/detail';
import InputPostalCode from 'app/views/components/block/postalcode';
import InputTelephone from 'app/views/components/block/telephone';
import { AuthContext } from 'app/hooks/context/auth';
import useApprovalRequest from 'app/hooks/corporation/users/approval/request';
import useInviteDelete from 'app/hooks/corporation/users/invite/delete';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import useApprovalAccount, {
  ApprovalAccount,
} from 'app/hooks/corporation/users/approval/account';
import useCorporationUsers, {
  CorporationUsers,
} from 'app/hooks/corporation/users/list';
import useInviteAccount, {
  InviteAccount,
} from 'app/hooks/corporation/users/invite/account';
import { ColorInfo } from 'app/style/theme';
import DepartmentNameDialog from 'app/views/components/modules/dialog/department';
import ConfirmDialog from 'app/views/components/block/dialog';
import UserAuthUpdateDialog from 'app/views/components/modules/dialog/user/auth';
import useApi from 'app/hooks/api';
import {
  ContractInfoListRequest,
  ContractItemListResponse,
} from 'app/service/customer/contract';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SortUnsortedIcon,
} from 'app/views/components/block/icon';
import onGaClick from 'app/utils/gaclick';
import useSortGrid from 'app/hooks/sortgrid';
import { OnDmpClick } from 'app/utils/dmp';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import useFileDownload from 'app/hooks/corporation/download';

const useStyles = makeStyles({
  titleTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '80px 0 20px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '80px 0 30px',
  },
  accTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 18,
  },
  customerRec: {
    padding: '0px 16px',
    '& > div': {
      float: 'left',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  textInputPerson: {
    width: '480px',
  },
  useAuthTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  mainText: {
    marginLeft: '22px',
    marginBottom: '25px',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: '5px',
    width: '500px',
  },
  tableRow: {
    height: 80,
    '& > *': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderLeft: `1px solid ${ColorInfo.gray03}`,
    },
    '& > *:first-child': {
      borderLeft: 'none',
    },
    '& td:first-child': {
      background: ColorInfo.gray05,
      width: '320px',
    },
  },
  tableRowList: {
    height: 70,
    '& > *': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableHead: {
    paddingLeft: 14,
  },
  tableCell: {
    padding: '0px 16px',
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 40,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child > div': {
      width: 450,
    },
    '& > *:not(:first-child)': {
      marginLeft: 20,
      wordBreak: 'keep-all',
    },
  },
  listTableHead: {
    padding: '20px 16px',
    lineHeight: 1,
    verticalAlign: 'middle',
    backgroundColor: ColorInfo.gray05,
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  listTableBody: {
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  listBtn: {
    width: 120,
    height: 42,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    cursor: 'pointer',
    fontSize: '1.5rem',
    lineHeight: 1.4,
    letterSpacing: 2,
    color: ColorInfo.white01,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    border: `2px solid ${ColorInfo.blue02}`,
    borderRadius: 4,
    backgroundColor: ColorInfo.blue02,
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  listBtnCancel: {
    color: ColorInfo.white01,
    borderColor: ColorInfo.gray02,
    backgroundColor: ColorInfo.gray02,
    '&:hover': {
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  listInvite: {
    color: ColorInfo.blue02,
  },
  listBtnTransfer: {
    color: ColorInfo.blue02,
    backgroundColor: ColorInfo.white01,
    '&:hover': {
      color: ColorInfo.white01,
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  listPager: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& button:first-child': {
      padding: '10px 0px 10px 65px',
      '&::before': {
        width: 30,
      },
    },
    '& button:last-child': {
      marginLeft: 60,
      padding: '10px 65px 10px 0px',
      '&::before': {
        width: 30,
      },
    },
    '& > *.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  detachLink: {
    marginTop: '10px',
    fontSize: '15px',
  },
  withdrawalLink: {
    marginTop: '10px',
    fontSize: '15px',
  },
  CorpTableCellHead: {
    paddingLeft: 30,
    backgroundColor: ColorInfo.gray05,
    borderRight: `1px solid ${ColorInfo.gray03}`,
  },
  CorpTableCellValue1: {
    padding: '0px',
    borderBottom: 'none',
  },
  CorpTableCellValue2: {
    textAlign: 'left',
    width: '680px',
    padding: '0px 0px 0px 30px',
    height: 80,
    borderTop: `1px solid ${ColorInfo.white01}`,
  },
  // テキストリンク
  textLink: {
    fontSize: '1.5rem',
    color: ColorInfo.blue01,
    backgroundColor: ColorInfo.white01,
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  textSpan: {
    fontSize: '1.5rem',
    color: ColorInfo.blue01,
    backgroundColor: ColorInfo.white01,
    textDecoration: 'none',
  },
});

const useDataGridStyles = makeStyles({
  root: {
    marginTop: 15,
    border: 'none',
    borderTop: `1px solid ${ColorInfo.gray03}`,
    borderRadius: '0',
    '& *.MuiDataGrid-iconButtonContainer > *.MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  columnHeader: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    backgroundColor: ColorInfo.gray05,
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& *.MuiTouchRipple-root': {
      left: 10,
    },
  },
  cell: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  row: {
    height: 70,
    '&.MuiDataGrid-withBorder': {
      border: 'none',
    },
    '&.MuiDataGrid-row:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const options = [
  {
    value: '30',
    label: '30件',
  },
  {
    value: '50',
    label: '50件',
  },
  {
    value: '100',
    label: '100件',
  },
];
// インタフェース
interface AccountManageInterface {
  useApproval: UseApprovalAccountInterface;
  useCorporation: UseCorporationUsersInterface;
  useInvite: UseInviteAcountInterface;
}

interface CorporationAccountInterface {
  useCorporation: UseCorporationUsersInterface;
}

interface AdminCorpMngInterface {
  useAdminCorp: UseAdminCorpMngInterface;
  setGlobalMessage: (globalMessage: string) => void;
}

interface UseAdminCorpMngInterface {
  adminCorpMng: AdminCorpMng;
}

interface CustomerInfoInterface {
  administratorEmail: string;
  setGlobalMessage: (globalMessage: string) => void;
  globalMessage: string;
}
interface InvitingAccountInterface {
  useApproval: UseApprovalAccountInterface;
  useInvite: UseInviteAcountInterface;
}

// 承認リクエストアカウント一覧Hookインタフェース
interface UseApprovalAccountInterface {
  isPageBackDisabled: boolean;
  isPageNextDisabled: boolean;
  handleInputEvent: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSearchBack: () => void;
  handleSearchNext: () => void;
  approvalAccountListAPI: (
    userId: string,
    pageIndex?: number,
    pageSize?: string
  ) => void;
  handleApprovalUpdate: () => void;
  approvalAccount: ApprovalAccount;
  apiCallStatus: number;
}

// 法人ユーザ一覧Hookインタフェース
interface UseCorporationUsersInterface {
  isPageBackDisabled: boolean;
  isPageNextDisabled: boolean;
  handleInputEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchBack: () => void;
  handleSearchNext: () => void;
  handleSearch: () => void;
  handleSearchDelete: () => void;
  corporationUsersListAPI: (
    corporationId: string,
    pageIndex?: number,
    pageSize?: string,
    searchValue?: string | undefined
  ) => void;
  handleCorporationUpdate: () => void;
  handleSearchSort: (
    sortItem: 'email' | 'name' | 'departmentName',
    sortType: 'asc' | 'desc'
  ) => void;
  corporationUsers: CorporationUsers;
  apiCallStatus: number;
}

// 招待中アカウント一覧Hookインタフェース
interface UseInviteAcountInterface {
  isPageBackDisabled: boolean;
  isPageNextDisabled: boolean;
  handleInputEvent: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSearchBack: () => void;
  handleSearchNext: () => void;
  inviteAccountListAPI: (
    userId: string,
    pageIndex?: number,
    pageSize?: string
  ) => void;
  handleInviteUpdate: () => void;
  inviteAccount: InviteAccount;
  apiCallStatus: number;
}

// お客様情報コンポーネント
const CustomerInfo: FC<CustomerInfoInterface> = memo(
  ({ administratorEmail, setGlobalMessage, globalMessage }) => {
    const classes = useStyles();
    const { authInfo } = useContext(AuthContext);
    // 解除Dialog
    const [detachDialog, setDetachDialog] = useState(false);

    // IF-SV-002契約情報一覧取得API エラーメッセージ
    const [message, setMessage] = useState('');

    // 利用中のサービス情報保持有無（解除ボタンの活性条件に用いる）
    const [haveUsageService, setHaveUsageService] = useState(false);

    // IF-SV-002契約情報一覧取得API hooks
    const {
      response: futureUsageServicesResponse,
      error: futureUsageServicesResponseError,
      callApi: futureUsageServiceListCallApi,
    } = useApi<ContractItemListResponse>();
    // IF-SV-002契約情報一覧取得API hooks
    const {
      response: nowUsageServicesResponse,
      error: nowUsageServicesResponseError,
      callApi: nowUsageServiceListCallApi,
    } = useApi<ContractItemListResponse>();

    // // IF-SV-002契約情報一覧取得API hooks
    // const {
    //   response: usageServicesResponse,
    //   error: usageServicesResponseError,
    //   callApi: usageServiceListCallApi,
    // } = useApi<ContractItemListResponse>();

    // IF-SV-002契約情報一覧取得call
    const usageServicesAPI = async (usageFlag: '0' | '1') => {
      const corporationId = authInfo?.corporationId || '';
      const scimId = authInfo?.scimId || '';
      // 契約内容呼出し
      const CONTRACT_INFO: ApiConfig = {
        url: `${ApiConst.CONTRACT_LIST.url}/${corporationId}/${scimId}`,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      };
      if (usageFlag === '0') {
        await futureUsageServiceListCallApi(
          CONTRACT_INFO,
          'GET',
          usageFlag ? ({ usageFlag } as ContractInfoListRequest) : null
        );
      } else if (usageFlag === '1') {
        await nowUsageServiceListCallApi(
          CONTRACT_INFO,
          'GET',
          usageFlag ? ({ usageFlag } as ContractInfoListRequest) : null
        );
      }
    };

    // 解除ボタンイベント
    const detachEvent = () => {
      // IF-SV-002契約情報一覧取得API呼出し
      setMessage('');
      void usageServicesAPI('0');
      void usageServicesAPI('1');
    };
    // IF-SV-002契約情報一覧取得call 結果
    useEffect(() => {
      if (futureUsageServicesResponse) {
        if (futureUsageServicesResponse.contractServiceList?.length > 0) {
          // 利用サービス有
          setHaveUsageService(true);
        } else {
          // 利用サービス無い場合にはfalseのまま
        }
      } else if (futureUsageServicesResponseError) {
        // エラー表示
        setMessage(futureUsageServicesResponseError.message);
      }
    }, [futureUsageServicesResponse, futureUsageServicesResponseError]);

    // IF-SV-002契約情報一覧取得call 結果
    useEffect(() => {
      if (nowUsageServicesResponse) {
        if (nowUsageServicesResponse.contractServiceList?.length > 0) {
          // 利用サービス有
          setHaveUsageService(true);
        } else {
          // 利用サービス無い場合にはfalseのまま
        }
      } else if (nowUsageServicesResponseError) {
        // エラー表示
        setMessage(nowUsageServicesResponseError.message);
      }
    }, [nowUsageServicesResponse, nowUsageServicesResponseError]);

    // IF-SV-002契約情報一覧取得call 結果
    useEffect(() => {
      if (
        (nowUsageServicesResponse || nowUsageServicesResponseError) &&
        (nowUsageServicesResponse || nowUsageServicesResponseError)
      ) {
        setDetachDialog(true);
      }
    }, [nowUsageServicesResponse, nowUsageServicesResponseError]);
    const handleAccountInfoChange = () => {
      if (
        !process.env.REACT_APP_ACCOUNT_INFO_URL ||
        !process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID ||
        !process.env.REACT_APP_ACCOUNT_INFO_CFILTER
      )
        return;

      const url = new URL(process.env.REACT_APP_ACCOUNT_INFO_URL);
      const params = new URLSearchParams({
        client_id: process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID,
        cfilter: process.env.REACT_APP_ACCOUNT_INFO_CFILTER,
      });
      url.search = params.toString();
      window.open(url.href, '_blank');
    };

    const handleDepartmentNameChange = () => {
      onGaClick('B-030-001-005');
      // DMPビーコンタグ：ユーザ区分「法人」以外用の変更ボタン押下
      OnDmpClick('B-030-001-005', authInfo?.scimId);
      setDepartmentNameDialog(true);
    };

    // ユーザ区分「法人」用の変更ボタン（SCIM更新）
    const UpdateBtnCorp = (gaTagId?: string) => {
      if (authInfo?.userTypeScim === UserTypeConst.CORP_LIGHT)
        return (
          <SubButton
            onClick={() => {
              // 新認証基盤に遷移するためGAタグを埋め込む
              if (gaTagId) {
                onGaClick(gaTagId);
                // DMPビーコンタグ：ユーザ区分「法人」用の変更ボタン（SCIM更新）押下
                OnDmpClick(gaTagId, authInfo?.scimId);
              }
              handleAccountInfoChange();
            }}
          >
            変更
          </SubButton>
        );
      return <></>;
    };
    // ユーザ区分「法人」以外の変更ボタン（SCIM更新）
    const UpdateBtnPersonScim = (gaTagId?: string) => {
      if (authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT)
        return (
          <SubButton
            onClick={() => {
              // 新認証基盤に遷移するためGAタグを埋め込む
              if (gaTagId) {
                onGaClick(gaTagId);
                // DMPビーコンタグ：ユーザ区分「法人」以外の変更ボタン（SCIM更新）押下
                OnDmpClick(gaTagId, authInfo?.scimId);
              }
              handleAccountInfoChange();
            }}
          >
            変更
          </SubButton>
        );
      return <></>;
    };

    // ユーザ区分「法人」以外用の変更ボタン（自TBL）
    const [departmentNameDialog, setDepartmentNameDialog] = useState(false);

    // 部署名変更APIで失敗した際のエラーメッセージ
    useEffect(() => {
      if (!departmentNameDialog && globalMessage) {
        window.scrollTo(0, 0);
      }
    }, [departmentNameDialog, globalMessage]);
    const UpdateBtnPerson = () => {
      if (authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT)
        return (
          <>
            <SubButton onClick={handleDepartmentNameChange}>変更</SubButton>
            <DepartmentNameDialog
              open={departmentNameDialog}
              setOpen={setDepartmentNameDialog}
              departmentName={
                authInfo?.departmentName ? authInfo?.departmentName : ''
              }
              setGlobalMessage={setGlobalMessage}
            />
          </>
        );
      return <></>;
    };

    // 電話番号フォーマット
    const telephoneInfo = `${authInfo?.telephoneNumber1 || ''} - ${
      authInfo?.telephoneNumber2 || ''
    } - ${authInfo?.telephoneNumber3 || ''}`;
    // 解除モーダル閉じるイベント
    const afterClose = () => {
      // 何もしない関数
    };

    // スクロール位置
    interface Scroll {
      scrollTo: string | null;
    }
    // 初期表示のスクロール位置指定
    const { state } = useLocation<Scroll>();
    useEffect(() => {
      // マイページ通知エリアから遷移
      if (state?.scrollTo === 'idRequest') {
        const element = document.getElementById('corpAccount');
        const top = Number(element?.getBoundingClientRect().top);
        window.scrollTo(0, top);
      }
    }, [state]);

    return (
      <>
        <Box className={classes.titleTop}>
          <Typography variant="h3" style={{ margin: '0 auto 0 0' }}>
            ログインユーザー
          </Typography>
          {/** GAタグ:お客さま情報-変更押下 */}
          <>{UpdateBtnCorp('B-030-001-001')}</>
        </Box>
        <Table aria-label="simple table">
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>
                メールアドレス
              </Typography>
            </TableCell>
            <TableCell className={classes.customerRec}>
              <div>
                <TextInput
                  name="email"
                  value={authInfo?.email}
                  disabled
                  className={
                    authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT
                      ? classes.textInputPerson
                      : ''
                  }
                  fullWidth
                  multiline
                />
                {/** GAタグ:お客さま情報-メールアドレス-変更押下 */}
                <>{UpdateBtnPersonScim('B-030-001-002')}</>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>氏名</Typography>
            </TableCell>
            <TableCell className={classes.customerRec}>
              <div>
                <TextInput
                  name="name"
                  value={authInfo?.name}
                  disabled
                  className={
                    authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT
                      ? classes.textInputPerson
                      : ''
                  }
                  fullWidth
                  multiline
                />
                <div>
                  {/** GAタグ:お客さま情報-氏名-変更押下 */}
                  <>{UpdateBtnPersonScim('B-030-001-003')}</>
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>
                氏名（カナ）
              </Typography>
            </TableCell>
            <TableCell className={classes.customerRec}>
              <div>
                <TextInput
                  name="nameKana"
                  value={authInfo?.nameKana}
                  disabled
                  className={
                    authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT
                      ? classes.textInputPerson
                      : ''
                  }
                  fullWidth
                  multiline
                />
                {/** GAタグ:お客さま情報-氏名（カナ）-変更押下 */}
                <>{UpdateBtnPersonScim('B-030-001-004')}</>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>部署名</Typography>
            </TableCell>
            <TableCell className={classes.customerRec}>
              <div>
                <TextInput
                  name="departmentName"
                  value={authInfo?.departmentName}
                  disabled
                  className={
                    authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT
                      ? classes.textInputPerson
                      : ''
                  }
                  fullWidth
                  multiline
                />
                <UpdateBtnPerson />
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>電話番号</Typography>
            </TableCell>
            <TableCell className={classes.customerRec}>
              <div>
                <TextInput
                  name="telephone"
                  value={telephoneInfo}
                  disabled
                  className={
                    authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT
                      ? classes.textInputPerson
                      : ''
                  }
                  fullWidth
                />
                {/** GAタグ:お客さま情報-電話番号-変更押下 */}
                <>{UpdateBtnPersonScim('B-030-001-007')}</>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>パスワード</Typography>
            </TableCell>
            <TableCell className={classes.customerRec}>
              <div>
                <TextInput
                  name="password"
                  value="パスワードは表示されません"
                  disabled
                  className={
                    authInfo?.userTypeScim !== UserTypeConst.CORP_LIGHT
                      ? classes.textInputPerson
                      : ''
                  }
                  fullWidth
                />
                {/** GAタグ:お客さま情報-パスワード変更押下 */}
                <>{UpdateBtnPersonScim('B-030-001-008')}</>
              </div>
            </TableCell>
          </TableRow>
        </Table>
        {administratorEmail && authInfo?.email !== administratorEmail && (
          <div className={classes.detachLink}>
            <Link
              href="/"
              onClick={(event) => {
                event.preventDefault();
                // GAタグ：お客さま情報-企業連携を解除するリンク押下
                onGaClick('B-030-001-601');
                // DMPビーコンタグ：お客さま情報-企業連携を解除するリンク押下
                OnDmpClick('B-030-001-601', authInfo?.scimId);
                void detachEvent();
              }}
            >
              <span>企業ユーザー登録の解除はこちら</span>
            </Link>
          </div>
        )}
        <UserAuthUpdateDialog
          scimId={authInfo?.scimId || ''}
          email={authInfo?.email || ''}
          name={authInfo?.name || ''}
          authorityApproval={authInfo?.authorityApproval || ''}
          authorityAssignment={authInfo?.authorityAssignment || ''}
          operatorId={authInfo?.scimId || ''}
          operatorAuthorityAssignment={authInfo?.authorityAssignment || ''}
          haveUsageService={haveUsageService}
          open={detachDialog}
          setOpen={setDetachDialog}
          error={message}
          afterClose={afterClose}
          detachOnly
        />
      </>
    );
  }
);

// 利用権限コンポーネント
const UsageAuthority: FC = memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const { authInfo } = useContext(AuthContext);
  const scimId = authInfo?.scimId || '';

  const event = () => {
    history.push(`${PageInfo.ACCOUNT_AUTH.path}/${scimId}`);
  };
  return (
    <>
      <Box className={classes.useAuthTitle}>
        <div>
          <Typography variant="h3">利用権限</Typography>
          <Typography variant="body1" className={classes.mainText2}>
            ログインユーザーの管理権限やサービス利用権限をご確認いただけます。
          </Typography>
        </div>
        <ButtonForward onClick={event}>利用権限一覧</ButtonForward>
      </Box>
    </>
  );
});

// ID連携一覧選択行情報
interface SelectRequestProps {
  msg: string;
  applyId: number;
  name: string;
}

// ID連携コンポーネント
const IdFederate: FC<AccountManageInterface> = memo(
  ({ useApproval, useCorporation, useInvite }) => {
    // 承認リクエストアカウント一覧Hook
    const {
      isPageBackDisabled,
      isPageNextDisabled,
      handleInputEvent,
      handleSearchBack,
      handleSearchNext,
      handleApprovalUpdate,
      approvalAccount,
      apiCallStatus,
    } = useApproval;
    const { rows } = approvalAccount;
    // 法人ユーザ一覧Hook
    const { handleCorporationUpdate } = useCorporation;
    // 招待中アカウント一覧Hook
    const { handleInviteUpdate } = useInvite;
    // 承認リクエストHook
    const { response, error, approvalRequest, deleteRequest } =
      useApprovalRequest();
    const classes = useStyles();
    const { authInfo } = useContext(AuthContext);

    // 再描画処理
    useEffect(() => {
      if (response) {
        handleApprovalUpdate();
        handleCorporationUpdate();
        handleInviteUpdate();
      } else if (error) {
        // リクエスト承認（許可、拒否）にて400エラー発生時
        // ダイアログ表示
        setInfomationDialog(true);
        // アカウント関連情報を再表示
        handleApprovalUpdate();
        handleCorporationUpdate();
        handleInviteUpdate();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error]);

    // 情報 Dialog
    const [infomationDialog, setInfomationDialog] = useState(false);

    // ボタン押下時の選択行情報
    const [selectRequest, setSelectRequest] = useState<
      SelectRequestProps | undefined
    >(undefined);

    // 許可イベント Dialog
    const [approvalDialog, setApprovalDialog] = useState(false);

    // 拒否イベント Dialog
    const [deleteDialog, setDeleteDialog] = useState(false);

    // 二重POST防止：OFFで初期化
    const processing = useRef(false);

    // 承認依頼を許可
    const approvalRequestConfirmed = async (applyId: number) => {
      // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;
      await approvalRequest(applyId);
    };

    // 承認依頼を拒否
    const deleteRequestConfirmed = async (applyId: number) => {
      // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;
      await deleteRequest(applyId);
    };

    return (
      <>
        <Typography
          variant="h4"
          style={{ marginTop: '-22px', marginBottom: 30 }}
        >
          企業ユーザー登録 申請許可
        </Typography>
        <Divider style={{ marginBottom: 30 }} />
        {apiCallStatus === 0 && (
          // API呼び出し未完了
          <></>
        )}
        {apiCallStatus === 1 && approvalAccount?.totalCount === 0 && (
          // 初期データ0件
          <Typography variant="body1" className={classes.mainText2}>
            現在、申請許可が必要なメールアドレスはありません。
          </Typography>
        )}
        {rows !== undefined &&
          rows !== null &&
          apiCallStatus === 1 &&
          approvalAccount?.totalCount !== 0 && (
            <>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                style={{ marginBottom: '15px', width: '100%' }}
              >
                <Typography variant="body1" style={{ marginRight: 20 }}>
                  全{approvalAccount?.totalCount}件
                </Typography>
                <Select
                  name="size"
                  value={approvalAccount?.size}
                  handleInputEvent={handleInputEvent}
                  options={options}
                />
              </Grid>

              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableRowList}>
                      <TableCell
                        align="center"
                        className={classes.listTableHead}
                      >
                        <Typography variant="body1">
                          メールアドレス
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.listTableHead}
                      >
                        <Typography variant="body1">氏名</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.listTableHead}
                      >
                        <Typography variant="body1">部署名</Typography>
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        className={classes.listTableHead}
                      >
                        <Typography variant="body1">申請</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow className={classes.tableRowList}>
                        <TableCell
                          align="left"
                          className={classes.listTableBody}
                        >
                          <Typography variant="body1">{row.email}</Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.listTableBody}
                        >
                          <Typography variant="body1">{row.name}</Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.listTableBody}
                        >
                          <Typography variant="body1">
                            {row.departmentName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          width={152}
                          className={classes.listTableBody}
                        >
                          <Button
                            onClick={() => {
                              // 二重POST防止：OFF
                              processing.current = false;
                              setSelectRequest({
                                msg: `「${row.name}」からの企業ユーザー登録の申請を許可します。
                                よろしいですか？`,
                                applyId: row.applyId,
                                name: `「${row.name}」`,
                              });
                              // GAタグ：ID連携リクエスト-許可押下
                              onGaClick('B-030-001-101');
                              // DMPビーコンタグ：ID連携リクエスト-許可押下
                              OnDmpClick('B-030-001-101', authInfo?.scimId);
                              setApprovalDialog(true);
                            }}
                            className={classes.listBtn}
                          >
                            許可
                          </Button>
                        </TableCell>
                        <TableCell
                          align="center"
                          width={152}
                          className={classes.listTableBody}
                        >
                          <Button
                            onClick={() => {
                              // 二重POST防止：OFF
                              processing.current = false;
                              setSelectRequest({
                                msg: `「${row.name}」からの企業ユーザー登録の申請を拒否します。
                                よろしいですか？`,
                                applyId: row.applyId,
                                name: `「${row.name}」`,
                              });
                              // GAタグ：ID連携リクエスト-拒否押下
                              onGaClick('B-030-001-104');
                              // DMPビーコンタグ：ID連携リクエスト-拒否押下
                              OnDmpClick('B-030-001-104', authInfo?.scimId);
                              setDeleteDialog(true);
                            }}
                            className={`${classes.listBtn} ${classes.listBtnCancel}`}
                          >
                            拒否
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {selectRequest && (
                <>
                  <ConfirmDialog
                    msg={selectRequest.msg}
                    open={approvalDialog}
                    setOpen={setApprovalDialog}
                    doYes={async () => {
                      // GAタグ：ID連携リクエスト許可モーダル-OK押下
                      onGaClick('B-030-001-102');
                      // DMPビーコンタグ：ID連携リクエスト許可モーダル-OK押下
                      OnDmpClick('B-030-001-102', authInfo?.scimId);
                      await approvalRequestConfirmed(selectRequest.applyId);
                    }}
                  />
                  <ConfirmDialog
                    msg={selectRequest.msg}
                    open={deleteDialog}
                    setOpen={setDeleteDialog}
                    doYes={async () => {
                      // GAタグ：ID連携リクエスト拒否モーダル-OK押下
                      onGaClick('B-030-001-105');
                      // DMPビーコンタグ：ID連携リクエスト拒否モーダル-OK押下
                      OnDmpClick('B-030-001-105', authInfo?.scimId);
                      await deleteRequestConfirmed(selectRequest.applyId);
                    }}
                  />
                </>
              )}
              <Toolbar
                disableGutters
                variant="dense"
                className={classes.listPager}
              >
                <ButtonLowBack
                  disabled={isPageBackDisabled}
                  onClick={handleSearchBack}
                  disableRipple
                >
                  前へ
                </ButtonLowBack>
                <ButtonLowForward
                  disabled={isPageNextDisabled}
                  onClick={handleSearchNext}
                  disableRipple
                >
                  次へ
                </ButtonLowForward>
              </Toolbar>
            </>
          )}
        {selectRequest && (
          <ConfirmDialog
            msg={`${MessageConst.INFO_ID_REQUEST_WARN}`}
            open={infomationDialog}
            setOpen={setInfomationDialog}
            doYes={() => {
              // 処理なし
            }}
            onlyOk
          />
        )}
      </>
    );
  }
);

// 選択ユーザ情報
interface SelectCorporationUserProps {
  scimId: string;
  email: string;
  name: string;
  authorityApproval: string;
  authorityAssignment: string;
  haveUsageService: boolean;
}

/**
 * アカウント一覧 / アカウント管理権限のヘッダーセル
 */
function IdManageAuthHeaderCell(): JSX.Element {
  return (
    <>
      <Typography style={{ padding: '16px 0 10px 0' }}>
        <Typography style={{ lineHeight: '25px', textAlign: 'center' }}>
          アカウント<br />管理権限
        </Typography>
      </Typography>
    </>
  );
}

// 企業登録コンポーネント
const CorporationAccount: FC<CorporationAccountInterface> = memo(
  ({ useCorporation }) => {
    const history = useHistory();
    const userInvitePush = () => {
      history.push(PageInfo.CORP_USERS_INVITE_INPUT.path);
    };
    const {
      isPageBackDisabled,
      isPageNextDisabled,
      handleInputEvent,
      handleSearchBack,
      handleSearchNext,
      handleSearch,
      handleSearchDelete,
      handleSearchSort,
      corporationUsers,
      apiCallStatus,
    } = useCorporation;
    const { rows } = corporationUsers;
    const classes = useStyles();
    const dataGridClasses = useDataGridStyles();
    const { authInfo } = useContext(AuthContext);

    // 変更 Dialog
    const [userAuthDialog, setUserAuthDialog] = useState(false);

    // ボタン押下時の選択行情報
    const [selectCorporationUser, setSelectCorporationUser] = useState<
      SelectCorporationUserProps | undefined
    >(undefined);

    // IF-SV-002契約情報一覧取得API hooks
    const {
      response: futureUsageServicesResponse,
      error: futureUsageServicesResponseError,
      callApi: futureUsageServiceListCallApi,
    } = useApi<ContractItemListResponse>();
    // IF-SV-002契約情報一覧取得API hooks
    const {
      response: nowUsageServicesResponse,
      error: nowUsageServicesResponseError,
      callApi: nowUsageServiceListCallApi,
    } = useApi<ContractItemListResponse>();

    // IF-SV-002契約情報一覧取得call
    const usageServicesAPI = async (scimId: string, usageFlag: '0' | '1') => {
      const corporationId = authInfo?.corporationId || '';
      // 契約内容呼出し
      const CONTRACT_INFO: ApiConfig = {
        url: `${ApiConst.CONTRACT_LIST.url}/${corporationId}/${scimId}`,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      };
      if (usageFlag === '0') {
        await futureUsageServiceListCallApi(
          CONTRACT_INFO,
          'GET',
          usageFlag ? ({ usageFlag } as ContractInfoListRequest) : null
        );
      } else if (usageFlag === '1') {
        await nowUsageServiceListCallApi(
          CONTRACT_INFO,
          'GET',
          usageFlag ? ({ usageFlag } as ContractInfoListRequest) : null
        );
      }
    };

    // IF-SV-002契約情報一覧取得call 結果
    useEffect(() => {
      if (!selectCorporationUser) return;
      if (futureUsageServicesResponse) {
        if (futureUsageServicesResponse.contractServiceList?.length > 0) {
          // 利用サービス有
          setSelectCorporationUser({
            ...selectCorporationUser,
            haveUsageService: true,
          });
        } else {
          // 利用サービス無
        }
      } else if (futureUsageServicesResponseError) {
        // エラー表示
        setMessage(futureUsageServicesResponseError.message);
      }
      // 変更ポップアップ表示
      setUserAuthDialog(true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [futureUsageServicesResponse, futureUsageServicesResponseError]);

    // IF-SV-002契約情報一覧取得call 結果
    useEffect(() => {
      if (!selectCorporationUser) return;

      if (nowUsageServicesResponse) {
        if (nowUsageServicesResponse.contractServiceList?.length > 0) {
          // 利用サービス有
          setSelectCorporationUser({
            ...selectCorporationUser,
            haveUsageService: true,
          });
        } else {
          // 利用サービス無
        }
      } else if (nowUsageServicesResponseError) {
        // エラー表示
        setMessage(nowUsageServicesResponseError.message);
      }
      // 変更ポップアップ表示
      setUserAuthDialog(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nowUsageServicesResponse, nowUsageServicesResponseError]);
    // IF-SV-002契約情報一覧取得API エラーメッセージ
    const [message, setMessage] = useState('');

    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'メールアドレス',
        sortable: false,
        width: 243,
        filterable: false,
        align: 'left',
        headerAlign: 'center',
      },
      {
        field: 'name',
        headerName: '氏名',
        sortable: true,
        width: 243,
        filterable: false,
        headerAlign: 'center',
      },
      {
        field: 'departmentName',
        headerName: '部署名',
        sortable: true,
        width: 242,
        filterable: false,
        headerAlign: 'center',
      },
      {
        field: 'idManageAuth',
        renderHeader: () => <IdManageAuthHeaderCell />,
        sortable: false,
        width: 135,
        filterable: false,
        renderCell: (params: GridCellParams) => {
          const { id, getValue } = params;
          const val = getValue(id, 'idManageAuth');
          if (val === '1') {
            return (
              <Typography style={{ color: ColorInfo.blue02 }}>●</Typography>
            );
          }
          return <Typography style={{ color: ColorInfo.gray02 }}>×</Typography>;
        },
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'authDetailChange',
        headerName: '権限変更',
        sortable: false,
        width: 135,
        filterable: false,
        renderCell: (params: GridCellParams) => {
          const { id, getValue } = params;

          let isAuthUpdate = false;
          if (getValue(id, 'adminFlag') === '1') {
            // 企業管理者ユーザのデータは変更不可
          } else if (authInfo?.authorityApproval === '1') {
            // 権限保有者は変更可能
            isAuthUpdate = true;
          }

          if (isAuthUpdate) {
            const scimId = getValue(id, 'scimId')?.toString() || '';
            const email = getValue(id, 'id')?.toString() || '';
            const name = getValue(id, 'name')?.toString() || '';
            const authorityApproval =
              getValue(id, 'idManageAuth')?.toString() || '';
            const authorityAssignment =
              getValue(id, 'assignment')?.toString() || '';

            // 変更ボタンイベント
            const updateEvent = () => {
              // GAタグ：アカウント管理権限変更ボタン押下
              onGaClick('B-030-001-201');
              // DMPビーコンタグ：アカウント管理権限変更ボタン押下
              OnDmpClick('B-030-001-201', authInfo?.scimId);
              setMessage('');
              // 選択行情報取得
              setSelectCorporationUser({
                scimId,
                email,
                name,
                authorityApproval,
                authorityAssignment,
                haveUsageService: false,
              });
              // API呼出し
              void usageServicesAPI(scimId, '0');
              void usageServicesAPI(scimId, '1');
            };
            // 変更ボタン
            return (
              <Button onClick={updateEvent} className={classes.listBtn}>
                変更
              </Button>
            );
          }
          return (
            <Button
              disabled
              className={`${classes.listBtn} ${classes.listBtnCancel}`}
            >
              変更
            </Button>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
    ];

    // SortGrid Hooks
    const { sortModel, handleSortModelChange } = useSortGrid();

    // GridのSort変更時
    useEffect(() => {
      if (sortModel.length !== 1) {
        return;
      }

      // APIパラメータ生成
      const sortedColumn = sortModel[0];
      let sortItem: 'name' | 'departmentName' | null = null;
      if (sortedColumn.field === 'name') {
        // 氏名
        sortItem = 'name';
      } else if (sortedColumn.field === 'departmentName') {
        // 部署名
        sortItem = 'departmentName';
      }
      let sortType: 'asc' | 'desc' | null = null;
      if (sortedColumn.sort === 'asc') {
        sortType = 'asc';
      } else if (sortedColumn.sort === 'desc') {
        sortType = 'desc';
      }

      // API呼出し
      if (sortItem && sortType) handleSearchSort(sortItem, sortType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortModel]);

    return (
      <>
        <Box className={classes.accTitle} style={{ marginTop: '13px' }}>
          <Typography variant="h4" style={{ margin: '25px 0 0' }}>
            企業ユーザー一覧
          </Typography>
          {authInfo?.authorityApproval === '1' && (
            <SubButton onClick={userInvitePush}>新規登録</SubButton>
          )}
        </Box>
        <Divider style={{ marginBottom: 30, marginTop: 5 }} />

        {apiCallStatus === 0 && (
          // Api呼出し状態が未完了のときは表を表示しない。
          <></>
        )}

        {apiCallStatus === 1 && corporationUsers?.totalCount === 0 && (
          // 必ず１件以上取れるため基本的にこの文言は表示されない
          // 初期データ0件
          <Typography variant="body1" className={classes.mainText2}>
            現在、企業ユーザー登録はされておりません。
          </Typography>
        )}

        {((apiCallStatus === 1 && corporationUsers?.totalCount !== 0) ||
          apiCallStatus === 2 ||
          apiCallStatus === 3) && (
          // 初期データ1件以上 / 検索中 / 検索クリア中
          <Box className={classes.search}>
            <Box className={classes.searchBox}>
              <TextInput
                name="searchValue"
                value={corporationUsers?.searchValue}
                placeholder="入力してください（メールアドレス、氏名、部署名など）"
                handleInputEvent={handleInputEvent}
              />
              <SubButton onClick={handleSearch}>検索</SubButton>
              <Link
                component="button"
                variant="body1"
                onClick={handleSearchDelete}
              >
                検索クリア
              </Link>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              style={{ margin: '36px 0 0' }}
            >
              <Typography variant="body1" style={{ marginRight: 20 }}>
                全{corporationUsers?.totalCount}件
              </Typography>
              <Select
                name="size"
                value={corporationUsers?.size}
                handleInputEvent={handleInputEvent}
                options={options}
              />
            </Grid>
          </Box>
        )}

        {apiCallStatus === 2 && corporationUsers?.totalCount === 0 && (
          // 検索した結果データが0件
          <div>
            <br />
            <Typography variant="body1" className={classes.mainText2}>
              検索条件と一致するユーザー情報はありません。
            </Typography>
          </div>
        )}

        {((apiCallStatus === 2 && corporationUsers?.totalCount !== 0) ||
          (apiCallStatus === 1 && corporationUsers?.totalCount !== 0) ||
          apiCallStatus === 3) &&
          rows !== undefined &&
          rows !== null && (
            // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中
            <DataGrid
              columns={columns}
              autoHeight
              hideFooter
              disableColumnMenu
              rows={rows.map((row) => ({
                id: row.email,
                name: row.name,
                departmentName: row.departmentName,
                idManageAuth: row.authorityApproval,
                assignment: row.authorityAssignment,
                adminFlag: row.administratorFlag,
                scimId: row.scimId,
              }))}
              headerHeight={70}
              rowHeight={70}
              components={{
                ColumnSortedAscendingIcon: SortAscendingIcon,
                ColumnSortedDescendingIcon: SortDescendingIcon,
                ColumnUnsortedIcon: SortUnsortedIcon,
              }}
              classes={{
                root: dataGridClasses.root,
                columnHeader: dataGridClasses.columnHeader,
                cell: dataGridClasses.cell,
                row: dataGridClasses.row,
              }}
              sortingMode="server"
              onSortModelChange={(model) => handleSortModelChange(model)}
              sortModel={sortModel}
              sortingOrder={['asc', 'desc']}
            />
          )}
        {selectCorporationUser && (
          <>
            <UserAuthUpdateDialog
              scimId={selectCorporationUser.scimId}
              email={selectCorporationUser.email}
              name={selectCorporationUser.name}
              authorityApproval={selectCorporationUser.authorityApproval}
              authorityAssignment={selectCorporationUser.authorityAssignment}
              operatorId={authInfo?.scimId || ''}
              operatorAuthorityAssignment={authInfo?.authorityAssignment || ''}
              haveUsageService={selectCorporationUser.haveUsageService}
              open={userAuthDialog}
              setOpen={setUserAuthDialog}
              error={message}
              afterClose={handleSearch}
            />
          </>
        )}

        {((apiCallStatus === 2 && corporationUsers?.totalCount !== 0) ||
          (apiCallStatus === 1 && corporationUsers?.totalCount !== 0) ||
          apiCallStatus === 3) && (
          // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中

          <Toolbar disableGutters variant="dense" className={classes.listPager}>
            <ButtonLowBack
              disabled={isPageBackDisabled}
              onClick={handleSearchBack}
              disableRipple
            >
              前へ
            </ButtonLowBack>
            <ButtonLowForward
              disabled={isPageNextDisabled}
              onClick={handleSearchNext}
              disableRipple
            >
              次へ
            </ButtonLowForward>
          </Toolbar>
        )}
      </>
    );
  }
);

// 招待中一覧選択行情報
interface SelectInviteProps {
  msg: string;
  inviteId: string;
}

// 招待中アカウントコンポーネント
const InvitingAccount: FC<InvitingAccountInterface> = memo(
  ({ useApproval, useInvite }) => {
    // 招待中アカウント一覧Hook
    const {
      isPageBackDisabled,
      isPageNextDisabled,
      handleInputEvent,
      handleSearchBack,
      handleSearchNext,
      handleInviteUpdate,
      inviteAccount,
      apiCallStatus,
    } = useInvite;
    const { rows } = inviteAccount;
    // 招待削除Hook
    const { response, inviteDeleteAPI } = useInviteDelete();
    // 承認リクエストアカウント一覧Hook
    const { handleApprovalUpdate } = useApproval;
    const classes = useStyles();

    const { authInfo } = useContext(AuthContext);

    // 再描画処理
    useEffect(() => {
      if (response) {
        handleApprovalUpdate();
        handleInviteUpdate();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    // ボタン押下時の選択行情報
    const [selectInvite, setSelectInvite] = useState<
      SelectInviteProps | undefined
    >(undefined);

    // 取消イベント Dialog
    const [deleteDialog, setDeleteDialog] = useState(false);

    // 二重POST防止：OFFで初期化
    const processing = useRef(false);

    // 招待を取消
    const inviteDeleteConfirmed = async (inviteId: string) => {
      // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;
      // API呼出し(招待を取消)
      await inviteDeleteAPI(inviteId);
    };

    return (
      <>
        <Typography variant="h4" style={{ marginTop: 45 }}>
          招待中メールアドレス
        </Typography>
        <Divider style={{ marginBottom: 30, marginTop: 25 }} />

        {apiCallStatus === 0 && (
          // API呼び出し未完了
          <></>
        )}
        {apiCallStatus === 1 && inviteAccount?.totalCount === 0 && (
          // 初期データ0件
          <Typography
            variant="body1"
            className={classes.mainText2}
            style={{ marginBottom: 80 }}
          >
            現在、招待中のメールアドレスはありません。
          </Typography>
        )}

        {rows !== undefined &&
          rows !== null &&
          apiCallStatus === 1 &&
          inviteAccount?.totalCount !== 0 && (
            <>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                style={{ marginBottom: '15px', width: '100%' }}
              >
                <Typography variant="body1" style={{ marginRight: 20 }}>
                  全{inviteAccount?.totalCount}件
                </Typography>
                <Select
                  name="size"
                  value={inviteAccount?.size}
                  handleInputEvent={handleInputEvent}
                  options={options}
                />
              </Grid>

              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <TableHead>
                    <TableRow className={classes.tableRowList}>
                      <TableCell className={classes.listTableHead}>
                        <Typography variant="body1">メールアドレス</Typography>
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="center"
                        className={classes.listTableHead}
                      >
                        <Typography variant="body1">状況</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow className={classes.tableRowList}>
                        <TableCell
                          align="left"
                          className={classes.listTableBody}
                        >
                          <Typography variant="body1">{row.email}</Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          width={152}
                          className={classes.listTableBody}
                        >
                          <Typography variant="body1">
                            <span className={classes.listInvite}>招待中</span>
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          width={152}
                          className={classes.listTableBody}
                        >
                          <Button
                            onClick={() => {
                              // 二重POST防止：OFF
                              processing.current = false;
                              setSelectInvite({
                                msg: `「${row.email}」への招待を取り消します。
                                取り消しを行うと、招待メールに記載されたURLは無効になります。
                                よろしいですか？`,
                                inviteId: row.inviteId,
                              });
                              // GAタグ：招待中アカウント-取消押下
                              onGaClick('B-030-001-301');
                              // DMPビーコンタグ：招待中アカウント-取消押下
                              OnDmpClick('B-030-001-301', authInfo?.scimId);
                              setDeleteDialog(true);
                            }}
                            className={`${classes.listBtn} ${classes.listBtnCancel}`}
                          >
                            取消
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {selectInvite && (
                <>
                  <ConfirmDialog
                    msg={selectInvite.msg}
                    open={deleteDialog}
                    setOpen={setDeleteDialog}
                    doYes={async () => {
                      // GAタグ：招待中アカウントモーダル-OK押下
                      onGaClick('B-030-001-302');
                      // GAタグ：招待中アカウントモーダル-OK押下
                      OnDmpClick('B-030-001-302', authInfo?.scimId);
                      await inviteDeleteConfirmed(selectInvite.inviteId);
                    }}
                  />
                </>
              )}

              <Toolbar
                disableGutters
                variant="dense"
                className={classes.listPager}
              >
                <ButtonLowBack
                  disabled={isPageBackDisabled}
                  onClick={handleSearchBack}
                  disableRipple
                >
                  前へ
                </ButtonLowBack>
                <ButtonLowForward
                  disabled={isPageNextDisabled}
                  onClick={handleSearchNext}
                  disableRipple
                >
                  次へ
                </ButtonLowForward>
              </Toolbar>
            </>
          )}
      </>
    );
  }
);

// 企業情報コンポーネント
const CorporationInfo: FC<AdminCorpMngInterface> = memo(
  ({ useAdminCorp, setGlobalMessage }) => {
    const classes = useStyles();
    const { adminCorpMng } = useAdminCorp;
    const { authInfo } = useContext(AuthContext);
    const { uploadFileList } = useAdminCorpMng();
    const { fileDownloadAPI, response, error } = useFileDownload();

    // API呼び出し(ファイルダウンロード)結果による処理
    useEffect(() => {
      if (response) {
        // 戻り値を別タブで表示
        setGlobalMessage('');

        window.open(response.signedURL, '_blank');
      } else if (error) {
        // 応答電文を設定

        setGlobalMessage(error.message);

        window.scrollTo(0, 0);
      }
    }, [error, response, setGlobalMessage]);

    const history = useHistory();
    const adminUserChangeEvent = () => {
      history.push(`${PageInfo.CORP_ADMIN_CHANGE_INPUT.path}`);
    };

    const corpInfoChangeEvent = () => {
      history.push(`${PageInfo.CORP_INFO_EDIT_INPUT.path}`);
    };

    return (
      <>
        <Box
          className={classes.title}
          style={{
            marginTop:
              adminCorpMng?.administratorEmail === authInfo?.email
                ? '45px'
                : '55px',
            marginBottom:
              adminCorpMng?.administratorEmail === authInfo?.email
                ? '17px'
                : '30px',
          }}
        >
          <Typography variant="h3" style={{ margin: '0 auto 0 0' }}>
            企業情報
          </Typography>
          {adminCorpMng?.administratorEmail === authInfo?.email && (
            <>
              <SubButton onClick={corpInfoChangeEvent}>変更</SubButton>
            </>
          )}
        </Box>

        <Table aria-label="simple table">
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>企業コード</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextInput
                name="corporationId"
                value={adminCorpMng?.corporationId}
                disabled
                fullWidth
                multiline
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>企業名</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextInput
                name="corporationName"
                value={adminCorpMng?.corporationName}
                disabled
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>
                企業名（カナ）
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextInput
                name="corporationNameKana"
                value={adminCorpMng?.corporationNameKana}
                disabled
                fullWidth
                multiline
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>郵便番号</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <InputPostalCode
                name1="corpAddressPostalCode1"
                value1={adminCorpMng?.corpAddressPostalCode1}
                name2="corpAddressPostalCode2"
                value2={adminCorpMng?.corpAddressPostalCode2}
                disabled
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>住所</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextInput
                name="corpAddress"
                value={adminCorpMng?.corporationAddress}
                disabled
                fullWidth
                multiline
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>
                代表電話番号
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <InputTelephone
                name1="corporationTelephoneNumber1"
                value1={adminCorpMng?.corporationTelephoneNumber1}
                name2="corporationTelephoneNumber2"
                value2={adminCorpMng?.corporationTelephoneNumber2}
                name3="corporationTelephoneNumber3"
                value3={adminCorpMng?.corporationTelephoneNumber3}
                disabled
              />
            </TableCell>
          </TableRow>
          {adminCorpMng?.administratorEmail === authInfo?.email &&
            uploadFileList.length > 0 && (
              <TableRow>
                <TableCell className={classes.CorpTableCellHead}>
                  <Typography>提出書類</Typography>
                </TableCell>

                <TableCell className={classes.CorpTableCellValue1}>
                  {uploadFileList.map((row) => (
                    <TableRow>
                      <TableCell className={classes.CorpTableCellValue2}>
                        <Link
                          className={classes.textLink}
                          href="/"
                          onClick={(event) => {
                            // GA, DMPビーコンタグ：ファイルダウンロード(IF-SP-008)
                            const gaTagId = 'B-030-001-009';
                            onGaClick(gaTagId);
                            OnDmpClick(gaTagId, authInfo?.scimId);
                            event.preventDefault();
                            const target = row.filepath ? row.filepath : '';
                            void fileDownloadAPI(target);
                          }}
                        >
                          {row.filename}
                        </Link>
                        <span className={classes.textSpan}>
                          {'　（提出日：'.concat(
                            row.uploadDate ? row.uploadDate : '',
                            '）'
                          )}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableCell>
              </TableRow>
            )}
        </Table>

        {adminCorpMng?.administratorEmail === authInfo?.email && (
          <div className={classes.withdrawalLink}>
            <Link
              href="/"
              onClick={(event) => {
                event.preventDefault();
                history.push(PageInfo.CORP_RESIGN_BASE.path);
              }}
            >
              <span>企業登録解除（退会）の手続きはこちら</span>
            </Link>
          </div>
        )}

        <Box
          className={classes.title}
          style={{
            marginTop:
              adminCorpMng?.administratorEmail === authInfo?.email
                ? '65px'
                : '80px',
            marginBottom:
              adminCorpMng?.administratorEmail === authInfo?.email
                ? '20px'
                : '30px',
          }}
        >
          <Typography variant="h3" style={{ margin: '0 auto 0 0' }}>
            企業管理者情報
          </Typography>
          {adminCorpMng?.administratorEmail === authInfo?.email && (
            <SubButton onClick={adminUserChangeEvent}>変更</SubButton>
          )}
        </Box>

        <Table aria-label="simple table" style={{ marginBottom: '105px' }}>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>氏名</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextInput
                name="administrator"
                value={adminCorpMng?.administratorName}
                disabled
                fullWidth
                multiline
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell>
              <Typography className={classes.tableHead}>
                メールアドレス
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextInput
                name="email"
                value={adminCorpMng?.administratorEmail}
                disabled
                fullWidth
                multiline
              />
            </TableCell>
          </TableRow>
        </Table>
      </>
    );
  }
);

// アカウント管理コンポーネント
const AccountManage: FC = memo(() => {
  const useApproval = useApprovalAccount();
  // 法人ユーザ一覧業務Hooks(0:[条件なし])
  const useCorporation = useCorporationUsers({ searchAuthType: '0' });
  const useInvite = useInviteAccount();
  const useAdminCorp = useAdminCorpMng();
  const { authInfo } = useContext(AuthContext);
  const classes = useStyles();

  // 共通エラーメッセージ
  const [globalMessage, setGlobalMessage] = useState('');
  return (
    <Template title="アカウント管理">
      <GlobalErrorMessage message={globalMessage} />

      <CustomerInfo
        administratorEmail={useAdminCorp.adminCorpMng.administratorEmail}
        setGlobalMessage={setGlobalMessage}
        globalMessage={globalMessage}
      />
      <UsageAuthority />
      <Typography id="corpAccount" variant="h3">
        企業ユーザー管理
      </Typography>
      <Typography
        variant="body1"
        className={classes.mainText2}
        style={{ marginBottom: '25px' }}
      >
        企業ユーザーの登録状況をご確認いただけます。
      </Typography>
      <br />
      {authInfo?.authorityApproval === '1' && (
        <>
          <IdFederate
            useApproval={useApproval}
            useCorporation={useCorporation}
            useInvite={useInvite}
          />
          <br />
        </>
      )}
      <CorporationAccount useCorporation={useCorporation} />
      <br />
      {authInfo?.authorityApproval === '1' && (
        <>
          <InvitingAccount useApproval={useApproval} useInvite={useInvite} />
        </>
      )}
      <CorporationInfo
        useAdminCorp={useAdminCorp}
        setGlobalMessage={setGlobalMessage}
      />
      <Box />
    </Template>
  );
});

export default AccountManage;
