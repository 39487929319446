import { AuthContext } from 'app/hooks/context/auth';
import { PageInfo } from 'app/utils/constants';
import { FC, useContext } from 'react';
import { Redirect } from 'react-router-dom';

const AuthenticatedGuard: FC = ({ children }) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  return authInfo?.isAuth ? (
    <>{children}</>
  ) : (
    <Redirect to={PageInfo.TOP.path} />
  );
};

export default AuthenticatedGuard;
