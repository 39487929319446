import { FC, memo } from 'react';
import Select, {
  SelectProps as MuiSelectProps,
} from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInfo } from 'app/style/theme';

const useStyles = makeStyles(() => ({
  select: {
    width: 150,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: ColorInfo.blue05,
    '& .MuiFilledInput-underline::before': {
      display: 'none',
    },
  },
}));

// インタフェース
interface SelectProps extends MuiSelectProps {
  handleInputEvent: any;
  options: { value: string; label: string }[];
}

const SelectOption: FC<SelectProps> = memo(
  ({ name, value, options, handleInputEvent }) => {
    const classes = useStyles();

    return (
      <FormControl variant="filled" className={classes.select}>
        <Select
          native
          labelId={name}
          id={name}
          name={name}
          value={value}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          onChange={handleInputEvent}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              style={{ lineHeight: 1 }}
            >
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default SelectOption;
