import { useEffect, useState, useContext } from 'react';
import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  ApprovalRequestAccountItem,
  ApprovalRequestAccountListResponse,
} from 'app/service/customer/corporation/users/approval/account';
import { AuthContext } from 'app/hooks/context/auth';

// 承認リクエストアカウント一覧情報
export interface ApprovalAccount {
  totalCount: number; // 総件数
  page: number; // 取得対象ページ番号
  size: string; // 表示対象件数
  rows?: ApprovalRequestAccountItem[] | null;
}
/**
 * 承認リクエストアカウント一覧業務カスタムHooks
 */
export const useApprovalAccount = () => {
  // 承認リクエストアカウント一覧情報
  const [approvalAccount, setApprovalAccount] =
    // 初期値設定
    useState<ApprovalAccount>({
      totalCount: 0,
      page: 0,
      size: '',
      rows: [],
    });

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 前へボタン制御（true：非活性、false：活性）
  const [isPageBackDisabled, setIsPageBackDisabled] = useState<boolean>(true);
  // 次へボタン制御（true：非活性、false：活性）
  const [isPageNextDisabled, setIsPageNextDisabled] = useState<boolean>(true);

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // プルダウンの場合はイベント発火
    if (name === 'size' && target.type !== 'checkbox') {
      handleSelect(target.value);
    } else {
      setApprovalAccount({
        ...approvalAccount,
        [name]: value,
      });
    }
  };

  // 表示件数変更アクション
  const handleSelect = (sizeIndex: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setApprovalAccount({
      ...approvalAccount,
      page: pageIndex,
      size: sizeIndex,
    });
    // 承認リクエストアカウント一覧情報リクエスト呼び出し
    approvalAccountListAPI(authInfo?.scimId || '', pageIndex, sizeIndex);
  };

  // 前へアクション
  const handleSearchBack = () => {
    // ページ減算
    const pageIndex = approvalAccount.page - 1;

    setApprovalAccount({
      ...approvalAccount,
      page: pageIndex,
      size: approvalAccount.size,
    });
    // 承認リクエストアカウント一覧情報リクエスト呼び出し
    approvalAccountListAPI(
      authInfo?.scimId || '',
      pageIndex,
      approvalAccount.size
    );
  };

  // 次へアクション
  const handleSearchNext = () => {
    // ページ加算
    const pageIndex = approvalAccount.page + 1;

    setApprovalAccount({
      ...approvalAccount,
      page: pageIndex,
      size: approvalAccount.size,
    });
    // 承認リクエストアカウント一覧情報リクエスト呼び出し
    approvalAccountListAPI(
      authInfo?.scimId || '',
      pageIndex,
      approvalAccount.size
    );
  };

  // 更新アクション
  const handleApprovalUpdate = () => {
    // 1ページ目に設定
    const pageIndex = 1;

    setApprovalAccount({
      ...approvalAccount,
      page: pageIndex,
    });
    // 承認リクエストアカウント一覧情報リクエスト呼び出し
    approvalAccountListAPI(
      authInfo?.scimId || '',
      pageIndex,
      approvalAccount.size
    );
  };

  // API呼び出しカスタムHooks
  const { response, error, callApi } =
    useApi<ApprovalRequestAccountListResponse>();

  // 承認リクエストアカウント一覧API
  const approvalAccountListAPI = (
    userId: string,
    pageIndex = 1,
    pageSize = '30'
  ) => {
    // 一覧API呼出し
    const APPROVAL_ACCOUNT_LIST: ApiConfig = {
      url: `/api/v1/corporation/users/approval/request/account/${userId}/${pageIndex}/${pageSize}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void callApi(APPROVAL_ACCOUNT_LIST, 'GET', null);
  };

  useEffect(() => {
    // 初期処理
    const pageIndex = 1;
    const pageSize = '30';

    // Api呼出しステータスを未完了(0)に設定
    setApiCallStatus(0);

    setApprovalAccount({
      totalCount: 1,
      page: pageIndex,
      size: pageSize,
      rows: [],
    });

    // 承認リクエストアカウント一覧API呼び出し
    approvalAccountListAPI(authInfo?.scimId || '', pageIndex, pageSize);
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // Api呼出しステータスを呼出し完了(1)に設定
      setApiCallStatus(1);

      // 承認リクエストアカウント一覧設定
      setApprovalAccount({
        ...approvalAccount,
        totalCount: response?.totalCount,
        rows: response?.dataList,
      });

      // 次へボタン制御
      if (response.totalPage > approvalAccount.page) {
        // 活性
        setIsPageNextDisabled(false);
      } else {
        // 非活性
        setIsPageNextDisabled(true);
      }

      // 前へボタン制御
      if (approvalAccount.page > 1) {
        // 活性
        setIsPageBackDisabled(false);
      } else {
        // 非活性
        setIsPageBackDisabled(true);
      }
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    isPageBackDisabled,
    isPageNextDisabled,
    handleInputEvent,
    handleSearchBack,
    handleSearchNext,
    approvalAccountListAPI,
    handleApprovalUpdate,
    approvalAccount,
    apiCallStatus,
  };
};
export default useApprovalAccount;
