import { FC, useState, useContext, useEffect } from 'react';
import Template from 'app/views/components/template';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  ButtonForward,
  ButtonBack,
  SubButton,
  ButtonCancel,
} from 'app/views/components/block/button';
import TextInput from 'app/views/components/atom/text';
import InputTelephone from 'app/views/components/block/telephone';
import FileUpload from 'app/views/components/modules/fileupload';
import {
  InputFileRowGrid,
  InputRowGridPadding,
} from 'app/views/components/block/layout';
import { ColorInfo } from 'app/style/theme';
import StepperContent from 'app/views/components/block/stepper';
import InputPostalCode from 'app/views/components/block/postalcode';
import useCorpInfoEdit from 'app/hooks/corporation/info/edit';
import SelectPrefecture from 'app/views/components/block/prefecture';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import useValidate, { validationProps } from 'app/hooks/validate/index';
import ConfirmDialog from 'app/views/components/modules/dialog/confirm';
import useCorpInfoEditCancel from 'app/hooks/corporation/info/edit/cancel';
import { AuthContext } from 'app/hooks/context/auth';
import { useHistory } from 'react-router-dom';
import { CorporationStatusConst, PageInfo } from 'app/utils/constants';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';
// スタイル
const useStyles = makeStyles(() => ({
  span: {
    color: ColorInfo.red01,
  },
  h2: {
    paddingLeft: '0px',
  },
  mainText: {
    marginLeft: '22px',
    marginBottom: '10px',
  },
  serviceList: {
    flexBasis: '100%',
    marginTop: '10px',
    marginLeft: '32px',
  },
  inputRow: {
    marginBottom: '15px',
  },
  fileArea: {
    marginLeft: '100px',
  },
  descAreaCenter: {
    // padding: '40px 0 0px 0',
    textAlign: 'center',
    marginBottom: '40px',
  },
  descAreaCenterComplete: {
    // padding: '40px 0 0px 0',
    textAlign: 'center',
    marginBottom: '80px',
  },
  confirm: {
    marginLeft: '-50px',
  },
  tableRow: {
    height: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 18,
    '& > *': {
      border: `1px solid ${ColorInfo.gray03}`,
    },
    '& td:first-child': {
      borderLeft: '0px',
    },
    '& td:last-child': {
      borderRight: '0px',
    },
  },
  fileTable: {
    margin: '10px 0 10px 0',
  },
  fileSelectMsg: {
    color: ColorInfo.gray03,
  },
  tableUserRow: {
    '& td': {
      borderLeft: '0px',
      borderRight: '0px',
    },
  },
  corpAddressPostalCodeWrapper: {
    display: 'flex',
  },
}));

const CompanyInfoEditPage: FC = () => {
  const classes = useStyles();
  // 項目の活性（入力）、非活性（入力以外）
  const isDisabled = (): boolean => {
    switch (status) {
      case 0:
        // 初期値の取得に失敗した場合にはエラーとする
        if (error) return true;
        return false;
        break;
      default:
        return true;
        break;
    }
  };

  // 企業申請（企業申請中、企業申請差戻）の判定
  const isCorpApply = (): boolean => {
    switch (authInfo?.corporationStatus) {
      case CorporationStatusConst.APPLYING:
      case CorporationStatusConst.APPLY_REJECT:
        return true;
      default:
        return false;
    }
  };

  // 企業情報変更カスタムHooks
  const {
    status,
    error,
    corporationInfo,
    corporationInfoMessage,
    setCorporationInfoMessage,
    inputRef,
    uploadFile,
    setUploadFile,
    handleInputEvent,
    handleNext,
    handleBack,
    handleGetAddress,
    globalMessage,
  } = useCorpInfoEdit();

  const {
    corporationNameValidation,
    corporationNameKanaValidation,
    corporationAddressLocalityValidation,
    corporationAddressStreetValidation,
    corporationAddressPostalCodeValidation,
    corporationAddressBuildingValidation,
    corporationTelephoneNumberValidation,
    applyReasonValidation,
  } = useValidate();

  const {
    corpInfoEditCancelResponse,
    corpInfoEditCancelError,
    CorpInfoEditCancelAPI,
  } = useCorpInfoEditCancel();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const { authInfo, setCorporationStatus } = useContext(AuthContext);
  // GlobalMessage情報
  const [gMessage, setGlobalMessage] = useState<string>('');
  const history = useHistory();

  const handleCancel = () => {
    // GA, DMPビーコンタグ：企業情報申請の申請を取り下げるボタン押下(申請取り下げのモーダル表示)時
    const gaTagId = 'B-030-014-001';
    onGaClick(gaTagId);
    OnDmpClick(gaTagId, authInfo?.scimId);
    setConfirmDialog(true);
  };

  const fetchCancel = async () => {
    await CorpInfoEditCancelAPI(
      authInfo?.corporationId ? authInfo?.corporationId : ''
    );
  };

  const fetchGetAddress = async () => {
    // GA, DMPビーコンタグ：郵便番号検索API実行
    const gaTagId = 'B-030-014-002';
    onGaClick(gaTagId);
    OnDmpClick(gaTagId, authInfo?.scimId);
    await handleGetAddress();
  };

  // 再描画処理
  useEffect(() => {
    if (corpInfoEditCancelResponse) {
      setConfirmDialog(false);

      // 法人ステータスを更新する
      if (
        authInfo?.corporationStatus === CorporationStatusConst.APPLYING ||
        authInfo?.corporationStatus === CorporationStatusConst.APPLY_REJECT
      ) {
        setCorporationStatus(CorporationStatusConst.SUSPENSION);
      } else {
        setCorporationStatus(CorporationStatusConst.IN_USE);
      }

      history.push(PageInfo.CORP_INFO_EDIT_CANCEL_COMPLETE.path);
    }
    if (corpInfoEditCancelError) {
      if (corpInfoEditCancelError?.message) {
        setGlobalMessage(corpInfoEditCancelError.message);
      }
      setConfirmDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corpInfoEditCancelResponse, corpInfoEditCancelError]);

  return (
    // 画面タイトル名
    <Template title="アカウント管理 - 企業情報変更">
      <StepperContent StepNumber={status} />
      <>
        {/* 申請完了画面 */}
        {status === 2 && (
          <>
            <Container>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ margin: '0px -12px' }}
              >
                <Box
                  style={{
                    marginBottom: '80px',
                    borderBottom: `1px solid ${ColorInfo.gray04}`,
                    width: 1000,
                  }}
                >
                  <Typography className={`${classes.descAreaCenterComplete}`}>
                    <Typography
                      style={{
                        marginTop: '52px',
                      }}
                    >
                      企業情報の変更申請を受け付けました。
                    </Typography>
                    <Typography style={{ marginTop: 30 }}>
                      審査結果は、3営業日以内にメールにてご連絡いたします。
                    </Typography>
                    <Typography>
                      1週間経っても連絡がない場合は、お手数ですがお問い合わせください。
                    </Typography>
                  </Typography>
                </Box>
                <Grid>
                  <ButtonForward
                    onClick={handleNext}
                    style={{ width: '580px', margin: 'auto' }}
                  >
                    トップへ
                  </ButtonForward>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
        {/* 申請入力画面と確認画面 */}
        {status !== 2 && (
          <Container>
            {status === 0 && (
              <>
                <div
                  style={{
                    marginTop: '52px',
                    marginBottom: globalMessage ? '77px' : '',
                  }}
                >
                  <GlobalErrorMessage message={globalMessage} />
                  <GlobalErrorMessage message={gMessage} />
                </div>
                <Typography className={`${classes.descAreaCenter}`}>
                  変更したい情報を入力し、登記事項証明書（現在事項証明書）をアップロードのうえ、「確認する」ボタンを押してください。
                </Typography>
              </>
            )}
            {status === 1 && (
              <>
                <Typography
                  className={`${classes.descAreaCenter}`}
                  style={{
                    marginTop: '52px',
                  }}
                >
                  ご変更内容をご確認のうえ、「この内容で申請する」ボタンを押してください。
                </Typography>
              </>
            )}
            <Typography variant="h3">企業情報</Typography>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="企業名"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corporationName"
                  value={corporationInfo?.corporationName}
                  placeholder="例)トヨタ自動車株式会社"
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationNameValidation({
                      value: event.target.value,
                    });
                    setCorporationInfoMessage({
                      ...corporationInfoMessage,
                      corporationName: message,
                    });
                  }}
                  error={!!corporationInfoMessage?.corporationName}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationInfoMessage?.corporationName}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="企業名（カナ）"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corporationNameKana"
                  placeholder="例)トヨタジドウシャカブシキガイシャ"
                  value={corporationInfo?.corporationNameKana}
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationNameKanaValidation({
                      value: event.target.value,
                    });
                    setCorporationInfoMessage({
                      ...corporationInfoMessage,
                      corporationNameKana: message,
                    });
                  }}
                  error={!!corporationInfoMessage?.corporationNameKana}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationInfoMessage?.corporationNameKana}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="郵便番号"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <div
                  className={
                    isDisabled()
                      ? classes.confirm
                      : classes.corpAddressPostalCodeWrapper
                  }
                >
                  <InputPostalCode
                    name1="corporationAddressPostalCode1"
                    value1={corporationInfo?.corporationAddressPostalCode1}
                    name2="corporationAddressPostalCode2"
                    value2={corporationInfo?.corporationAddressPostalCode2}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    handleFocusEvent={(event) => {
                      const message = corporationAddressPostalCodeValidation(
                        corporationInfo?.corporationAddressPostalCode1,
                        corporationInfo?.corporationAddressPostalCode2
                      );
                      setCorporationInfoMessage({
                        ...corporationInfoMessage,
                        corporationAddressPostalCode: message,
                      });
                    }}
                    error={
                      !!corporationInfoMessage?.corporationAddressPostalCode
                    }
                  />
                  {status === 0 && (
                    <SubButton
                      onClick={() => fetchGetAddress()}
                      style={{ marginLeft: '10px' }}
                    >
                      住所検索
                    </SubButton>
                  )}
                </div>
                {status === 0 && (
                  <Typography color="error">
                    {corporationInfoMessage?.corporationAddressPostalCode}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            {status === 0 && (
              <>
                <Box sx={{ margin: '5px 0px 5px 20px' }}>
                  <InputRowGridPadding
                    label="都道府県"
                    required={isDisabled()}
                    className={classes.inputRow}
                  >
                    <div className={isDisabled() ? classes.confirm : ''}>
                      <SelectPrefecture
                        name="corporationAddressRegion"
                        value={corporationInfo?.corporationAddressRegion}
                        handleInputEvent={handleInputEvent}
                        disabled={isDisabled()}
                      />
                    </div>
                    {status === 0 && (
                      <Typography color="error">
                        {corporationInfoMessage?.corporationAddressRegion}
                      </Typography>
                    )}
                  </InputRowGridPadding>
                </Box>
                <Box sx={{ margin: '5px 0px 5px 20px' }}>
                  <InputRowGridPadding
                    label="市区町村"
                    required={isDisabled()}
                    className={classes.inputRow}
                  >
                    <TextInput
                      className={isDisabled() ? classes.confirm : ''}
                      name="corporationAddressLocality"
                      placeholder="例)中村区名駅"
                      value={corporationInfo?.corporationAddressLocality}
                      handleInputEvent={handleInputEvent}
                      disabled={isDisabled()}
                      fullWidth
                      onBlur={(event) => {
                        const message = corporationAddressLocalityValidation({
                          value: event.target.value,
                        });
                        setCorporationInfoMessage({
                          ...corporationInfoMessage,
                          corporationAddressLocality: message,
                        });
                      }}
                      error={
                        !!corporationInfoMessage?.corporationAddressLocality
                      }
                    />
                    {status === 0 && (
                      <Typography color="error">
                        {corporationInfoMessage?.corporationAddressLocality}
                      </Typography>
                    )}
                  </InputRowGridPadding>
                </Box>
                <Box sx={{ margin: '5px 0px 5px 20px' }}>
                  <InputRowGridPadding
                    label="丁目番地"
                    required={isDisabled()}
                    className={classes.inputRow}
                  >
                    <TextInput
                      className={isDisabled() ? classes.confirm : ''}
                      name="corporationAddressStreet"
                      placeholder="例)4丁目7番1号"
                      value={corporationInfo?.corporationAddressStreet}
                      handleInputEvent={handleInputEvent}
                      disabled={isDisabled()}
                      fullWidth
                      onBlur={(event) => {
                        const message = corporationAddressStreetValidation({
                          value: event.target.value,
                        });
                        setCorporationInfoMessage({
                          ...corporationInfoMessage,
                          corporationAddressStreet: message,
                        });
                      }}
                      error={!!corporationInfoMessage?.corporationAddressStreet}
                    />
                    {status === 0 && (
                      <Typography color="error">
                        {corporationInfoMessage?.corporationAddressStreet}
                      </Typography>
                    )}
                  </InputRowGridPadding>
                </Box>
                <Box sx={{ margin: '5px 0px 5px 20px' }}>
                  <InputRowGridPadding
                    label="建物名"
                    required
                    className={classes.inputRow}
                  >
                    <TextInput
                      className={isDisabled() ? classes.confirm : ''}
                      name="corporationAddressBuilding"
                      placeholder={!isDisabled() ? '例)トヨタタワー' : ''}
                      value={corporationInfo?.corporationAddressBuilding}
                      handleInputEvent={handleInputEvent}
                      disabled={isDisabled()}
                      fullWidth
                      onBlur={(event) => {
                        const message = corporationAddressBuildingValidation({
                          value: event.target.value,
                        });
                        setCorporationInfoMessage({
                          ...corporationInfoMessage,
                          corporationAddressBuilding: message,
                        });
                      }}
                      error={
                        !!corporationInfoMessage?.corporationAddressBuilding
                      }
                    />
                    {status === 0 && (
                      <Typography color="error">
                        {corporationInfoMessage?.corporationAddressBuilding}
                      </Typography>
                    )}
                  </InputRowGridPadding>
                </Box>
              </>
            )}
            {status === 1 && (
              <>
                <Box sx={{ margin: '5px 0px 5px 20px' }}>
                  <InputRowGridPadding
                    label="住所"
                    required={isDisabled()}
                    className={classes.inputRow}
                  >
                    <TextInput
                      className={isDisabled() ? classes.confirm : ''}
                      name="corporationAddresses"
                      value={`${
                        corporationInfo?.corporationAddressRegion
                      }${' '}${
                        corporationInfo?.corporationAddressLocality
                      }${' '}${
                        corporationInfo?.corporationAddressStreet
                      }${' '}${
                        corporationInfo?.corporationAddressBuilding || ''
                      }`}
                      handleInputEvent={handleInputEvent}
                      disabled={isDisabled()}
                      fullWidth
                    />
                  </InputRowGridPadding>
                </Box>
              </>
            )}
            <Box sx={{ margin: '5px 0px -10px 20px' }}>
              <InputRowGridPadding
                label="代表電話番号"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <div className={isDisabled() ? classes.confirm : ''}>
                  <InputTelephone
                    name1="corporationTelephoneNumber1"
                    value1={corporationInfo?.corporationTelephoneNumber1}
                    name2="corporationTelephoneNumber2"
                    value2={corporationInfo?.corporationTelephoneNumber2}
                    name3="corporationTelephoneNumber3"
                    value3={corporationInfo?.corporationTelephoneNumber3}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    handleFocusEvent={(event) => {
                      const result = corporationTelephoneNumberValidation(
                        corporationInfo?.corporationTelephoneNumber1,
                        corporationInfo?.corporationTelephoneNumber2,
                        corporationInfo?.corporationTelephoneNumber3
                      );
                      setCorporationInfoMessage({
                        ...corporationInfoMessage,
                        corporationTelephoneNumber: result[0],
                        corporationTelephoneNumber1: result[1],
                        corporationTelephoneNumber2: result[2],
                        corporationTelephoneNumber3: result[3],
                      });
                    }}
                    error={
                      !!corporationInfoMessage?.corporationTelephoneNumber ||
                      !!corporationInfoMessage?.corporationTelephoneNumber1 ||
                      !!corporationInfoMessage?.corporationTelephoneNumber2 ||
                      !!corporationInfoMessage?.corporationTelephoneNumber3
                    }
                  />
                </div>
                {status === 0 && (
                  <>
                    <Typography color="error">
                      {corporationInfoMessage?.corporationTelephoneNumber}
                    </Typography>
                    <Typography color="error">
                      {corporationInfoMessage?.corporationTelephoneNumber1}
                    </Typography>
                    <Typography color="error">
                      {corporationInfoMessage?.corporationTelephoneNumber2}
                    </Typography>
                    <Typography color="error">
                      {corporationInfoMessage?.corporationTelephoneNumber3}
                    </Typography>
                  </>
                )}
              </InputRowGridPadding>
            </Box>

            <Typography variant="h3">
              登記事項証明書（現在事項証明書）
              {status === 0 && <span className={classes.span}>＊</span>}
            </Typography>
            {!isDisabled() && (
              <Typography variant="body1" className={classes.mainText}>
                登記事項証明書（現在事項証明書）等をアップロードしてください。（PDF）
                <br />
                １ファイルあたりの上限は15MBです。
              </Typography>
            )}
            <Box sx={{ margin: '30px 0px 5px' }}>
              <Table aria-label="simple table" className={classes.fileTable}>
                {/** アップロードエリア */}
                {!isDisabled() && !uploadFile?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell
                      style={{ width: '900px', wordBreak: 'break-all' }}
                    >
                      <Typography className={classes.fileSelectMsg}>
                        PDFファイルを選択して下さい
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <FileUpload
                        inputRef={inputRef}
                        file={uploadFile}
                        setUploadFile={setUploadFile}
                        disabled={isDisabled()}
                        deleteButton={false}
                        uploadButton
                        uploadCallBack={() => {
                          const gaTagId = 'B-030-014-003';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!isDisabled() && uploadFile?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell
                      style={{ width: '900px', wordBreak: 'break-all' }}
                    >
                      <Typography>{uploadFile?.name}</Typography>
                    </TableCell>

                    <TableCell>
                      <FileUpload
                        inputRef={inputRef}
                        file={uploadFile}
                        setUploadFile={setUploadFile}
                        disabled={isDisabled()}
                        deleteButton
                        deleteCallBack={() => {
                          const gaTagId = 'B-030-014-004';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {isDisabled() && uploadFile?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell
                      style={{ width: '900px', wordBreak: 'break-all' }}
                    >
                      <Typography>{uploadFile?.name}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Table>

              {status === 0 && (
                <Typography color="error" style={{ marginTop: 20 }}>
                  {corporationInfoMessage?.uploadFile}
                </Typography>
              )}
            </Box>
            <Typography variant="h3" style={{ marginTop: '68px' }}>
              申請理由{status === 0 && <span className={classes.span}>＊</span>}
            </Typography>
            {!isDisabled() && (
              <Typography variant="body1" className={classes.mainText}>
                {isCorpApply() && (
                  <>
                    利用を検討いただいているサービス名もしくは商談をしているトヨタ自動車の担当部署および担当者氏名を記載ください。
                    <br />
                    ※申請理由の内容によっては、企業登録の申請を否認する場合がございます。
                  </>
                )}
                {!isCorpApply() && (
                  <>
                    変更申請の理由を記載ください。
                  </>
                )}
              </Typography>
            )}
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <TextInput
                name="applyReason"
                value={corporationInfo?.applyReason}
                placeholder="申請理由を記載ください。"
                handleInputEvent={handleInputEvent}
                disabled={isDisabled()}
                minRows={3}
                maxRows={3}
                multiline
                fullWidth
                onBlur={(event) => {
                  const message = applyReasonValidation(
                    { value: event.target.value },
                    '\r\n',
                  );
                  setCorporationInfoMessage({
                    ...corporationInfoMessage,
                    applyReason: message,
                  });
                }}
                error={!!corporationInfoMessage?.applyReason}
              />
              {status === 0 && (
                <Typography color="error">
                  {corporationInfoMessage?.applyReason}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                marginTop: '50px',
                marginBottom: '60px',
              }}
            />
            <Grid
              container
              direction="row"
              style={{ margin: '80px -12px 50px' }}
            >
              {/* ボタン配置 */}
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>
                  {status === 0 && 'キャンセル'}
                  {status === 1 && '戻る'}
                </ButtonBack>
              </Grid>

              <Grid item xs={6} style={{ padding: '0px 12px' }}>
                <ButtonForward
                  onClick={handleNext}
                  disabled={error}
                  style={{ margin: 'auto' }}
                >
                  {status === 0 && '確認する'}
                  {status === 1 && 'この内容で申請する'}
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </Grid>
            {status === 0 && (
              <>
                <Grid
                  container
                  direction="row"
                  style={{ margin: '0px -12px 105px' }}
                >
                  {/* ボタン配置 */}
                  <Grid item xs={3} />
                  <Grid item xs={6}>
                    <ButtonCancel onClick={handleCancel}>
                      申請を取り下げる
                    </ButtonCancel>
                    <ConfirmDialog
                      title="申請を取り下げますか？"
                      body="申請を取り下げると、申請データは削除されます。\n削除されたデータをもとに戻すことはできません。"
                      open={confirmDialog}
                      setOpen={setConfirmDialog}
                      okText="取り下げる"
                      cancelText="キャンセル"
                      okFetch={async () => {
                        await fetchCancel();
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </>
            )}
          </Container>
        )}
      </>
    </Template>
  );
};
export default CompanyInfoEditPage;
