import { FC } from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInfo } from 'app/style/theme';
import { useLocation } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import Header, { HeaderProps } from '../block/header';
import Footer from '../block/footer/index';

export interface TemplateProps {
  title?: string;
  headerProps?: HeaderProps;
}
const Template: FC<TemplateProps> = ({ children, title, headerProps }) => {
  const useStyles = makeStyles(() => ({
    teaser: {
      width: '100%',
      height: '120px',
      padding: '0 50px',
      marginBottom: 10,
      backgroundColor: `${ColorInfo.gray05}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiTypography-h1': {
        maxWidth: '1000px',
      },
    },
    body: {
      maxWidth: 1000,
      margin: '0 auto',
      padding: 0,
    },
    wrap: {
      position: 'relative',
      fontWeight: 400,
      // fontSize: '62.5%',
      overflow: 'hidden',
    },
    nonresWrap: {
      minWidth: 1200,
      position: 'relative',
      fontWeight: 400,
      // fontSize: '62.5%',
      overflow: 'hidden',
    },
  }));
  const classes = useStyles();
  // Location
  const { pathname } = useLocation();

  const isResponsive = (path: string): boolean => {
    switch (path) {
      case PageInfo.TOP.path: // top画面
        return true;
        break;
      default:
        return false;
        break;
    }
  };
  return (
    <div
      className={`${
        isResponsive(pathname) ? classes.wrap : classes.nonresWrap
      }`}
    >
      <Header
        elevation={headerProps?.elevation}
        transparent={headerProps?.transparent}
      />
      {title && (
        <Box className={classes.teaser}>
          <Typography variant="h1">{title}</Typography>
        </Box>
      )}
      <Container className={classes.body}>
        <Box>{children}</Box>
      </Container>
      <Footer />
    </div>
  );
};

export default Template;
