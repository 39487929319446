import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UpdateResponse } from 'app/service/customer/corporation/users/mail';
import {
  ApiConst,
  ApiConfig,
  PageInfo,
  UserTypeConst,
} from 'app/utils/constants';
import useApi from 'app/hooks/api';
import useOneId from 'app/hooks/oneId';

/**
 * ユーザ招待業務カスタムHooks
 */
export const useCorpUsersInviteMail = (id: string) => {
  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<UpdateResponse>();

  // 招待者情報取得API
  const usersInviteMailAPI = () => {
    const req = {
      /** empty */
    };
    const mailConf: ApiConfig = {
      url: `${ApiConst.CORP_USERS_INVITE.url}/${id}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      withoutAuth: true,
    };
    // 登録API呼出し
    void callApi(mailConf, 'GET', req);
  };

  // ログイン関連(OneID Connect関連)カスタムHooks
  const { eventLoginVisit, eventLoginVisitCreate } = useOneId();

  const history = useHistory();
  // 招待者情報取得API実行後処理
  useEffect(() => {
    if (response) {
      // 正常
      /** 招待した方がIDを持っていない場合 */
      if (response.userType == null) {
        /** OneID認可要求 */
        eventLoginVisitCreate(id);
      } else if (response.userType !== undefined) {
        switch (response.userType) {
          /** 招待した人がプレミアム（既にS基盤登録済） */
          case UserTypeConst.CORP_PREMIUM:
          case UserTypeConst.PERSON:
            /** ユーザ招待エラー画面へ遷移。処理終了 */
            history.replace(
              `${PageInfo.CORP_USERS_INVITE_MAIL_ERROR.path}/${response.userType}`
            );
            break;
          default:
            /** OneID認可要求 */
            eventLoginVisit(id);
            break;
        }
      }
    } else if (error?.code) {
      /** 400エラーが来た場合、ユーザ招待エラー画面へ遷移。処理終了 */
      history.replace(
        `${PageInfo.CORP_USERS_INVITE_MAIL_ERROR.path}/${UserTypeConst.NOT_USER}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    usersInviteMailAPI,
  };
};
export default useCorpUsersInviteMail;
