import { FC } from 'react';
import Template from 'app/views/components/template';
import { Container, Grid, Typography, Link } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonForward } from 'app/views/components/block/button';
import { getFaqUrl, PageInfo, UserTypeConst } from 'app/utils/constants';
import useOneId from 'app/hooks/oneId';
import { OuterBlankLink } from 'app/views/components/atom/link';
import onGaClick from 'app/utils/gaclick';

const CompanyUsersInviteMailPage: FC = () => {
  // pathパラメータ取得(:userType)
  const params = useParams<{ userType: string }>();
  const history = useHistory();
  const { eventMypage } = useOneId();
  return (
    <>
      <Template title="企業ユーザー招待エラー">
        <Container fixed>
          {params.userType === UserTypeConst.NOT_INVITED_USER && (
            <Grid>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ marginBottom: '0px' }}
              >
                <Grid style={{ marginBottom: '80px', marginTop: '90px' }}>
                  <Typography align="center">
                    現在ご利用中のメールアドレスは、企業ユーザー登録ができません。
                    <br />
                    招待メールを受信したメールアドレスで、新規登録またはログインをしてください。
                  </Typography>
                </Grid>
                <Grid>
                  <>
                    <ButtonForward
                      onClick={(event) => {
                        event.preventDefault();
                        history.push(PageInfo.TOP.path);
                      }}
                      style={{ margin: 'auto' }}
                    >
                      トップへ
                    </ButtonForward>
                  </>
                </Grid>
              </Grid>
            </Grid>
          )}
          {params.userType === UserTypeConst.CORP_PREMIUM && (
            <Grid>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ marginBottom: '0px' }}
              >
                <Grid style={{ marginBottom: '80px', marginTop: '90px' }}>
                  <Typography align="center">
                    現在ご利用中のメールアドレスは、すでに他の企業ユーザーとして登録されています。
                    <br />
                    <br />
                    招待を受ける場合は、企業ユーザー登録の解除が必要です。
                    <br />
                    企業ユーザー登録を解除のうえ、招待メールに記載のURLよりToyota
                    Biz Centerをご利用ください。
                  </Typography>
                </Grid>
                <Grid>
                  <>
                    <ButtonForward
                      onClick={(event) => {
                        event.preventDefault();
                        history.push(PageInfo.MYPAGE.path);
                      }}
                      style={{ margin: 'auto' }}
                    >
                      マイページ
                    </ButtonForward>
                    {/* 7月号口時点では非表示 */}
                    {/* <Typography align="center">
                      <Grid
                        container
                        justifyContent="center"
                        direction="column"
                      >
                        <ButtonForward
                          onClick={() => eventMypage()}
                          style={{ margin: 'auto' }}
                        >
                          マイページ
                        </ButtonForward>
                      </Grid>
                      <br />
                      「TOYOTAアカウント」の解除方法は、
                      <OuterBlankLink
                        href={getFaqUrl(3) || '/'}
                        text="こちら（よくあるご質問）"
                        // GAタグ:ユーザ招待鰓エラー-よくあるご質問
                        gaTagId="B-030-006-002"
                      />
                      をご確認ください。
                    </Typography> */}
                  </>
                </Grid>
              </Grid>
            </Grid>
          )}
          {params.userType === UserTypeConst.PERSON && (
            <Grid>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ marginBottom: '0px' }}
              >
                <Grid style={{ marginBottom: '80px', marginTop: '90px' }}>
                  <Typography align="center">
                    個人区分エラー
                    <br />
                    <br />
                    Toyota Biz Centerをご利用いただくには、法人の「TOYOTAアカウント」である必要があります。
                    <br />
                    法人の「TOYOTAアカウント」の登録については、
                    <Link target="_blank" href="https://faq.toyota.jp/faq/show/6647">
                      <span>こちら</span>
                    </Link>
                    でご確認ください。
                  </Typography>
                </Grid>
                <Grid>
                  <>
                    <ButtonForward
                      onClick={(event) => {
                        event.preventDefault();
                        history.push(PageInfo.MYPAGE.path);
                      }}
                      style={{ margin: 'auto' }}
                    >
                      マイページ
                    </ButtonForward>
                  </>
                </Grid>
              </Grid>
            </Grid>
          )}
          {params.userType === UserTypeConst.NOT_USER && (
            <Grid>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ marginBottom: '0px' }}
                // style={{ marginTop: '90px' }}
              >
                <Grid style={{ marginBottom: '80px', marginTop: '90px' }}>
                  <Typography align="center">
                    招待が無効になりました。
                    <br />
                    企業管理者またはアカウント管理権限保有者にご確認のうえ、再度招待を受けてください。
                  </Typography>
                </Grid>
                <Grid>
                  <>
                    <ButtonForward
                      onClick={(event) => {
                        event.preventDefault();
                        history.push(PageInfo.TOP.path);
                      }}
                      style={{ margin: 'auto' }}
                    >
                      トップへ
                    </ButtonForward>
                  </>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </Template>
    </>
  );
};

export default CompanyUsersInviteMailPage;
