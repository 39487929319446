import useApi from 'app/hooks/api';
import {
  FielDownloadResponse,
  FielDownloadRequest,
} from 'app/service/customer/download';
import { ApiConst } from 'app/utils/constants';

/**
 * 各種ファイルのダウンロードAPI
 */
export const useFileDownload = () => {
  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<FielDownloadResponse>();

  // 各種ファイルのダウンロードAPI呼出し
  const fileDownloadAPI = (guideUrl: string) => {
    const req: FielDownloadRequest = { filePath: encodeURI(guideUrl) };
    void callApi(ApiConst.FILE_DOWNLOAD, 'GET', req);
  };

  return {
    fileDownloadAPI,
    response,
    error,
  };
};
export default useFileDownload;
