import { FC, memo, useContext } from 'react';
import Template from 'app/views/components/template';
import {
  FormControl,
  Grid,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import { ButtonForward } from 'app/views/components/block/button';
import { PageInfo, OuterLinkInfo } from 'app/utils/constants';
import { useHistory, Link } from 'react-router-dom';
import { ColorInfo } from 'app/style/theme';
import { AuthContext } from 'app/hooks/context/auth';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick, OnOutDmpClick } from 'app/utils/dmp';

// スタイル
const useStyles = makeStyles(() => ({
  text: {
    paddingLeft: 15,
    '& strong': {
      fontWeight: 'normal',
      color: ColorInfo.red01,
    },
    '& span': {
      paddingRight: 15,
    },
  },
  // メニューリンク関連
  textLink: {
    fontSize: '1.5rem',
    color: ColorInfo.blue02,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
}));

// 問い合わせ画面コンポーネント
const InquiryBranchPage: FC = memo(() => {
  const history = useHistory();
  const classes = useStyles();
  const { authInfo } = useContext(AuthContext);
  return (
    <>
      <Template title="お問い合わせ">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          style={{ margin: 0 }}
        >
          <Typography variant="h3">Toyota Biz Centerについて</Typography>
          <Typography className={classes.text}>
            <Box sx={{ marginTop: '5px', textAlign: 'left' }}>
              「企業登録について」「アカウント管理権限について」など、Toyota
              Biz Centerについてのお問い合わせはこちらから。
            </Box>
            <Box
              sx={{ marginTop: '5px', marginBottom: '30px', textAlign: 'left' }}
            >
              トヨタ自動車株式会社
              <span />
              お客様相談センターより回答いたします。以下の注意事項をご確認のうえ、お問い合わせください。
            </Box>
            {/* 7月号口時点では ※操作や機能についてのご質問は～～～は非表示 そのうち復活する可能性あるので、コメントアウト */}
            {/* <Box
              sx={{
                marginTop: '5px',
                marginBottom: '30px',
                textAlign: 'left',
              }}
            >
              ※操作や機能についてのご質問は、
              {/* TODO 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる }
              <a
                href={OuterLinkInfo.FAQ_LINK_URL.path}
                target="_blank"
                rel="noreferrer"
                onClick={(event) => {
                  onGaClick('B-020-004-002');
                  // DMPビーコン
                  OnOutDmpClick(
                    OuterLinkInfo.FAQ_LINK_URL.path,
                    authInfo?.scimId
                  );
                }}
              >
                よくあるご質問
              </a>
              {/* <Link
              to="/"
              className={`${classes.textLink}`}
              onClick={(event) => {
                event.preventDefault();
               history.push(PageInfo.FAQ.path);
              }}
            >
              よくあるご質問
            </Link> }
              もご覧ください。
            </Box> */}
            <Box
              sx={{
                marginTop: '5px',
                marginBottom: '15px',
                textAlign: 'left',
              }}
            >
              ＜注意事項＞
            </Box>
            <Box sx={{ marginTop: '5px', textAlign: 'left' }}>
              <span>①</span>お問い合わせ内容は
              <strong>「おクルマ以外や弊社について」</strong>
              をお選びください。
            </Box>
            <Box sx={{ marginTop: '5px', textAlign: 'left' }}>
              <span>②</span>タイトルに
              <strong>「Toyota Biz Center」</strong>
              とご入力ください。
            </Box>
          </Typography>
          <Grid
            container
            justifyContent="center"
            style={{ margin: '80px -12px 0px' }}
          >
            <Box>
              <FormControl component="fieldset">
                <ButtonForward
                  onClick={() => {
                    // GAタグ:お問い合わせ分岐-Toyota Biz Centerについてボタン押下
                    onGaClick('B-020-004-001');
                    // DMPビーコンタグ：お問い合わせ分岐-Toyota Biz Centerについてボタン押下
                    OnOutDmpClick(
                      OuterLinkInfo.TOYOTA_BIZ_CENTER_INQUIRY_URL.path,
                      authInfo?.scimId
                    );
                    window.open(
                      OuterLinkInfo.TOYOTA_BIZ_CENTER_INQUIRY_URL.path
                    );
                  }}
                >
                  Toyota Biz Centerについて
                </ButtonForward>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          style={{ margin: 0 }}
        >
          <Typography variant="h3">サービスに関するお問い合わせ</Typography>
          <Typography className={classes.text}>
            <Box sx={{ textAlign: 'left' }}>
              Toyota Biz
              Centerのご提供するサービスについてのご相談、お申し込み、契約変更などのお問い合わせはこちらから。
            </Box>
            <Box sx={{ marginTop: '5px', textAlign: 'left' }}>
              サービス担当者よりご連絡いたします。対象のサービスを選択のうえ、お気軽にお問い合わせください。
            </Box>
          </Typography>
          <Grid
            container
            justifyContent="center"
            style={{ margin: '80px -12px 0px' }}
          >
            <Box>
              <FormControl component="fieldset">
                <ButtonForward
                  onClick={() => history.push(PageInfo.INQUIRY_INPUT.path)}
                >
                  サービスについて
                </ButtonForward>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Template>
    </>
  );
});

export default InquiryBranchPage;
