import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext } from 'react';
import {
  UpdateRequest,
  UpdateResponse,
} from 'app/service/customer/corporation/users/update';
import { ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';

/**
 * 部署名変更
 */
export const useCorpUsersUpdate = () => {
  // 認証コンテキスト
  const { authInfo, setDepartmentName } = useContext(AuthContext);

  const [name, setName] = useState('');
  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<UpdateResponse>();

  // 部署名変更API呼出し
  const usersDepartmentNameUpdateAPI = (departmentName: string) => {
    setName(departmentName);
    const scimId = authInfo?.scimId ? authInfo?.scimId : '';
    const req: UpdateRequest = {
      scimId,
      departmentName,
    };
    void callApi(ApiConst.CORP_USERS_UPDATE, 'POST', req);
  };

  // 部署名変更API応答処理
  useEffect(() => {
    if (response) {
      // 認証情報を更新(ID連携要求APIによる)
      setDepartmentName(name);
    } else if (error?.code) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    usersDepartmentNameUpdateAPI,
    response,
    error,
  };
};
export default useCorpUsersUpdate;
