/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useState, useRef } from 'react';
import { ApiConst, ApiConfig, PageInfo } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { AuthContext } from 'app/hooks/context/auth';
import { useHistory, useParams } from 'react-router-dom';
import {
  ContractUsersInviteResponse,
  ContractUsersInviteRequest,
  ContractUsersInviteRegistRequest,
  ContractUsersInviteRegistResponse,
} from 'app/service/contract/users/invite';
import useValidate from 'app/hooks/validate';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

// 利用者招待メッセージ
export interface ContractUsersInviteMessage {
  option: string;
  dataList: string;
}
// 利用者招待
export interface ContractUsersInvite {
  page: number; // 取得対象ページ番号
  size: string; // 表示対象件数
  sortItem?: 'name' | 'departmentName' | null; // ソート項目
  sortType?: 'asc' | 'desc' | null; // ソート順
  searchValue: string; // 検索条件
  totalCount: number; // 総件数
  contractOptionList: ContractOptionItem[];
  dataList: DataItem[];
  confirmDataList: ConfirmDataItem[];
  contractServiceName: string;
  contractMenuName: string;
  contractCourseName: string;
  contractOptionName: string;
  contractName: string;
  allSelected: boolean;
  hasServiceList: boolean;

  contractNo: string;
  contractMenuId: string;
  contractCourseId: string;
}

export interface ContractOptionItem {
  selected: boolean;
  contractOptionName: string;
  contractOptionId: string;
}

export interface DataItem {
  usageAuthority: boolean;
  authorization: boolean;
  id: string;
  email: string;
  name: string;
  departmentName: string;
}

export interface ConfirmDataItem {
  usageAuthority: string;
  authorization: string;
  id: string;
  email: string;
  name: string;
  departmentName: string;
}

// RecipientInfoItem IF
export interface RecipientInfoItem {
  uninvitedScimId: string;
  authorityFlag: string;
}

// OptionItem IF
export interface OptionItem {
  contractOptionId: string;
}
/**
 * 利用者招待業務カスタムHooks
 */
export const useContractUsersInvite = () => {
  // 利用者招待情報
  const [contractUsersInvite, setContractUsersInvite] =
    // 初期値設定
    useState<ContractUsersInvite>({
      page: 0, // 取得対象ページ番号
      size: '', // 表示対象件数
      searchValue: '', // 検索条件
      totalCount: 0, // 総件数
      contractOptionList: [],
      dataList: [],
      confirmDataList: [],
      contractServiceName: '',
      contractMenuName: '',
      contractCourseName: '',
      contractOptionName: '',
      contractName: '',
      allSelected: false,
      hasServiceList: true,

      contractNo: '',
      contractMenuId: '',
      contractCourseId: '',
    });

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // 利用者招待メッセージ
  const [contractUsersInviteMessage, setContractUsersInviteMessage] =
    // 初期値設定
    useState<ContractUsersInviteMessage>({
      option: '',
      dataList: '',
    });
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  // ValidateHooks
  const valid = useValidate();

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // 画面状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   * 検索中：2
   * 検索クリア：3
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  // 前へボタン制御（true：非活性、false：活性）
  const [isPageBackDisabled, setIsPageBackDisabled] = useState<boolean>(true);
  // 次へボタン制御（true：非活性、false：活性）
  const [isPageNextDisabled, setIsPageNextDisabled] = useState<boolean>(true);

  interface ParamTypes {
    contractNo: string;
    contractMenuId: string;
    contractCourseId: string;
  }
  const { contractNo, contractMenuId, contractCourseId } =
    useParams<ParamTypes>();
  const [contractParams, setContractParms] = useState<ParamTypes>({
    contractNo,
    contractMenuId,
    contractCourseId,
  });
  // サービス選択
  const handleCheckEvent = (name: string, value: boolean) => {
    const options: ContractOptionItem[] =
      contractUsersInvite.contractOptionList.map((row) => ({
        selected: row.contractOptionId === name ? value : row.selected,
        contractOptionName: row.contractOptionName,
        contractOptionId: row.contractOptionId,
      }));

    setContractUsersInvite({
      ...contractUsersInvite,
      contractOptionList: options,
    });
  };

  // 利用権限追加
  const handleUsageAuthorityCheckEvent = (name: string, value: boolean) => {
    // OFF時は権限付与もOFFにする
    const datalist: DataItem[] = contractUsersInvite.dataList.map((row) => ({
      usageAuthority: row.id === name ? value : row.usageAuthority,
      authorization: row.id === name && !value ? false : row.authorization,
      id: row.id,
      email: row.email,
      name: row.name,
      departmentName: row.departmentName,
    }));

    setContractUsersInvite({
      ...contractUsersInvite,
      dataList: datalist,
    });
  };

  // 権限付与追加
  const handleAuthorizationCheckEvent = (name: string, value: boolean) => {
    // ON時は利用権限もONにする
    const datalist: DataItem[] = contractUsersInvite.dataList.map((row) => ({
      usageAuthority: row.id === name && value ? value : row.usageAuthority,
      authorization: row.id === name ? value : row.authorization,
      id: row.id,
      email: row.email,
      name: row.name,
      departmentName: row.departmentName,
    }));

    setContractUsersInvite({
      ...contractUsersInvite,
      dataList: datalist,
    });
  };

  // 全てのユーザーを選択
  const handleAllSelect = (name: string, value: boolean) => {
    const datalist: DataItem[] = contractUsersInvite.dataList.map((row) => ({
      usageAuthority: value,
      authorization: !value ? false : row.authorization,
      id: row.id,
      email: row.email,
      name: row.name,
      departmentName: row.departmentName,
    }));

    setContractUsersInvite({
      ...contractUsersInvite,
      dataList: datalist,
      [name]: value,
    });
  };

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // プルダウンの場合はイベント発火
    if (name === 'size' && target.type !== 'checkbox') {
      handleSelect(target.value);
    } else {
      setContractUsersInvite({
        ...contractUsersInvite,
        [name]: value,
      });
    }
  };

  // 表示件数変更アクション
  const handleSelect = (sizeIndex: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setContractUsersInvite({
      ...contractUsersInvite,
      page: pageIndex,
      size: sizeIndex,
    });
    // 未招待ユーザー一覧情報リクエスト呼び出し
    contractUsersInviteListAPI(
      pageIndex,
      sizeIndex,
      contractUsersInvite.searchValue
    );
  };

  // 前へアクション
  const handleSearchBack = () => {
    // ページ減算
    const pageIndex = contractUsersInvite.page - 1;

    setContractUsersInvite({
      ...contractUsersInvite,
      page: pageIndex,
      size: contractUsersInvite.size,
    });
    // 未招待ユーザー一覧情報リクエスト呼び出し
    contractUsersInviteListAPI(
      pageIndex,
      contractUsersInvite.size,
      contractUsersInvite.searchValue
    );
  };

  // 次へアクション
  const handleSearchNext = () => {
    // ページ加算
    const pageIndex = contractUsersInvite.page + 1;

    setContractUsersInvite({
      ...contractUsersInvite,
      page: pageIndex,
      size: contractUsersInvite.size,
    });
    // 未招待ユーザー一覧情報リクエスト呼び出し
    contractUsersInviteListAPI(
      pageIndex,
      contractUsersInvite.size,
      contractUsersInvite.searchValue
    );
  };

  // 検索アクション
  const handleSearch = () => {
    // 1ページ目に設定
    const pageIndex = 1;

    setContractUsersInvite({
      ...contractUsersInvite,
      searchValue: contractUsersInvite.searchValue,
      page: pageIndex,
      size: contractUsersInvite.size,
    });

    // 未招待ユーザー一覧情報リクエスト呼び出し
    contractUsersInviteListAPI(
      pageIndex,
      contractUsersInvite.size,
      contractUsersInvite.searchValue
    );
  };

  // 並び替え
  const handleSearchSort = (
    sortItem: 'name' | 'departmentName' | null | undefined,
    sortType: 'asc' | 'desc' | null | undefined
  ) => {
    // 並び替え情報設定
    setContractUsersInvite({
      ...contractUsersInvite,
      sortItem,
      sortType,
    });

    // 未招待ユーザー一覧情報リクエスト呼び出し
    contractUsersInviteListAPI(
      contractUsersInvite.page,
      contractUsersInvite.size,
      contractUsersInvite.searchValue,
      sortItem,
      sortType
    );
  };

  // 検索条件削除
  const handleSearchDelete = () => {
    // 1ページ目に設定
    const pageIndex = 1;

    // Api呼び出しステータスを 3(検索クリア)に設定
    if (apiCallStatus === 2) setApiCallStatus(3);

    setContractUsersInvite({
      ...contractUsersInvite,
      searchValue: '',
    });

    // 未招待ユーザー一覧情報リクエスト呼び出し
    contractUsersInviteListAPI(pageIndex, contractUsersInvite.size);
  };

  // 戻るアクション
  const history = useHistory();
  const handleBack = () => {
    history.go(-1);
  };

  // メッセージ初期化
  const initContractUsersInviteMessage = () => {
    setContractUsersInviteMessage({
      ...contractUsersInviteMessage,
      option: '',
      dataList: '',
    });
  };

  // 入力チェック処理
  const inputCheck = (): boolean => {
    initContractUsersInviteMessage();
    let result = false;

    // オプション必須チェック
    let optionMessage = '';
    const options = contractUsersInvite.contractOptionList.map(
      (row) => row.selected
    );
    optionMessage = valid.oneOfMultipleRequiredCheck(
      'オプション',
      options,
      '選択'
    );
    result = result || !!optionMessage;

    // ユーザー追加必須チェック
    let dataListMessage = '';
    const datalist = contractUsersInvite.dataList.map(
      (row) => row.usageAuthority
    );
    dataListMessage = valid.oneOfMultipleRequiredCheck(
      '追加する利用者',
      datalist,
      '選択'
    );
    result = result || !!dataListMessage;

    // メッセージ設定
    setContractUsersInviteMessage({
      ...contractUsersInviteMessage,
      option: optionMessage,
      dataList: dataListMessage,
    });

    if (result) {
      // エラーメッセージ
      setGlobalMessage(
        `入力内容にエラーがあります。ご確認のうえ再度「確認」ボタンを押してください。`
      );
      window.scrollTo(0, 0);
    } else {
      setGlobalMessage('');
    }

    return result;
  };

  // 次処理
  const handleNext = () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力チェック
        if (!inputCheck()) {
          confirm();
          nextPage();
        }
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        void contractUsersInviteRegistAPI();
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        nextPage();
        break;
    }
  };

  // 画面遷移
  const nextPage = () => {
    // 次画面へ遷移（状態を加算）
    setStatus((preStatus) => preStatus + 1);
    switch (status) {
      case 0:
        // ブラウザバック対応 確認画面への遷移はpush
        history.push(PageInfo.CONTRACT_USERS_INVITE_CONFIRM.path);
        break;
      case 1:
        setApiCallStatus(1);
        // ブラウザバック対応 完了画面への遷移はreplace
        history.replace(PageInfo.CONTRACT_USERS_INVITE_COMPLETE.path);
        break;
      case 2:
        setApiCallStatus(1);
        history.replace(
          `${PageInfo.CONTRACT_USERS_MANAGE.path}/${contractParams.contractNo}/${contractParams.contractMenuId}/${contractParams.contractCourseId}`
        );
        break;
      default:
        history.push(PageInfo.CONTRACT_USERS_INVITE_INPUT.path);
        break;
    }
  };

  // 確認画面用処理
  const confirm = () => {
    const datas: ConfirmDataItem[] = contractUsersInvite.dataList
      .filter((row) => row.usageAuthority)
      .map((row) => ({
        usageAuthority: row.usageAuthority ? '●' : '-',
        authorization: row.authorization ? '●' : '-',
        id: row.id,
        email: row.email,
        name: row.name,
        departmentName: row.departmentName,
      }));

    setContractUsersInvite({
      ...contractUsersInvite,
      confirmDataList: datas,
    });
  };

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ContractUsersInviteResponse>();

  // API呼び出しカスタムHooks
  const {
    response: registResponse,
    error: registError,
    callApi: registApi,
  } = useApi<ContractUsersInviteRegistResponse>();

  // 未招待ユーザー一覧API
  const contractUsersInviteListAPI = (
    pageIndex = 1,
    pageSize = '30',
    searchValue?: string,
    sortItem?: 'name' | 'departmentName' | null, // ソート項目
    sortType?: 'asc' | 'desc' | null // ソート順
  ) => {
    // API Request設定
    const corporationId = authInfo?.corporationId || '';
    const operatorId = authInfo?.scimId || '';

    const req: ContractUsersInviteRequest = {};
    if (searchValue) req.searchValue = searchValue;
    if (searchValue) setApiCallStatus(2); // 画面の状態を検索中＝２に設定

    // ソートを設定
    if (contractUsersInvite.sortItem)
      req.sortItem = contractUsersInvite.sortItem;
    if (sortItem) req.sortItem = sortItem;
    if (contractUsersInvite.sortType)
      req.sortType = contractUsersInvite.sortType;
    if (sortType) req.sortType = sortType;

    // 一覧取得API呼出し
    const CONTRACT_USERS_LIST: ApiConfig = {
      url: `/api/v1/usage/contract/uninvite/users/list/${corporationId}/${contractParams?.contractNo}/${contractParams?.contractMenuId}/${contractParams?.contractCourseId}/${operatorId}/${pageIndex}/${pageSize}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void callApi(CONTRACT_USERS_LIST, 'GET', req);
  };

  // サービス利用ユーザー登録API
  const contractUsersInviteRegistAPI = () => {
    // サービス選択
    const options: OptionItem[] = contractUsersInvite.contractOptionList
      .filter((row) => row.selected)
      .map((row) => ({
        contractOptionId: row.contractOptionId,
      }));

    const datas: RecipientInfoItem[] = contractUsersInvite.dataList
      .filter((row) => row.usageAuthority)
      .map((row) => ({
        uninvitedScimId: row.id,
        authorityFlag: row.authorization ? '1' : '0',
      }));

    // API Request設定
    const req: ContractUsersInviteRegistRequest = {
      corporationId: authInfo?.corporationId || '',
      scimId: authInfo?.scimId || '',
      contractNo: contractUsersInvite.contractNo,
      contractMenuId: contractUsersInvite.contractMenuId,
      contractCourseId: contractUsersInvite.contractCourseId,
      contractOptionList: options,
      dataList: datas,
    };

    // 一覧取得API呼出し
    void registApi(ApiConst.CONTRACT_SERVICES_USER_REGIST, 'POST', req);
  };

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: `${PageInfo.CONTRACT_USERS_INVITE_INPUT.path}/${contractNo}/${contractMenuId}/${contractCourseId}`,
        confirm: PageInfo.CONTRACT_USERS_INVITE_CONFIRM.path,
        complete: PageInfo.CONTRACT_USERS_INVITE_COMPLETE.path,
      },
      haveParam: PageInfo.CONTRACT.path,
    },
  };
  const browser = useBrowserBack(props);

  useEffect(() => {
    if (status === 1) {
      if (inputCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, status]);
  useEffect(() => {
    // 初期画面（初回レンダリングhistory履歴を上書き）
    if (contractNo && contractMenuId && contractCourseId) {
      // パラメータが取得できた場合（契約内容照会画面からの一連操作で遷移した場合）
      // そのまま表示
    } else {
      // 上記以外の場合はuseBrowserに任せて、契約内容照会に飛ばす
      return;
    }
    // 初期処理
    const pageIndex = 1;
    const pageSize = '30';

    // Api呼び出しを初期化
    setApiCallStatus(0);

    setContractUsersInvite({
      page: pageIndex, // 取得対象ページ番号
      size: pageSize, // 表示対象件数
      searchValue: '', // 検索条件
      totalCount: 1, // 総件数
      contractOptionList: [],
      dataList: [],
      confirmDataList: [],
      contractServiceName: '',
      contractMenuName: '',
      contractCourseName: '',
      contractOptionName: '',
      contractName: '',
      allSelected: false,
      hasServiceList: true,

      contractNo,
      contractMenuId,
      contractCourseId,
    });

    // パラメータが取得できた場合（契約内容照会画面からの一連操作で遷移した場合）
    if (contractNo && contractMenuId && contractCourseId) {
      // 未招待ユーザー一覧API呼び出し
      contractUsersInviteListAPI(pageIndex, pageSize);
    }
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registResponse) {
      nextPage();
      // 初期処理
      const pageIndex = 1;
      const pageSize = '30';

      // Api呼び出しを初期化
      setApiCallStatus(0);

      setContractUsersInvite({
        page: pageIndex, // 取得対象ページ番号
        size: pageSize, // 表示対象件数
        searchValue: '', // 検索条件
        totalCount: 1, // 総件数
        contractOptionList: [],
        dataList: [],
        confirmDataList: [],
        contractServiceName: '',
        contractMenuName: '',
        contractCourseName: '',
        contractOptionName: '',
        contractName: '',
        allSelected: false,
        hasServiceList: true,
        sortItem: null,
        sortType: null,
        contractNo,
        contractMenuId,
        contractCourseId,
      });
      // 入力内容をリセットするためにAPIを呼び出す
      if (
        contractParams?.contractNo &&
        contractParams?.contractMenuId &&
        contractParams?.contractCourseId
      ) {
        // 未招待ユーザー一覧API呼び出し
        contractUsersInviteListAPI(pageIndex, pageSize);
      }
      // 二重POST防止：招待送信後のエラーハンドリングにあわせてここでOFF
      processing.current = false;
    } else if (registError?.code) {
      // API入力値エラー
      // 応答電文を設定
      setGlobalMessage(registError?.message);
      // 入力画面へ戻る
      history.go(-1);
      window.scrollTo(0, 0);

      // 二重POST防止：招待送信後のエラーハンドリングにあわせてここでOFF
      processing.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registError, registResponse]);

  useEffect(() => {
    if (response) {
      // 確認⇒完了
      // Api呼び出しステータスが 0(初期状態) もしくは 3(検索クリア)の場合はステータスを1に設定
      if (apiCallStatus === 0 || apiCallStatus === 3) setApiCallStatus(1);

      // 入力画面
      const { contractOptionList, dataList } = response;
      let hasService = true;
      // サービス覧設定
      let contractOptionRows: ContractOptionItem[];
      if (contractOptionList) {
        contractOptionList.forEach((row) => {
          if (row.contractOptionId === '0') {
            hasService = false;
          }
        });
        if (hasService) {
          contractOptionRows = contractOptionList.map((row) => ({
            selected: false,
            contractOptionId: row.contractOptionId,
            contractOptionName: row.contractOptionName,
          }));
        } else {
          contractOptionRows = contractOptionList.map((row) => ({
            selected: true,
            contractOptionId: row.contractOptionId,
            contractOptionName: row.contractOptionName,
          }));
        }
      } else {
        contractOptionRows = [];
      }

      // 未招待ユーザー一覧設定
      let dataRows: DataItem[];
      if (dataList) {
        dataRows = dataList.map((row) => ({
          usageAuthority: false,
          authorization: false,
          id: row.scimId,
          email: row.email,
          name: row.name,
          departmentName: row.departmentName,
        }));
      } else {
        dataRows = [];
      }

      setContractUsersInvite({
        ...contractUsersInvite,
        totalCount: response.totalCount,
        contractServiceName: response.contractServiceName,
        contractMenuName: response.contractMenuName,
        contractCourseName: response.contractCourseName,
        contractOptionName: response.contractOptionName,
        contractName: response.contractName,
        contractOptionList: contractOptionRows,
        dataList: dataRows,
        hasServiceList: hasService,
      });

      // 次へボタン制御
      if (response.totalPage > contractUsersInvite.page) {
        // 活性
        setIsPageNextDisabled(false);
      } else {
        // 非活性
        setIsPageNextDisabled(true);
      }

      // 前へボタン制御
      if (contractUsersInvite.page > 1) {
        // 活性
        setIsPageBackDisabled(false);
      } else {
        // 非活性
        setIsPageBackDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    status,
    handleBack,
    handleNext,
    isPageBackDisabled,
    isPageNextDisabled,
    handleInputEvent,
    handleCheckEvent,
    handleUsageAuthorityCheckEvent,
    handleAuthorizationCheckEvent,
    handleSearchBack,
    handleSearchNext,
    handleSearch,
    handleSearchDelete,
    handleAllSelect,
    contractUsersInvite,
    contractUsersInviteMessage,
    globalMessage,
    handleSearchSort,
    apiCallStatus,
  };
};
export default useContractUsersInvite;
