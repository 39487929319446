import { FC, memo, ChangeEventHandler } from 'react';
import TextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// スタイル
const useStyles = makeStyles(() => ({
  root: {
    '&$disabled $notchedOutline': {
      border: 'none',
    },
  },
  focused: {},
  disabled: {},
  notchedOutline: {},
}));
// インタフェース
interface BaseProps {
  name: string;
  handleInputEvent?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string | null | undefined;
  placeholder: string;
  multiline: string;
  variant: string;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
}

// インタフェース
type TextInputProps = Omit<BaseProps, keyof MuiTextFieldProps> &
  MuiTextFieldProps;

// テキストフィールド
const TextInput: FC<TextInputProps> = memo(
  ({
    name,
    handleInputEvent,
    value,
    placeholder,
    variant,
    multiline,
    disabled = false,
    fullWidth = false,
    error = false,
    ...props
  }) => {
    const classes = useStyles();
    const variantItem = disabled ? 'outlined' : 'filled';
    const InputPropsItem = disabled
      ? {
          classes: {
            root: classes.root,
            disabled: classes.disabled,
            notchedOutline: classes.notchedOutline,
          },
        }
      : {
          classes: {
            root: classes.root,
            focused: classes.focused,
          },
        };

    return (
      <>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          id={name}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={handleInputEvent}
          disabled={disabled}
          fullWidth={fullWidth}
          variant={variantItem}
          InputProps={InputPropsItem}
          error={error}
          multiline={multiline}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            'data-testid': `${name}`,
          }}
        />
      </>
    );
  }
);
export default TextInput;
