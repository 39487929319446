import React from 'react';
import { Box } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { ButtonConfirm } from 'app/views/components/block/button';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px 150px 20px!important',
    },
    contentsMainArea: {
      display: 'block ',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center',
    },
    buttonArea: {
      marginTop: 30,
    },
  });

interface ConfirmDialogProps extends WithStyles<typeof styles> {
  title: string;
  body: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelText: string;
  okText: string;
  okFetch: () => void;
}

const ConfirmDialog = withStyles(styles)(
  ({
    title,
    body,
    open,
    setOpen,
    cancelText,
    okText,
    okFetch,
    classes,
  }: ConfirmDialogProps) => {
    // \nを改行に変換
    const texts = body.split(/\\n/).map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));

    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <DialogContent className={classes.root}>
          <Box className={classes.contentsMainArea}>
            <Typography variant="body1">
              {title}
              <br />
              <br />
              {texts}
            </Typography>
          </Box>

          <DialogActions className={classes.buttonArea}>
            <ButtonConfirm
              onClick={() => {
                setOpen(false);
              }}
            >
              {cancelText}
            </ButtonConfirm>
            <ButtonConfirm
              onClick={() => {
                okFetch();
              }}
              style={{ marginLeft: 50 }}
            >
              {okText}
            </ButtonConfirm>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
);

export default ConfirmDialog;
