import useApi from 'app/hooks/api';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import {
  NoticeItem,
  NoticeListResponse,
  NoticeYearItem,
  NoticeListRequest,
} from 'app/service/customer/notice';
import { ApiConfig } from 'app/utils/constants';

// お知らせ一覧
export interface NoticeList {
  page: number;
  size: string;
  totalCount: number;
  totalPage: number;
  yearList?: NoticeYearItem[] | null;
  dataList?: NoticeItem[] | null;
}
/**
 * お知らせ一覧取得Hooks
 */
export const useNoticeList = (initFlag: string) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 表示年
  const [viewYear, setViewYear] = useState<string>('');

  // お知らせ一覧
  const [noticeList, setNoticeList] =
    // 初期値設定
    useState<NoticeList>({
      page: 0,
      size: '',
      totalCount: 0,
      totalPage: 0,
      yearList: [],
      dataList: [],
    });

  // 前へボタン制御（true：非活性、false：活性）
  const [isPageBackDisabled, setIsPageBackDisabled] = useState<boolean>(true);
  // 次へボタン制御（true：非活性、false：活性）
  const [isPageNextDisabled, setIsPageNextDisabled] = useState<boolean>(true);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // プルダウンの場合はイベント発火
    if (name === 'size' && target.type !== 'checkbox') {
      handleSelect(target.value);
    } else {
      setNoticeList({
        ...noticeList,
        [name]: value,
      });
    }
  };

  // 表示件数変更アクション
  const handleSelect = (sizeIndex: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setNoticeList({
      ...noticeList,
      page: pageIndex,
      size: sizeIndex,
    });
    // お知らせ一覧情報リクエスト呼び出し
    contractAuthListAPI(pageIndex, sizeIndex, viewYear);
  };

  // 前へアクション
  const handleSearchBack = () => {
    // ページ減算
    const pageIndex = noticeList.page - 1;

    setNoticeList({
      ...noticeList,
      page: pageIndex,
      size: noticeList.size,
    });
    // お知らせ一覧情報リクエスト呼び出し
    contractAuthListAPI(pageIndex, noticeList.size, viewYear);
  };

  // 次へアクション
  const handleSearchNext = () => {
    // ページ加算
    const pageIndex = noticeList.page + 1;

    setNoticeList({
      ...noticeList,
      page: pageIndex,
      size: noticeList.size,
    });
    // お知らせ一覧情報リクエスト呼び出し
    contractAuthListAPI(pageIndex, noticeList.size, viewYear);
  };

  // 年切り替えアクション
  const handleSearchYear = (year: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setNoticeList({
      ...noticeList,
      page: pageIndex,
      size: noticeList.size,
    });
    setViewYear(year);

    // お知らせ一覧情報リクエスト呼び出し
    contractAuthListAPI(pageIndex, noticeList.size, year);
  };

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<NoticeListResponse>();

  // お知らせ一覧API
  const contractAuthListAPI = (
    pageIndex = 1,
    pageSize = '100',
    year?: string
  ) => {
    // API Request設定
    const req: NoticeListRequest = {};
    if (year) req.year = year;

    // 認証非認証確認
    if (authInfo?.isAuth) {
      // 一覧取得API呼出し
      const NOTICE_AUTH_LIST: ApiConfig = {
        url: `/api/v1/support/notice/list/auth/${pageIndex}/${pageSize}`,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      };
      void callApi(NOTICE_AUTH_LIST, 'GET', req);
    } else {
      // 一覧取得API呼出し
      const NOTICE_LIST: ApiConfig = {
        url: `/api/v1/support/notice/list/${pageIndex}/${pageSize}`,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        withoutAuth: true,
      };
      void callApi(NOTICE_LIST, 'GET', req);
    }
  };

  useEffect(() => {
    // 初期処理
    let pageIndex;
    let pageSize;
    let systemYear;

    switch (initFlag) {
      case '0': // '0' TOP画面
        pageIndex = 1;
        pageSize = '3';
        break;
      case '1': // '1' お知らせ一覧画面
        pageIndex = 1;
        pageSize = '100';
        systemYear = new Date().toLocaleString().substring(0, 4);
        setViewYear(systemYear);
        break;
      default:
        // その他
        pageIndex = 1;
        pageSize = '100';
        break;
    }

    setNoticeList({
      page: pageIndex,
      size: pageSize,
      totalPage: 0,
      totalCount: 1,
      yearList: [],
      dataList: [],
    });

    // お知らせ一覧API呼び出し
    contractAuthListAPI(pageIndex, pageSize, systemYear);
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // お知らせ一覧設定
      setNoticeList({
        ...noticeList,
        ...response,
      });

      // 次へボタン制御
      if (response.totalPage > noticeList.page) {
        // 活性
        setIsPageNextDisabled(false);
      } else {
        // 非活性
        setIsPageNextDisabled(true);
      }

      // 前へボタン制御
      if (noticeList.page > 1) {
        // 活性
        setIsPageBackDisabled(false);
      } else {
        // 非活性
        setIsPageBackDisabled(true);
      }
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    noticeList,
    viewYear,
    isPageBackDisabled,
    isPageNextDisabled,
    handleInputEvent,
    handleSearchBack,
    handleSearchNext,
    handleSearchYear,
  };
};
export default useNoticeList;
