import { AuthContext } from 'app/hooks/context/auth';
import useOneId from 'app/hooks/oneId';
import { FC, useContext, useEffect } from 'react';

export function LoginUserInfo(): null {
  const { callUserInfo } = useOneId();
  useEffect(() => {
    void callUserInfo();
  }, [callUserInfo]);

  return null;
}

/**
 * LocalStorage保存したアクセストークンで認証するためのレンダリングなしのコンポーネント
 */
export const LoginUserInfoAuth: FC = ({ children }) => {
  const { ready } = useContext(AuthContext);

  if (ready) {
    return <LoginUserInfo />;
  }
  // callUserInfoで認証処理後に呼び出される。
  return <>{children}</>;
};

export default LoginUserInfoAuth;
