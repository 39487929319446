import { useCallback } from 'react';

declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

const onGaClick = (contentsID: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'SCcustomlink',
    SCcustomlink: `ex-${contentsID}`,
  });
};
export default onGaClick;
