import { useEffect, useState, useContext } from 'react';
import { Request, Response } from 'app/service/contract/detail/';
import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'app/hooks/context/auth';

// 画面表示IF
export interface ContractDetail {
  contractNo: string;
  contractName: string;
  contractServiceName: string;
  contractMenuName: string;
  contractCourseName: string;
  contractOptionName: string;
  contractDateStart: string;
  contractDateEnd: string;
  contractBillingDate: string;
  closingDate: string;
  chargeType: string;
  chargeUnitPrice: string;
  usageRecordLimit: string;
  status: string;
  periodicSendDatetime: string;
  notificationDestinationUrl: string;
  notificationDestinationMail: string;
  dataList: DataItem[];
}

export interface DataItem {
  item: string;
  value: string;
}

/**
 * 契約内容照会－契約詳細業務カスタムHooks
 */
export const useContractDetail = (contractNo: string) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 法人ユーザ一覧情報
  const [contractDetail, setContractDetail] =
    // 初期値設定
    useState<ContractDetail>({
      contractNo: '',
      contractName: '',
      contractServiceName: '',
      contractMenuName: '',
      contractCourseName: '',
      contractOptionName: '',
      contractDateStart: '',
      contractDateEnd: '',
      contractBillingDate: '',
      closingDate: '',
      chargeType: '',
      chargeUnitPrice: '',
      usageRecordLimit: '',
      status: '',
      periodicSendDatetime: '',
      notificationDestinationUrl: '',
      notificationDestinationMail: '',
      dataList: [],
    });

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<Response>();

  // 契約情報詳細取得API
  const contractDetailAPI = () => {
    const scimId = authInfo?.scimId || '';
    // API Request設定
    const req: Request = {};
    // 契約情報詳細取得API呼出し
    const CONTRACT_DETAIL: ApiConfig = {
      url: `/api/v1/usage/contract/info/details/${contractNo}/${scimId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };

    // 契約情報詳細取得API呼出し
    void callApi(CONTRACT_DETAIL, 'GET', req);
  };

  useEffect(() => {
    // 初期処理
    // 契約情報詳細取得
    contractDetailAPI();

    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 契約情報詳細取得API実行後処理
  useEffect(() => {
    if (response) {
      // 正常時
      setContractDetail({
        ...contractDetail,
        dataList: response.dataList,
      });
    } else if (error?.code) {
      // 異常時
      setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    contractDetail,
    globalMessage,
  };
};
export default useContractDetail;
