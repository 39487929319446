import { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from 'app/views/components/atom/text';

// スタイル
const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'left',
  },
  input: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'left',
    width: '250px',
    marginRight: '20px',
  },
  output: {
    padding: '16px 14px',
  },
}));

// インタフェース
interface Props {
  name1: string;
  value1: string;
  placeholder1: string;
  name2: string;
  value2: string;
  placeholder2: string;
  handleInputEvent?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  handleFocusEvent?: React.FocusEventHandler<HTMLInputElement>;
  error?: boolean;
}

// 氏名入力
const InputName: FC<Props> = memo(
  ({
    name1,
    value1,
    placeholder1,
    name2,
    value2,
    placeholder2,
    handleInputEvent,
    disabled = false,
    handleFocusEvent,
    error = false,
  }) => {
    const classes = useStyles();

    return (
      <>
        <Box className={classes.root}>
          {!disabled && (
            <>
              <TextInput
                className={classes.input}
                placeholder={placeholder1}
                name={name1}
                value={value1}
                handleInputEvent={handleInputEvent}
                disabled={disabled}
                onBlur={handleFocusEvent}
                fullWidth
                error={error}
              />
              <TextInput
                className={classes.input}
                placeholder={placeholder2}
                name={name2}
                value={value2}
                handleInputEvent={handleInputEvent}
                disabled={disabled}
                onBlur={handleFocusEvent}
                fullWidth
                error={error}
              />
            </>
          )}
          {disabled && (
            <>
              <Typography className={classes.output}>
                {value1}
                {'　'}
                {value2}
              </Typography>
            </>
          )}
        </Box>
      </>
    );
  }
);

export default InputName;
