import { FC, memo } from 'react';
import { Typography } from '@material-ui/core';
import Select, {
  SelectProps as MuiSelectProps,
} from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInfo } from 'app/style/theme';

const useStyles = makeStyles(() => ({
  select: {
    borderRadius: '6px 6px 0 0',
    '& .MuiSelect-filled': {
      backgroundColor: ColorInfo.gray05,
      color: ColorInfo.blue01,
      padding: '0 12px',
      height: '56px',
    },
    '& .MuiSelect-iconFilled': {
      color: ColorInfo.gray01,
    },
  },
  output: {
    padding: '16px 14px',
  },
}));

// インタフェース
interface SelectProps extends MuiSelectProps {
  handleInputEvent: any;
  disabled?: boolean;
  value: string;
}

const options = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

const SelectOption: FC<SelectProps> = memo(
  ({ name, value, handleInputEvent, disabled }) => {
    const classes = useStyles();
    return (
      <>
        {!disabled && (
          <>
            <FormControl variant="filled" className={classes.select}>
              <Select
                native
                labelId={name}
                id={name}
                name={name}
                value={value}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                onChange={handleInputEvent}
              >
                {options.map((option) => (
                  <option
                    key={option}
                    value={option}
                    style={{ color: ColorInfo.blue01 }}
                  >
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {disabled && (
          <>
            <Typography className={classes.output}>{value}</Typography>
          </>
        )}
      </>
    );
  }
);

export default SelectOption;
