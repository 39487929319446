import { ApiConfig, ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import { ApprovalRequestCountResponse } from 'app/service/customer/corporation/users/approval/requestCount';

/**
 * リクエスト件数取得Hooks
 */
export const useRequestCount = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // リクエスト件数
  const [count, setCount] = useState(0);

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ApprovalRequestCountResponse>();

  const requestCountAPI = () => {
    // IDリクエスト件数取得API呼び出し
    const scimId = authInfo?.scimId || '';
    const req: ApiConfig = {
      url: `${ApiConst.CORP_USERS_APPROVAL_REQUEST_COUNT.url}${scimId}`,
      headers: ApiConst.CORP_USERS_APPROVAL_REQUEST_COUNT.headers,
    };
    void callApi(req, 'GET', null);
  };

  useEffect(() => {
    // IDリクエスト件数取得API呼び出し
    requestCountAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // ご利用中のサービス情報設定
      setCount(response.totalCount);
    } else if (error?.code) {
      // API入力値エラー
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return { count };
};
export default useRequestCount;
