import { LineSeparator } from "app/utils/constants";

export interface validationProps {
  value: string;
  require?: boolean;
}
/**
 * ValidateHooks
 */
export const useValidate = () => {
  // 必須チェック
  const requiredCheck = (
    name: string,
    value: any,
    message?: string
  ): string => {
    if (!value) {
      return `${name}を${message || '入力'}してください`;
    }
    return '';
  };

  // 禁則文字チェック
  const banCheck = (value: string): string => {
    const reg = /[”|"|'|,|?|&|/|<|>|\t]/;
    if (value && reg.test(value)) {
      return '「”（全角）」「"」「\'」「,」「?」「&」「/」「<」「>」TAB文字は使用できません';
    }
    return '';
  };

  // 最大桁数チェック
  const maxlengthCheck = (
    name: string,
    value: string,
    length: number
  ): string => {
    if (value.length > length) {
      return `${name}は${length}文字以内で入力してください`;
    }
    return '';
  };

  // 最小桁数チェック
  const minlengthCheck = (
    name: string,
    value: string,
    length: number
  ): string => {
    if (value.length < length) {
      return `${name}は${length}文字以上で入力してください`;
    }
    return '';
  };

  // メールアドレス形式チェック
  const mailaddressCheck = (value: string): string => {
    const reg = /^[a-zA-Z0-9\-_.]+@[a-zA-Z0-9\-_.]+$/;
    if (value && !reg.test(value)) {
      return 'メールアドレスの形式で入力してください';
    }
    return '';
  };
  // PDF形式チェック
  const pdfCheck = (value: string): string => {
    const reg = /.+\.[p|P][d|D][f|F]/;
    if (value && !reg.test(value)) {
      return 'PDFファイルを選択してください';
    }
    return '';
  };
  // 電話番号桁数チェック
  const telephoneMaxlengthCheck = (
    name: string,
    value1: string,
    value2: string,
    value3: string,
    length: number
  ): string => {
    if (
      value1.length > length ||
      value2.length > length ||
      value3.length > length
    ) {
      return `${name}は各${length}文字以内で入力してください`;
    }
    return '';
  };

  // 複数半角数字チェック
  const multipleNumberCheck = (
    name: string,
    valueList: Array<string>
  ): string => {
    let result = '';
    const reg = /^[0-9]+$/;
    valueList.forEach((value) => {
      if (value && !reg.test(value)) {
        result = `${name}は半角数字で入力してください`;
      }
    });
    return result;
  };

  // 複数の内全て 必須チェック
  const allOfMultipleRequiredCheck = (
    name: string,
    valueList: Array<any>,
    message?: string
  ): string => {
    let result = '';
    valueList.forEach((value) => {
      if (!value) {
        result = `${name}を${message || '入力'}してください`;
      }
    });
    return result;
  };

  // 複数の内一つ 必須チェック
  const oneOfMultipleRequiredCheck = (
    name: string,
    valueList: Array<any>,
    message?: string
  ): string => {
    let result = `${name}を${message || '入力'}してください`;
    valueList.forEach((value) => {
      if (value) {
        result = '';
      }
    });
    return result;
  };

  // 同一チェック
  const sameCheck = (
    name1: string,
    name2: string,
    value1: any,
    value2: any
  ): string => {
    if (value1 === value2) {
      return '';
    }
    return `${name1}と${name2}が一致していません`;
  };

  // 固定桁数チェック
  const fixlengthCheck = (
    name: string,
    value: string,
    length: number
  ): string => {
    // 未入力は正常扱い
    if (!value) return '';
    if (value.length !== length) {
      return `${name}は${length}文字で入力してください`;
    }
    return '';
  };

  // 文字数範囲チェック
  const rangelengthCheck = (
    name: string,
    value: string,
    min: number | undefined | null,
    max: number | undefined | null
  ): string => {
    const { length } = value;
    // 未入力は正常扱い
    if (!value) return '';
    if ((min || min === 0) && (max || max === 0)) {
      if (min <= length && max >= length) return '';
      return `${name}は${min}～${max}文字で入力してください`;
    }
    if (max || max === 0) {
      if (max >= length) return '';
      return `${name}は${max}文字以内で入力してください`;
    }
    if (min || min === 0) {
      if (min <= length) return '';
      return `${name}は${min}文字以上で入力してください`;
    }
    return '';
  };

  // 電話番号合計桁数チェック
  const telephoneSumlengthCheck = (
    name: string,
    value1: string,
    value2: string,
    value3: string
  ): string => {
    // 未入力は正常扱い
    if (!value1 && !value2 && !value3) return '';
    if (
      value1.length + value2.length + value3.length > 11 ||
      value1.length + value2.length + value3.length < 10
    ) {
      return `${name}は10文字以上11文字以内で入力してください`;
    }
    return '';
  };

  // 全角のみチェック
  const zenCheck = (name: string, value: string): string => {
    const reg = /^[^ -~]+$/;
    if (value && !reg.test(value)) {
      return `${name}は全角文字で入力してください`;
    }
    return '';
  };

  // 全角カナのみチェック
  const zenKanaCheck = (name: string, value: string): string => {
    const reg = /^[ァ-ンヴー]+$/;
    if (value && !reg.test(value)) {
      return `${name}は全角カタカナで入力してください`;
    }
    return '';
  };
  // ファイル名称重複チェック
  const fileNameDuplicateCheck = (valueList: string[]): string => {
    if (new Set(valueList).size < valueList.length) {
      return '同じ名前のファイルはアップロードできません';
    }
    return '';
  };
  const corporationNameValidation = (param: validationProps): string => {
    // 企業名 必須チェック
    let corporationNameMessage = '';
    if (param.require) {
      corporationNameMessage = requiredCheck('企業名', param.value);
    }
    // 企業名 桁数チェック
    corporationNameMessage =
      corporationNameMessage || maxlengthCheck('企業名', param.value, 40);
    // 企業名 禁則文字チェック
    corporationNameMessage = corporationNameMessage || banCheck(param.value);
    // 企業名 全角チェック
    corporationNameMessage =
      corporationNameMessage || zenCheck('企業名', param.value);
    return corporationNameMessage;
  };

  const corporationNameKanaValidation = (param: validationProps): string => {
    let corporationNameKanaMessage = '';
    if (param.require) {
      // 企業名（カナ） 必須チェック
      corporationNameKanaMessage = requiredCheck('企業名(カナ)', param.value);
    }
    // 企業名（カナ） 桁数チェック
    corporationNameKanaMessage =
      corporationNameKanaMessage ||
      maxlengthCheck('企業名(カナ)', param.value, 40);
    // 企業名(カナ) 禁則文字チェック
    corporationNameKanaMessage =
      corporationNameKanaMessage || banCheck(param.value);
    // 企業名(カナ) 全角カナチェック
    corporationNameKanaMessage =
      corporationNameKanaMessage || zenKanaCheck('企業名(カナ)', param.value);
    return corporationNameKanaMessage;
  };

  const departmentNameValidation = (param: validationProps): string => {
    // 部署名 桁数チェック
    let departmentNameMessage = '';
    if (param.require) {
      departmentNameMessage = requiredCheck('部署名', param.value);
    }
    departmentNameMessage =
      departmentNameMessage || maxlengthCheck('部署名', param.value, 80);
    // 部署名 禁則文字チェック
    departmentNameMessage = departmentNameMessage || banCheck(param.value);
    // 部署名 全角チェック
    departmentNameMessage =
      departmentNameMessage || zenCheck('部署名', param.value);
    return departmentNameMessage;
  };
  const departmentNameKanaValidation = (param: validationProps): string => {
    // 部署名(カナ) 桁数チェック
    let departmentNameKanaMessage = '';
    if (param.require) {
      departmentNameKanaMessage = requiredCheck('部署名(カナ)', param.value);
    }
    departmentNameKanaMessage =
      departmentNameKanaMessage ||
      maxlengthCheck('部署名(カナ)', param.value, 160);

    // 部署名(カナ) 禁則文字チェック
    departmentNameKanaMessage =
      departmentNameKanaMessage || banCheck(param.value);

    // 部署名(カナ) 全角カナチェック
    departmentNameKanaMessage =
      departmentNameKanaMessage || zenKanaCheck('部署名(カナ)', param.value);
    return departmentNameKanaMessage;
  };
  const corporationAddressLocalityValidation = (
    param: validationProps
  ): string => {
    // 市区町村 桁数チェック
    let corpAddressLocalityMessage = '';
    if (param.require) {
      corpAddressLocalityMessage = requiredCheck('市区町村', param.value);
    }
    corpAddressLocalityMessage =
      corpAddressLocalityMessage || maxlengthCheck('市区町村', param.value, 50);
    // 市区町村 禁則文字チェック
    corpAddressLocalityMessage =
      corpAddressLocalityMessage || banCheck(param.value);
    return corpAddressLocalityMessage;
  };
  const corporationAddressStreetValidation = (
    param: validationProps
  ): string => {
    // 丁目番地 桁数チェック
    let corpAddressStreetMessage = '';

    if (param.require) {
      corpAddressStreetMessage = requiredCheck('丁目番地', param.value);
    }
    corpAddressStreetMessage =
      corpAddressStreetMessage || maxlengthCheck('丁目番地', param.value, 50);

    // 丁目番地 禁則文字チェック
    corpAddressStreetMessage =
      corpAddressStreetMessage || banCheck(param.value);
    return corpAddressStreetMessage;
  };
  const corporationAddressBuildingValidation = (
    param: validationProps
  ): string => {
    // 建物名 桁数チェック
    let corpAddressBuildingMessage = '';
    if (param.require) {
      corpAddressBuildingMessage = requiredCheck('建物名', param.value);
    }
    corpAddressBuildingMessage =
      corpAddressBuildingMessage || maxlengthCheck('建物名', param.value, 50);
    // 建物名 禁則文字チェック
    corpAddressBuildingMessage =
      corpAddressBuildingMessage || banCheck(param.value);
    return corpAddressBuildingMessage;
  };
  // 郵便番号バリデーションチェック
  const corporationAddressPostalCodeValidation = (
    value1: string,
    value2: string,
    required?: boolean
  ): string => {
    // 郵便番号 桁数チェック
    let corpAddressPostalCodeMessage = '';
    if (required) {
      corpAddressPostalCodeMessage = allOfMultipleRequiredCheck('郵便番号', [
        value1,
        value2,
      ]);
    }
    corpAddressPostalCodeMessage =
      corpAddressPostalCodeMessage || fixlengthCheck('郵便番号', value1, 3);
    corpAddressPostalCodeMessage =
      corpAddressPostalCodeMessage || fixlengthCheck('郵便番号', value2, 4);
    // 郵便番号 半角数字チェック
    corpAddressPostalCodeMessage =
      corpAddressPostalCodeMessage ||
      multipleNumberCheck('郵便番号', [value1, value2]);
    return corpAddressPostalCodeMessage;
  };

  // 電話番号バリデーションチェック
  const corporationTelephoneNumberValidation = (
    value1: string,
    value2: string,
    value3: string,
    require?: boolean
  ): string[] => {
    // 代表電話番号1 代表電話番号2 代表電話番号3 数値チェック
    let corporationTelephoneNumberMessage = '';
    if (require) {
      corporationTelephoneNumberMessage = allOfMultipleRequiredCheck(
        '代表電話番号',
        [value1, value2, value3]
      );
    }
    corporationTelephoneNumberMessage =
      corporationTelephoneNumberMessage ||
      multipleNumberCheck('代表電話番号', [value1, value2, value3]);

    // 代表電話番号1 桁数チェック(2～5桁)
    let corporationTelephoneNumberMessage1 = '';
    // 数値チェックにはじかれた場合にはチェックしない
    if (!corporationTelephoneNumberMessage) {
      corporationTelephoneNumberMessage1 =
        corporationTelephoneNumberMessage1 ||
        rangelengthCheck('代表電話番号（市外局番）', value1, 2, 5);
    }

    // 代表電話番号2 桁数チェック(1~4桁)
    let corporationTelephoneNumberMessage2 = '';
    // 数値チェックにはじかれた場合にはチェックしない
    if (!corporationTelephoneNumberMessage) {
      corporationTelephoneNumberMessage2 =
        corporationTelephoneNumberMessage2 ||
        maxlengthCheck('代表電話番号（市内局番）', value2, 4);
    }

    // 代表電話番号3 桁数チェック(4桁固定)
    let corporationTelephoneNumberMessage3 = '';
    // 数値チェックにはじかれた場合にはチェックしない
    if (!corporationTelephoneNumberMessage) {
      corporationTelephoneNumberMessage3 =
        corporationTelephoneNumberMessage3 ||
        fixlengthCheck('代表電話番号（加入者番号）', value3, 4);
    }

    // 電話番号の合計桁数が10~11桁以内であることをチェック
    // 各チェックにはじかれている場合にはチェックしない
    if (
      !corporationTelephoneNumberMessage1 &&
      !corporationTelephoneNumberMessage2 &&
      !corporationTelephoneNumberMessage3
    ) {
      corporationTelephoneNumberMessage =
        corporationTelephoneNumberMessage ||
        telephoneSumlengthCheck('代表電話番号', value1, value2, value3);
    }
    const result = [
      corporationTelephoneNumberMessage,
      corporationTelephoneNumberMessage1,
      corporationTelephoneNumberMessage2,
      corporationTelephoneNumberMessage3,
    ];
    return result;
  };
  const inquiryTextValidation = (param: validationProps): string => {
    // 問い合わせ内容 必須チェック
    let inquiryTextMessage = '';
    if (param.require) {
      inquiryTextMessage = requiredCheck('問い合わせ内容', param.value);
    }
    // 問い合わせ内容 桁数チェック
    inquiryTextMessage =
      inquiryTextMessage || maxlengthCheck('問い合わせ内容', param.value, 1000);
    return inquiryTextMessage;
  };
  const corporationRegionValidation = (param: validationProps): string => {
    // 都道府県 必須チェック
    let corpAddressRegionMessage = '';
    if (param.require) {
      corpAddressRegionMessage = requiredCheck('都道府県', param.value);
    }
    // 都道府県 桁数チェック
    corpAddressRegionMessage =
      corpAddressRegionMessage || maxlengthCheck('都道府県', param.value, 4);
    return corpAddressRegionMessage;
  };
  // 添付ファイルリスト バリデーションチェック
  const fileNameListValidation = (
    valueList: (string | undefined)[],
    required?: boolean
  ): string => {
    // 添付ファイルリスト 必須チェック
    let uploadFileMessage = '';
    if (required) {
      uploadFileMessage = oneOfMultipleRequiredCheck(
        '登記事項証明書（現在事項証明書）',
        valueList,
        'アップロード'
      );
    }
    valueList.forEach((value) => {
      uploadFileMessage = uploadFileMessage || pdfCheck(value || '');
      uploadFileMessage =
        uploadFileMessage ||
        maxlengthCheck('登記事項証明書（現在事項証明書）', value || '', 100);
    });
    return uploadFileMessage;
  };
  const mailValidation = (param: validationProps, key: string): string => {
    let emailAddressMessage = '';
    // メールアドレス必須チェック
    if (param.require) {
      emailAddressMessage = requiredCheck(key, param.value);
    }
    // メールアドレス 桁数チェック
    emailAddressMessage =
      emailAddressMessage || maxlengthCheck(key, param.value, 60);
    // メールアドレス 形式チェック
    emailAddressMessage = emailAddressMessage || mailaddressCheck(param.value);
    return emailAddressMessage;
  };
  const nameValidation = (
    familyName: string,
    givenName: string,
    require?: boolean
  ): string => {
    let nameMessage = '';
    if (require) {
      // 姓 必須チェック
      nameMessage = requiredCheck('氏名（漢字）', familyName);
      // 名 必須チェック
      nameMessage = nameMessage || requiredCheck('氏名（漢字）', givenName);
    }
    // 姓 桁数チェック
    nameMessage = nameMessage || maxlengthCheck('氏名（漢字）', familyName, 50);
    // 姓 禁則文字チェック
    nameMessage = nameMessage || banCheck(familyName);
    // 姓 全角チェック
    nameMessage = nameMessage || zenCheck('氏名（漢字）', familyName);
    // 名 桁数チェック
    nameMessage = nameMessage || maxlengthCheck('氏名（漢字）', givenName, 50);
    // 名 全角チェック
    nameMessage = nameMessage || zenCheck('氏名（漢字）', givenName);
    // 名 禁則文字チェック
    nameMessage = nameMessage || banCheck(givenName);
    return nameMessage;
  };

  const nameKanaValidation = (
    familyNameKana: string,
    givenNameKana: string,
    require?: boolean
  ): string => {
    let nameKanaMessage = '';
    if (require) {
      // 姓 必須チェック
      nameKanaMessage = requiredCheck('氏名（カナ）', familyNameKana);
      // 名 必須チェック
      nameKanaMessage =
        nameKanaMessage || requiredCheck('氏名（カナ）', givenNameKana);
    }
    // セイ 桁数チェック
    nameKanaMessage =
      nameKanaMessage || maxlengthCheck('氏名（カナ）', familyNameKana, 50);
    // メイ 桁数チェック
    nameKanaMessage =
      nameKanaMessage || maxlengthCheck('氏名（カナ）', givenNameKana, 50);
    // セイ 禁則文字チェック
    nameKanaMessage = nameKanaMessage || banCheck(familyNameKana);
    // メイ 禁則文字チェック
    nameKanaMessage = nameKanaMessage || banCheck(givenNameKana);
    // セイ 全角カナチェック
    nameKanaMessage =
      nameKanaMessage || zenKanaCheck('氏名（カナ）', familyNameKana);
    // メイ 全角カナチェック
    nameKanaMessage =
      nameKanaMessage || zenKanaCheck('氏名（カナ）', givenNameKana);
    return nameKanaMessage;
  };

  // 添付ファイルリスト 最大サイズバリデーションチェック
  const fileMaxSizeValidation = (valueList: (number | undefined)[]): string => {
    const MAX_FILE_SIZE = 1024 * 1024 * 15; // 15MB
    let uploadFileMaxSizeMessage = '';
    valueList.forEach((value) => {
      if (value && MAX_FILE_SIZE < value) {
        uploadFileMaxSizeMessage =
          'アップロード可能なファイルサイズ上限を超過しています';
      }
    });
    return uploadFileMaxSizeMessage;
  };

  // 申請理由バリデーションチェック
  const applyReasonValidation = (param: validationProps, separator: LineSeparator): string => {
    let applyReasonMessage = '';
    // 申請理由 必須チェック
    if (param.require) {
      // 改行コードを除去した入力値で必須チェックを行う
      applyReasonMessage = requiredCheck('申請理由', param.value.replaceAll('\n', ''));
    }

    // 指定した改行コードに変換した入力値で後続のチェックを行う
    const checkValue = param.value.split('\n').join(separator);

    // 申請理由 桁数チェック
    applyReasonMessage =
      applyReasonMessage || maxlengthCheck('申請理由', checkValue, 100);
    // 申請理由 禁則文字チェック
    applyReasonMessage =
      applyReasonMessage || banCheck(checkValue);
    return applyReasonMessage;
  };

  return {
    requiredCheck,
    banCheck,
    maxlengthCheck,
    minlengthCheck,
    mailaddressCheck,
    telephoneMaxlengthCheck,
    multipleNumberCheck,
    allOfMultipleRequiredCheck,
    oneOfMultipleRequiredCheck,
    sameCheck,
    pdfCheck,
    rangelengthCheck,
    fixlengthCheck,
    telephoneSumlengthCheck,
    zenCheck,
    zenKanaCheck,
    fileNameDuplicateCheck,
    corporationNameValidation,
    corporationNameKanaValidation,
    departmentNameValidation,
    departmentNameKanaValidation,
    corporationAddressLocalityValidation,
    corporationAddressStreetValidation,
    corporationAddressPostalCodeValidation,
    corporationAddressBuildingValidation,
    corporationTelephoneNumberValidation,
    fileNameListValidation,
    inquiryTextValidation,
    corporationRegionValidation,
    mailValidation,
    nameValidation,
    nameKanaValidation,
    fileMaxSizeValidation,
    applyReasonValidation,
  };
};
export default useValidate;
