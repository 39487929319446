import { useState, useEffect } from 'react';
import {
  ServiceListRequest,
  ServiceListResponse,
} from 'app/service/contract/service/list';
import { ApiConfig, ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';

// 利用契約サービス一覧
export interface ContractServiceInfo {
  contractCorporationId: string; // 契約法人ID
  contractCorporationName: string; // 契約法人名
  contractName: string; // 契約名
  contractServiceName: string; // サービス名
  contractServiceUri: string; // サービスサイトURI
  contractMenuName: string; // メニュー名
  contractNo: string; // 契約番号
  contractDepartmentName: string; // 契約部署名
  administratorId: string; // 管理者ID
  administratorName: string; // 管理者名
  contractDateStart: string; // 契約開始日
  contractDateEnd: string; // 契約終了日
  status: string; // ステータス
  statusName: string; // 状況
}
/**
 * 利用契約サービス一覧取得カスタムHooks
 */
export const useContractServiceList = () => {
  // 利用契約サービス一覧のサービス情報
  const [contractServiceInfo, setContractServiceInfo] = useState<
    ContractServiceInfo[] | undefined
  >();

  // 利用契約サービス一覧取得 IF-SV-001 API呼出しHooks
  const { response, error, callApi } = useApi<ServiceListResponse>();

  // 利用契約サービス一覧取得 IF-SV-001 API呼出しエラー(true:正常)
  const [contractServiceInfoReslt, setContractServiceInfoReslt] =
    useState(true);

  // 利用契約サービス一覧取得 IF-SV-001
  const contractServiceListAPI = async (
    corporationId: string,
    pageIndex = 1,
    pageSize = '30'
  ) => {
    // クエリパラメータ設定
    const req: ServiceListRequest = {
      ...{
        usageFlag: '2',
      },
    };
    // 登録API呼出し
    const serviceListApiPath: ApiConfig = {
      url: `${ApiConst.CONTRACT_SERVICE_LIST.url}/${corporationId}/${pageIndex}/${pageSize}`,
      headers: ApiConst.CONTRACT_SERVICE_LIST.headers,
    };

    await callApi(serviceListApiPath, 'GET', req);
  };

  // 利用契約サービス一覧取得 IF-SV-001 レスポンス
  useEffect(() => {
    if (response) {
      setContractServiceInfoReslt(true);
      if (response?.dataList) {
        // 利用契約サービス一覧
        setContractServiceInfo(
          response?.dataList.map((row) => ({
            contractCorporationId: row.contractCorporationId,
            contractCorporationName: row.contractCorporationName,
            contractName: row.contractName,
            contractServiceName: row.contractServiceName,
            contractMenuName: row.contractMenuName,
            contractNo: row.contractNo,
            contractDepartmentName: row.contractDepartmentName,
            administratorId: row.administratorId,
            administratorName: row.administratorName,
            contractDateStart: row.contractDateStart,
            contractDateEnd: row.contractDateEnd,
            status: row.status,
            statusName: row.statusName,
            contractServiceUri: row.contractServiceUri,
          }))
        );
      }
    } else if (error?.code) {
      // API値エラー
      setContractServiceInfoReslt(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    contractServiceListAPI,
    contractServiceInfoReslt,
    contractServiceInfo,
  };
};
export default useContractServiceList;
