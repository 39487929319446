import { createTheme } from '@material-ui/core/styles';

// Color
export class ColorInfo {
  static readonly blue01: string = '#0E3447';

  static readonly blue02: string = '#1B8AC2';

  static readonly blue03: string = '#74C8F2';

  static readonly blue04: string = '#DBECF5';

  static readonly blue05: string = '#F1F8FB';

  static readonly blue06: string = '#F7FCFE';

  static readonly red01: string = '#C1272D';

  static readonly red02: string = '#FBF2F2';

  static readonly gray01: string = '#758891';

  static readonly gray02: string = '#A3AEB4';

  static readonly gray03: string = '#CFD6DA';

  static readonly gray04: string = '#D7DCDE';

  static readonly gray05: string = '#F5F7F8';

  static readonly white01: string = '#FFFFFF';
}

const theme = createTheme({
  // default thema `https://material-ui.com/customization/default-theme/`
  palette: {
    primary: {
      // メインカラー
      main: ColorInfo.blue05,
    },
    secondary: {
      // サブカラー
      main: ColorInfo.blue02,
    },
    text: {
      // 入力Color
      primary: ColorInfo.gray02,
      // 部品Color
      secondary: ColorInfo.blue02,
      // 表示Color
      disabled: ColorInfo.blue01,
    },
    error: {
      main: ColorInfo.red01,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Helvetica Neue',
      'Hiragino Sans',
      'Hiragino Kaku Gothic ProN',
      'Meiryo',
      'sans-serif',
    ].join(),
    h1: {
      width: '1100px',
      fontSize: '2.6rem',
      lineHeight: 1.4,
    },
    h2: {
      marginTop: '60px',
      fontSize: '3.2rem',
      lineHeight: 1.4,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: '1px',
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '1px',
      position: 'relative',
      paddingLeft: '20px',
      margin: '10px 0 10px 0',
      '&::before': {
        position: 'absolute',
        top: '50%',
        left: 0,
        content: `""`,
        width: '10px',
        height: '2px',
        backgroundColor: `${ColorInfo.blue02}`,
      },
    },
    h5: {
      fontSize: '1.65rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1.5rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  props: {
    MuiContainer: {
      disableGutters: true,
    },
    MuiButton: {
      variant: 'contained',
      size: 'large',
      disableElevation: true,
    },
    MuiTextField: {
      variant: 'filled',
      autoComplete: 'off',
    },
    MuiInput: {},
    MuiGrid: {
      spacing: 3,
      justifyContent: 'center',
      alignItems: 'center',
    },
    MuiCheckbox: {
      color: 'secondary',
    },
    MuiRadio: {
      color: 'secondary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiInputLabel: {
      color: 'primary',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: ColorInfo.blue01,
      },
      h1: {
        color: ColorInfo.blue01,
      },
      h2: {
        color: ColorInfo.blue01,
      },
      h3: {
        color: ColorInfo.blue01,
        marginTop: '80px',
        marginBottom: '30px',
        padding: '0 0 3px 22px',
        borderLeft: `2px solid ${ColorInfo.blue02}`,
      },
      h4: {
        color: ColorInfo.blue01,
      },
    },
    MuiButton: {
      root: {
        color: ColorInfo.blue02,
      },
      contained: {
        color: ColorInfo.blue02,
      },
    },
    MuiTextField: {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& .Mui-error': {
          backgroundColor: ColorInfo.red02,
          borderBottom: `1px solid ${ColorInfo.red01}`,
        },
      },
    },
    MuiFilledInput: {
      root: {
        color: ColorInfo.blue01,
        backgroundColor: ColorInfo.gray05,
      },
      input: {
        height: '56px',
        outline: 'none',
        padding: '0 12px',
        borderRadius: '6px 6px 0 0',
      },
      underline: {
        '&::before': {
          transition: 'unset',
          borderBottom: `1px solid ${ColorInfo.gray01}`,
        },
        '&::after': {
          display: 'none',
        },
        '&:hover::before': {
          borderBottom: `1px solid ${ColorInfo.gray01}`,
        },
      },
      inputMultiline: {
        height: 'initial',
        padding: '16.5px 14px',
      },
      multiline: {
        padding: '0 0',
        outline: 'none',
        borderRadius: '6px 6px 0 0',
      },
    },
    MuiGrid: {
      container: {
        margin: 10,
      },
    },
    MuiRadio: {
      root: {
        marginLeft: 10,
        color: '#f5f7f8',
        border: '1px solid #cfd6da;',
      },
    },
    MuiLink: {
      root: {
        color: ColorInfo.blue02,
      },
    },
    MuiInputLabel: {
      root: {
        color: ColorInfo.blue01,
      },
    },
    MuiCheckbox: {
      root: {
        color: ColorInfo.gray01,
      },
    },
    MuiStepIcon: {
      active: {
        fill: ColorInfo.blue02,
      },
    },
    MuiStepLabel: {
      label: {
        color: ColorInfo.gray02,
        fontSize: '1.5rem',
        '&.MuiStepLabel-active': {
          color: ColorInfo.blue01,
        },
      },
    },
    MuiSelect: {
      filled: {
        width: 150,
        height: 'auto',
        backgroundColor: ColorInfo.blue05,
        padding: '14px 38px 13px 20px',
        color: ColorInfo.blue02,
        fontSize: '1.5rem',
        lineHeight: 1,
        border: 'none',
        outline: 'none',
        backgroundImage: 'none',
        boxShadow: 'none',
        appearance: 'none',
        cursor: 'pointer',
      },
      iconFilled: {
        top: 'auto',
        fontSize: 30,
        color: ColorInfo.blue02,
      },
      select: {
        '&:focus': {
          backgroundColor: ColorInfo.blue05,
        },
      },
    },
    MuiStepper: {
      root: {
        alignItems: 'center',
      },
      alternativeLabel: {
        alignItems: 'center',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
export default theme;
