import { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { ColorInfo } from '../../../../style/theme';

const useStyles = makeStyles({
  root: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 66,
  },
});

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 26,
    alignItems: 'center',
  },
  active: {
    color: ColorInfo.blue02,
    '& > div': {
      outline: `5px solid ${ColorInfo.blue04}`,
    },
  },
  circle: {
    position: 'relative',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: ColorInfo.gray02,
    zIndex: 1,
    fontSize: 18,
  },
});

const CustomStepIcon: FC<StepIconProps> = ({ active }) => {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.circle} />
    </div>
  );
};

export interface StepProps {
  StepNumber: number;
  Steps?: string[];
}

// statusと表示内容を受け取り表示する
const StepperContent: FC<StepProps> = ({
  StepNumber,
  Steps = ['入力', '確認', '完了'],
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Stepper activeStep={StepNumber} alternativeLabel>
        {Steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default StepperContent;
