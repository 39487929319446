import {
  ContractMenuList,
  ContractItemListResponse,
  ContractInfoListRequest,
} from 'app/service/customer/contract';
import { ApiConfig, ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'app/hooks/context/auth';

// 契約内容照会
export interface Contract {
  rows: ContractMenuList[];
}
/**
 * 契約内容照会カスタムHooks
 */
export const useContractInfo = (
  usageFlag?: ContractInfoListRequest['usageFlag']
) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  // 契約内容
  const [contract, setContract] =
    // 初期値設定
    useState<Contract>({
      rows: [],
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ContractItemListResponse>();
  const contractAPI = (corporationId: string, scimId: string) => {
    // IF-SV-002契約内容呼出し
    const CONTRACT_INFO: ApiConfig = {
      url: `${ApiConst.CONTRACT_LIST.url}/${corporationId}/${scimId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void callApi(
      CONTRACT_INFO,
      'GET',
      usageFlag ? ({ usageFlag } as ContractInfoListRequest) : null
    );
  };

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  useEffect(() => {
    // 初期処理
    setContract({
      rows: [],
    });
    setApiCallStatus(0);
    // 契約内容照会API呼出し
    contractAPI(authInfo?.corporationId || '', authInfo?.scimId || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // 契約内容照会設定
      setContract({
        rows: response?.contractServiceList,
      });
      setApiCallStatus(1);
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return { contract, apiCallStatus };
};
export default useContractInfo;
