import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonForward } from 'app/views/components/block/button';
import { ColorInfo } from 'app/style/theme';
import TextInput from 'app/views/components/atom/text';
import { AuthContext } from 'app/hooks/context/auth';
import useCorpUsersUpdate from 'app/hooks/corporation/users/update';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';
import useValidate from 'app/hooks/validate';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 50,
    },
    buttonClose: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    iconStyle: {
      fontSize: 32,
      stroke: 'white',
      fontColor: ColorInfo.blue01,
    },
    contentsMainArea: {
      display: 'block ',
      alignItems: 'center',
      justifyContent: 'space-between',
      // paddingLeft: 70,
    },
    contentsArea: {
      display: 'flex',
      minHeight: '60px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: 18,
    },
    contentsLabel: {
      minWidth: '150px',
    },
    buttonArea: {
      marginTop: 30,
      marginBottom: -20,
    },
  });

interface DepartmentNameDialogProps extends WithStyles<typeof styles> {
  departmentName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setGlobalMessage: (globalMessage: string) => void;
}

const DepartmentNameDialog = withStyles(styles)(
  ({
    departmentName,
    open,
    setOpen,
    setGlobalMessage,
    classes,
  }: DepartmentNameDialogProps) => {
    // 認証コンテキスト
    const { setDepartmentName, authInfo } = useContext(AuthContext);

    // 部署名（更新値）
    const [updateName, setUpdateName] = useState('');

    // 二重POST防止：OFFで初期化
    const processing = useRef(false);
    // 部署名（更新値）
    const [checkDepartmentName, setCheckDepartmentName] = useState('');
    // ValidateHooks
    const valid = useValidate();
    // 入力チェック
    const inputCheck = (): boolean => {
      setCheckDepartmentName('');
      let result = false;
      let departmentNameMessage = '';
      // 部署名 桁数チェック
      departmentNameMessage =
        departmentNameMessage || valid.maxlengthCheck('部署名', updateName, 80);
      result = result || !!departmentNameMessage;
      // 部署名 禁則文字チェック
      departmentNameMessage =
        departmentNameMessage || valid.banCheck(updateName);
      result = result || !!departmentNameMessage;
      // 部署名 全角チェック
      departmentNameMessage =
        departmentNameMessage || valid.zenCheck('部署名', updateName);
      result = result || !!departmentNameMessage;
      // メッセージ設定
      setCheckDepartmentName(departmentNameMessage);
      return result;
    };
    // 部署名変更API Hooks
    const { usersDepartmentNameUpdateAPI, response, error } =
      useCorpUsersUpdate();

    // 部署名変更API
    const handleUpdate = () => {
      // GAタグ:お客さま情報-部署名変更モーダル-変更する押下
      onGaClick('B-030-001-006');
      // DMPビーコンタグ：お客さま情報-部署名変更モーダル-変更する押下
      OnDmpClick('B-030-001-006', authInfo?.scimId);
      if (inputCheck()) {
        return;
      } // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;
      usersDepartmentNameUpdateAPI(updateName);
    };

    // 部署名変更API応答処理
    useEffect(() => {
      if (response) {
        // 二重POST防止：OFF
        processing.current = false;
        // 認証情報を更新(ID連携要求APIによる)
        setDepartmentName(updateName);
        setOpen(false);
        setGlobalMessage('');
      } else if (error?.code) {
        // 二重POST防止：OFF
        processing.current = false;
        setOpen(false);
        setGlobalMessage(error.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error]);

    // 入力値 onChange
    const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const value = target.value ? target.value : '';
      setUpdateName(value);
    };
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          className={classes.buttonClose}
          onClick={() => setOpen(false)}
          size="small"
        >
          <CloseIcon className={classes.iconStyle} />
        </IconButton>

        <DialogContent className={classes.root}>
          <Box className={classes.contentsMainArea}>
            <Box className={classes.contentsArea}>
              <Typography className={classes.contentsLabel}>部署名</Typography>
              <Typography>{departmentName}</Typography>
            </Box>
            <Box className={classes.contentsArea}>
              <Typography className={classes.contentsLabel}>
                新しい部署名
              </Typography>
              <TextInput
                name="searchValue"
                placeholder="入力してください"
                value={updateName}
                handleInputEvent={handleInputEvent}
                onBlur={inputCheck}
                fullWidth
                style={{ width: '450px' }}
                error={!!checkDepartmentName}
              />
            </Box>
            <Typography color="error">{checkDepartmentName}</Typography>
          </Box>

          <DialogActions className={classes.buttonArea}>
            <ButtonForward
              onClick={() => {
                handleUpdate();
              }}
              style={{ margin: '10px auto' }}
            >
              変更する
            </ButtonForward>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
);

export default DepartmentNameDialog;
