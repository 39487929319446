import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApiConst,
  PageInfo,
  MessageConst,
  ItemNameContst,
  ApiConfig,
} from 'app/utils/constants';
import useApi from 'app/hooks/api';
import useValidate from 'app/hooks/validate';
import {
  ManageAuthResponse,
  AuthTransferRegistResponse,
  AuthTransferRegistRequest,
} from 'app/service/customer/corporation/auth';
import { CorprationUserResponse } from 'app/service/customer/corporation/users';

// 移譲元情報
export interface TransferSource {
  email: string | null;
  name: string | null;
  administratorFlag: string | null;
  authorityApproval: string | null;
  authorityAssignment: string | null;
  contractServiceList: TransferSourceService[] | null;
}

// 移譲元情報(サービス情報)
export interface TransferSourceService {
  contractName: string | null;
  contractServiceId: string | null;
  contractServiceName: string | null;
  contractMenuId: string | null;
  contractMenuName: string | null;
  contractCourseId: string | null;
  contractCourseName: string | null;
  administratorId: string | null;
  administratorName: string | null;
  administratorEmail: string | null;
  contractOptionList: TransferSourceOption[] | null;
}

// 移譲元情報(オプション情報)
export interface TransferSourceOption {
  contractOptionId: string | null;
  contractOptionName: string | null;
  contractOptionUsageFlag: string | null;
  authorityFlag: string | null;
  issuerName: string | null;
  issuerMail: string | null;
}

// 移譲先情報(オプション情報)
export interface TransferDestination {
  scimId: string | null;
  corporationId: string | null;
  email: string | null;
  name: string | null;
  nameKana: string | null;
  departmentName: string | null;
  departmentNameKana: string | null;
  postalCode: string | null;
  region: string | null;
  locality: string | null;
  street: string | null;
  building: string | null;
  telephoneNumber1: string | null;
  telephoneNumber2: string | null;
  telephoneNumber3: string | null;
  administratorFlag: string | null;
  authorityApproval: string | null;
  authorityAssignment: string | null;
}

/**
 * 管理権限移譲Hooks
 */
export const useAuthTransfer = (srcScimId: string) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  const corporationId = authInfo?.corporationId || '';

  // 画面遷移
  const history = useHistory();

  // 画面（0:入力、1:確認、2:完了）
  const [screen, setScreen] = useState(0);

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // ValidationMessage情報
  const [validationMessage, setValidationMessage] = useState({
    destEmail: '',
  });

  // ValidateHooks
  const valid = useValidate();

  // 入力情報
  const [destEmail, setDestEmail] = useState('');

  // 移譲元情報
  const [transferSource, setTransferSource] =
    // 初期値設定
    useState<TransferSource>({
      email: '',
      name: '',
      administratorFlag: '',
      authorityApproval: '',
      authorityAssignment: '',
      contractServiceList: [],
    });

  // 移譲先情報
  const [transferDestination, setTransferDestination] =
    // 初期値設定
    useState<TransferDestination>({
      scimId: '',
      corporationId: '',
      email: '',
      name: '',
      nameKana: '',
      departmentName: '',
      departmentNameKana: '',
      postalCode: '',
      region: '',
      locality: '',
      street: '',
      building: '',
      telephoneNumber1: '',
      telephoneNumber2: '',
      telephoneNumber3: '',
      administratorFlag: '',
      authorityApproval: '',
      authorityAssignment: '',
    });

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target?.value;
    setDestEmail(value);
  };

  // API定義(登録済み法人ユーザー検索)
  const {
    response: corprationUserResponse,
    error: corprationUserErrorResponse,
    callApi: corprationUserApi,
  } = useApi<CorprationUserResponse>();

  // API呼び出し(登録済み法人ユーザー検索)
  const userRefAPI = async () => {
    const email = destEmail || '';
    const api: ApiConfig = {
      url: `${ApiConst.CORP_REGISTED_USERS.url}/${corporationId}/${email}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    await corprationUserApi(api, 'GET', null);
  };

  // API呼び出し(登録済み法人ユーザー検索)結果による処理
  useEffect(() => {
    if (corprationUserResponse) {
      setTransferDestination({ ...corprationUserResponse });
      // 遷移
      setScreen((preScreen) => preScreen + 1);
      history.push(`${PageInfo.AUTH_TRANSFER_CONFIRM.path}/${srcScimId}`);
    } else if (corprationUserErrorResponse?.code) {
      // エラーメッセージ設定
      setGlobalMessage(MessageConst.GLOBAL_INOUT);
      setValidationMessage({
        ...validationMessage,
        destEmail:
          '該当するユーザが存在しません。入力したメールアドレスを確認してください。',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corprationUserResponse, corprationUserErrorResponse]);

  // API定義(利用権限移譲登録)
  const {
    response: authTransferRegistResponse,
    error: authTransferRegistErrorResponse,
    callApi: authTransferRegistApi,
  } = useApi<AuthTransferRegistResponse>();

  // API呼び出し(利用権限移譲登録)
  const authTransferAPI = async () => {
    const req: AuthTransferRegistRequest = {
      // APIを適宜設定
      corporationId,
      userId: srcScimId,
      changeUserId: corprationUserResponse?.scimId || '',
      operatorId: authInfo?.scimId || '',
    };
    await authTransferRegistApi(ApiConst.AUTH_TRANSFER_REGIST, 'POST', req);
  };

  // API呼び出し(利用権限移譲登録)結果による処理
  useEffect(() => {
    if (authTransferRegistResponse) {
      // 遷移
      setScreen((preScreen) => preScreen + 1);
      history.push(PageInfo.AUTH_TRANSFER_COMPLETE.path);
    } else if (authTransferRegistErrorResponse?.code) {
      // エラーメッセージ設定
      setGlobalMessage(MessageConst.NOACTION);
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTransferRegistResponse, authTransferRegistErrorResponse]);

  // API定義(管理権限情報取得)
  const {
    response: manageAuthResponse,
    error: manageAuthErrorResponse,
    callApi: manageAuthApi,
  } = useApi<ManageAuthResponse>();

  // API呼び出し(管理権限情報取得)
  const authRefAPI = async () => {
    const api: ApiConfig = {
      url: `${ApiConst.MANAGE_AUTH.url}/${corporationId}/${srcScimId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    await manageAuthApi(api, 'GET', null);
  };

  // 画面初期表示
  useEffect(() => {
    // メッセージ初期化
    messageClear();

    // Api呼出しステータスを未完了(0)に設定
    setApiCallStatus(0);

    // API呼び出し(管理権限情報取得)
    void authRefAPI();

    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API呼び出し(管理権限情報取得)結果による処理
  useEffect(() => {
    if (manageAuthResponse) {
      // Api呼出し状態を呼出し完了(1)に設定
      setApiCallStatus(1);

      setTransferSource({ ...manageAuthResponse });
    } else if (manageAuthErrorResponse?.code) {
      // エラーメッセージ設定
      setGlobalMessage(MessageConst.NOACTION);
    }
  }, [manageAuthResponse, manageAuthErrorResponse]);

  // メッセージエリアクリア
  const messageClear = () => {
    setGlobalMessage('');

    // バリデーションメッセージ設定
    setValidationMessage({
      destEmail: '',
    });
  };

  // 戻るアクション
  const handleBack = () => {
    // メッセージクリア
    setGlobalMessage('');

    // 次画面へ遷移（状態を減算）
    setScreen((preScreen) => preScreen - 1);
    history.go(-1);
  };

  // 次処理
  const handleNext = () => {
    // メッセージクリア
    setGlobalMessage('');
    switch (screen) {
      case 0: // 入力画面
        if (inputCheck()) {
          // API呼び出し(登録済み法人ユーザー検索)
          void userRefAPI();
        }
        break;
      case 1: // 確認画面
        // API呼び出し(利用権限移譲登録)
        void authTransferAPI();
        // 遷移
        break;
      default:
        // 完了画面
        setScreen((preScreen) => preScreen + 1);
        history.push(PageInfo.ACCOUNT_MANAGE.path);
        break;
    }
  };

  // イベント時チェック
  const inputCheck = (): boolean => {
    let result = true;

    // 必須チェック
    let emailMessage = '';
    emailMessage = valid.requiredCheck(ItemNameContst.MAILADDRESS, destEmail);
    // 桁数チェック
    emailMessage =
      emailMessage ||
      valid.maxlengthCheck(ItemNameContst.MAILADDRESS, destEmail, 60);
    // 形式チェック
    emailMessage = emailMessage || valid.mailaddressCheck(destEmail);

    result = !emailMessage;

    // バリデーションメッセージ設定
    setValidationMessage({
      ...validationMessage,
      destEmail: emailMessage,
    });

    if (!result) {
      // グローバルメッセージ設定
      setGlobalMessage(MessageConst.GLOBAL_INOUT);
    }

    return result;
  };

  // 入力時チェック
  const validationCheck = (): boolean => {
    let result = true;
    // メールアドレス形式チェック
    const emailMessage = valid.mailaddressCheck(destEmail);
    result = !emailMessage;

    // バリデーションメッセージ設定
    setValidationMessage({
      ...validationMessage,
      destEmail: emailMessage,
    });

    return result;
  };

  return {
    screen,
    destEmail,
    handleInputEvent,
    transferSource,
    transferDestination,
    globalMessage,
    validationMessage,
    validationCheck,
    handleBack,
    handleNext,
    apiCallStatus,
  };
};
export default useAuthTransfer;
