import { useContext, useEffect, useState } from 'react';
import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  InviteAccountItem,
  InviteAccountListResponse,
} from 'app/service/customer/corporation/users/invite/account';
import { AuthContext } from 'app/hooks/context/auth';

// 招待中アカウント一覧情報
export interface InviteAccount {
  totalCount: number;
  page: number; // 取得対象ページ番号
  size: string; // 表示対象件数
  rows?: InviteAccountItem[] | null;
}
/**
 * 招待中アカウント一覧業務カスタムHooks
 */
export const useInviteAccount = () => {
  // 招待中アカウント一覧情報
  const [inviteAccount, setInviteAccount] =
    // 初期値設定
    useState<InviteAccount>({
      totalCount: 0,
      page: 0,
      size: '',
      rows: [],
    });

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 前へボタン制御（true：非活性、false：活性）
  const [isPageBackDisabled, setIsPageBackDisabled] = useState<boolean>(true);
  // 次へボタン制御（true：非活性、false：活性）
  const [isPageNextDisabled, setIsPageNextDisabled] = useState<boolean>(true);

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // プルダウンの場合はイベント発火
    if (name === 'size' && target.type !== 'checkbox') {
      handleSelect(target.value);
    } else {
      setInviteAccount({
        ...inviteAccount,
        [name]: value,
      });
    }
  };

  // 表示件数変更アクション
  const handleSelect = (sizeIndex: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setInviteAccount({
      ...inviteAccount,
      page: pageIndex,
      size: sizeIndex,
    });
    // 招待中アカウント一覧情報リクエスト呼び出し
    inviteAccountListAPI(authInfo?.scimId || '', pageIndex, sizeIndex);
  };

  // 前へアクション
  const handleSearchBack = () => {
    // ページ減算
    const pageIndex = inviteAccount.page - 1;

    setInviteAccount({
      ...inviteAccount,
      page: pageIndex,
      size: inviteAccount.size,
    });
    // 招待中アカウント一覧情報リクエスト呼び出し
    inviteAccountListAPI(authInfo?.scimId || '', pageIndex, inviteAccount.size);
  };

  // 次へアクション
  const handleSearchNext = () => {
    // ページ加算
    const pageIndex = inviteAccount.page + 1;

    setInviteAccount({
      ...inviteAccount,
      page: pageIndex,
      size: inviteAccount.size,
    });
    // 招待中アカウント一覧情報リクエスト呼び出し
    inviteAccountListAPI(authInfo?.scimId || '', pageIndex, inviteAccount.size);
  };

  // 更新アクション
  const handleInviteUpdate = () => {
    // 1ページ目に設定
    const pageIndex = 1;

    setInviteAccount({
      ...inviteAccount,
      page: pageIndex,
    });
    // 招待中アカウント一覧情報リクエスト呼び出し
    inviteAccountListAPI(authInfo?.scimId || '', pageIndex, inviteAccount.size);
  };

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<InviteAccountListResponse>();

  // 招待中アカウント一覧API
  const inviteAccountListAPI = (
    userId: string,
    pageIndex = 1,
    pageSize = '30'
  ) => {
    // 一覧API呼出し
    const INVITE_ACCOUNT_LIST: ApiConfig = {
      url: `/api/v1/corporation/users/invite/account/${userId}/${pageIndex}/${pageSize}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void callApi(INVITE_ACCOUNT_LIST, 'GET', null);
  };

  useEffect(() => {
    // 初期処理
    const pageIndex = 1;
    const pageSize = '30';

    // Api呼出し状態を未完了(0)に設定
    setApiCallStatus(0);

    setInviteAccount({
      totalCount: 1,
      page: pageIndex,
      size: pageSize,
      rows: [],
    });

    // 招待中アカウント一覧API呼び出し
    inviteAccountListAPI(authInfo?.scimId || '', pageIndex, pageSize);
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // Api呼出し状態を呼出し完了(1)に設定
      setApiCallStatus(1);

      // 招待中アカウント一覧設定
      setInviteAccount({
        ...inviteAccount,
        totalCount: response?.totalCount,
        rows: response?.dataList,
      });

      // 次へボタン制御
      if (response.totalPage > inviteAccount.page) {
        // 活性
        setIsPageNextDisabled(false);
      } else {
        // 非活性
        setIsPageNextDisabled(true);
      }

      // 前へボタン制御
      if (inviteAccount.page > 1) {
        // 活性
        setIsPageBackDisabled(false);
      } else {
        // 非活性
        setIsPageBackDisabled(true);
      }
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    isPageBackDisabled,
    isPageNextDisabled,
    handleInputEvent,
    handleSearchBack,
    handleSearchNext,
    inviteAccountListAPI,
    handleInviteUpdate,
    inviteAccount,
    apiCallStatus,
  };
};
export default useInviteAccount;
