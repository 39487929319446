import { useContext, useEffect, useState } from 'react';
import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  CorprationUsersItem,
  CorprationUsersListRequest,
  CorprationUsersListResponse,
} from 'app/service/customer/corporation/users/list';
import { AuthContext } from 'app/hooks/context/auth';

// 企業情報
export interface CorporationUsers {
  searchValue: string; // 検索条件
  totalCount: number; // 総件数
  page: number; // 取得対象ページ番号
  size: string; // 表示対象件数
  rows?: CorprationUsersItem[] | null;
  sortItem?: 'email' | 'name' | 'departmentName' | null; // ソート項目
  sortType?: 'asc' | 'desc' | null; // ソート順
}

/**
 * 法人ユーザ一覧業務カスタムHooks
 */
interface useCorporationUsersProps {
  searchAuthType?: '0' | '1';
}

export const useCorporationUsers = (param?: useCorporationUsersProps) => {
  // 法人ユーザ一覧情報
  const [corporationUsers, setCorporationUsers] =
    // 初期値設定
    useState<CorporationUsers>({
      searchValue: '',
      totalCount: 0,
      page: 0,
      size: '',
      rows: [],
    });

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 前へボタン制御（true：非活性、false：活性）
  const [isPageBackDisabled, setIsPageBackDisabled] = useState<boolean>(true);
  // 次へボタン制御（true：非活性、false：活性）
  const [isPageNextDisabled, setIsPageNextDisabled] = useState<boolean>(true);

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   * 検索中：2
   * 検索クリア：3
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // プルダウンの場合はイベント発火
    if (name === 'size' && target.type !== 'checkbox') {
      handleSelect(target.value);
    } else {
      setCorporationUsers({
        ...corporationUsers,
        [name]: value,
      });
    }
  };

  // 表示件数変更アクション
  const handleSelect = (sizeIndex: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setCorporationUsers({
      ...corporationUsers,
      page: pageIndex,
      size: sizeIndex,
    });
    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      pageIndex,
      sizeIndex,
      corporationUsers.searchValue
    );
  };

  // 前へアクション
  const handleSearchBack = () => {
    // ページ減算
    const pageIndex = corporationUsers.page - 1;

    setCorporationUsers({
      ...corporationUsers,
      page: pageIndex,
      size: corporationUsers.size,
    });
    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      pageIndex,
      corporationUsers.size,
      corporationUsers.searchValue
    );
  };

  // 次へアクション
  const handleSearchNext = () => {
    // ページ加算
    const pageIndex = corporationUsers.page + 1;
    setCorporationUsers({
      ...corporationUsers,
      page: pageIndex,
      size: corporationUsers.size,
    });
    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      pageIndex,
      corporationUsers.size,
      corporationUsers.searchValue
    );
  };

  // 検索アクション
  const handleSearch = () => {
    // 1ページ目に設定
    const pageIndex = 1;
    
    setCorporationUsers({
      ...corporationUsers,
      searchValue: corporationUsers.searchValue,
      page: pageIndex,
      size: corporationUsers.size,
    });

    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      pageIndex,
      corporationUsers.size,
      corporationUsers.searchValue
    );
  };

  // 検索条件削除
  const handleSearchDelete = () => {
    // 1ページ目に設定
    const pageIndex = 1;
    // 検索状態のときは、ステータスを3に設定
    if (apiCallStatus === 2) setApiCallStatus(3);

    setCorporationUsers({
      ...corporationUsers,
      searchValue: '',
    });

    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      pageIndex,
      corporationUsers.size
    );
  };

  // 更新アクション
  const handleCorporationUpdate = () => {
    // 1ページ目に設定
    const pageIndex = 1;

    setCorporationUsers({
      ...corporationUsers,
      page: pageIndex,
    });
    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      pageIndex,
      corporationUsers.size,
      corporationUsers.searchValue
    );
  };

  // 並び替え
  const handleSearchSort = (
    sortItem: 'email' | 'name' | 'departmentName' | null | undefined,
    sortType: 'asc' | 'desc' | null | undefined
  ) => {
    // 並び替え情報設定
    setCorporationUsers({
      ...corporationUsers,
      sortItem,
      sortType,
    });
    // 法人ユーザ一覧情報リクエスト呼び出し
    corporationUsersListAPI(
      authInfo?.corporationId || '',
      corporationUsers.page,
      corporationUsers.size,
      corporationUsers.searchValue,
      sortItem,
      sortType
    );
  };

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<CorprationUsersListResponse>();

  // 法人ユーザ一覧API
  const corporationUsersListAPI = (
    corporationId: string,
    pageIndex = 1,
    pageSize = '30',
    searchValue?: string,
    sortItem?: 'email' | 'name' | 'departmentName' | null, // ソート項目
    sortType?: 'asc' | 'desc' | null // ソート順
  ) => {
    // API Request設定
    const req: CorprationUsersListRequest = {};
    if (searchValue) req.searchValue = searchValue;
    if (searchValue) setApiCallStatus(2); // 画面の状態を検索中＝２に設定
    if (param?.searchAuthType) req.searchAuthType = param?.searchAuthType;

    if (corporationUsers.sortItem) req.sortItem = corporationUsers.sortItem;
    if (sortItem) req.sortItem = sortItem;
    if (corporationUsers.sortType) req.sortType = corporationUsers.sortType;
    if (sortType) req.sortType = sortType;

    // 一覧取得API呼出し
    const CORPORATION_USERS_LIST: ApiConfig = {
      url: `/api/v1/corporation/users/${corporationId}/${pageIndex}/${pageSize}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void callApi(CORPORATION_USERS_LIST, 'GET', req);
  };

  useEffect(() => {
    // 初期処理
    const pageIndex = 1;
    const pageSize = '30';

    setCorporationUsers({
      searchValue: '',
      totalCount: 1,
      page: pageIndex,
      size: pageSize,
      rows: [],
    });

    // Api呼び出し状態を初期化する
    setApiCallStatus(0);

    // 法人ユーザ一覧API呼び出し
    corporationUsersListAPI(authInfo?.corporationId || '', pageIndex, pageSize);
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // Api呼び出しステータスが 0(初期状態) もしくは 3(検索クリア)の場合はステータスを1に設定
      if (apiCallStatus === 0 || apiCallStatus === 3) setApiCallStatus(1);

      // 法人ユーザ一覧設定
      setCorporationUsers({
        ...corporationUsers,
        totalCount: response?.totalCount,
        rows: response?.dataList,
      });

      // 次へボタン制御
      if (response.totalPage > corporationUsers.page) {
        // 活性
        setIsPageNextDisabled(false);
      } else {
        // 非活性
        setIsPageNextDisabled(true);
      }

      // 前へボタン制御
      if (corporationUsers.page > 1) {
        // 活性
        setIsPageBackDisabled(false);
      } else {
        // 非活性
        setIsPageBackDisabled(true);
      }
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    isPageBackDisabled,
    isPageNextDisabled,
    handleInputEvent,
    handleSearchBack,
    handleSearchNext,
    handleSearch,
    handleSearchDelete,
    corporationUsersListAPI,
    handleCorporationUpdate,
    handleSearchSort,
    corporationUsers,
    apiCallStatus,
  };
};
export default useCorporationUsers;
