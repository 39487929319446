import { FC } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { ButtonLow } from 'app/views/components/block/button';
import { useHistory } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { useNoticeList } from 'app/hooks/notice';
import theme, { ColorInfo } from 'app/style/theme';
import useDevice from 'app/hooks/device';

interface NoticeListInterface {
  noticeListHook: ReturnType<typeof useNoticeList>;
}
export const useNoticeModule = () => {
  const useStyles = makeStyles({
    background01: {
      display: 'block',
      width: 104,
      fontSize: '1.2rem',
      textAlign: 'center',
      backgroundColor: ColorInfo.blue01,
      color: ColorInfo.white01,
      lineHeight: 'inherit',
    },
    background02: {
      display: 'block',
      width: 104,
      fontSize: '1.2rem',
      textAlign: 'center',
      backgroundColor: ColorInfo.blue02,
      color: ColorInfo.white01,
      lineHeight: 'inherit',
    },
    row: {
      width: '100%',
      margin: 0,
    },
    day: {
      width: 110,
      color: ColorInfo.gray01,
      minWidth: 250,
    },
    cat: {
      width: 140,
    },
    title: {
      width: 750,
      lineHeight: 1.4,
      [theme.breakpoints.down('sm')]: {
        marginTop: -5,
        lineHeight: 1.1,
        padding: '0 0 10px',
      },
    },
    category: {
      justifyContent: 'flex-start',
    },
  });
  const classes = useStyles();

  const GetCategoryIcon = (categoryType: string) => {
    switch (categoryType) {
      case '0': // "0”：「メンテナンス」
        return (
          <Typography className={classes.background01}>メンテナンス</Typography>
        );
      case '1': // "1”：「サービス情報」
        return (
          <Typography className={classes.background02}>サービス</Typography>
        );
      default:
        return true;
    }
  };

  // お知らせ一覧表示
  const ViewNociteList: FC<NoticeListInterface> = ({ noticeListHook }) => {
    const { noticeList } = noticeListHook;
    const history = useHistory();
    const isMobile = useDevice();
    const detailPush = (noticeId: string) => {
      const path = `${PageInfo.NOTICE_BASE.path}/${noticeId}`;
      history.push(path);
    };
    return (
      <>
        {noticeList.dataList !== null && noticeList.dataList !== undefined && (
          <>
            {noticeList.dataList.map((row) => (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                className={classes.row}
                key={row.notificationId}
              >
                <Grid
                  container
                  item
                  xs={4}
                  md={2}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Typography className={classes.day}>
                    {row.viewDateStart}
                  </Typography>
                </Grid>
                <Grid container item xs={8} md={4} className={classes.category}>
                  <Box className={classes.cat}>
                    {GetCategoryIcon(row.categoryType)}
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Box className={classes.title}>
                    <ButtonLow
                      onClick={() => detailPush(row.notificationId)}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {row.notificationTitle}
                    </ButtonLow>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </>
    );
  };
  return { GetCategoryIcon, ViewNociteList };
};
export default useNoticeModule;
