import { FC, useContext, useState } from 'react';
import { Toolbar, Button, AppBar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import { ColorInfo } from 'app/style/theme';
import Menu from 'app/views/components/block/menu';
import { useHistory, Link } from 'react-router-dom';
import { PageInfo, UserTypeConst } from 'app/utils/constants';
import { AuthContext } from 'app/hooks/context/auth';
import { useOneId } from 'app/hooks/oneId';
import useLogout from 'app/hooks/logout';
import useDevice from 'app/hooks/device';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';

const useStyles = makeStyles({
  headerArea: {
    width: '100%',
    height: 100,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: ColorInfo.white01,
    '&.is-transparent': {
      backgroundColor: 'transparent',
    },
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 50,
  },
  mobileHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    '& a': {
      display: 'block',
      lineHeight: 1,
    },
    '& img': {
      width: 230,
      height: 'auto',
    },
  },
  mobileHeaderLogo: {
    '& a': {
      display: 'block',
      lineHeight: 1,
    },
    '& img': {
      width: 180,
      height: 'auto',
    },
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50,
    '& li': {
      paddingLeft: 10,
      paddingRight: 0,
    },
  },
  headerButton: {
    height: 50,
    width: 170,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.4rem',
    lineHeight: 1,
    fontWeight: 600,
    letterSpacing: 1,
    color: ColorInfo.blue02,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    borderRadius: 4,
    backgroundColor: ColorInfo.blue05,
    transition: 'all 0.3s',
    '&:hover': {
      color: ColorInfo.white01,
      backgroundColor: ColorInfo.blue03,
    },
    '& svg': {
      fill: 'currentColor',
    },
  },
  headerButtonText: {
    marginLeft: 8,
  },
  menuList: {
    marginLeft: '15px',
    marginRight: '10px',
    width: '65px',
  },

  menuIcon: {
    width: '50px',
    height: '50px',
    marginLeft: '15px',
    marginRight: '15px',
    padding: 0,
    minWidth: '50px',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  menuButton: {
    padding: 0,
  },
  menu: {
    width: '430px',
    height: '800px',
    // backgroundColor: ColorInfo.blue01,
    color: ColorInfo.blue01,
  },
});

export interface HeaderProps {
  elevation?: number;
  transparent?: boolean;
}

const Header: FC<HeaderProps> = ({ elevation = 0, transparent }) => {
  // Drawer の開閉状態
  const [drawerOpen, setDrawerOpen] = useState(false);
  // Drawer の開閉イベント
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Drawer の開閉状態を反転
  };
  // 端末判定
  const isMobile = useDevice();

  const classes = useStyles();
  const history = useHistory();
  const { authInfo } = useContext(AuthContext);
  // ログイン関連(OneID Connect関連)カスタムHooks
  const { eventLogin, eventLoginCreate } = useOneId();
  // ログアウト
  const logout = useLogout();
  // ログインアイコン
  const LoginIcon = () => (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.21,19.5h-7V18h7a1.25,1.25,0,0,0,1.25-1.25v-14A1.25,1.25,0,0,0,16.21,1.5h-7V0h7A2.75,2.75,0,0,1,19,2.75v14A2.75,2.75,0,0,1,16.21,19.5Z" />
      <polygon points="9.07 4.55 8.01 5.61 11.4 9 1 9 1 10.5 11.4 10.5 8.01 13.89 9.07 14.95 14.27 9.75 9.07 4.55" />
    </svg>
  );
  // 新規登録アイコン
  const LoginCreateIcon = () => (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="cls-1"
        d="M19.31,3.07,17,.73a1.8,1.8,0,0,0-2.47,0L.32,14.91v4.81H5.13L19.31,5.54A1.75,1.75,0,0,0,19.31,3.07ZM4.51,18.22H1.82V15.53L13.16,4.18l2.69,2.69ZM18.25,4.48,16.91,5.81,14.22,3.12l1.34-1.33a.27.27,0,0,1,.35,0l2.34,2.34A.24.24,0,0,1,18.25,4.48Z"
      />
    </svg>
  );
  // ログアウトアイコン
  const LogoutIcon = () => (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.21,19.5h-7V18h7a1.25,1.25,0,0,0,1.25-1.25v-14A1.25,1.25,0,0,0,16.21,1.5h-7V0h7A2.75,2.75,0,0,1,19,2.75v14A2.75,2.75,0,0,1,16.21,19.5Z" />
      <polygon points="6.2 4.55 7.26 5.61 3.87 9 14.27 9 14.27 10.5 3.87 10.5 7.26 13.89 6.2 14.95 1 9.75 6.2 4.55" />
    </svg>
  );

  // マイページアイコン
  const MypageIcon = () => (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.35,11.17a4.59,4.59,0,1,1,4.58-4.59A4.59,4.59,0,0,1,10.35,11.17Zm0-7.67a3.09,3.09,0,1,0,3.08,3.08A3.08,3.08,0,0,0,10.35,3.5Z" />
      <path d="M16.28,17.87a6.29,6.29,0,0,0-11.87,0L3,17.37a7.8,7.8,0,0,1,14.7,0Z" />
    </svg>
  );
  const HeadButton = () => {
    // ID連携済
    if (authInfo?.userType === UserTypeConst.CORP_PREMIUM) {
      return (
        <>
          {!isMobile && (
            <ListItem>
              <Button
                className={classes.headerButton}
                onClick={() => history.push(PageInfo.MYPAGE.path)}
              >
                <MypageIcon />
                <span className={classes.headerButtonText}>マイページ</span>
              </Button>
            </ListItem>
          )}
          <ListItem className={classes.menuList}>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </ListItem>
        </>
      );
    }
    // ログイン済（ID連携済）
    if (authInfo?.isAuth && !isMobile) {
      return (
        <>
          <ListItem>
            <Button className={classes.headerButton} onClick={() => logout()}>
              <LogoutIcon />
              <span className={classes.headerButtonText}>ログアウト</span>
            </Button>
          </ListItem>
        </>
      );
    }
    return (
      <>
        {!isMobile && (
          <>
            <ListItem>
              <Button
                className={classes.headerButton}
                onClick={() => {
                  // GAタグ：ヘッダ-新規登録押下
                  onGaClick('B-000-000-H-001');
                  // DMPビーコンタグ：ヘッダ-新規登録押下
                  OnDmpClick('B-000-000-H-001', authInfo?.scimId);
                  eventLoginCreate();
                }}
                startIcon={<LoginCreateIcon />}
              >
                <span className={classes.headerButtonText}>新規登録</span>
              </Button>
            </ListItem>
            <ListItem>
              <Button
                className={classes.headerButton}
                onClick={() => {
                  // GAタグ：ヘッダ-ログイン押下
                  onGaClick('B-000-000-H-002');
                  // DMPビーコンタグ：ヘッダ-ログイン押下
                  OnDmpClick('B-000-000-H-002', authInfo?.scimId);
                  eventLogin();
                }}
              >
                <LoginIcon />
                <span className={classes.headerButtonText}>ログイン</span>
              </Button>
            </ListItem>
          </>
        )}
      </>
    );
  };
  return (
    <AppBar
      position="relative"
      component="header"
      elevation={elevation}
      color={transparent ? 'transparent' : 'primary'}
    >
      <Toolbar
        className={`${classes.headerArea} ${
          transparent ? 'is-transparent' : ''
        }`}
      >
        <List
          className={isMobile ? classes.mobileHeaderLeft : classes.headerLeft}
        >
          <ListItem
            className={isMobile ? classes.mobileHeaderLogo : classes.headerLogo}
          >
            <Link to={PageInfo.ENTORY.path}>
              <img
                src={`${process.env.PUBLIC_URL}/logo.svg`}
                alt="Toyota Biz Center"
              />
            </Link>
          </ListItem>
        </List>
        <List className={classes.headerRight}>{HeadButton()}</List>
      </Toolbar>
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        className={classes.menu}
        anchor="right"
        PaperProps={{
          style: {
            backgroundColor: ColorInfo.blue01,
          },
        }}
        ModalProps={{
          style: {
            position: 'relative',
            inset: 'none',
          },
        }}
      >
        <Menu onClose={handleDrawerToggle} />
      </Drawer>
    </AppBar>
  );
};

export default Header;
