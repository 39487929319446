import { FC, memo } from 'react';
import {
  FormControlLabel,
  FormControl,
  makeStyles,
  Radio,
} from '@material-ui/core';
import { FiberManualRecordRounded, LensRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  label: {
    fontSize: '1.8rem',
    marginLeft: '12px',
  },
});

// インタフェース
interface RadioProps {
  label: string;
  value: string;
  name: string;
  handleCheckEvent: (name: string, value: boolean) => void;
  disabled?: boolean;
}

const InputRadio: FC<RadioProps> = memo(
  ({ label, value, name, handleCheckEvent, disabled }) => {
    const classes = useStyles();

    return (
      <>
        {!disabled && (
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Radio
                  name={name}
                  style={{ padding: 0 }}
                  icon={<LensRounded style={{ fontSize: 32 }} />}
                  checkedIcon={
                    <FiberManualRecordRounded style={{ fontSize: 32 }} />
                  }
                  disableRipple
                />
              }
              label={label}
              value={value}
              onChange={(event, check) => handleCheckEvent(name, check)}
              classes={{ label: classes.label }}
            />
          </FormControl>
        )}
      </>
    );
  }
);
interface SimpleRadioProps {
  name: string;
  value: string;
  selectedValue: string | null;
  onChange: (value: string, check: boolean) => void;
}
export const SimpleRadio: FC<SimpleRadioProps> = memo(
  ({ name, value, selectedValue, onChange }) => (
    <>
      <Radio
        checked={value === selectedValue}
        value={value}
        onChange={(event, check) => onChange(value, check)}
        name={name}
        style={{ padding: 0, margin: 0 }}
        icon={<LensRounded style={{ fontSize: 32 }} />}
        checkedIcon={<FiberManualRecordRounded style={{ fontSize: 32 }} />}
        disableRipple
      />
    </>
  )
);

export default InputRadio;
