import { FC, memo } from 'react';
import clsx from 'clsx';
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Grid,
  makeStyles,
  Box,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ColorInfo } from 'app/style/theme';

const useStyles = makeStyles({
  label: {
    fontSize: '1.8rem',
    marginLeft: '12px',
  },
  req: {
    fontSize: '1.8rem',
    marginLeft: '12px',
    '&::after': {
      content: '"*"',
      color: ColorInfo.red01,
    },
  },
  icon: {
    position: 'relative',
    top: 0,
    left: 0,
    display: 'block',
    width: '24px',
    height: '24px',
    border: `1px solid ${ColorInfo.gray03}`,
    background: ColorInfo.gray05,
    borderRadius: '4px',
  },
  checkedIcon: {
    position: 'relative',
    top: 0,
    left: 0,
    display: 'block',
    width: '24px',
    height: '24px',
    border: `1px solid ${ColorInfo.gray03}`,
    borderRadius: '4px',
    backgroundColor: ColorInfo.blue02,
    '&::before': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '50%',
      height: '3px',
      borderRadius: '2px',
      transform: 'translate(-5px, 2px) rotateZ(-135deg)',
      transformOrigin: '2px 2px',
      background: ColorInfo.white01,
    },
    '&::after': {
      content: `""`,
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '80%',
      height: '3px',
      borderRadius: '2px',
      transform: 'translate(-4px, 3px) rotateZ(-45deg)',
      transformOrigin: '2px 2px',
      background: ColorInfo.white01,
    },
  },
});

// インタフェース
interface checkListProps {
  id: string;
  label: string;
  value: string;
  name: string;
  checked: boolean;
  handleCheckEvent: (name: string, value: boolean) => void;
  disabled?: boolean;
  required?: boolean;
}
// インタフェース
interface checkProps {
  label: string;
  value: boolean;
  name: string;
  handleCheckEvent: (name: string, value: boolean) => void;
  disabled?: boolean;
  required?: boolean;
}

const InputCheck: FC<checkProps> = memo(
  ({ label, value, name, handleCheckEvent, disabled, required = false }) => {
    const classes = useStyles();

    return (
      <>
        {disabled && value && (
          <Grid container direction="row">
            <Typography variant="body1">{label}</Typography>
            {required && (
              <Box sx={{ color: ColorInfo.red01, fontSize: '1.8rem' }}>*</Box>
            )}
          </Grid>
        )}
        {!disabled && (
          <Grid>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    name={name}
                    icon={<span className={classes.icon} />}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    disableRipple
                  />
                }
                label={label}
                value={value}
                onChange={(event, check) => handleCheckEvent(name, check)}
                checked={value}
                classes={{ label: required ? classes.req : classes.label }}
              />
            </FormControl>
          </Grid>
        )}
      </>
    );
  }
);

export const InputCheckList: FC<checkListProps> = memo(
  ({
    id,
    label,
    checked,
    value,
    name,
    handleCheckEvent,
    disabled,
    required = false,
  }) => {
    const classes = useStyles();

    return (
      <>
        {disabled && value && (
          <Grid container direction="row">
            <Typography variant="body1">{label}</Typography>
            {required && (
              <Box sx={{ color: ColorInfo.red01, fontSize: '1.8rem' }}>*</Box>
            )}
          </Grid>
        )}
        {!disabled && (
          <Grid>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    name={name}
                    icon={<span className={classes.icon} />}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    disableRipple
                  />
                }
                id={id}
                label={label}
                value={value}
                onChange={(event, check) => handleCheckEvent(id, check)}
                checked={checked}
                classes={{ label: required ? classes.req : classes.label }}
              />
            </FormControl>
          </Grid>
        )}
      </>
    );
  }
);

export const CheckIcon: FC = memo(() => {
  const classes = useStyles();
  return <span className={classes.icon} />;
});

export const CheckIconChecked: FC = memo(() => {
  const classes = useStyles();
  return <span className={clsx(classes.icon, classes.checkedIcon)} />;
});

export default InputCheck;
