import { ThemeProvider } from '@material-ui/core/styles';
import { AuthContextProvider } from 'app/hooks/context/auth';
import theme from 'app/style/theme';
import { PageInfo } from 'app/utils/constants';
import ScrollToTop from 'app/views/components/block/scrolltop';
import AccountManage from 'app/views/pages/account/manage';
import { AccountAuthPage } from 'app/views/pages/account/manage/auth/transfer';
import ContractDetail from 'app/views/pages/contract/detail';
import Contract from 'app/views/pages/contract/index';
import ContractTerms from 'app/views/pages/contract/terms';
import ContractUsersInvitePage from 'app/views/pages/contract/users/invite';
import ContractUsersManage from 'app/views/pages/contract/users/manage/index';
import CompanyApplyPage from 'app/views/pages/corporation/apply';
import CompanyIdFederatePage from 'app/views/pages/corporation/id/federate';
import CompanyInfoEditPage from 'app/views/pages/corporation/info/edit';
import CompanyInfoEditCancelPage from 'app/views/pages/corporation/info/edit/cancel/complete';
import { ContractResignRootPage } from 'app/views/pages/corporation/resign';
import CompanyAdminChangePage from 'app/views/pages/corporation/users/admin/change';
import CompanyUsersInvitePage from 'app/views/pages/corporation/users/invite';
import CompanyUsersInviteMailPage from 'app/views/pages/corporation/users/mail';
import EntryPage from 'app/views/pages/entry';
import Error404Page from 'app/views/pages/error/404';
import Error500Page from 'app/views/pages/error/500';
// TODO 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる
// import FaqPage from 'app/views/pages/faq';
// import PartsListPage from 'app/views/pages/help/parts';
import HowToGuidePage from 'app/views/pages/howtoguide';
import InquiryPage from 'app/views/pages/inquiry';
import InquiryBranchPage from 'app/views/pages/inquiry/inquirybranch';
import IntroduceServicePage from 'app/views/pages/introduce';
import MyPage from 'app/views/pages/mypage';
import NoticeDetail from 'app/views/pages/notice/detail/index';
import NoticeList from 'app/views/pages/notice/index';
import ServiceDetail from 'app/views/pages/service/detail/index';
import ServiceUsePage from 'app/views/pages/service/use/index';
import Terms from 'app/views/pages/terms';
import TopPage from 'app/views/pages/top';
import AuthenticatedGuard from 'AuthenticatedGuard';
import {
  AuthenticatedRoute,
  AuthExcludePersonRoute,
  AuthIdFederateRoute,
} from 'AuthenticatedRoute';
import LoginUserInfoAuth from 'LoginUserInfoAuth';
import { FC } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ApiKeyListPage from 'app/views/pages/contract/detail/apikey';
import CompanyApplyTermsPage from 'app/views/pages/corporation/apply/terms';
import AttributePage from 'app/views/pages/attribute';
import AboutPage from 'app/views/pages/about';
import LinkDetach from 'app/views/pages/link/detach';
import LinkDetachComplete from 'app/views/pages/link/detach/complete';
import SdpTracking from 'app/utils/dmp/tracking';

const App: FC = () => (
  <Router>
    <ScrollToTop />
    <ThemeProvider theme={theme}>
      <Switch>
        {/* 認証不要 */}
        <Route path={PageInfo.ERROR_404.path} component={Error404Page} exact />
        <Route path={PageInfo.ERROR_500.path} component={Error500Page} exact />

        <Route
          path={`${PageInfo.CORP_USERS_INVITE_MAIL_ERROR.path}/:userType`}
          component={CompanyUsersInviteMailPage}
          exact
        />

        {/* ヘルプパーツへはアクセス不可 */}
        {/** <Route path="/help/parts" component={PartsListPage} exact /> */}

        <AuthContextProvider>
          <LoginUserInfoAuth>
            <SdpTracking />
            <Switch>
              {/* 認証不要 */}
              <Route path={PageInfo.ENTORY.path} component={EntryPage} exact />
              <Route path={PageInfo.TOP.path} component={TopPage} exact />
              <Route
                path={PageInfo.INTRODUCE.path}
                component={IntroduceServicePage}
                exact
              />
              <Route path={PageInfo.INQUIRY_BASE.path}>
                <InquiryRouter />
              </Route>
              {/* 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる */}
              {/* <Route path={PageInfo.FAQ.path} exact>
                <FaqPage />
              </Route> */}
              <Route
                path={PageInfo.NOTICE_BASE.path}
                component={NoticeList}
                exact
              />
              <Route
                path={PageInfo.NOTICE_PARAM.path}
                component={NoticeDetail}
                exact
              />
              <Route
                path={PageInfo.ATTRIBUTE.path}
                component={AttributePage}
                exact
              />
              <Route path={PageInfo.ABOUT.path} component={AboutPage} exact />
              {/* 登録方法選択画面へのパスはTOPへリダイレクトさせる */}
              <Route path={PageInfo.REGISTRATION.path}>
                <Redirect to={PageInfo.TOP.path} />
              </Route>
              {/* 認証必要 */}
              <AuthenticatedRoute
                path={PageInfo.TERM_APPLY.path}
                component={Terms}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.TERM_FEDERATE.path}
                component={Terms}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.CONTRACT_TERMS.path}
                component={ContractTerms}
                exact
              />
              <Route path={PageInfo.CORP_APPLY_BASE.path}>
                <AuthenticatedGuard>
                  <CompanyApplyRouter />
                </AuthenticatedGuard>
              </Route>
              <Route path={PageInfo.CORP_FEDERATE_BASE.path}>
                <AuthenticatedGuard>
                  <CompanyIdFederateRouter />
                </AuthenticatedGuard>
              </Route>
              <Route path={PageInfo.CORP_USERS_INVITE_BASE.path}>
                <AuthenticatedGuard>
                  <CompanyUsersInviteRouter />
                </AuthenticatedGuard>
              </Route>
              <Route path={PageInfo.CORP_RESIGN_BASE.path}>
                <AuthenticatedGuard>
                  <CorporationResignRouter />
                </AuthenticatedGuard>
              </Route>
              <AuthIdFederateRoute
                path={PageInfo.ACCOUNT_MANAGE.path}
                component={AccountManage}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.CONTRACT.path}
                component={Contract}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.CONTRACT_USERS_MANAGE_PARAM.path}
                component={ContractUsersManage}
                exact
              />
              <Route path={PageInfo.CONTRACT_USERS_INVITE_BASE.path}>
                <AuthenticatedGuard>
                  <ContractUsersInviteRouter />
                </AuthenticatedGuard>
              </Route>
              <AuthenticatedRoute
                path={PageInfo.MYPAGE.path}
                component={MyPage}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.SERVICE_USE.path}
                component={ServiceUsePage}
                exact
              />
              <AuthenticatedRoute
                path={`${PageInfo.SERVICE_DETAIL.path}:serviceId`}
                component={ServiceDetail}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.CONTRACT_DETAIL_PARAM.path}
                component={ContractDetail}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.ACCOUNT_AUTH_PARAM.path}
                component={AccountAuthPage}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.CONTRACT_APIKEY_PARAM.path}
                component={ApiKeyListPage}
                exact
              />
              <Route path={PageInfo.CORP_ADMIN_CHANGE_BASE.path}>
                <AuthenticatedGuard>
                  <CorpAdminChangeRouter />
                </AuthenticatedGuard>
              </Route>
              <Route path={PageInfo.CORP_INFO_EDIT_BASE.path}>
                <AuthenticatedGuard>
                  <CompanyInfoEditRouter />
                </AuthenticatedGuard>
              </Route>
              <Route
                path={PageInfo.HOW_TO_GUIDE.path}
                component={HowToGuidePage}
                exact
              />
              <AuthenticatedRoute
                path={PageInfo.LINK_DETACH.path}
                component={LinkDetach}
                exact
              />
              <Route
                path={PageInfo.LINK_DETACH_COMPLETE.path}
                component={LinkDetachComplete}
                exact
              />
              <Route component={Error404Page} />
            </Switch>
          </LoginUserInfoAuth>
        </AuthContextProvider>
        <Route component={Error404Page} />
      </Switch>
    </ThemeProvider>
  </Router>
);
// 企業申請Router
const CompanyApplyRouter: FC = () => (
  <Switch>
    <AuthExcludePersonRoute
      path={PageInfo.CORP_APPLY_TERMS.path}
      component={CompanyApplyTermsPage}
      exact
    />

    <AuthExcludePersonRoute
      path={PageInfo.CORP_APPLY_INPUT.path}
      component={CompanyApplyPage}
      exact
    />
    <AuthExcludePersonRoute
      path={PageInfo.CORP_APPLY_CONFIRM.path}
      component={CompanyApplyPage}
      exact
    />
    <AuthExcludePersonRoute
      path={PageInfo.CORP_APPLY_COMPLETE.path}
      component={CompanyApplyPage}
      exact
    />
    <Route path={PageInfo.CORP_APPLY_BASE.path} component={Error404Page} />
  </Switch>
);
// 企業ID連携
const CompanyIdFederateRouter: FC = () => (
  <Switch>
    <AuthExcludePersonRoute
      path={PageInfo.CORP_FEDERATE_INPUT.path}
      component={CompanyIdFederatePage}
      exact
    />
    <AuthExcludePersonRoute
      path={PageInfo.CORP_FEDERATE_CONFIRM.path}
      component={CompanyIdFederatePage}
      exact
    />
    <AuthExcludePersonRoute
      path={PageInfo.CORP_FEDERATE_COMPLETE.path}
      component={CompanyIdFederatePage}
      exact
    />
    <Route path={PageInfo.CORP_FEDERATE_BASE.path} component={Error404Page} />
  </Switch>
);
// ユーザ招待
const CompanyUsersInviteRouter: FC = () => (
  <Switch>
    <Route
      path={PageInfo.CORP_USERS_INVITE_INPUT.path}
      component={CompanyUsersInvitePage}
      exact
    />
    <Route
      path={PageInfo.CORP_USERS_INVITE_CONFIRM.path}
      component={CompanyUsersInvitePage}
      exact
    />
    <Route
      path={PageInfo.CORP_USERS_INVITE_COMPLETE.path}
      component={CompanyUsersInvitePage}
      exact
    />
    <Route
      path={PageInfo.CORP_USERS_INVITE_BASE.path}
      component={Error404Page}
    />
  </Switch>
);

// 問い合わせ
const InquiryRouter: FC = () => (
  <Switch>
    <Route
      path={PageInfo.INQUIRY_BRANCH.path}
      component={InquiryBranchPage}
      exact
    />
    <Route path={PageInfo.INQUIRY_INPUT.path} component={InquiryPage} exact />
    <Route path={PageInfo.INQUIRY_CONFIRM.path} component={InquiryPage} exact />
    <Route
      path={PageInfo.INQUIRY_COMPLETE.path}
      component={InquiryPage}
      exact
    />
    <Route path={PageInfo.INQUIRY_BASE.path} component={Error404Page} />
  </Switch>
);

// 契約内容照会－利用者招待Router
const ContractUsersInviteRouter: FC = () => (
  <Switch>
    <Route
      path={PageInfo.CONTRACT_USERS_INVITE_INPUT_PARAM.path}
      component={ContractUsersInvitePage}
      exact
    />
    <Route
      path={PageInfo.CONTRACT_USERS_INVITE_CONFIRM.path}
      component={ContractUsersInvitePage}
      exact
    />
    <Route
      path={PageInfo.CONTRACT_USERS_INVITE_COMPLETE.path}
      component={ContractUsersInvitePage}
      exact
    />
    <Route
      path={PageInfo.CONTRACT_USERS_INVITE_BASE.path}
      component={Error404Page}
    />
  </Switch>
);

// 企業管理者変更Router
const CorpAdminChangeRouter: FC = () => (
  <Switch>
    <Route
      path={PageInfo.CORP_ADMIN_CHANGE_INPUT.path}
      component={CompanyAdminChangePage}
      exact
    />
    <Route
      path={PageInfo.CORP_ADMIN_CHANGE_CONFIRM.path}
      component={CompanyAdminChangePage}
      exact
    />
    <Route
      path={PageInfo.CORP_ADMIN_CHANGE_COMPLETE.path}
      component={CompanyAdminChangePage}
      exact
    />
    <Route
      path={PageInfo.CORP_ADMIN_CHANGE_BASE.path}
      component={Error404Page}
    />
  </Switch>
);
// 企業情報変更Router
const CompanyInfoEditRouter: FC = () => (
  <Switch>
    <Route
      path={PageInfo.CORP_INFO_EDIT_INPUT.path}
      component={CompanyInfoEditPage}
      exact
    />
    <Route
      path={PageInfo.CORP_INFO_EDIT_CONFIRM.path}
      component={CompanyInfoEditPage}
      exact
    />
    <Route
      path={PageInfo.CORP_INFO_EDIT_COMPLETE.path}
      component={CompanyInfoEditPage}
      exact
    />
    <Route
      path={PageInfo.CORP_INFO_EDIT_CANCEL_COMPLETE.path}
      component={CompanyInfoEditCancelPage}
      exact
    />
    <Route path={PageInfo.CORP_INFO_EDIT_BASE.path} component={Error404Page} />
  </Switch>
);
// 企業退会Router
const CorporationResignRouter: FC = () => (
  <Switch>
    <Route
      path={PageInfo.CORP_RESIGN_BASE.path}
      component={ContractResignRootPage}
      exact
    />
    <Route
      path={PageInfo.CORP_RESIGN_CONFIRM.path}
      component={ContractResignRootPage}
      exact
    />
    <Route
      path={PageInfo.CORP_RESIGN_COMPLETE.path}
      component={ContractResignRootPage}
      exact
    />
  </Switch>
);
export default App;
