import { FC, memo, useEffect } from 'react';
import Template from 'app/views/components/template';
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Toolbar,
  Container,
  Link as ALink,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Select from 'app/views/components/block/select';
import {
  ButtonForward,
  SubButton,
  ButtonBack,
  ButtonLowForward,
  ButtonLowBack,
} from 'app/views/components/block/button';
import TextInput from 'app/views/components/atom/text';
import { ColorInfo } from 'app/style/theme';
import StepperContent from 'app/views/components/block/stepper';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import InputCheck from 'app/views/components/block/checkbox';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { PageInfo } from 'app/utils/constants';
import useContractUsersInvite, {
  ContractUsersInvite,
  ContractUsersInviteMessage,
} from 'app/hooks/contract/users/invite';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SortUnsortedIcon,
} from 'app/views/components/block/icon';
import useSortGrid from 'app/hooks/sortgrid';

// スタイル
const useStyles = makeStyles(() => ({
  span: {
    color: ColorInfo.red01,
  },
  h2: {
    paddingLeft: '0px',
  },
  mainText: {
    marginBottom: '10px',
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child > div': {
      width: 450,
    },
    '& > *:not(:first-child)': {
      marginLeft: 20,
      wordBreak: 'keep-all',
    },
  },
  listPager: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& button:first-child': {
      padding: '10px 0px 10px 65px',
      '&::before': {
        width: 30,
      },
    },
    marginBottom: '70px',
    '& button:last-child': {
      marginLeft: 60,
      padding: '10px 65px 10px 0px',
      '&::before': {
        width: 30,
      },
    },
    '& > *.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  listPagerConfirm: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& button:first-child': {
      padding: '10px 0px 10px 65px',
      '&::before': {
        width: 30,
      },
    },
    marginBottom: '25px',
    '& button:last-child': {
      marginLeft: 60,
      padding: '10px 65px 10px 0px',
      '&::before': {
        width: 30,
      },
    },
    '& > *.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  contractName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '470px',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: '-50px',
    width: '500px',
  },
}));

const useDataGridStyles = makeStyles({
  root: {
    marginTop: 15,
    border: 'none',
    borderTop: `1px solid ${ColorInfo.gray03}`,
    borderRadius: '0',
    '& *.MuiDataGrid-iconButtonContainer > *.MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  columnHeader: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    backgroundColor: ColorInfo.gray05,
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& *.MuiTouchRipple-root': {
      left: 10,
    },
  },
  cell: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  row: {
    height: 70,
    '&.MuiDataGrid-withBorder': {
      border: 'none',
    },
    '&.MuiDataGrid-row:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const options = [
  {
    value: '30',
    label: '30件',
  },
  {
    value: '50',
    label: '50件',
  },
  {
    value: '100',
    label: '100件',
  },
];

// インタフェース
interface ContractUsersInviteInterface {
  useContract: useContractUsersInviteInterface;
}
interface DataItem {
  usageAuthority: boolean;
  authorization: boolean;
  id: string;
  email: string;
  name: string;
  departmentName: string;
}
interface useContractUsersInviteInterface {
  status: number;
  handleBack: () => void;
  handleNext: () => void;
  isPageBackDisabled: boolean;
  isPageNextDisabled: boolean;
  handleInputEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckEvent: (name: string, value: boolean) => void;
  handleAuthorizationCheckEvent: (name: string, value: boolean) => void;
  handleUsageAuthorityCheckEvent: (name: string, value: boolean) => void;
  handleSearchBack: () => void;
  handleSearchNext: () => void;
  handleSearch: () => void;
  handleSearchDelete: () => void;
  handleAllSelect: (name: string, value: boolean) => void;
  contractUsersInvite: ContractUsersInvite;
  contractUsersInviteMessage: ContractUsersInviteMessage;
  globalMessage: string;
  handleSearchSort: (
    sortItem: 'name' | 'departmentName',
    sortType: 'asc' | 'desc'
  ) => void;
  apiCallStatus: number;
}

// サービス選択コンポーネント
const ContractUserInviteServiceSelect: FC<ContractUsersInviteInterface> = memo(
  ({ useContract }) => {
    // 契約内容照会－利用者招待務カスタムHooks
    const {
      status,
      handleCheckEvent,
      contractUsersInvite,
      contractUsersInviteMessage,
    } = useContract;

    const {
      contractServiceName: serviceName,
      contractName: name,
      contractOptionList: rows,
      hasServiceList: hasService,
    } = contractUsersInvite;

    const classes = useStyles();

    // 項目の活性（入力）、非活性（入力以外）
    const isDisabled = (): boolean => {
      switch (status) {
        case 0:
          return false;
          break;
        default:
          return true;
          break;
      }
    };

    // サービス名表示
    const serviceNameArea = () => {
      if (!isDisabled()) {
        return (
          <>
            <Typography variant="body1" className={classes.mainText}>
              利用権限を付与する項目を選択してください。
            </Typography>
            <Grid
              container
              justifyContent="flex-start"
              style={{ margin: '15px auto -15px', width: '80%' }}
            >
              {rows !== undefined &&
                rows !== null &&
                rows.map((row) => (
                  <Grid item xs={6} key={row.contractOptionId}>
                    <InputCheck
                      label={row.contractOptionName}
                      name={row.contractOptionId}
                      value={row.selected}
                      handleCheckEvent={handleCheckEvent}
                      disabled={isDisabled()}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        );
      }
      return (
        <Grid
          container
          justifyContent="flex-start"
          style={{ margin: '15px 0', width: '80%' }}
        >
          {rows !== undefined &&
            rows !== null &&
            rows.map((row) => (
              <>
                {row.selected && (
                  <Grid item sm={12} key={row.contractOptionId}>
                    <Typography>{row.contractOptionName}</Typography>
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      );
    };

    return (
      <>
        {/* サービス名 */}
        <Box sx={{ marginTop: '66px' }}>
          <Typography>{serviceName}</Typography>
          <Typography style={{ fontSize: '2rem' }}>{name}</Typography>
        </Box>
        {hasService && (
          <>
            <Typography variant="h3">オプション</Typography>
            {serviceNameArea()}
            {status === 0 && (
              <Typography color="error">
                {contractUsersInviteMessage?.option}
              </Typography>
            )}
          </>
        )}
      </>
    );
  }
);

// 招待ユーザー一覧コンポーネント
const ContractUserInviteUserList: FC<ContractUsersInviteInterface> = memo(
  ({ useContract }) => {
    // 契約内容照会－利用者招待務カスタムHooks
    const {
      status,
      handleInputEvent,
      handleUsageAuthorityCheckEvent,
      handleAuthorizationCheckEvent,
      isPageBackDisabled,
      isPageNextDisabled,
      handleSearchBack,
      handleSearchNext,
      handleSearch,
      handleSearchDelete,
      handleAllSelect,
      contractUsersInvite,
      contractUsersInviteMessage,
      handleSearchSort,
      apiCallStatus,
    } = useContract;

    const dataGridClasses = useDataGridStyles();

    const classes = useStyles();

    // 項目の活性（入力）、非活性（入力以外）
    const isDisabled = (): boolean => {
      switch (status) {
        case 0:
          return false;
          break;
        default:
          return true;
          break;
      }
    };

    // 一覧データ
    const {
      dataList,
      confirmDataList,
      contractNo,
      contractMenuId,
      contractCourseId,
    } = contractUsersInvite;
    const rows = !isDisabled ? dataList : confirmDataList;

    const columns: GridColDef[] = [
      {
        field: 'usageAuthority',
        headerName: '利用権限',
        sortable: false,
        width: 100,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const { row } = params;
          const data = row as DataItem;
          if (!isDisabled()) {
            return (
              <Box sx={{ marginTop: '30px', marginLeft: '40px' }}>
                <InputCheck
                  label=""
                  name={data.id}
                  value={data.usageAuthority}
                  handleCheckEvent={handleUsageAuthorityCheckEvent}
                />
              </Box>
            );
          }
          return <Typography>{data.usageAuthority}</Typography>;
        },
      },
      {
        field: 'authorization',
        headerName: '利用権限付与機能',
        sortable: false,
        width: 100,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
          <>
            <div style={{ height: 70 }}>
              <div
                style={{
                  height: '35px',
                  lineHeight: '35px',
                  textAlign: 'center',
                }}
              >
                利用権限
              </div>
              <div
                style={{
                  height: '35px',
                  lineHeight: '35px',
                  textAlign: 'center',
                }}
              >
                付与機能
              </div>
            </div>
          </>
        ),
        renderCell: (params) => {
          const { row } = params;
          const data = row as DataItem;
          if (!isDisabled()) {
            return (
              <Box sx={{ marginTop: '30px', marginLeft: '40px' }}>
                <InputCheck
                  label=""
                  name={data.id}
                  value={data.authorization}
                  handleCheckEvent={handleAuthorizationCheckEvent}
                />
              </Box>
            );
          }
          return <Typography>{data.authorization}</Typography>;
        },
      },
      {
        field: 'email',
        headerName: 'メールアドレス',
        sortable: false,
        width: 250,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'name',
        headerName: '氏名',
        sortable: !isDisabled(),
        width: 250,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'departmentName',
        headerName: '部署名',
        sortable: !isDisabled(),
        width: 298,
        filterable: false,
        headerAlign: 'center',
        align: 'center',
      },
    ];

    // SortGrid Hooks
    const { sortModel, handleSortModelChange } = useSortGrid();

    // GridのSort変更時
    useEffect(() => {
      if (sortModel.length !== 1) {
        return;
      }

      // APIパラメータ生成 'id' | 'name' | 'departmentName'
      const sortedColumn = sortModel[0];
      let sortItem: 'name' | 'departmentName' | null = null;
      if (sortedColumn.field === 'name') {
        // 名前
        sortItem = 'name';
      } else if (sortedColumn.field === 'departmentName') {
        // 部署名
        sortItem = 'departmentName';
      }

      let sortType: 'asc' | 'desc' | null = null;
      if (sortedColumn.sort === 'asc') {
        sortType = 'asc';
      } else if (sortedColumn.sort === 'desc') {
        sortType = 'desc';
      }

      // API呼出し
      if (sortItem && sortType) handleSearchSort(sortItem, sortType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortModel]);

    return (
      <>
        <Typography variant="h3">利用者追加</Typography>

        {apiCallStatus === 0 && (
          // API呼び出し未完了
          <></>
        )}
        {apiCallStatus === 1 && contractUsersInvite?.totalCount === 0 && (
          // 初期データ0件
          <>
            <Typography variant="body1" className={classes.mainText2}>
              現在、選択されたサービスに招待できる企業ユーザーはいません。
            </Typography>
            <Typography color="error">
              {contractUsersInviteMessage?.dataList}
            </Typography>
          </>
        )}

        {((apiCallStatus === 1 && contractUsersInvite?.totalCount !== 0) ||
          apiCallStatus === 2 ||
          apiCallStatus === 3) &&
          !isDisabled() && (
            // 初期データ1件以上 / 検索中 / 検索クリア中
            <>
              <Typography component="div">
                <Box sx={{ textAlign: 'left' }}>
                  利用権限を付与する企業ユーザーを一覧から選択してください。
                </Box>
                <Box sx={{ marginBottom: '20px', textAlign: 'left' }}>
                  選択した企業ユーザーに招待メールを送信します。
                </Box>
                <Box sx={{ textAlign: 'left' }}>※複数登録可</Box>
                <Box sx={{ textAlign: 'left' }}>
                  ※一覧には、上記サービスの利用権限がないユーザーのみ表示されます。
                </Box>
                <Box
                  sx={{
                    marginLeft: '15px',
                    marginBottom: '30px',
                    textAlign: 'left',
                  }}
                >
                  一覧に表示されていない（すでに利用権限をお持ちの）ユーザーは、
                  <Link
                    to={`${PageInfo.CONTRACT_USERS_MANAGE.path}/${contractNo}/${contractMenuId}/${contractCourseId}`}
                  >
                    サービス利用者一覧
                  </Link>
                  の「変更」から権限を変更してください。
                </Box>
              </Typography>
              <Box className={classes.search}>
                <Box className={classes.searchBox}>
                  <TextInput
                    name="searchValue"
                    value={contractUsersInvite?.searchValue}
                    placeholder="入力してください（メールアドレス、氏名、部署名など）"
                    handleInputEvent={handleInputEvent}
                  />
                  <SubButton onClick={handleSearch}>検索</SubButton>
                  <ALink
                    component="button"
                    variant="body1"
                    onClick={handleSearchDelete}
                  >
                    検索クリア
                  </ALink>
                </Box>
              </Box>

              {((apiCallStatus === 2 &&
                contractUsersInvite?.totalCount !== 0) ||
                (apiCallStatus === 1 &&
                  contractUsersInvite?.totalCount !== 0) ||
                apiCallStatus === 3) && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    margin: '25px 30px',
                  }}
                >
                  <InputCheck
                    label="すべてのユーザーを選択"
                    name="allSelected"
                    value={contractUsersInvite.allSelected}
                    handleCheckEvent={handleAllSelect}
                    disabled={isDisabled()}
                  />
                  <Grid item xs={4} />
                  <Grid style={{ display: 'block' }}>
                    <Typography variant="body1">
                      全{contractUsersInvite?.totalCount}件
                    </Typography>
                  </Grid>
                  <Grid style={{ marginRight: '70px' }}>
                    <Select
                      name="size"
                      value={contractUsersInvite?.size}
                      handleInputEvent={handleInputEvent}
                      options={options}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}

        {apiCallStatus === 2 && contractUsersInvite?.totalCount === 0 && (
          // 検索した結果データが0件
          <div>
            <br />
            <Typography variant="body1" className={classes.mainText2}>
              検索条件と一致するユーザー情報はありません。
            </Typography>
            <Typography color="error">
              {contractUsersInviteMessage?.dataList}
            </Typography>
          </div>
        )}

        {((apiCallStatus === 2 && contractUsersInvite?.totalCount !== 0) ||
          (apiCallStatus === 1 && contractUsersInvite?.totalCount !== 0) ||
          apiCallStatus === 3) && (
          // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中
          <div>
            {!isDisabled() && (
              <Box sx={{ textAlign: 'left' }}>
                利用権限：サービスを利用できる権限 ／
                利用権限付与機能：サービスの利用権限を他のユーザーに付与できる権限
              </Box>
            )}
            <DataGrid
              columns={columns}
              autoHeight
              hideFooter
              disableColumnMenu
              rows={!isDisabled() ? dataList : confirmDataList}
              headerHeight={70}
              rowHeight={70}
              components={{
                ColumnSortedAscendingIcon: SortAscendingIcon,
                ColumnSortedDescendingIcon: SortDescendingIcon,
                ColumnUnsortedIcon: SortUnsortedIcon,
              }}
              classes={{
                root: dataGridClasses.root,
                columnHeader: dataGridClasses.columnHeader,
                cell: dataGridClasses.cell,
                row: dataGridClasses.row,
              }}
              sortingMode="server"
              onSortModelChange={(model) => handleSortModelChange(model)}
              sortModel={sortModel}
              sortingOrder={['asc', 'desc']}
            />
          </div>
        )}
        {((apiCallStatus === 2 && contractUsersInvite?.totalCount !== 0) ||
          (apiCallStatus === 1 && contractUsersInvite?.totalCount !== 0) ||
          apiCallStatus === 3) &&
          status === 0 && (
            // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中
            <Typography color="error">
              {contractUsersInviteMessage?.dataList}
            </Typography>
          )}
        <Toolbar
          disableGutters
          variant="dense"
          className={
            !isDisabled() ? classes.listPager : classes.listPagerConfirm
          }
        >
          {((apiCallStatus === 2 && contractUsersInvite?.totalCount !== 0) ||
            (apiCallStatus === 1 && contractUsersInvite?.totalCount !== 0) ||
            apiCallStatus === 3) &&
            !isDisabled() && (
              // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中
              <>
                <ButtonLowBack
                  disabled={isPageBackDisabled}
                  onClick={handleSearchBack}
                  disableRipple
                >
                  前へ
                </ButtonLowBack>
                <ButtonLowForward
                  disabled={isPageNextDisabled}
                  onClick={handleSearchNext}
                  disableRipple
                >
                  次へ
                </ButtonLowForward>
              </>
            )}
        </Toolbar>
      </>
    );
  }
);

const ContractUsersInvitePage: FC = () => {
  const useContract = useContractUsersInvite();
  const { status, handleBack, handleNext, globalMessage, contractUsersInvite } =
    useContract;

  function getSteps() {
    return ['入力', '確認', '完了'];
  }
  // 項目の活性（入力）、非活性（入力以外）
  const isDisabled = (): boolean => {
    switch (status) {
      case 0:
        return false;
        break;
      default:
        return true;
        break;
    }
  };

  // 画面タイトル名
  const title = (): string => {
    switch (status) {
      case 0: // 契約内容照会－利用者招待入力
        return '契約内容照会－利用者招待';
        break;
      case 1: // 契約内容照会－利用者招待確認
        return '契約内容照会－利用者招待';
        break;
      case 2: // 契約内容照会－利用者招待完了
        return '契約内容照会－利用者招待';
        break;
      default:
        return '　';
        break;
    }
  };

  // レンダリング
  return (
    <Template title={`${title()}`}>
      <Box style={{ marginTop: '56px' }}>
        <StepperContent StepNumber={status} Steps={getSteps()} />
      </Box>
      <>
        {/* 完了画面 */}
        {status === 2 && (
          <>
            <Container>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ margin: '0px -12px' }}
              >
                <Grid>
                  <Typography
                    gutterBottom
                    align="center"
                    style={{ marginBottom: 30, marginTop: 55 }}
                  >
                    招待メールを送信しました。
                  </Typography>
                  <Typography
                    gutterBottom
                    align="center"
                    style={{ marginBottom: 80 }}
                  >
                    利用状況は、「契約内容照会 -
                    利用者管理」ページからご確認いただけます。
                  </Typography>
                </Grid>
                <Grid>
                  <ButtonForward
                    onClick={handleNext}
                    style={{ margin: 'auto' }}
                  >
                    利用者管理ページへ
                  </ButtonForward>
                </Grid>
              </Grid>
            </Container>
          </>
        )}

        {/* 入力画面と確認画面 */}
        {status !== 2 && (
          <Container>
            <div
              style={{
                marginTop: '52px',
                marginBottom: globalMessage ? '77px' : '',
              }}
            >
              <GlobalErrorMessage message={globalMessage} />
            </div>
            {/* {status === 0 && <GlobalErrorMessage message={globalMessage} />} */}
            {isDisabled() && (
              <Box sx={{ paddingBottom: '14px' }}>
                <Typography align="center">
                  ご入力内容をご確認のうえ、「送信」ボタンを押してください。
                </Typography>
              </Box>
            )}

            {/* サービス選択コンポーネント */}

            <ContractUserInviteServiceSelect useContract={useContract} />

            {/* サービス選択コンポーネント */}
            <ContractUserInviteUserList useContract={useContract} />

            <Grid
              container
              direction="row"
              style={{ margin: '0px -12px 105px' }}
            >
              {/* ボタン配置 */}
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>
                  {status === 0 && 'キャンセル'}
                  {status === 1 && '戻る'}
                </ButtonBack>
              </Grid>

              <Grid item xs={6} style={{ padding: '0px 12px' }}>
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  {status === 0 && '確認'}
                  {status === 1 && '送信'}
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Container>
        )}
      </>
    </Template>
  );
};

export default ContractUsersInvitePage;
