import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { AuthContext } from 'app/hooks/context/auth';

import { ButtonForward } from 'app/views/components/block/button';
import { ColorInfo } from 'app/style/theme';
import ConfirmDialog from 'app/views/components/block/dialog';
import { ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  UserAuthUpdateRequest,
  UserAuthUpdateResponse,
  UserDetachRequest,
  UserDetachResponse,
} from 'app/service/customer/corporation/auth';
import useLogout from 'app/hooks/logout';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 50,
    },
    firstTitle: {
      marginTop: 0,
    },
    errorMessage: {
      height: 70,
      paddingTop: 35,
    },
    buttonClose: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    iconStyle: {
      fontSize: 32,
      stroke: 'white',
      fontColor: ColorInfo.blue01,
    },
    button: {
      width: 120,
      height: 42,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 10px',
      cursor: 'pointer',
      fontSize: '1.5rem',
      lineHeight: 1.4,
      letterSpacing: 2,
      color: ColorInfo.white01,
      textAlign: 'center',
      verticalAlign: 'middle',
      textDecoration: 'none',
      border: `2px solid ${ColorInfo.blue02}`,
      borderRadius: 4,
      backgroundColor: ColorInfo.blue02,
      transition: 'all 0.3s',
      '&:hover': {
        color: ColorInfo.white01,
        borderColor: ColorInfo.blue03,
        backgroundColor: ColorInfo.blue03,
      },
      '&:disabled': {
        color: ColorInfo.white01,
        borderColor: ColorInfo.gray02,
        backgroundColor: ColorInfo.gray02,
      },
    },
    buttonCancel: {
      color: ColorInfo.white01,
      borderColor: ColorInfo.gray02,
      backgroundColor: ColorInfo.gray02,
    },
    // 未選択
    buttonNoSelect: {
      color: ColorInfo.gray02,
      backgroundColor: ColorInfo.gray05,
      borderColor: ColorInfo.gray05,
      '&:disabled': {
        color: ColorInfo.gray02,
        borderColor: ColorInfo.gray05,
        backgroundColor: ColorInfo.gray05,
      },
    },
    tableHead: {
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      padding: '20px 16px',
      lineHeight: 1,
      verticalAlign: 'middle',
      backgroundColor: ColorInfo.gray05,
      borderTop: `1px solid ${ColorInfo.gray03}`,
      borderBottom: `1px solid ${ColorInfo.gray03}`,
      borderLeft: `1px solid ${ColorInfo.gray03}`,
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    tableBody: {
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      padding: '10px 16px',
      borderBottom: `1px solid ${ColorInfo.gray03}`,
      borderLeft: `1px solid ${ColorInfo.gray03}`,
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    tableRow: {
      height: 80,
      '& > *': {
        color: ColorInfo.blue01,
        fontSize: '1.5rem',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: `1px solid ${ColorInfo.gray03}`,
      },
      '& > *:first-child': {
        borderLeft: 'none',
      },
      '& th': {
        width: '26.5%',
        background: ColorInfo.gray05,
      },
    },
    tableRowList: {
      height: 70,
    },
    mainActionArea: {
      display: 'block',
      padding: '8px 0px',
    },
    mainAction: {
      display: 'flex',
      margin: '10px auto',
      justifyContent: 'center',
    },
    detachArea: {
      backgroundColor: ColorInfo.gray05,
      display: 'block',
      padding: '12px',
      margin: '0px 0px 0px -12px !important',
    },
    detachAction: {
      backgroundColor: ColorInfo.gray05,
      display: 'flex',
      padding: '12px',
    },
    detachDesc: {
      marginRight: '50px',
    },
    title: {
      margin: '20px 0 10px 0',
    },
    message: {
      margin: '25px 0 0px 0',
    },
    list: {
      // listStyle: 'disc',
      '& > li > ul > li': {
        listStyleType: 'none',
      },
      '& > li': {
        listStyleType: 'disc',
        paddingLeft: '12px',
        marginLeft: '20px',
        '& > *': {
          lineHeight: '35px',
        },
        '& span': {
          paddingLeft: 15,
        },
      },
    },
  });

interface UserInfoTableProps extends WithStyles<typeof styles> {
  email: string;
  name: string;
}

const UserInfoTable = withStyles(styles)(
  ({ name, email, classes }: UserInfoTableProps) => (
    <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell component="th">メールアドレス</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell component="th">氏名</TableCell>
            <TableCell style={{ wordBreak: 'break-word' }}>{name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
);

interface UserAuthorityTableProps extends WithStyles<typeof styles> {
  authApproval: string;
  setAuthApproval: (value: string) => void;
  authAssignment: string;
  setAuthAssignment: (value: string) => void;
  disabled: boolean;
}

const UserAuthorityTable = withStyles(styles)(
  ({
    authApproval,
    setAuthApproval,
    authAssignment,
    setAuthAssignment,
    disabled,
    classes,
  }: UserAuthorityTableProps) => (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRowList}>
            <TableCell align="center" className={classes.tableHead}>
              内容
            </TableCell>
            <TableCell align="center" className={classes.tableHead}>
              権限有無
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="authApproval" className={classes.tableRowList}>
            <TableCell className={classes.tableBody} width="100%">
              企業ユーザー登録申請の承認、招待、企業ユーザー登録の解除
            </TableCell>
            <TableCell className={classes.tableBody}>
              <ToggleButtonGroup
                exclusive
                onChange={() => {
                  if (authApproval === '1') {
                    setAuthApproval('0');
                    setAuthAssignment('0');
                  } else {
                    setAuthApproval('1');
                  }
                }}
              >
                <ToggleButton
                  value="1"
                  className={`${classes.button} ${
                    authApproval === '0' ? classes.buttonNoSelect : ''
                  }`}
                  disabled={disabled}
                >
                  有
                </ToggleButton>
                <ToggleButton
                  value="0"
                  className={`${classes.button} ${
                    authApproval !== '0' ? classes.buttonNoSelect : ''
                  }`}
                  disabled={disabled}
                >
                  無
                </ToggleButton>
              </ToggleButtonGroup>
            </TableCell>
          </TableRow>
          <TableRow key="authAssignment" className={classes.tableRowList}>
            <TableCell className={classes.tableBody} width="100%">
              アカウント管理権限の付与機能
            </TableCell>
            <TableCell className={classes.tableBody}>
              <ToggleButtonGroup
                exclusive
                onChange={() => {
                  setAuthAssignment(authAssignment === '1' ? '0' : '1');
                }}
              >
                <ToggleButton
                  value="1"
                  className={`${classes.button} ${
                    authAssignment === '0' ? classes.buttonNoSelect : ''
                  }`}
                  disabled={disabled || authApproval === '0'}
                >
                  有
                </ToggleButton>
                <ToggleButton
                  value="0"
                  className={`${classes.button} ${
                    authAssignment !== '0' ? classes.buttonNoSelect : ''
                  }`}
                  disabled={disabled || authApproval === '0'}
                >
                  無
                </ToggleButton>
              </ToggleButtonGroup>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
);

interface UserAuthUpdateDialogProps extends WithStyles<typeof styles> {
  scimId: string;
  email: string;
  name: string;
  authorityApproval: string;
  authorityAssignment: string;
  operatorId: string;
  operatorAuthorityAssignment: string;
  haveUsageService: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  error?: string;
  afterClose: any;
  detachOnly?: boolean;
}

const UserAuthUpdateDialog = withStyles(styles)(
  ({
    scimId,
    email,
    name,
    authorityApproval,
    authorityAssignment,
    operatorId,
    operatorAuthorityAssignment,
    haveUsageService,
    open,
    setOpen,
    error,
    afterClose,
    detachOnly = false,
    classes,
  }: UserAuthUpdateDialogProps) => {
    const [authApproval, setAuthApproval] = useState(authorityApproval);
    const [authAssignment, setAuthAssignment] = useState(authorityAssignment);
    const [currentApproval, setCurrentApproval] = useState(authorityApproval);
    const [currentAssignment, setCurrentAssignment] =
      useState(authorityAssignment);
    const [detach, setDetach] = useState(false);

    useEffect(() => {
      if (open) {
        initMessage();
        setGlobalErrorMessage(error);
        setAuthApproval(authorityApproval);
        setAuthAssignment(authorityAssignment);
        setCurrentApproval(authorityApproval);
        setCurrentAssignment(authorityAssignment);
        setDetach(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    // GlobalMessage情報
    const [globalErrorMessage, setGlobalErrorMessage] = useState<string>();
    const [globalMessage, setGlobalMessage] = useState<string>();

    // ログイン情報
    const { setAuth, authInfo } = useContext(AuthContext);

    const initMessage = () => {
      setGlobalErrorMessage('');
      setGlobalMessage('');
    };

    // 二重POST防止：OFFで初期化
    const processing = useRef(false);

    // IF-CO-036 管理権限更新API hooks
    const {
      response: userAuthUpdateResponse,
      error: userAuthUpdateResponseError,
      callApi: userAuthUpdateCallApi,
    } = useApi<UserAuthUpdateResponse>();

    // IF-CO-036 管理権限更新API call
    const authUpdateAPI = async () => {
      // GAタグ：企業ユーザー権限変更モーダル-権限を変更する押下
      onGaClick('B-030-001-202');
      // DMPビーコン：企業ユーザー権限変更モーダル-権限を変更する押下
      OnDmpClick('B-030-001-202', authInfo?.scimId);
      // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;
      initMessage();
      // リクエスト設定
      const req: UserAuthUpdateRequest = {
        scimId,
        authorityApproval: authApproval,
        authorityAssignment: authAssignment,
        operatorId,
      };
      await userAuthUpdateCallApi(ApiConst.CORP_USER_AUTH_UPDATE, 'POST', req);
    };

    // IF-CO-036 管理権限更新API 結果
    useEffect(() => {
      if (userAuthUpdateResponse) {
        // 正常
        setGlobalMessage('権限変更が完了しました。');
        setCurrentApproval(authApproval);
        setCurrentAssignment(authAssignment);
        // 二重POST防止：OFF
        processing.current = false;
      } else if (userAuthUpdateResponseError) {
        // エラー
        setGlobalErrorMessage(userAuthUpdateResponseError.message);
        // 二重POST防止：OFF
        processing.current = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuthUpdateResponse, userAuthUpdateResponseError]);

    // IF-CO-037 企業連携解除API hooks
    const {
      response: userDetachResponse,
      error: userDetachResponseError,
      callApi: userDetachCallApi,
    } = useApi<UserDetachResponse>();

    // IF-CO-037 企業連携解除API call
    const detachAPI = async () => {
      // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;
      initMessage();
      // リクエスト設定
      const req: UserDetachRequest = {
        operatorId,
        scimId,
        relationDetachmentLinkFlag: detachOnly ? '1' : '0',
      };
      if (detachOnly) {
        // GAタグ：企業連携解除モーダル-企業連携を解除ボタン押下
        onGaClick('B-030-001-603');
        // DMPビーコン：企業連携解除モーダル-企業連携を解除ボタン押下
        OnDmpClick('B-030-001-603', authInfo?.scimId);
      } else {
        // GAタグ：企業ユーザー権限変更モーダル-企業連携を解除する押下
        onGaClick('B-030-001-204');
        // DMPビーコン：企業ユーザー権限変更モーダル-企業連携を解除する押下
        OnDmpClick('B-030-001-204', authInfo?.scimId);
      }
      await userDetachCallApi(ApiConst.CORP_USER_DETACH, 'POST', req);
    };

    // ログアウト
    const logout = useLogout();

    // IF-CO-037 企業連携解除API 結果
    useEffect(() => {
      if (userDetachResponse) {
        // 正常
        setGlobalMessage('企業ユーザー登録を解除しました。');
        setDetach(true);
        // 二重POST防止：OFF
        processing.current = false;

        if (scimId === operatorId) {
          // 自分自身で解除実施した場合はログアウト
          logout();
        }
      } else if (userDetachResponseError) {
        // エラー
        // 二重POST防止：OFF
        processing.current = false;
        setGlobalErrorMessage(userDetachResponseError.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetachResponse, userDetachResponseError]);

    const isDisabled = (): boolean => {
      if (error) {
        // エラー発生している（表示時）
        return true;
      }
      if (detach) {
        // 解除済
        return true;
      }
      if (operatorAuthorityAssignment !== '1') {
        // 管理権限の付与を保持していない
        return true;
      }
      if (scimId === operatorId) {
        if (currentAssignment !== '1') {
          // ログイン者自身が変更が不可の権限の状態
          return true;
        }
      }
      return false;
    };

    const isDetachDisabled = (): boolean => {
      if (error) {
        // エラー発生している（表示時）
        return true;
      }
      if (detach) {
        // 解除済
        return true;
      }
      if (haveUsageService) {
        // 利用サービスが存在する
        return true;
      }
      if (detachOnly) {
        // 解除リンクの場合は管理権限あっても活性（利用サービスが存在する場合は非活性）
        return false;
      }
      if (currentApproval === '1') {
        // 管理権限あり
        return true;
      }
      return false;
    };

    // 企業解除イベント Dialog
    const [detachDialog, setDetachDialog] = useState(false);

    const doClose = () => {
      if (!detachOnly && scimId === operatorId) {
        // ログイン者の権限変更内容をログイン情報に更新する
        // 解除リンクからの場合は更新しない
        setAuth(currentApproval, currentAssignment);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      afterClose();
      setOpen(false);
    };

    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <IconButton
          aria-label="close"
          className={classes.buttonClose}
          onClick={doClose}
          size="small"
        >
          <CloseIcon className={classes.iconStyle} />
        </IconButton>

        <DialogContent className={classes.root}>
          {!detachOnly && (
            <Box>
              <Typography variant="h3" className={classes.firstTitle}>
                利用者情報
              </Typography>
              <UserInfoTable email={email} name={name} />
              <Typography variant="h3">アカウント管理権限</Typography>
              <UserAuthorityTable
                authApproval={authApproval}
                setAuthApproval={setAuthApproval}
                authAssignment={authAssignment}
                setAuthAssignment={setAuthAssignment}
                disabled={isDisabled()}
              />
            </Box>
          )}
          <Box className={classes.errorMessage}>
            {globalErrorMessage && (
              <Typography align="center" color="error">
                {globalErrorMessage}
              </Typography>
            )}

            {globalMessage && (
              <Typography align="center">{globalMessage}</Typography>
            )}
          </Box>

          <DialogActions className={classes.mainActionArea}>
            {!detachOnly && (
              <Box
                className={classes.mainAction}
                style={{ margin: '10px auto 80px auto' }}
              >
                <ButtonForward onClick={authUpdateAPI} disabled={isDisabled()}>
                  権限を変更する
                </ButtonForward>
              </Box>
            )}
            <Box className={classes.detachArea}>
              <Typography variant="h3" className={classes.title}>
                企業ユーザー登録解除
              </Typography>
              <Box className={classes.detachAction}>
                <Box component="span" className={classes.detachDesc}>
                  <Typography>企業ユーザー登録を解除します。</Typography>
                  <Typography>
                    解除後は、Toyota Biz
                    Centerでのすべてのサービスがご利用いただけなくなりますのでご注意ください。
                  </Typography>
                  <Typography>
                    ※「TOYOTAアカウント」は、引き続きご利用いただけます。
                  </Typography>

                  <Typography style={{ margin: '25px 0 0' }}>
                    以下の場合は、企業ユーザー登録の解除が行えません。
                    <ul className={classes.list}>
                      <li>ご利用可能なサービスがある場合</li>
                      <li>「企業管理者」の場合</li>
                      <li>「アカウント管理権限」を保有している場合</li>
                    </ul>
                  </Typography>
                </Box>
                <Box component="span" style={{ marginTop: '30px' }}>
                  <ButtonForward
                    onClick={() => {
                      setDetachDialog(true);
                      if (detachOnly) {
                        // GAタグ：企業連携解除モーダル-企業連携を解除ボタン押下
                        onGaClick('B-030-001-602');
                        // DMPビーコン：企業連携解除モーダル-企業連携を解除ボタン押下
                        OnDmpClick('B-030-001-602', authInfo?.scimId);
                      } else {
                        // GAタグ：管理権限変更モーダル-企業連携を解除する押下
                        onGaClick('B-030-001-203');
                        // DMPビーコンタグ：管理権限変更モーダル-企業連携を解除する押下
                        OnDmpClick('B-030-001-203', authInfo?.scimId);
                      }
                    }}
                    disabled={isDetachDisabled()}
                  >
                    企業ユーザー登録を解除する
                  </ButtonForward>
                </Box>
              </Box>
            </Box>
            <ConfirmDialog
              msg={`企業ユーザー登録を解除します。
              よろしいですか。`}
              open={detachDialog}
              setOpen={setDetachDialog}
              doYes={detachAPI}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
);

export default UserAuthUpdateDialog;
