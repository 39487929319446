import { FC, useContext } from 'react';
import Template from 'app/views/components/template';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useCorpApply } from 'app/hooks/corporation/apply';
import {
  ButtonForward,
  ButtonBack,
  SubButton,
} from 'app/views/components/block/button';
import TextInput from 'app/views/components/atom/text';
import InputTelephone from 'app/views/components/block/telephone';
import FileUpload from 'app/views/components/modules/fileupload';
import { InputRowGridPadding } from 'app/views/components/block/layout';
import { ColorInfo } from 'app/style/theme';
import StepperContent from 'app/views/components/block/stepper';
import InputPostalCode from 'app/views/components/block/postalcode';
import SelectPrefecture from 'app/views/components/block/prefecture';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import useValidate, { validationProps } from 'app/hooks/validate';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';
import { AuthContext } from 'app/hooks/context/auth';

// スタイル
const useStyles = makeStyles(() => ({
  span: {
    color: ColorInfo.red01,
  },
  h2: {
    paddingLeft: '0px',
  },
  mainText: {
    marginLeft: '22px',
    marginBottom: '10px',
  },
  mainTextNoMargin: {
    marginLeft: '0px',
    marginBottom: '10px',
  },
  fileSelectMsg: {
    color: ColorInfo.gray03,
  },
  serviceList: {
    flexBasis: '100%',
    marginTop: '10px',
    marginLeft: '32px',
  },
  inputRow: {
    marginBottom: '15px',
  },
  confirm: {
    marginLeft: '-50px',
    '& ::placeholder': {
      visibility: 'hidden',
    },
  },
  table: {
    margin: '10px 0 10px 0',
    borderTop: `1px solid ${ColorInfo.gray03}`,
  },

  fileTable: {
    width: '1000px',
  },
  tableRow: {
    height: '100px',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 18,
    '& > *': {
      border: `1px solid ${ColorInfo.gray03}`,
    },
    '& td:first-child': {
      borderLeft: '0px',
    },
    '& td:last-child': {
      borderRight: '0px',
    },
  },
  tableRowNonBorder: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 18,
    '& td:first-child': {
      borderLeft: '0px',
    },
    '& td:last-child': {
      borderRight: '0px',
    },
  },
  tableUserRow: {
    '& td': {
      borderLeft: '0px',
      borderRight: '0px',
    },
  },
  tableHeadRow: {
    background: ColorInfo.gray05,
    '& td': {
      textAlign: 'center',
    },
  },
  tableFileName: {
    width: '900px',
    wordBreak: 'break-all',
  },
  tableMarkCell: {
    textAlign: 'center',
  },
  note: {
    margin: '10px 0 0 0',
  },
  corpAddressPostalCodeWrapper: {
    display: 'flex',
  },
  summaryText: {
    textAlign: 'center',
  },
}));

const CompanyApplyPage: FC = () => {
  // 企業申請業務カスタムHooks
  const {
    status,
    setSameFileNameMessage,
    corporationApply,
    corporationApplyMessage,
    setCorporationApplyMessage,
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    uploadFile1,
    uploadFile2,
    uploadFile3,
    uploadFile4,
    uploadFile5,
    setUploadFile1,
    setUploadFile2,
    setUploadFile3,
    setUploadFile4,
    setUploadFile5,
    handleInputEvent,
    handleNext,
    handleBack,
    handleGetAddress,
    globalMessage,
  } = useCorpApply();

  const {
    corporationNameValidation,
    corporationNameKanaValidation,
    departmentNameValidation,
    departmentNameKanaValidation,
    corporationAddressLocalityValidation,
    corporationAddressStreetValidation,
    corporationAddressPostalCodeValidation,
    corporationAddressBuildingValidation,
    corporationTelephoneNumberValidation,
    applyReasonValidation,
  } = useValidate();

  const { authInfo } = useContext(AuthContext);

  const classes = useStyles();
  // 項目の活性（入力）、非活性（入力以外）
  const isDisabled = (): boolean => {
    switch (status) {
      case 0:
        return false;
        break;
      default:
        return true;
        break;
    }
  };
  const currentSetUploadFile = (): number => {
    if (!uploadFile1) {
      return 1;
    }
    if (!uploadFile2) {
      return 2;
    }
    if (!uploadFile3) {
      return 3;
    }
    if (!uploadFile4) {
      return 4;
    }
    if (!uploadFile5) {
      return 5;
    }
    return 5;
  };

  // 画面タイトル名
  const title = (): string => {
    switch (status) {
      case 0: // 企業申請入力
        return '企業登録';
        break;
      case 1: // 企業申請確認
        return '企業登録';
        break;
      case 2: // 企業申請完了
        return '企業登録';
        break;
      default:
        return '　';
        break;
    }
  };

  // レンダリング
  return (
    <Template title={`${title()}`}>
      <StepperContent StepNumber={status} />
      <>
        {/* 申請完了画面 */}
        {status === 2 && (
          <>
            <Typography>
              <Box
                sx={{
                  marginTop: '55px',
                  marginBottom: '30px',
                  textAlign: 'center',
                }}
              >
                企業登録の申請を受け付けました。
              </Box>
              <Box
                sx={{
                  marginTop: '5px',
                  marginBottom: '30px',
                  textAlign: 'center',
                }}
              >
                審査結果は、3営業日以内にメールにてご連絡いたします。
              </Box>
              <Box
                sx={{
                  marginTop: '5px',
                  textAlign: 'center',
                }}
              >
                また、ご本人様確認のため、お電話を差し上げる場合があります。
              </Box>
              <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                1週間経っても連絡がない場合は、お手数ですがお問い合わせください。
              </Box>
            </Typography>
            <Grid style={{ marginBottom: '45px', marginTop: '75px' }}>
              <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                トップへ
              </ButtonForward>
            </Grid>
          </>
        )}

        {/* 申請入力画面と確認画面 */}
        {status !== 2 && (
          <Container>
            <div
              style={{
                marginTop: '52px',
                marginBottom: globalMessage ? '77px' : '',
              }}
            >
              <GlobalErrorMessage message={globalMessage} />
            </div>
            {/* {status === 0 && <GlobalErrorMessage message={globalMessage} />} */}

            {status === 0 ? (
              <p className={classes.summaryText}>
                以下のフォームに必須事項をご入力のうえ、「確認」ボタンを押してください。
              </p>
            ) : (
              <p className={classes.summaryText}>
                ご入力内容をご確認のうえ、「この内容で申請する」ボタンを押してください。
              </p>
            )}

            <Typography variant="h3" style={{ marginTop: '75px' }}>
              企業情報
            </Typography>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="企業名"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corporationName"
                  value={corporationApply?.corporationName}
                  placeholder="例)トヨタ自動車株式会社"
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationNameValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      corporationName: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.corporationName}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.corporationName}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="企業名（カナ）"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corporationNameKana"
                  placeholder="例)トヨタジドウシャカブシキガイシャ"
                  value={corporationApply?.corporationNameKana}
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationNameKanaValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      corporationNameKana: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.corporationNameKana}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.corporationNameKana}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="郵便番号"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <div
                  className={
                    isDisabled()
                      ? classes.confirm
                      : classes.corpAddressPostalCodeWrapper
                  }
                >
                  <InputPostalCode
                    name1="corpAddressPostalCode1"
                    value1={corporationApply?.corpAddressPostalCode1}
                    name2="corpAddressPostalCode2"
                    value2={corporationApply?.corpAddressPostalCode2}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    handleFocusEvent={() => {
                      const message = corporationAddressPostalCodeValidation(
                        corporationApply?.corpAddressPostalCode1,
                        corporationApply?.corpAddressPostalCode2
                      );
                      setCorporationApplyMessage({
                        ...corporationApplyMessage,
                        corpAddressPostalCode: message,
                      });
                    }}
                    error={!!corporationApplyMessage?.corpAddressPostalCode}
                  />
                  {status === 0 && (
                    <SubButton
                      onClick={() => handleGetAddress()}
                      style={{ marginLeft: '10px' }}
                    >
                      住所検索
                    </SubButton>
                  )}
                </div>
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.corpAddressPostalCode}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="都道府県"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <div className={isDisabled() ? classes.confirm : ''}>
                  <SelectPrefecture
                    name="corpAddressRegion"
                    value={corporationApply?.corpAddressRegion}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                  />
                  {status === 0 && (
                    <Typography color="error">
                      {corporationApplyMessage?.corpAddressRegion}
                    </Typography>
                  )}
                </div>
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="市区町村"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corpAddressLocality"
                  placeholder="例)中村区名駅"
                  value={corporationApply?.corpAddressLocality}
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationAddressLocalityValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      corpAddressLocality: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.corpAddressLocality}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.corpAddressLocality}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="丁目番地"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corpAddressStreet"
                  placeholder="例)4丁目7番1号"
                  value={corporationApply?.corpAddressStreet}
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationAddressStreetValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      corpAddressStreet: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.corpAddressStreet}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.corpAddressStreet}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="建物名"
                required
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="corpAddressBuilding"
                  placeholder="例)トヨタタワー"
                  value={corporationApply?.corpAddressBuilding}
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  onBlur={(event) => {
                    const message = corporationAddressBuildingValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      corpAddressBuilding: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.corpAddressBuilding}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.corpAddressBuilding}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="代表電話番号"
                required={isDisabled()}
                className={classes.inputRow}
              >
                <div className={isDisabled() ? classes.confirm : ''}>
                  <InputTelephone
                    name1="corporationTelephoneNumber1"
                    value1={corporationApply?.corporationTelephoneNumber1}
                    name2="corporationTelephoneNumber2"
                    value2={corporationApply?.corporationTelephoneNumber2}
                    name3="corporationTelephoneNumber3"
                    value3={corporationApply?.corporationTelephoneNumber3}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    handleFocusEvent={() => {
                      const message = corporationTelephoneNumberValidation(
                        corporationApply?.corporationTelephoneNumber1,
                        corporationApply?.corporationTelephoneNumber2,
                        corporationApply?.corporationTelephoneNumber3
                      );
                      setCorporationApplyMessage({
                        ...corporationApplyMessage,
                        corporationTelephoneNumber: message[0],
                        corporationTelephoneNumber1: message[1],
                        corporationTelephoneNumber2: message[2],
                        corporationTelephoneNumber3: message[3],
                      });
                    }}
                    error={
                      !!corporationApplyMessage?.corporationTelephoneNumber ||
                      !!corporationApplyMessage?.corporationTelephoneNumber1 ||
                      !!corporationApplyMessage?.corporationTelephoneNumber2 ||
                      !!corporationApplyMessage?.corporationTelephoneNumber3
                    }
                  />
                  {status === 0 && (
                    <>
                      <Typography color="error">
                        {corporationApplyMessage?.corporationTelephoneNumber}
                      </Typography>
                      <Typography color="error">
                        {corporationApplyMessage?.corporationTelephoneNumber1}
                      </Typography>
                      <Typography color="error">
                        {corporationApplyMessage?.corporationTelephoneNumber2}
                      </Typography>
                      <Typography color="error">
                        {corporationApplyMessage?.corporationTelephoneNumber3}
                      </Typography>
                    </>
                  )}
                </div>
              </InputRowGridPadding>
            </Box>

            <Typography variant="h3" style={{ marginTop: '68px' }}>
              お客さま情報
            </Typography>
            {!isDisabled() && (
              <Typography variant="body1" className={classes.mainText}>
                お客さま情報が企業管理者として設定されます。企業管理者は登録後に変更することが可能です。
              </Typography>
            )}
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding label="氏名" required>
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="name"
                  value={corporationApply?.name}
                  handleInputEvent={handleInputEvent}
                  disabled
                  fullWidth
                />
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="部署名"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                required={isDisabled() || corporationApply?.isEditable}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="departmentName"
                  placeholder="例）営業部（ない場合は「なし」とご入力ください。）"
                  value={corporationApply?.departmentName}
                  handleInputEvent={handleInputEvent}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  disabled={isDisabled() || corporationApply?.isEditable}
                  fullWidth
                  onBlur={(event) => {
                    const message = departmentNameValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      departmentName: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.departmentName}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.departmentName}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="部署名（カナ）"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                required={isDisabled() || corporationApply?.isEditable}
                className={classes.inputRow}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="departmentNameKana"
                  placeholder="例）エイギョウブ（ない場合は「ナシ」とご入力ください。）"
                  value={corporationApply?.departmentNameKana}
                  handleInputEvent={handleInputEvent}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  disabled={isDisabled() || corporationApply?.isEditable}
                  fullWidth
                  onBlur={(event) => {
                    const message = departmentNameKanaValidation({
                      value: event.target.value,
                    });
                    setCorporationApplyMessage({
                      ...corporationApplyMessage,
                      departmentNameKana: message,
                    });
                  }}
                  error={!!corporationApplyMessage?.departmentNameKana}
                />
                {status === 0 && (
                  <Typography color="error">
                    {corporationApplyMessage?.departmentNameKana}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Typography variant="h3" style={{ marginTop: '68px' }}>
              確認情報{status === 0 && <span className={classes.span}>＊</span>}
            </Typography>
            {!isDisabled() && (
              <Typography variant="body1" className={classes.mainText}>
                登記事項証明書（現在事項証明書）等をアップロードしてください。（PDF）
                <br />
                １ファイルあたりの上限は15MBです。
              </Typography>
            )}
            {isDisabled() &&
              !uploadFile1 &&
              !uploadFile2 &&
              !uploadFile3 &&
              !uploadFile4 &&
              !uploadFile5 && (
                <TableCell style={{ width: '1000px' }}>
                  <Typography
                    variant="body1"
                    className={classes.mainTextNoMargin}
                  >
                    アップロードされたファイルはありません。
                  </Typography>
                </TableCell>
              )}

            <Box sx={{ margin: '0px' }}>
              <Table aria-label="simple table" className={classes.fileTable}>
                {/** アップロードエリア */}
                {!isDisabled() && !uploadFile1?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography className={classes.fileSelectMsg}>
                        PDFファイルを選択して下さい
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        inputRef={inputRef1}
                        file={uploadFile1}
                        setUploadFile={setUploadFile1}
                        disabled={isDisabled()}
                        deleteButton={false}
                        uploadButton
                        fileNameList={[
                          uploadFile1?.name || '',
                          uploadFile2?.name || '',
                          uploadFile3?.name || '',
                          uploadFile4?.name || '',
                          uploadFile5?.name || '',
                        ].filter(Boolean)}
                        setSameNameMessage={setSameFileNameMessage}
                        uploadCallBack={() => {
                          const gaTagId = 'B-010-003-001';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!isDisabled() && uploadFile1?.name && !uploadFile2?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography className={classes.fileSelectMsg}>
                        PDFファイルを選択して下さい
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <FileUpload
                        inputRef={inputRef2}
                        file={uploadFile2}
                        setUploadFile={setUploadFile2}
                        disabled={isDisabled()}
                        deleteButton={false}
                        uploadButton
                        fileNameList={[
                          uploadFile1?.name || '',
                          uploadFile2?.name || '',
                          uploadFile3?.name || '',
                          uploadFile4?.name || '',
                          uploadFile5?.name || '',
                        ].filter(Boolean)}
                        setSameNameMessage={setSameFileNameMessage}
                        uploadCallBack={() => {
                          const gaTagId = 'B-010-003-002';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!isDisabled() &&
                  uploadFile1?.name &&
                  uploadFile2?.name &&
                  !uploadFile3?.name && (
                    <TableRow
                      className={`${classes.tableRow} ${classes.tableUserRow}`}
                    >
                      <TableCell className={classes.tableFileName}>
                        <Typography className={classes.fileSelectMsg}>
                          PDFファイルを選択して下さい
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <FileUpload
                          inputRef={inputRef3}
                          file={uploadFile3}
                          setUploadFile={setUploadFile3}
                          disabled={isDisabled()}
                          deleteButton={false}
                          uploadButton
                          fileNameList={[
                            uploadFile1?.name || '',
                            uploadFile2?.name || '',
                            uploadFile3?.name || '',
                            uploadFile4?.name || '',
                            uploadFile5?.name || '',
                          ].filter(Boolean)}
                          setSameNameMessage={setSameFileNameMessage}
                          uploadCallBack={() => {
                            const gaTagId = 'B-010-003-003';
                            onGaClick(gaTagId);
                            OnDmpClick(gaTagId, authInfo?.scimId);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                {!isDisabled() &&
                  uploadFile1?.name &&
                  uploadFile2?.name &&
                  uploadFile3?.name &&
                  !uploadFile4?.name && (
                    <TableRow
                      className={`${classes.tableRow} ${classes.tableUserRow}`}
                    >
                      <TableCell className={classes.tableFileName}>
                        <Typography className={classes.fileSelectMsg}>
                          PDFファイルを選択して下さい
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <FileUpload
                          inputRef={inputRef4}
                          file={uploadFile4}
                          setUploadFile={setUploadFile4}
                          disabled={isDisabled()}
                          deleteButton={false}
                          uploadButton
                          fileNameList={[
                            uploadFile1?.name || '',
                            uploadFile2?.name || '',
                            uploadFile3?.name || '',
                            uploadFile4?.name || '',
                            uploadFile5?.name || '',
                          ].filter(Boolean)}
                          setSameNameMessage={setSameFileNameMessage}
                          uploadCallBack={() => {
                            const gaTagId = 'B-010-003-004';
                            onGaClick(gaTagId);
                            OnDmpClick(gaTagId, authInfo?.scimId);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                {!isDisabled() &&
                  uploadFile1?.name &&
                  uploadFile2?.name &&
                  uploadFile3?.name &&
                  uploadFile4?.name &&
                  !uploadFile5?.name && (
                    <TableRow
                      className={`${classes.tableRow} ${classes.tableUserRow}`}
                    >
                      <TableCell
                        className={classes.tableFileName}
                        style={{ border: 'none' }}
                      >
                        <Typography className={classes.fileSelectMsg}>
                          PDFファイルを選択して下さい
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <FileUpload
                          inputRef={inputRef5}
                          file={uploadFile5}
                          setUploadFile={setUploadFile5}
                          disabled={isDisabled()}
                          deleteButton={false}
                          uploadButton
                          fileNameList={[
                            uploadFile1?.name || '',
                            uploadFile2?.name || '',
                            uploadFile3?.name || '',
                            uploadFile4?.name || '',
                            uploadFile5?.name || '',
                          ].filter(Boolean)}
                          setSameNameMessage={setSameFileNameMessage}
                          uploadCallBack={() => {
                            const gaTagId = 'B-010-003-005';
                            onGaClick(gaTagId);
                            OnDmpClick(gaTagId, authInfo?.scimId);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                {!isDisabled() && uploadFile1?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography>{uploadFile1?.name}</Typography>
                    </TableCell>

                    <TableCell>
                      <FileUpload
                        inputRef={inputRef1}
                        file={uploadFile1}
                        setUploadFile={setUploadFile1}
                        disabled={isDisabled()}
                        deleteButton
                        deleteCallBack={() => {
                          const gaTagId = 'B-010-003-006';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {isDisabled() && uploadFile1?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography>{uploadFile1?.name}</Typography>
                    </TableCell>
                  </TableRow>
                )}

                {!isDisabled() && uploadFile2?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      {uploadFile2?.name && (
                        <Typography>{uploadFile2.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        inputRef={inputRef2}
                        file={uploadFile2}
                        setUploadFile={setUploadFile2}
                        disabled={isDisabled()}
                        deleteButton
                        deleteCallBack={() => {
                          const gaTagId = 'B-010-003-007';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {isDisabled() && uploadFile2?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography>{uploadFile2.name}</Typography>
                    </TableCell>
                  </TableRow>
                )}

                {!isDisabled() && uploadFile3?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      {uploadFile3?.name && (
                        <Typography>{uploadFile3.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        inputRef={inputRef3}
                        file={uploadFile3}
                        setUploadFile={setUploadFile3}
                        disabled={isDisabled()}
                        deleteButton
                        deleteCallBack={() => {
                          const gaTagId = 'B-010-003-008';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {isDisabled() && uploadFile3?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography>{uploadFile3.name}</Typography>
                    </TableCell>
                  </TableRow>
                )}

                {!isDisabled() && uploadFile4?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      {uploadFile4?.name && (
                        <Typography>{uploadFile4.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        inputRef={inputRef4}
                        file={uploadFile4}
                        setUploadFile={setUploadFile4}
                        disabled={isDisabled()}
                        deleteButton
                        deleteCallBack={() => {
                          const gaTagId = 'B-010-003-009';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {isDisabled() && uploadFile4?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography>{uploadFile4.name}</Typography>
                    </TableCell>
                  </TableRow>
                )}

                {!isDisabled() && uploadFile5?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      {uploadFile5?.name && (
                        <Typography>{uploadFile5.name}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <FileUpload
                        inputRef={inputRef5}
                        file={uploadFile5}
                        setUploadFile={setUploadFile5}
                        disabled={isDisabled()}
                        deleteButton
                        deleteCallBack={() => {
                          const gaTagId = 'B-010-003-010';
                          onGaClick(gaTagId);
                          OnDmpClick(gaTagId, authInfo?.scimId);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {isDisabled() && uploadFile5?.name && (
                  <TableRow
                    className={`${classes.tableRow} ${classes.tableUserRow}`}
                  >
                    <TableCell className={classes.tableFileName}>
                      <Typography>{uploadFile5.name}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
              {status === 0 && (
                <Typography color="error" style={{ marginTop: 20 }}>
                  {corporationApplyMessage?.uploadFile}
                </Typography>
              )}
            </Box>
            <Typography variant="h3" style={{ marginTop: '68px' }}>
              申請理由{status === 0 && <span className={classes.span}>＊</span>}
            </Typography>
            {!isDisabled() && (
              <Typography variant="body1" className={classes.mainText}>
                利用を検討いただいているサービス名もしくは商談をしているトヨタ自動車の担当部署および担当者氏名を記載ください。
                <br />
                ※申請理由の内容によっては、企業登録の申請を否認する場合がございます。
              </Typography>
            )}
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <TextInput
                name="applyReason"
                value={corporationApply?.applyReason}
                placeholder="申請理由を記載ください。"
                handleInputEvent={handleInputEvent}
                disabled={isDisabled()}
                minRows={3}
                maxRows={3}
                multiline
                fullWidth
                onBlur={(event) => {
                  const message = applyReasonValidation(
                    { value: event.target.value },
                    '\r\n',
                  );
                  setCorporationApplyMessage({
                    ...corporationApplyMessage,
                    applyReason: message,
                  });
                }}
                error={!!corporationApplyMessage?.applyReason}
              />
              {status === 0 && (
                <Typography color="error">
                  {corporationApplyMessage?.applyReason}
                </Typography>
              )}
            </Box>
            <Grid
              container
              direction="row"
              style={{ margin: '80px -12px 105px' }}
              alignItems="center"
            >
              {/* ボタン配置 */}
              {status === 1 && (
                <Grid item xs={3}>
                  <ButtonBack onClick={handleBack}>戻る</ButtonBack>
                </Grid>
              )}

              <Grid
                container
                item
                xs={status === 1 ? 6 : 12}
                alignItems="center"
              >
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  {status === 0 && '確認'}
                  {status === 1 && 'この内容で申請する'}
                </ButtonForward>
              </Grid>
              {status === 1 && <Grid item xs={3} />}
            </Grid>
          </Container>
        )}
      </>
    </Template>
  );
};

export default CompanyApplyPage;
