/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import Template from 'app/views/components/template';
import { Grid, Typography, Link, makeStyles, Toolbar } from '@material-ui/core';
import {
  ButtonBack,
  ButtonLowBack,
  ButtonLowForward,
} from 'app/views/components/block/button';
import Select from 'app/views/components/block/select';
import { useHistory } from 'react-router-dom';
import useNoticeList from 'app/hooks/notice';
import useNoticeModule from 'app/views/components/modules/notice';
import { ColorInfo } from 'app/style/theme';
import { PageInfo } from 'app/utils/constants';

const useStyles = makeStyles({
  listPager: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& button:first-child': {
      padding: '10px 0px 10px 65px',
      '&::before': {
        width: 30,
      },
    },
    '& button:last-child': {
      marginLeft: 60,
      padding: '10px 65px 10px 0px',
      '&::before': {
        width: 30,
      },
    },
    '& > *.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  backButton: {
    marginBottom: '-10px',
  },
});

const options = [
  {
    value: '30',
    label: '30件',
  },
  {
    value: '50',
    label: '50件',
  },
  {
    value: '100',
    label: '100件',
  },
];

// お知らせ一覧
const NoticeList: FC = memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const noticeListHook = useNoticeList('1');
  const { ViewNociteList } = useNoticeModule();
  const {
    noticeList,
    viewYear,
    handleInputEvent,
    handleSearchBack,
    handleSearchNext,
    handleSearchYear,
    isPageBackDisabled,
    isPageNextDisabled,
  } = noticeListHook;
  const ViewDateDisabled = (year: string) => {
    if (year === viewYear) {
      return (
        <Typography style={{ marginLeft: '10px', marginRight: '10px' }}>
          {year}
        </Typography>
      );
    }
    return (
      <Link
        component="button"
        underline="always"
        onClick={() => handleSearchYear(year)}
        style={{
          color: ColorInfo.blue01,
          marginLeft: '10px',
          marginRight: '10px',
        }}
      >
        <Typography>{year}</Typography>
      </Link>
    );
  };
  return (
    <>
      <Template title="お知らせ">
        <Typography variant="h3" style={{ marginTop: '90px' }}>
          お知らせ
        </Typography>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-start"
          style={{
            marginTop: '15px',
            marginLeft: '5px',
            marginBottom: '30px',
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            item
            xs={8}
          >
            {noticeList.yearList !== null && noticeList.yearList !== undefined && (
              <>
                {noticeList.yearList.map((row, count) => (
                  <>
                    {ViewDateDisabled(row.viewYearStart)}
                    {noticeList.yearList?.length !== count + 1 && (
                      <Typography>|</Typography>
                    )}
                  </>
                ))}
              </>
            )}
          </Grid>
          <Grid item xs={4} container direction="row" justifyContent="flex-end">
            <Select
              name="size"
              value={noticeList?.size}
              options={options}
              handleInputEvent={handleInputEvent}
              style={{ marginRight: 'auto' }}
            />
          </Grid>
        </Grid>
        <ViewNociteList noticeListHook={noticeListHook} />
        <Toolbar disableGutters variant="dense" className={classes.listPager}>
          <ButtonLowBack
            disabled={isPageBackDisabled}
            onClick={handleSearchBack}
            disableRipple
          >
            前へ
          </ButtonLowBack>
          <ButtonLowForward
            disabled={isPageNextDisabled}
            onClick={handleSearchNext}
            disableRipple
          >
            次へ
          </ButtonLowForward>
        </Toolbar>
        <div className={classes.backButton}>
          <ButtonBack onClick={() => history.push(PageInfo.TOP.path)}>
            戻る
          </ButtonBack>
        </div>
      </Template>
    </>
  );
});

export default NoticeList;
