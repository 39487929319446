import { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from 'app/views/components/atom/text';

// スタイル
const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'left',
  },
  input: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'left',
    width: '90px',
  },
  separater: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    margin: '0',
  },
  output: {
    padding: '16px 14px',
  },
}));

// インタフェース
interface Props {
  name1: string;
  value1: string;
  name2: string;
  value2: string;
  name3: string;
  value3: string;
  handleInputEvent?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  handleFocusEvent?: React.FocusEventHandler<HTMLInputElement>;
  separater?: boolean;
  error?: boolean;
}

// 電話番号
const InputTelephone: FC<Props> = memo(
  ({
    name1,
    value1,
    name2,
    value2,
    name3,
    value3,
    handleInputEvent,
    disabled = false,
    handleFocusEvent,
    separater = true,
    error = false,
  }) => {
    const classes = useStyles();
    const separaterValue = separater ? '－' : '';
    return (
      <>
        {!disabled && (
          <Box className={classes.root}>
            <TextInput
              className={classes.input}
              placeholder="例）03"
              name={name1}
              value={value1}
              handleInputEvent={handleInputEvent}
              disabled={disabled}
              onBlur={handleFocusEvent}
              error={error}
            />
            <TextInput
              className={classes.separater}
              name="sep1"
              value={separaterValue}
              disabled
            />
            <TextInput
              className={classes.input}
              placeholder="例）0000"
              name={name2}
              value={value2}
              handleInputEvent={handleInputEvent}
              disabled={disabled}
              onBlur={handleFocusEvent}
              error={error}
            />
            <TextInput
              className={classes.separater}
              name="sep2"
              value={separaterValue}
              disabled
            />
            <TextInput
              className={classes.input}
              placeholder="例）0000"
              name={name3}
              value={value3}
              handleInputEvent={handleInputEvent}
              disabled={disabled}
              onBlur={handleFocusEvent}
              error={error}
            />
          </Box>
        )}
        {disabled && (
          <>
            <Typography className={classes.output}>
              {value1}
              {' － '}
              {value2}
              {' － '}
              {value3}
            </Typography>
          </>
        )}
      </>
    );
  }
);

export default InputTelephone;
