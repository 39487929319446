import { AuthContext } from 'app/hooks/context/auth';
import { useState, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiConst, PageInfo } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  LinkDetachRequest,
  LinkDetachResponse,
} from 'app/service/customer/link/detach';

/**
 * サービス解除業務カスタムHooks
 */
export const useLinkDetach = () => {
  const { authInfo, setUserInfo } = useContext(AuthContext);

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // 戻るアクション
  const history = useHistory();

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<LinkDetachResponse>();

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // 次処理
  const handleNext = () => {
    // 二重POST防止：POST中なら処理せず
    if (processing.current) return;
    // 二重POST防止：POST中
    processing.current = true;
    // サービス解除リクエスト呼び出し
    void linkDetachAPI();
  };

  // サービス解除API
  const linkDetachAPI = () => {
    const scimId = authInfo?.scimId ? authInfo?.scimId : '';
    const req: LinkDetachRequest = {
      scimId,
    };
    // サービス解除API呼出し
    void callApi(ApiConst.LINK_DETACH, 'POST', req);
  };

  useEffect(() => {
    if (response) {
      setUserInfo(null);
      // ブラウザバック対応 サービス解除完了はreplace
      history.replace(PageInfo.LINK_DETACH_COMPLETE.path);
      // ブラウザバックができるので二重POST防止：OFF
      processing.current = false;
    } else if (error?.code) {
      // エラー発生により二重POST防止：OFF
      processing.current = false;
      // 応答電文を設定
      setGlobalMessage(error.message);
      window.scrollTo(0, 0);
    }
  }, [error, history, response, setUserInfo]);

  return {
    globalMessage,
    handleNext,
  };
};
export default useLinkDetach;
