import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiConst, MessageConst, PageInfo } from 'app/utils/constants';
import useApi, { ErrorResponse } from 'app/hooks/api';
import { AuthContext } from 'app/hooks/context/auth';
import { CorpDeleteRequest } from 'app/service/customer/corporation/resign/confirm';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

const useCorpResign = (): {
  corpResignApi: (corpId: string) => void;
  resignError: ErrorResponse | undefined;
  resignResponse: void | undefined;
} => {
  const {
    callApi,
    error: resignError,
    response: resignResponse,
  } = useApi<void>();

  const corpResignApi = (corpId: string) => {
    const req: CorpDeleteRequest = {
      corporationId: corpId,
    };
    void callApi(ApiConst.CORP_DELETE, 'POST', req);
  };
  return {
    corpResignApi,
    resignError,
    resignResponse,
  };
};
//
/**
 * 企業退会Hooks
 */
export const useCorpDelete = () => {
  // 認証コンテキスト
  const { authInfo, setAuthResign } = useContext(AuthContext);
  // 申請状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);
  const history = useHistory();
  const { corpResignApi, resignError, resignResponse } = useCorpResign();

  const [agreeChecked, setAgreeChecked] = useState<boolean>(false);
  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');
  // 次処理
  const handleNext = () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力画面の場合、入力チェック実施
        setStatus((preStatus) => preStatus + 1);
        history.push(PageInfo.CORP_RESIGN_CONFIRM.path);
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        // 企業退会リクエスト呼び出し
        corpResignApi(authInfo?.corporationId || '');
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        history.push(PageInfo.TOP.path);
        break;
    }
  };

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: PageInfo.CORP_RESIGN_BASE.path,
        confirm: PageInfo.CORP_RESIGN_CONFIRM.path,
        complete: PageInfo.CORP_RESIGN_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);
  // useEffect(() => {
  //   if (status === 1) {
  //     if (!agreeChecked) {
  //       history.go(-1);
  //     }
  //   }
  // }, [agreeChecked, history, status]);
  useEffect(() => {
    if (resignResponse) {
      // 正常
      setAuthResign();
      setStatus((preStatus) => preStatus + 1);
      history.replace(PageInfo.CORP_RESIGN_COMPLETE.path);
      // 二重POST防止：招待送信後のエラーハンドリングにあわせてここでOFF
      processing.current = false;
    } else if (resignError?.code) {
      // 退会エラー
      // エラーメッセージ設定
      setGlobalMessage(MessageConst.NOACTION);
      handleBack();
      window.scrollTo(0, 0);
      // 二重POST防止：招待送信後のエラーハンドリングにあわせてここでOFF
      processing.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resignError, resignResponse]);

  const handleBack = () => {
    history.go(-1);
  };

  return {
    status,
    globalMessage,
    handleNext,
    handleBack,
    setGlobalMessage,
    agreeChecked,
    setAgreeChecked,
  };
};
export default useCorpDelete;
