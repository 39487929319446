import { FC, memo, ReactNode } from 'react';
import Button, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

// インタフェース
interface MainProps extends MuiButtonProps {
  disabled?: boolean;
  forward?: boolean;
  back?: boolean;
}
// 通常ボタン(アローアイコンあり)
const MainButton: FC<MainProps> = memo(
  ({ children, forward = false, back = false, ...props }) => (
    <>
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onClick={props.onClick}
      >
        {children}
      </Button>
    </>
  )
);

// サブボタン(アローアイコンなし)
const SubButton: FC<MainProps> = memo(({ children, ...props }) => (
  <>
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={props.onClick}
    >
      {children}
    </Button>
  </>
));

// インタフェース
interface LinkProps extends MuiButtonProps {
  to: string;
  disabled?: boolean;
  forward?: boolean;
  back?: boolean;
  forwardIcon?: ReactNode;
  backIcon?: ReactNode;
}

// リンクボタン
const LinkMainButton: FC<LinkProps> = memo(
  ({
    to,
    disabled = false,
    forwardIcon = undefined,
    backIcon = undefined,
    children,
  }) => {
    const startIcon = backIcon || undefined;
    const endIcon = forwardIcon || undefined;
    return (
      <>
        <Button
          component={Link}
          to={to}
          disabled={disabled}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          {children}
        </Button>
      </>
    );
  }
);
export { MainButton, SubButton, LinkMainButton };
