import { FC, memo, useContext, useEffect } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { AuthContext } from 'app/hooks/context/auth';
import useOneId from 'app/hooks/oneId';
import useCorpUsersInviteMail from 'app/hooks/corporation/users/mail';
import useCorpUsersInviteRegist from 'app/hooks/corporation/users/regist';
import { UpdateRequest } from 'app/service/customer/corporation/users/regist';

// 招待メールクリックで招待情報取得して、正常かどうかを判断(メールのURLクリック)
const Mail: FC = memo(() => {
  // ロケーション
  const location = useLocation();
  const { search } = location;

  // 招待ID取得
  const inviteId = new URLSearchParams(search).get('inviteId') || '';

  // ユーザ招待業務カスタムHooks
  const { usersInviteMailAPI } = useCorpUsersInviteMail(inviteId);

  useEffect(() => {
    usersInviteMailAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

// 招待メールで招待情報が正常の場合のログイン後(新認証基盤画面の後)
const Invite: FC = memo(() => {
  // ロケーション
  const location = useLocation();
  const { search } = location;

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // ログイン関連(OneID Connect関連)カスタムHooks
  const { accessTokenAPI } = useOneId();

  // アクセストークンパラメータ取得
  const code = new URLSearchParams(search).get('code') || '';
  const state = new URLSearchParams(search).get('state') || '';
  const inviteId = new URLSearchParams(search).get('inviteId') || '';
  const prompt = new URLSearchParams(search).get('prompt');

  // ID連携要求Hooks
  const { usersInviteRegistAPI } = useCorpUsersInviteRegist(inviteId);

  // アクセストークン要求API
  const history = useHistory();
  useEffect(() => {
    if (code) {
      accessTokenAPI(code, state, prompt, inviteId, 0);
    } else {
      // codeが取得できない場合はエラー
      history.push(`${PageInfo.ERROR_500.path}?ptn=auth-error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ID連携API呼出し
  useEffect(() => {
    if (!authInfo || !authInfo.isAuth) return;
    // 招待メールリンククリック直後の場合は、ID連携API呼出しID連携済に更新
    const req: UpdateRequest = {
      userId: authInfo?.scimId || '',
      inviteId,
    };
    usersInviteRegistAPI(req);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  return <></>;
});

// 企業申請の規約同意後(新認証基盤画面の後)
const CorpApply: FC = memo(() => {
  // ロケーション
  const location = useLocation();
  const { search } = location;

  // ログイン関連(OneID Connect関連)カスタムHooks
  const { accessTokenAPI } = useOneId();

  // アクセストークンパラメータ取得
  const code = new URLSearchParams(search).get('code') || '';
  const state = new URLSearchParams(search).get('state') || '';

  const history = useHistory();
  useEffect(() => {
    if (code) {
      accessTokenAPI(code, state, null, null, 4);
    } else {
      // codeが取得できない場合はエラー
      history.push(`${PageInfo.ERROR_500.path}?ptn=auth-error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

// 企業ID連携登録の規約同意後(新認証基盤画面の後)
const IdFederate: FC = memo(() => {
  // ロケーション
  const location = useLocation();
  const { search } = location;

  // ログイン関連(OneID Connect関連)カスタムHooks
  const { accessTokenAPI } = useOneId();

  // アクセストークンパラメータ取得
  const code = new URLSearchParams(search).get('code') || '';
  const state = new URLSearchParams(search).get('state') || '';

  const history = useHistory();
  useEffect(() => {
    if (code) {
      accessTokenAPI(code, state, null, null, 5);
    } else {
      // codeが取得できない場合はエラー
      history.push(`${PageInfo.ERROR_500.path}?ptn=auth-error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

// ログイン後(新認証基盤画面の後)
const Login: FC = memo(() => {
  // ロケーション
  const location = useLocation();
  const { search } = location;

  // ログイン関連(OneID Connect関連)カスタムHooks
  const { accessTokenAPI } = useOneId();

  // アクセストークンパラメータ取得
  const code = new URLSearchParams(search).get('code') || '';
  const state = new URLSearchParams(search).get('state') || '';
  const prompt = new URLSearchParams(search).get('prompt');
  // promptがcreateの場合は、登録方法選択画面(2)、それ以外はTOP画面（3）
  let ptn = prompt === 'create' ? 2 : 3;
  // promptがloginと無しでptnを設定
  if (ptn === 3) ptn = prompt === 'login' ? 9 : 3;

  const history = useHistory();
  useEffect(() => {
    if (code) {
      accessTokenAPI(code, state, prompt, null, ptn);
    } else {
      // codeが取得できない場合はエラー
      history.push(`${PageInfo.ERROR_500.path}?ptn=auth-error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

// マイページ(招待エラー画面などログイン前からの遷移)
const MyPage: FC = memo(() => {
  // ロケーション
  const location = useLocation();
  const { search } = location;

  // ログイン関連(OneID Connect関連)カスタムHooks
  const { accessTokenAPI } = useOneId();

  // アクセストークンパラメータ取得
  const code = new URLSearchParams(search).get('code') || '';
  const state = new URLSearchParams(search).get('state') || '';
  const prompt = new URLSearchParams(search).get('prompt');
  // promptがloginと無しでptnを設定
  const ptn = prompt === 'login' ? 10 : 7;

  const history = useHistory();
  useEffect(() => {
    if (code) {
      accessTokenAPI(code, state, prompt, null, ptn);
    } else {
      // codeが取得できない場合はエラー
      history.push(`${PageInfo.ERROR_500.path}?ptn=auth-error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

const EntryPage: FC = () => {
  // ロケーション情報
  const location = useLocation();
  const { search } = location;
  const param = new URLSearchParams(search).get('next');

  switch (param) {
    case 'login':
      return <Login />;
    case 'corpApply':
      return <CorpApply />;
    case 'idFederate':
      return <IdFederate />;
    case 'invite':
      return <Mail />;
    case 'entry':
      return <Invite />;
    case 'mypage':
      return <MyPage />;
    case 'inquiry':
      return <Redirect to={PageInfo.INQUIRY_INPUT.path} />;
    default:
      return <Redirect to={PageInfo.TOP.path} />;
  }
};

export default EntryPage;
