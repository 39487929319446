import {
  Box,
  Grid,
  Toolbar,
  Typography,
  Hidden,
  Link,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  styled,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { AuthContext } from 'app/hooks/context/auth';
import useOneId from 'app/hooks/oneId';
import useDevice from 'app/hooks/device';
import {
  PageInfo,
  OuterLinkInfo,
  CorporationStatusConst,
  UserTypeConst,
} from 'app/utils/constants';
import useNoticeList from 'app/hooks/notice';
import theme, { ColorInfo } from 'app/style/theme';
import {
  OuterBlankLink,
  OuterBlankLinkArrow,
} from 'app/views/components/atom/link';
import { ButtonLowForward } from 'app/views/components/block/button';
import IntroduceServiceList from 'app/views/components/block/introduce';
import MainVisual from 'app/views/components/modules/mainvisual';
import useNoticeModule from 'app/views/components/modules/notice';
import Template from 'app/views/components/template';
import { ComponentPropsWithoutRef, FC, memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick, OnOutDmpClick } from 'app/utils/dmp';
import { useAdminCorpMng } from 'app/hooks/corporation/detail';

const useStyles = makeStyles(() => ({
  mainWrapGray: {
    width: '100vw',
    margin: '0 calc(50% - 50vw)',
    backgroundColor: ColorInfo.gray05,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 20px 70px',
      margin: '0',
    },
  },
  mainWrapDefault: {
    width: '100vw',
    margin: '0 calc(50% - 50vw)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 20px 70px',
      margin: '0',
    },
  },
  mainWrapService: {
    maxWidth: 1000,
    margin: '0 auto',
    padding: '80px 0 100px',
    '& > h3': {
      marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '70px 0 50px',
    },
  },
  difinition: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '80px 0 60px',
    '& > dt': {
      fontSize: '150%',
      borderLeft: '10px solid #333',
      paddingLeft: 15,
      marginBottom: 20,
    },
    '& > dd > ul': {
      paddingLeft: 6,
    },
    '& > dd > div > div > div': {
      widht: '100%',
      border: '1px solid #CCC',
    },
    '& > dd > div > div > div > div': {
      width: '100%',
      height: '100px',
      backgroundColor: '#CCC',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '80px 0 0',
    },
  },
  imageButton: {
    backgroundColor: 'rgba(51,112,121,0)',
  },
  useNotice: {
    padding: '20px',
    border: `1px solid ${ColorInfo.blue01}`,
  },
  useNoticeTitle: {
    margin: '20px',
    padding: '20px',
    textAlign: 'center',
    borderBottom: `3px solid ${ColorInfo.gray01}`,
  },
  loginItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    '& > *:first-child': {
      marginBottom: 15,
    },
  },
  useNoticeContents: {
    margin: 'auto',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  textup: {
    verticalAlign: 'super',
  },
  span: {
    color: ColorInfo.red01,
  },
  mainWrap: {
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: 30,
    },
  },
  mainTitle: {
    maxWidth: '1000px',
    padding: '140px 40px 0',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      margin: '0 20px',
    },
  },

  mainP: {
    marginTop: 20,
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 2,
    letterSpacing: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      paddingBottom: 40,
    },
  },
  mainLogo: {
    width: '58%',
    minWidth: 250,
  },
  mobileSide: {
    padding: '0px 40px',
  },
}));

const SectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface SectionProps extends ComponentPropsWithoutRef<'div'> {
  order: number;
}

const Section: FC<SectionProps> = ({ order, style, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div style={{ ...style, order }} {...props}>
    {children}
  </div>
);

// Main Title
const MainTitle: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainWrap}>
      <div className={classes.mainTitle}>
        <Typography component="h1">
          <img
            src="image/top_logo.svg"
            alt="Toyota Biz Center"
            className={classes.mainLogo}
          />
        </Typography>
        <Typography className={classes.mainP}>
          Toyota Biz
          Centerは、トヨタ自動車がご提供する企業向けサービスのポータルサイトです。
          <br />
          企業向けサービスのご利用に必要な情報の管理や手続きを行えます。
        </Typography>
      </div>
    </div>
  );
};

// お知らせ一覧
const NoticeList: FC = memo(() => {
  const classes = useStyles();
  const history = useHistory();
  const { ViewNociteList } = useNoticeModule();
  const noticeListHook = useNoticeList('0');
  return (
    <div className={classes.mainWrapGray}>
      <Box component="dl" className={classes.difinition}>
        <Toolbar
          disableGutters
          variant="dense"
          style={{ marginBottom: '0px', marginTop: '-20px' }}
        >
          <Typography variant="h3" style={{ margin: '0 auto 25px 0' }}>
            お知らせ
          </Typography>
        </Toolbar>
        <ViewNociteList noticeListHook={noticeListHook} />
        <Grid
          container
          justifyContent="flex-end"
          style={{ width: '100%', margin: '0 0 0 -4' }}
        >
          <ButtonLowForward
            name="noticeList"
            style={{
              height: 56,
              backgroundColor: 'transparent',
              marginTop: '9px',
            }}
            onClick={() => history.push(PageInfo.NOTICE_BASE.path)}
          >
            一覧を見る
          </ButtonLowForward>
        </Grid>
      </Box>
    </div>
  );
});

const topStyles = () =>
  createStyles({
    mainWrap: {
      maxWidth: 1000,
      margin: '0 auto',
      padding: '0 40px 50px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        margin: '0 20px 40px',
      },
    },
    text: {
      lineHeight: 2,
      '& strong': {
        fontWeight: 'normal',
        color: ColorInfo.red01,
        '& strong > sup': {
          fontSize: '1.3rem',
          verticalAlign: 'top',
        },
      },
    },
    twoBanners: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      marginTop: 50,
      padding: 20,
      height: '100%',
      borderRadius: 4,
      color: ColorInfo.blue02,
      textDecoration: 'none',
      transition: 'all 0.3s',
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        justifyContent: 'center',
      },
    },
    bannerTitle: {
      padding: '35px 40px!important',
      maxWidth: 470,
      cursor: 'pointer',
      borderRadius: 4,
      transition: 'all 0.3s',
      '&:hover': {
        color: ColorInfo.white01,
        backgroundColor: ColorInfo.blue03,
      },
      '&:hover >::before': {
        backgroundColor: ColorInfo.white01,
      },
      '&:hover > ::after': {
        borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
      },
      marginBottom: 20,
      minHeight: '150px',
      backgroundColor: ColorInfo.blue05,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      position: 'relative',
      '& svg': {
        fill: 'currentColor',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
        padding: '25px!important',
      },
    },
    buttonTitle: {
      '&::before': {
        position: 'absolute',
        top: '35%',
        right: 50,
        content: "''",
        width: 35,
        height: 1,
        backgroundColor: ColorInfo.blue02,
        transition: 'all 0.3s',
        '&:hover': {
          borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '&::after': {
        position: 'absolute',
        top: '35%',
        right: 35,
        transform: 'translateY(-6px)',
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 15px 7px 0',
        borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
        transition: 'all 0.3s',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    bannerText: {
      color: 'inherit',
      marginTop: 20,
      letterSpacing: 1,
      '& span': {
        fontSize: '1.3rem',
      },
    },
    topInner: {
      marginLeft: 15,
      fontSize: '2.1rem',
      fontWeight: 600,
    },
    loginInfo: {
      marginTop: 20,
      fontSize: '1.3rem',
      color: ColorInfo.gray01,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    textLink: {
      color: ColorInfo.blue02,
      fontSize: '1.5rem',
      textDecoration: 'none',
      '&:visited': {
        color: ColorInfo.blue02,
        textDecoration: 'none',
      },
      '&:hover, &:active': {
        textDecoration: 'underline',
      },
    },
    topPageInfo: {
      padding: 25,
      marginBottom: 10,
      borderRadius: 4,
      backgroundColor: ColorInfo.red02,
      border: `1px solid ${ColorInfo.red01}`,
      '& p': {
        fontSize: '1.7rem',
        fontWeight: 600,
        lineHeight: 2,
        textAlign: 'center',
        letterSpacing: 1,
      },
      '& p strong': {
        fontWeight: 600,
        color: ColorInfo.red01,
      },
    },
    ar: {
      display: 'block',
      textAlign: 'right',
    },
  });

const TopLogin = withStyles(topStyles)(
  ({ classes }: WithStyles<typeof topStyles>) => {
    const { eventLogin, eventLoginCreate } = useOneId();
    const { authInfo } = useContext(AuthContext);
    return (
      <div className={classes.mainWrap}>
        <Typography className={classes.text}>
          Toyota Biz Centerをご利用いただくには、
          <strong>企業ユーザー登録</strong>
          が必要です。
          <br />
          <strong>
            「TOYOTAアカウント」<sup>※1</sup>
            でログイン後、企業登録<sup>※2</sup>
          </strong>
          し、企業ユーザー登録を行ってください。
        </Typography>
        <Grid container className={classes.twoBanners}>
          <Grid
            alignItems="flex-start"
            className={classes.bannerTitle}
            container
            item
            sm={12}
            md={6}
            onClick={(event) => {
              event.preventDefault();
              // GAタグ:TOP画面-新規登録押下
              onGaClick('B-000-002-001');
              // DMPビーコンタグ：TOP画面-新規登録押下
              OnDmpClick('B-000-002-001', authInfo?.scimId);
              eventLoginCreate();
            }}
          >
            <Grid
              container
              item
              md={12}
              justifyContent="flex-start"
              className={classes.buttonTitle}
            >
              <svg
                viewBox="0 0 28 28"
                width="28"
                height="28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.64,26.19H2V20.55l18-18a1.92,1.92,0,0,1,2.65,0l3,3a1.88,1.88,0,0,1,0,2.65ZM3.2,25H7.14L24.81,7.32a.68.68,0,0,0,0-1l-3-3a.69.69,0,0,0-.95,0L3.2,21Z" />
                <rect
                  x="19.85"
                  y="4.35"
                  width="1.2"
                  height="6.78"
                  transform="translate(0.52 16.73) rotate(-45)"
                />
              </svg>
              <span className={classes.topInner}>新規登録</span>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <Typography className={classes.bannerText}>
                TOYOTAアカウントをお持ちでない方
              </Typography>
            </Grid>
          </Grid>

          <Grid
            style={{ padding: '35px 40px' }}
            alignItems="flex-start"
            className={classes.bannerTitle}
            container
            item
            sm={12}
            md={6}
            onClick={(event) => {
              event.preventDefault();
              // GAタグ：TOP画面-ログインボタンクリック
              onGaClick('B-000-002-002');
              // DMPビーコンタグ：TOP画面-ログインボタンクリック
              OnDmpClick('B-000-002-002', authInfo?.scimId);
              eventLogin();
            }}
          >
            <Grid
              container
              item
              md={12}
              justifyContent="flex-start"
              className={classes.buttonTitle}
            >
              <svg
                viewBox="0 0 28 28"
                width="28"
                height="28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="12.74 20.75 11.89 19.9 17.87 13.92 11.89 7.95 12.74 7.1 19.56 13.92 12.74 20.75" />
                <path d="M22.82,26.85H13.24v-1.2h9.58A2.14,2.14,0,0,0,25,23.51V4.34A2.14,2.14,0,0,0,22.82,2.2H13.24V1h9.58a3.35,3.35,0,0,1,3.34,3.34V23.51A3.35,3.35,0,0,1,22.82,26.85Z" />
                <rect x="2" y="13.32" width="16.72" height="1.2" />
              </svg>
              <span className={classes.topInner}>ログイン</span>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start">
              <Typography className={classes.bannerText}>
                TOYOTAアカウントをお持ちの方
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.loginInfo}>
          <p>
            ※1 TOYOTAアカウント
            <br />
            「TOYOTAアカウント」とは、トヨタ自動車がご提供するさまざまなサービスを安全・快適にご利用いただけるお客様認証サービスです。
            <br />
            <br />
            ※2 企業登録
            <br />
            企業登録には、「TOYOTAアカウント」でログイン後、登録申請が必要です。
            <br />
            法人の「TOYOTAアカウント」の登録については、
            <Link target="_blank" href="https://faq.toyota.jp/faq/show/6647">
              <span>こちら</span>
            </Link>
            でご確認ください。
            <br />
            トヨタ自動車の基準に沿った審査を経て企業登録されます（1企業1登録）。
            <br />
            審査には「登記事項証明書（現在事項証明書）」が必要となりますので、予めご用意をお願いいたします。
            <br />
            <br />
          </p>
        </div>
        <OuterBlankLink
          href={`${PageInfo.ABOUT.path}#linked`}
          text="企業登録／企業ユーザー登録についてはこちら"
          // 企業情報の登録およびID連携の手順はこちら押下(GA,dmp発火無し)
          // gaTagId="B-000-002-004"
        />
      </div>
    );
  }
);

const topRegistStyles = () =>
  createStyles({
    mainWrap: {
      maxWidth: 1000,
      margin: '0 auto',
      padding: '0 40px 50px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        margin: '0 20px 40px',
      },
    },
    text: {
      lineHeight: 2,
      '& strong': {
        fontWeight: 'normal',
        color: ColorInfo.red01,
        '& strong > sup': {
          fontSize: '1.3rem',
          verticalAlign: 'top',
        },
      },
    },
    twoBanners: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
      marginTop: 30,
      padding: 20,
      height: '100%',
      borderRadius: 4,
      color: ColorInfo.blue02,
      textDecoration: 'none',
      transition: 'all 0.3s',
    },
    bannerSummary: {
      color: ColorInfo.blue02,
      textDecoration: 'none',
      transition: 'all 0.3s',
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
    },
    twoBannersInner: {
      display: 'flex',
      alignItems: 'stretch',
      transition: 'all 0.3s',
      maxWidth: 470,
      width: '100%',
      justifyContent: 'left',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    },
    bannerTitle: {
      maxWidth: 470,
      cursor: 'pointer',
      borderRadius: 4,
      transition: 'all 0.3s',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        maxWidth: 'none',
      },
      '&:hover': {
        color: ColorInfo.white01,
        backgroundColor: ColorInfo.blue03,
      },
      '&:hover >::before': {
        backgroundColor: ColorInfo.white01,
      },
      '&:hover > ::after': {
        borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
      },
      marginBottom: 20,
      minHeight: '150px',
      backgroundColor: ColorInfo.blue05,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      position: 'relative',
      '& svg': {
        fill: 'currentColor',
      },
    },
    buttonTitle: {
      '&::before': {
        position: 'absolute',
        top: '35%',
        right: 50,
        content: "''",
        width: 35,
        height: 1,
        backgroundColor: ColorInfo.blue02,
        transition: 'all 0.3s',
        '&:hover': {
          borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '&::after': {
        position: 'absolute',
        top: '35%',
        right: 35,
        transform: 'translateY(-6px)',
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 15px 7px 0',
        borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
        transition: 'all 0.3s',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    bannerText: {
      color: 'inherit',
      marginTop: 20,
      // letterSpacing: 1,
      '& span': {
        fontSize: '1.3rem',
      },
    },
    topInner: {
      marginLeft: 15,
      fontSize: '2.1rem',
      fontWeight: 600,
    },
    loginInfo: {
      marginTop: 20,
      fontSize: '1.3rem',
      color: ColorInfo.gray01,
    },
    textLink: {
      color: ColorInfo.blue02,
      fontSize: '1.5rem',
      textDecoration: 'none',
      '&:visited': {
        color: ColorInfo.blue02,
        textDecoration: 'none',
      },
      '&:hover, &:active': {
        textDecoration: 'underline',
      },
    },
    topPageInfo: {
      padding: 25,
      marginBottom: 10,
      borderRadius: 4,
      backgroundColor: ColorInfo.red02,
      border: `1px solid ${ColorInfo.red01}`,
      '& p': {
        fontSize: '1.7rem',
        fontWeight: 600,
        lineHeight: 2,
        textAlign: 'center',
        letterSpacing: 1,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.3rem',
          textAlign: 'left',
        },
        '& small': {
          fontSize: '1.4rem',
          fontWeight: 500,
        },
      },
      '& p strong': {
        fontWeight: 600,
        color: ColorInfo.red01,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },
    ar: {
      display: 'block',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        '& a': { fontSize: '1.2rem' },
      },
    },
  });

const TopRegist = withStyles(topRegistStyles)(
  ({ classes }: WithStyles<typeof topRegistStyles>) => {
    const { eventFederateTerms } = useOneId();
    const { authInfo } = useContext(AuthContext);
    const history = useHistory();

    const { adminCorpMng } = useAdminCorpMng();
    const isAdminCorp = adminCorpMng?.administratorEmail === authInfo?.email;
    type PageInfoType = '0' | '1' | '2' | '3' | '4';
    const getPageInfoType = (): PageInfoType => {
      if (authInfo?.userTypeScim === UserTypeConst.PERSON) {
        if (
          !authInfo?.corporationId ||
          authInfo?.corporationStatus === CorporationStatusConst.SUSPENSION
        ) {
          return '1';
        }
        if (authInfo?.corporationStatus === CorporationStatusConst.APPLYING) {
          return '2';
        }
        if (
          isAdminCorp &&
          authInfo?.corporationStatus === CorporationStatusConst.APPLY_REJECT
        ) {
          return '3';
        }
        return '0';
      }

      if (
        !authInfo?.corporationId ||
        authInfo?.corporationStatus === CorporationStatusConst.SUSPENSION
      ) {
        return '4';
      }
      if (authInfo?.corporationStatus === CorporationStatusConst.APPLYING) {
        return '2';
      }
      if (
        isAdminCorp &&
        authInfo?.corporationStatus === CorporationStatusConst.APPLY_REJECT
      ) {
        return '3';
      }
      return '0';
    };
    return (
      <div className={classes.mainWrap}>
        {getPageInfoType() === '1' && (
          <div className={classes.topPageInfo}>
            <p>
              Toyota Biz
              Centerをご利用いただくには、法人の「TOYOTAアカウント」である必要があります。
              <br />
              本サービスを解除いただき、法人の「TOYOTAアカウント」を再作成してください。
              <br />
              本サービスの解除は
              <Link
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.LINK_DETACH.path);
                }}
              >
                <span>こちら</span>
              </Link>
              からお手続きいただけます。
            </p>
          </div>
        )}
        {getPageInfoType() === '2' && (
          <div className={classes.topPageInfo}>
            <p>
              「企業登録」を申請中の方は、今しばらくお待ちください。
              <br />
              申請をキャンセル、または登録手続きの途中で本サービスの解除を希望される方は、
              <br />
              <Link
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.CORP_INFO_EDIT_INPUT.path);
                }}
              >
                <span>こちら</span>
              </Link>
              からお手続きいただけます。
            </p>
          </div>
        )}
        {getPageInfoType() === '3' && (
          <div className={classes.topPageInfo}>
            <p>
              申請が差し戻されました。
              <br />
              申請内容の確認・修正、または取り下げを希望されるかたは
              <Link
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.CORP_INFO_EDIT_INPUT.path);
                }}
              >
                <span>こちら</span>
              </Link>
              からお手続きいただけます。
            </p>
          </div>
        )}
        {getPageInfoType() === '4' && (
          <div className={classes.topPageInfo}>
            <p>
              Toyota Biz
              Centerをご利用いただくには、企業ユーザー登録が必要です。
              <br />
              企業登録を行っていない／わからない方は「企業登録」、
              <br />
              企業登録を行っている企業の方は「企業ユーザー登録」を行ってください。
              <br />
              <small>
                ※企業登録は、１企業につき１登録のため、事前に登録有無をご確認ください。
              </small>
              <br />
              登録手続きの途中で本サービスの解除を希望される方は、
              <br />
              <Link
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.LINK_DETACH.path);
                }}
              >
                <span>こちら</span>
              </Link>
              からお手続きいただけます。
            </p>
          </div>
        )}
        {getPageInfoType() !== '0' && (
          <div className={classes.ar} style={{ textAlign: 'right' }}>
            <OuterBlankLink
              href={`${PageInfo.ABOUT.path}#linked`}
              text="企業登録／企業ユーザー登録についてはこちら"
            />
          </div>
        )}
        {getPageInfoType() === '4' && (
          <Grid container className={classes.twoBanners}>
            <Grid
              xs={12}
              md={6}
              container
              item
              className={classes.twoBannersInner}
            >
              <Typography className={classes.bannerSummary}>
                企業登録を行っていない／わからない方
              </Typography>
              <Grid
                container
                item
                className={classes.bannerTitle}
                style={{
                  padding: '35px min(3vw, 40px)',
                }}
                alignItems="flex-start"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.CORP_APPLY_TERMS.path);
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  className={classes.buttonTitle}
                >
                  <svg
                    viewBox="0 0 28 28"
                    width="28"
                    height="28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polygon points="0 27.7 0 26.5 28 26.5 28.01 27.7 0 27.7" />
                    <polygon points="24.22 26.68 23.02 26.68 23.02 19.45 15.67 19.45 15.67 26.68 14.47 26.68 14.47 18.25 24.22 18.25 24.22 26.68" />
                    <polygon points="5.69 26.68 4.49 26.68 4.49 1 20.99 3.4 20.99 18.77 19.79 18.77 19.79 4.44 5.69 2.39 5.69 26.68" />
                    <rect x="9.46" y="8.42" width="6.56" height="1.2" />
                    <rect x="9.46" y="12.25" width="6.56" height="1.2" />
                    <polygon points="21.41 26.68 20.21 26.68 20.21 23.78 18.48 23.78 18.48 26.68 17.28 26.68 17.28 22.58 21.41 22.58 21.41 26.68" />
                  </svg>
                  <span className={classes.topInner}>企業登録</span>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Typography className={classes.bannerText}>
                    ※登記事項証明書など公的書類をご準備ください。
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              md={6}
              container
              item
              className={classes.twoBannersInner}
            >
              <Typography className={classes.bannerSummary}>
                企業登録を行っている企業の方
              </Typography>
              <Grid
                style={{
                  padding: '35px min(3vw, 40px)',
                }}
                alignItems="flex-start"
                className={classes.bannerTitle}
                container
                item
                onClick={(event) => {
                  event.preventDefault();
                  // GAタグ:TOP画面-ID連携
                  onGaClick('B-000-002-003');
                  // DMPビーコンタグ：TOP画面-ID連携
                  OnDmpClick('B-000-002-003', authInfo?.scimId);
                  eventFederateTerms();
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  className={classes.buttonTitle}
                >
                  <svg
                    viewBox="0 0 28 28"
                    width="28"
                    height="28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M26.74,28H22V26.5H26.5V22H28v4.73A1.25,1.25,0,0,1,26.74,28Z" />
                    <path d="M28,6H26.5V1.5H22V0h4.73A1.25,1.25,0,0,1,28,1.26Z" />
                    <path d="M1.5,6H0V1.26A1.25,1.25,0,0,1,1.26,0H6V1.5H1.5Z" />
                    <path d="M6,28H1.26A1.25,1.25,0,0,1,0,26.74V22H1.5V26.5H6Z" />
                    <path d="M14.49,15.5a5.32,5.32,0,1,1,5.32-5.32A5.33,5.33,0,0,1,14.49,15.5Zm0-9.14a3.82,3.82,0,1,0,3.82,3.82A3.82,3.82,0,0,0,14.49,6.36Z" />
                    <path d="M7.27,23.59l-1.41-.5a9.15,9.15,0,0,1,17.26,0l-1.42.5a7.66,7.66,0,0,0-14.43,0Z" />
                  </svg>
                  <span className={classes.topInner}>企業ユーザー登録</span>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Typography className={classes.bannerText}>
                    ※事前に企業管理者をご確認ください。
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
);

const functionStyles = () =>
  createStyles({
    mainWrapGray: {
      width: '100vw',
      margin: '0 calc(50% - 50vw)',
      backgroundColor: ColorInfo.gray05,
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px 40px',
        margin: '0',
      },
    },
    mainWrap: {
      maxWidth: 1000,
      margin: '0 auto',
      padding: '80px 0 60px',
      '& > h3': {
        marginTop: '0px',
      },
    },
    titleTop: {
      marginTop: 0,
      padding: '0 0 3px 22px',
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: 1,
      borderLeft: `2px solid ${ColorInfo.blue02}`,
      '& + p': {
        marginTop: 25,
      },
    },
    functionList: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '30px 0 30px',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 306,
        height: 124,
        fontSize: '1.8rem',
        lineHeight: 1.4,
        fontWeight: 600,
        textAlign: 'center',
        color: ColorInfo.blue02,
        backgroundColor: ColorInfo.white01,
      },
      '& li:nth-child(n+4)': {
        marginTop: 36,
      },
      '& li span': {
        fontSize: '1.4rem',
        fontWeight: 'normal',
        color: ColorInfo.gray01,
      },
    },
    function: {
      lineHeight: 1.4,
      maxWidth: '306px',
      height: 124,
      marginBottom: 36,
      fontWeight: 600,
      textAlign: 'center',
      color: ColorInfo.blue02,
      backgroundColor: ColorInfo.white01,
    },
    functionText: {
      lineHeight: 1.4,
      margin: 'auto',
      fontWeight: 600,
      textAlign: 'center',
      color: ColorInfo.blue02,
      backgroundColor: ColorInfo.white01,
      fontSize: '1.8rem',
      '& span': {
        fontSize: '1.4rem',
        fontWeight: 'normal',
        color: ColorInfo.gray01,
        display: 'inline-block',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.3rem',
        },
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    mobilePadding: {
      padding: '0px 40px',
    },
    ar: {
      textAlign: 'right',
    },
    btnArrow: {
      position: 'relative',
      padding: '10px 105px 10px 0',
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      fontWeight: 500,
      border: 0,
      verticalAlign: 'middle',
      textDecoration: 'none',
      transition: 'all 0.3s',
      '&::before': {
        position: 'absolute',
        top: '50%',
        right: 15,
        content: "''",
        width: 70,
        height: 1,
        backgroundColor: ColorInfo.blue01,
        transition: 'all 0.3s',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '&::after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-6px)',
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 15px 7px 0',
        borderColor: `transparent transparent ${ColorInfo.gray01} transparent`,
        transition: 'all 0.3s',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      '&:hover': {
        color: ColorInfo.blue02,
        '&::before': {
          backgroundColor: ColorInfo.blue02,
        },
        '&::after': {
          borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
        },
      },
    },
    gridWrap: {
      [theme.breakpoints.down('sm')]: {
        padding: '0 15px',
      },
    },
  });

const FunctionList = withStyles(functionStyles)(
  ({ classes }: WithStyles<typeof functionStyles>) => (
    <div className={classes.mainWrapGray}>
      <div className={classes.mainWrap}>
        <Typography
          variant="h3"
          style={{ marginTop: '-20px', marginBottom: '30px' }}
        >
          Toyota Biz Centerについて
        </Typography>
        <Box className={classes.gridWrap}>
          <Grid
            container
            spacing={3}
            justifyContent="space-around"
            style={{ gap: '0px 15px' }}
          >
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <Typography variant="body1">
                Toyota Biz
                Centerでは、企業向けのさまざまな機能をご利用いただけます。
              </Typography>
            </Grid>
            <Grid container item xs={6} sm={4} className={classes.function}>
              <Typography className={classes.functionText}>
                企業情報の管理
              </Typography>
            </Grid>
            <Grid container item xs={6} sm={4} className={classes.function}>
              <Typography className={classes.functionText}>
                企業向け
                <br />
                サービスのお申込み
              </Typography>
            </Grid>
            <Grid container item xs={6} sm={4} className={classes.function}>
              <Typography className={classes.functionText}>
                ご契約サービスの
                <br />
                ご利用
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              sm={4}
              spacing={3}
              className={classes.function}
            >
              <Typography className={classes.functionText}>
                ご契約内容の
                <br />
                確認・変更
              </Typography>
            </Grid>
            <Grid container item xs={6} sm={4} className={classes.function}>
              <Typography className={classes.functionText}>
                ご契約サービスの
                <br />
                利用状況
              </Typography>
            </Grid>
            <Grid container item xs={6} sm={4} className={classes.function}>
              <Typography className={classes.functionText}>
                企業ユーザー管理
              </Typography>
            </Grid>
            <Grid xs={12} justifyContent="flex-end">
              <p className={classes.ar} style={{ marginRight: '24px' }}>
                <OuterBlankLinkArrow
                  href={PageInfo.ABOUT.path}
                  text="詳細を見る"
                  // GAタグ：TOP画面Toyota Biz Centerでできること-詳細を見る押下（内部リンクのためGA・DMP発火不要）
                  // gaTagId="B-000-002-005"
                />
              </p>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
);

const ServiceList: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainWrapDefault}>
      <div className={classes.mainWrapService}>
        <Typography variant="h3" style={{ marginTop: '-20px' }}>
          サービス紹介
        </Typography>
        <Typography variant="body1">
          Toyota Biz Centerでご利用いただける企業向けサービスをご紹介します。
        </Typography>
        <IntroduceServiceList />
      </div>
    </div>
  );
};

const otherButtonStyles = () =>
  createStyles({
    mainWrap: {
      maxWidth: 1000,
      margin: '12px auto 0',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px 40px',
        margin: '0',
      },
    },
    twoBanners: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      height: '100%',
      padding: '40px 45px 25px 45px',
      borderRadius: 4,
      color: ColorInfo.blue02,
      textDecoration: 'none',
      transition: 'all 0.3s',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        padding: '0 20px',
      },
    },
    bannerTitle: {
      cursor: 'pointer',
      borderRadius: 4,
      transition: 'all 0.3s',
      '&:hover': {
        color: ColorInfo.white01,
        backgroundColor: ColorInfo.blue03,
      },
      '&:hover >::before': {
        backgroundColor: ColorInfo.white01,
      },
      '&:hover > ::after': {
        borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
      },
      minHeight: '189px',
      backgroundColor: ColorInfo.blue05,

      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginTop: 35,
      '& svg': {
        fill: 'currentColor',
      },
      padding: '35px 40px!important',
    },
    buttonTitle: {
      padding: '35px 40px',
      flexDirection: 'column',
    },
    bannerText: {
      color: 'inherit',
      marginTop: 20,
      lineHeight: 2,
      letterSpacing: 1,
      '& span': {
        fontSize: '1.3rem',
      },
    },
    topInner: {
      marginLeft: 15,
      marginBottom: 20,
      fontSize: '2.1rem',
      fontWeight: 600,
    },
    loginInfo: {
      marginTop: 40,
      fontSize: '1.3rem',
      color: ColorInfo.gray01,
    },
    textLink: {
      color: ColorInfo.blue02,
      fontSize: '1.5rem',
      textDecoration: 'none',
      '&:visited': {
        color: ColorInfo.blue02,
        textDecoration: 'none',
      },
      '&:hover, &:active': {
        textDecoration: 'underline',
      },
    },
  });

const OtherButtons = withStyles(otherButtonStyles)(
  ({ classes }: WithStyles<typeof otherButtonStyles>) => {
    const history = useHistory();
    const { authInfo } = useContext(AuthContext);
    return (
      <div className={classes.mainWrap}>
        <Grid container className={classes.twoBanners}>
          <Grid
            item
            className={classes.bannerTitle}
            container
            alignItems="flex-start"
            xs={12}
            md={4}
            onClick={(event) => {
              event.preventDefault();
              history.push(PageInfo.HOW_TO_GUIDE.path);
            }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              className={classes.buttonTitle}
            >
              <span className={classes.topInner}>ご利用ガイド</span>
              <svg
                viewBox="0 0 48 48"
                width="28"
                height="28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M29.68,42.56H18.33v-8.3a2.08,2.08,0,0,0-1.09-1.81A14.07,14.07,0,0,1,9.89,19.4,14.36,14.36,0,0,1,23.58,5.94a14.13,14.13,0,0,1,7.2,26.51,2.08,2.08,0,0,0-1.1,1.82Zm-9.85-1.5h8.35V34.27a3.6,3.6,0,0,1,1.88-3.14A12.62,12.62,0,0,0,23.62,7.44a12.82,12.82,0,0,0-12.23,12A12.54,12.54,0,0,0,18,31.13a3.6,3.6,0,0,1,1.87,3.13Z" />
                <path d="M24,46.79a4.28,4.28,0,0,1-4.27-4.27h1.5a2.77,2.77,0,0,0,5.54,0h1.5A4.28,4.28,0,0,1,24,46.79Z" />
                <rect x="19.07" y="34.03" width="9.85" height="1.5" />
                <rect x="19.07" y="37.55" width="9.85" height="1.5" />
                <rect x="23.25" y="1" width="1.5" height="2.81" />
                <rect
                  x="14.45"
                  y="3.36"
                  width="1.5"
                  height="2.81"
                  transform="translate(-0.35 8.24) rotate(-30)"
                />
                <rect
                  x="8.01"
                  y="9.8"
                  width="1.5"
                  height="2.82"
                  transform="translate(-5.32 13.19) rotate(-60)"
                />
                <rect x="5" y="19.25" width="2.81" height="1.5" />
                <rect
                  x="7.36"
                  y="28.05"
                  width="2.81"
                  height="1.5"
                  transform="translate(-13.23 8.24) rotate(-30)"
                />
                <rect
                  x="38.49"
                  y="27.39"
                  width="1.5"
                  height="2.81"
                  transform="translate(-5.32 48.38) rotate(-60.01)"
                />
                <rect x="40.19" y="19.25" width="2.81" height="1.5" />
                <rect
                  x="37.83"
                  y="10.45"
                  width="2.82"
                  height="1.5"
                  transform="translate(-0.34 21.12) rotate(-30)"
                />
                <rect
                  x="31.39"
                  y="4.01"
                  width="2.81"
                  height="1.5"
                  transform="translate(12.27 30.78) rotate(-60)"
                />
              </svg>
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.bannerTitle}
            container
            alignItems="flex-start"
            xs={12}
            md={4}
            onClick={(event) => {
              event.preventDefault();
              history.push(PageInfo.INQUIRY_BRANCH.path);
            }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              className={classes.buttonTitle}
            >
              <span className={classes.topInner}>お問い合わせ</span>
              <svg
                viewBox="0 0 28 28"
                width="28"
                height="28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.6,23.2H3.6A2.61,2.61,0,0,1,1,20.6V7.6A2.61,2.61,0,0,1,3.6,5h20a2.61,2.61,0,0,1,2.6,2.6v13A2.61,2.61,0,0,1,23.6,23.2Zm-20-17A1.4,1.4,0,0,0,2.2,7.6v13A1.4,1.4,0,0,0,3.6,22h20A1.4,1.4,0,0,0,25,20.6V7.6a1.4,1.4,0,0,0-1.4-1.4Z" />
                <polygon points="13.59 17.39 1.82 7.22 2.61 6.31 13.61 15.81 25.22 6.14 25.98 7.06 13.59 17.39" />
                <rect
                  x="0.1"
                  y="17.09"
                  width="10.43"
                  height="1.2"
                  transform="translate(-10.95 8.94) rotate(-45)"
                />
                <rect
                  x="21.09"
                  y="12.48"
                  width="1.2"
                  height="10.43"
                  transform="translate(-6.16 20.52) rotate(-45)"
                />
              </svg>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            className={classes.bannerTitle}
            onClick={(event) => {
              onGaClick('B-000-002-006');
              // DMPビーコン
              OnOutDmpClick(OuterLinkInfo.FAQ_LINK_URL.path, authInfo?.scimId);
              window.open(OuterLinkInfo.FAQ_LINK_URL.path);
            }}
            // onClick={(event) => {
            //   event.preventDefault();
            //   TODO 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる
            //   history.push(OuterLinkInfo.FAQ_LINK_URL.path);
            // }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              className={classes.buttonTitle}
            >
              <span className={classes.topInner}>よくあるご質問</span>
              <svg
                viewBox="0 0 28 28"
                width="28"
                height="28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M13.51,26A12.51,12.51,0,1,1,26,13.51,12.52,12.52,0,0,1,13.51,26Zm0-23.52a11,11,0,1,0,11,11A11,11,0,0,0,13.51,2.5Z" />
                <path d="M13.71,20a1,1,0,1,0-1-1A1,1,0,0,0,13.71,20Z" />
                <path d="M14.52,16.57l-1.5,0a4,4,0,0,1,.69-2.42,8.79,8.79,0,0,1,.94-1c.73-.69,1.41-1.34,1.41-2.22a2.42,2.42,0,0,0-2.68-2.31,2.81,2.81,0,0,0-3,2.57L8.91,11a4.27,4.27,0,0,1,4.47-3.89,3.92,3.92,0,0,1,4.18,3.81,4.65,4.65,0,0,1-1.87,3.31,6.4,6.4,0,0,0-.78.81A2.75,2.75,0,0,0,14.52,16.57Z" />
              </svg>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);

const TopPage: FC = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  const isMobile = useDevice();
  return (
    <>
      {!isMobile ? (
        <MainVisual src="main.mp4" />
      ) : (
        <>
          <img
            src="image/main_img.png"
            alt="Toyota Biz Center"
            style={{ position: 'absolute', width: '100%' }}
          />
        </>
      )}

      <Template headerProps={{ elevation: 0, transparent: true }}>
        <SectionWrapper>
          <Section order={1}>
            <MainTitle />
          </Section>
          <Section order={2}>
            {!authInfo?.isAuth && <TopLogin />}
            {authInfo?.isAuth && (
              <>
                <TopRegist />
              </>
            )}
          </Section>
          <Section order={4}>
            <ServiceList />
          </Section>
          <Section order={authInfo?.isAuth ? 5 : 3}>
            <FunctionList />
          </Section>
          <Section order={authInfo?.isAuth ? 3 : 5}>
            <NoticeList />
          </Section>
          <Section order={6}>
            <OtherButtons />
          </Section>
        </SectionWrapper>
      </Template>
    </>
  );
};

export default TopPage;
