import { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { ColorInfo } from 'app/style/theme';
import { PageInfo, OuterLinkInfo } from 'app/utils/constants';
import Divider from '@material-ui/core/Divider';
import useLogout from 'app/hooks/logout';
import onGaClick from 'app/utils/gaclick';
import { OnOutDmpClick } from 'app/utils/dmp';
import { AuthContext } from 'app/hooks/context/auth';
import { Link as ALink } from '@material-ui/core';

const useStyles = makeStyles({
  menu: {
    width: '430px',
    padding: '0 50px 60px 50px',
    backgroundColor: ColorInfo.blue01,
  },
  menuClose: {
    width: '100%',
    height: '100px',
    position: 'relative',
    backgroundColor: ColorInfo.blue01,
    color: ColorInfo.white01,
  },
  menuBody: {
    margin: '20px 0 25px 0',
    padding: '0',
  },
  menuFooter: {
    margin: '20px 0 25px 0',
    padding: '0',
  },
  menuItem: {
    display: 'block',
    padding: '11px 0 11px 18px',
    fontSize: '1.4rem',
    lineHeight: '1.1',
    letterSpacing: '1px',
    textDecoration: 'none',
    color: ColorInfo.white01,
    position: 'relative',
  },
  menuItem2: {
    display: 'block',
    padding: '11px 0 11px 0px',
    fontSize: '1.4rem',
    lineHeight: '1.1',
    letterSpacing: '1px',
    textDecoration: 'none',
    color: ColorInfo.white01,
    position: 'relative',
  },
  menuItem3: {
    padding: '11px 0 11px 0px',
  },
  // ボタン関連
  button: {
    padding: '1px 6px 1px 6px',
    height: '58px',
    width: '330px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.4rem',
    lineHeight: 1,
    fontWeight: 600,
    letterSpacing: '2px',
    color: ColorInfo.white01,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    borderRadius: '4px',
    backgroundColor: ColorInfo.blue02,
    transition: 'all 0.3s',
    '&:hover': {
      color: ColorInfo.white01,
      backgroundColor: ColorInfo.blue03,
    },
  },
  buttonInquiry: {
    backgroundColor: ColorInfo.white01,
    color: ColorInfo.blue02,
  },
  closeIconButton: {
    width: '50px',
    height: '50px',
    top: '25px',
    right: '0px',
    cursor: 'pointer',
    position: 'absolute',
    color: ColorInfo.white01,
  },
  closeIconSvg: {
    fontSize: '4rem',
  },
  // ログアウト
  logoutSvg: {
    height: 'auto',
    fill: ColorInfo.white01,
  },
  // メニューリンク関連
  textLink: {
    fontSize: '1.4rem',
    margin: '0',
    color: ColorInfo.white01,
    backgroundColor: ColorInfo.blue01,
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      color: ColorInfo.blue03,
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  linkBefore1: {
    '&::before': {
      position: 'absolute',
      top: '50%',
      left: 0,
      content: `""`,
      width: '10px',
      height: '2px',
      backgroundColor: `${ColorInfo.white01}`,
    },
  },
  linkBefore2: {
    marginLeft: '20px',
    paddingLeft: '20px',
    '&::before': {
      position: 'absolute',
      top: '11px',
      left: '143px',
      content: `""`,
      width: '1px',
      height: '12px',
      backgroundColor: `${ColorInfo.white01}`,
    },
  },
  divider: {
    backgroundColor: ColorInfo.gray01,
    height: '1px',
    padding: '0',
  },
});

interface MenuProps {
  onClose: () => void;
}

const Menu: FC<MenuProps> = ({ onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const logout = useLogout();

  const handleClose = () => {
    logout();
    onClose();
  };
  const handlePage = (toPath: string): void => {
    if (location.pathname === toPath) {
      onClose();
    } else {
      history.push(toPath);
    }
  };

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  const LogoutIcon = () => (
    <svg
      viewBox="0 0 20 22"
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.logoutSvg}
    >
      <path d="M16.21,19.5h-7V18h7a1.25,1.25,0,0,0,1.25-1.25v-14A1.25,1.25,0,0,0,16.21,1.5h-7V0h7A2.75,2.75,0,0,1,19,2.75v14A2.75,2.75,0,0,1,16.21,19.5Z" />
      <polygon points="6.2 4.55 7.26 5.61 3.87 9 14.27 9 14.27 10.5 3.87 10.5 7.26 13.89 6.2 14.95 1 9.75 6.2 4.55" />
    </svg>
  );
  const MailIcon = () => <MailOutlineIcon />;

  return (
    <>
      <div className={classes.menu}>
        <List className={classes.menuClose}>
          <IconButton
            aria-label="close"
            className={classes.closeIconButton}
            onClick={onClose}
          >
            <CloseIcon className={classes.closeIconSvg} />
          </IconButton>
        </List>
        <List className={classes.menuFooter}>
          <ListItem className={classes.menuItem2}>
            <Button
              className={`${classes.button} ${classes.buttonInquiry}`}
              startIcon={<MailIcon />}
              onClick={() => handlePage(PageInfo.INQUIRY_BRANCH.path)}
            >
              お問い合わせ
            </Button>
          </ListItem>
        </List>
        <List className={classes.menuBody}>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.TOP.path);
              }}
            >
              TOP
            </Link>
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.MYPAGE.path);
              }}
            >
              マイページ
            </Link>
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.SERVICE_USE.path);
              }}
            >
              ご利用可能なサービス
            </Link>
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.CONTRACT.path);
              }}
            >
              契約一覧
            </Link>
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.INTRODUCE.path);
              }}
            >
              サービス紹介
            </Link>
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.ACCOUNT_MANAGE.path);
              }}
            >
              アカウント管理
            </Link>
          </ListItem>
          {/* お知らせ画面は仮リンク */}
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink} ${classes.linkBefore1}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.NOTICE_BASE.path);
              }}
            >
              お知らせ
            </Link>
          </ListItem>
        </List>
        <List className={classes.menuBody}>
          <ListItem className={`${classes.menuItem} ${classes.menuItem3}`}>
            <Divider className={classes.divider} />
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.ABOUT.path);
              }}
            >
              Toyota Biz Centerについて
            </Link>
          </ListItem>
          <ListItem className={`${classes.textLink}${classes.menuItem}`}>
            {/* TODO 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる */}
            <ALink
              href={OuterLinkInfo.FAQ_LINK_URL.path}
              className={`${classes.textLink}`}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                onGaClick('B-000-000-M-001');
                // DMPビーコン
                OnOutDmpClick(
                  OuterLinkInfo.FAQ_LINK_URL.path,
                  authInfo?.scimId
                );
                // 手動で閉じる
                onClose();
              }}
            >
              よくあるご質問
            </ALink>

            {/* <Link
              to="/"
              className={`${classes.textLink}`}
              onClick={(event) => {
                event.preventDefault();
               history.push(PageInfo.FAQ.path);
              }}
            >
              よくあるご質問
            </Link> */}
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.HOW_TO_GUIDE.path);
              }}
            >
              ご利用ガイド
            </Link>
          </ListItem>
          <ListItem className={classes.menuItem}>
            <Link
              to="/"
              className={`${classes.textLink}`}
              onClick={(event) => {
                event.preventDefault();
                handlePage(PageInfo.CONTRACT_TERMS.path);
              }}
            >
              規約一覧
            </Link>
          </ListItem>
        </List>
        <List className={classes.menuFooter}>
          <ListItem className={classes.menuItem2}>
            <Button
              className={classes.button}
              startIcon={<LogoutIcon />}
              onClick={handleClose}
            >
              ログアウト
            </Button>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default Menu;
