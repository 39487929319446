import { FC, useCallback, useEffect } from 'react';
import Template from 'app/views/components/template';
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableCell,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useApiKey } from 'app/hooks/contract/detail/apiKey/index';
import { ColorInfo } from 'app/style/theme';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { ButtonBack } from 'app/views/components/block/button';
import useContractDetail from 'app/hooks/contract/detail';

const useStyles = makeStyles({
  tableRow: {
    height: 80,
    '& > *': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    '& td:first-child': {
      background: ColorInfo.gray05,
    },
  },
  // メニューリンク関連
  textLink: {
    fontSize: '1.5rem',
    color: ColorInfo.blue02,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  contractName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '470px',
  },
  backButtonStyle: {
    marginTop: '5px',
    marginBottom: '-25px',
  },
});

const ApiKeyListPage: FC = () => {
  // 利用規約一覧業務カスタムHooks
  const { apiKeyList } = useApiKey();
  const classes = useStyles();
  const history = useHistory();
  interface params {
    contractNo: string;
  }
  const params = useParams<params>();
  interface apiKeyParams {
    contractServiceName: string;
    contractName: string;
  }
  const { contractDetail, globalMessage } = useContractDetail(
    params.contractNo
  );
  // 前の画面から渡された契約情報
  const { state } = useLocation<apiKeyParams>();
  const handleBack = () => {
    history.go(-1);
  };
  // URLの契約番号をglobalのwindowに保存しておく
  useEffect(() => {
    if (params.contractNo) window.sdpContractNo = params.contractNo;
  }, [params.contractNo]);
  // レンダリング
  return (
    <>
      <Template title="契約内容照会-APIキー">
        <Box style={{ marginTop: '90px', marginBottom: '75px' }}>
          <Typography>{contractDetail.contractServiceName}</Typography>
          <Typography style={{ fontSize: '2rem' }}>
            {contractDetail.contractName}
          </Typography>
        </Box>
        <Typography variant="h3" style={{ marginTop: '50px' }}>
          APIキー一覧
        </Typography>

        <Table aria-label="simple table">
          {apiKeyList &&
            apiKeyList.rows !== undefined &&
            apiKeyList.rows !== null &&
            apiKeyList.rows.map((row) => (
              <>
                <TableRow className={classes.tableRow}>
                  <TableCell>
                    <Typography>APIキー</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.apiKey}</Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
        </Table>

        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.backButtonStyle}
        >
          <Grid item xs={3}>
            <ButtonBack onClick={() => handleBack()}>戻る</ButtonBack>
          </Grid>
          <Grid item xs={9} />
        </Grid>
      </Template>
    </>
  );
};
export default ApiKeyListPage;
