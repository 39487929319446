import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ColorInfo } from 'app/style/theme';
import Template from 'app/views/components/template';
import { Link, Typography } from '@material-ui/core';
import { GuidePathBizCenter } from 'app/utils/constants';
import { useContext } from 'react';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';
import { AuthContext } from 'app/hooks/context/auth';

const styles = () =>
  createStyles({
    guide: {
      marginTop: 30,
      '& ul': {
        padding: 0,
      },
      '& li': {
        padding: '0 25px',
        borderBottom: `1px solid ${ColorInfo.gray03}`,
        borderTop: `1px solid ${ColorInfo.gray03}`,
      },
      '& li a': {
        display: 'block',
        padding: '22px 0',
        textDecoration: 'none',
        color: ColorInfo.blue01,
        fontSize: '1.5rem',
        '&:hover': {
          color: ColorInfo.blue02,
          textDecoration: 'underline',
        },
      },
    },
  });

const HowToGuidePage = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    const { authInfo } = useContext(AuthContext);
    const handleDownloadFIle = (url: string) => {
      window.open(url, '_blank');
    };
    return (
      <>
        <Template title="ご利用ガイド">
          <div className={classes.guide}>
            <Typography variant="h3" style={{ marginTop: '90px' }}>
              Toyota Biz Center
            </Typography>
            <ul>
              <li>
                <Link
                  href="/"
                  onClick={(event) => {
                    // GA, DMPビーコンタグ：ご利用ガイド表示時
                    const gaTagId = 'B-000-007-001';
                    onGaClick(gaTagId);
                    OnDmpClick(gaTagId, authInfo?.scimId);
                    event.preventDefault();
                    handleDownloadFIle(GuidePathBizCenter.GUIDE_BIZ_USE);
                  }}
                >
                  <span>ご利用ガイド</span>
                </Link>
              </li>
            </ul>
            {/* サービスの利用ガイドは掲載しないため非表示※今後復活させる可能性あり */}
            {/* <Typography variant="h3">サービス</Typography>
            <ul>
              <li>
                <Link
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    void fileDownloadAPI(GuidePathService.GUIDE_SERVICE_DRIVE);
                  }}
                >
                  <span>
                    トヨタドライブ統計／ドライブデータダッシュボードの使い方
                  </span>
                </Link>
              </li>
            </ul> */}
          </div>
        </Template>
      </>
    );
  }
);

export default HowToGuidePage;
