import Template from 'app/views/components/template';
import './page_about.css';
import { Link } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { FC, memo, useContext, useEffect, useRef } from 'react';
import useOneId from 'app/hooks/oneId';
import { AuthContext } from 'app/hooks/context/auth';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';

const AboutPage: FC = memo(() => {
  // reactのページだとハッシュタグのアンカーリンクが不安定（できる場合もある）ので、
  // わざとアンカーリンクと違う名前をURLに付与して、自前でスクロールを行う
  useEffect(() => {
    if (window.location.hash === '#linked') {
      // マイページ通知エリアから遷移
      const element = document.getElementById('howto');
      const top = Number(element?.getBoundingClientRect().top);
      window.scrollTo(0, top);
    }
  }, []);
  const { eventLogin, eventLoginCreate } = useOneId();

  const { authInfo } = useContext(AuthContext);
  return (
    // Toyota Biz Centerについて
    <Template title="Toyota Biz Centerについて">
      <div className="main-wrap">
        <h2 className="title-h3" id="title">
          Toyota Biz Centerとは
        </h2>
        <div className="about-wrap">
          <p>
            Toyota Biz
            Centerは、トヨタ自動車がご提供する企業向けサービスのポータルサイトです。
            <br />
            ご利用には企業ユーザー登録が必要です。「TOYOTAアカウント」でログイン後、企業登録を行い、企業ユーザー登録を行ってください。
          </p>
          <p>
            詳しくは
            <a className="text-link" href="#howto">
              こちら
            </a>
            をご確認ください。
          </p>

          <h3 className="title-h4">主な機能</h3>
          <ul className="function-list">
            <li>
              <p>企業情報の管理</p>
            </li>
            <li>
              <p>
                企業向け
                <br />
                サービスのお申込み
                <br />
              </p>
            </li>
            <li>
              <p>
                ご契約サービスの
                <br />
                ご利用
              </p>
            </li>
            <li>
              <p>
                ご契約内容の
                <br />
                確認・変更
              </p>
            </li>
            <li>
              <p>
                ご契約サービスの
                <br />
                利用状況
              </p>
            </li>
            <li>
              <p>企業ユーザー管理</p>
            </li>
          </ul>
        </div>

        <h2 className="title-h3">サービスのご利用について</h2>
        <ul className="howto-step">
          <li className="step-li">
            <div className="step-li_inner">
              <span className="step_num">STEP 1</span>問い合わせ・商談
            </div>
          </li>
          <li className="step-li">
            <div className="step-li_inner">
              <span className="step_num">STEP 2</span>申込み・ご契約
            </div>
          </li>
          <li className="step-li">
            <div className="step-li_inner">
              <span className="step_num">STEP 3</span>ご利用開始
            </div>
          </li>
        </ul>

        <h3 className="howto-step_ttl">
          <span className="step_icon">STEP 1</span>問い合わせ・商談
        </h3>
        <div className="howto-step_box dotline">
          <p>
            <Link className="text-link" to={PageInfo.INQUIRY_INPUT.path}>
              サービスに関するお問い合わせ
            </Link>
            から「サービスを使いたい」「サービスについて・料金について知りたい」など、お気軽にお問い合わせください。
            <br />
            <br />
            追って担当者よりご連絡いたします。
            <br />
            利用目的などをお聞きしたうえで、目的や予算にあったプランをご提案いたします。
          </p>
        </div>

        <h3 className="howto-step_ttl">
          <span className="step_icon">STEP 2</span>申込み・ご契約
        </h3>
        <div className="howto-step_box dotline">
          <p>
            ご提案内容や料金にご納得いただけましたら、申込み手続きに進みます。
            <br />
            担当者より別途契約方法についてご案内いたします。
          </p>
        </div>

        <h3 className="howto-step_ttl">
          <span className="step_icon">STEP 3</span>ご利用開始
        </h3>
        <div className="howto-step_box">
          <p>
            サービスのご利用には、Toyota Biz
            Centerへの企業ユーザー登録が必要です。
            <br />
            ご登録がお済みでない方は、
            <a className="text-link" href="#howto">
              こちら
            </a>
            をご確認ください。
            <br />
            <br />
            Toyota Biz Centerにご登録済みの方は
            <Link
              className="text-link"
              onClick={(event) => {
                event.preventDefault();
                // GAタグ:ログインボタン押下
                onGaClick('B-000-011-001');
                // DMPビーコンタグ：ログインボタン押下
                OnDmpClick('B-000-011-001', authInfo?.scimId);
                eventLogin();
              }}
              to="#title"
            >
              ログイン
            </Link>
            後、マイページからご契約サービスをご利用いただけます。
          </p>
        </div>

        <h2 id="howto" className="title-h3">
          企業登録／企業ユーザー登録について
        </h2>
        <p className="linked-text">
          Toyota Biz Centerをご利用いただくには、企業ユーザー登録が必要です。
          <br />
          「TOYOTAアカウント」でログイン後、企業登録を行い、企業ユーザー登録を行ってください。
        </p>
        <div className="howto-proc">
          <h2 className="proc-ttl1">
            企業登録を行っていない／わからない方
          </h2>
          <div className="proc-wrap">
            <ul className="proc-flow">
              <li>
                １．Toyota Biz Centerに
                <Link
                  className="text-link"
                  onClick={(event) => {
                    event.preventDefault();
                    // GAタグ:ログインボタン押下
                    onGaClick('B-000-011-002');
                    // DMPビーコンタグ：ログインボタン押下
                    OnDmpClick('B-000-011-002', authInfo?.scimId);
                    eventLogin();
                  }}
                  to="#title"
                >
                  ログイン
                </Link>
                後、TOPページの「企業登録」から申請を行ってください。
              </li>
              <li>
                ２．事務局にて、トヨタ自動車の基準に沿った審査を実施します。
              </li>
              <li>３．審査通過後、企業登録が行われます。</li>
            </ul>
            <ul className="notes">
              <li>
                ※「企業登録」申請には、「TOYOTAアカウント」の取得が必要です。「TOYOTAアカウント」をお持ちでない方は、
                <Link
                  className="text-link"
                  onClick={(event) => {
                    event.preventDefault();
                    // GAタグ:ログインボタン押下
                    onGaClick('B-000-011-003');
                    // DMPビーコンタグ：ログインボタン押下
                    OnDmpClick('B-000-011-003', authInfo?.scimId);
                    eventLoginCreate();
                  }}
                  to="#title"
                >
                  こちら
                </Link>
                から取得してください。
              </li>
            </ul>
          </div>
        </div>
        <div className="howto-proc">
          <h2 className="proc-ttl1">企業登録を行っている企業の方</h2>
          <div className="proc-wrap">
            <ul className="proc-flow">
              <li>
                １．Toyota Biz Centerに
                <Link
                  className="text-link"
                  onClick={(event) => {
                    event.preventDefault();
                    // GAタグ:ログインボタン押下
                    onGaClick('B-000-011-004');
                    // DMPビーコンタグ：ログインボタン押下
                    OnDmpClick('B-000-011-004', authInfo?.scimId);
                    eventLogin();
                  }}
                  to="#title"
                >
                  ログイン
                </Link>
                後、TOPページの「企業ユーザー登録」から申請を行ってください。
              </li>
              <li>
                ２．企業管理者により申請が承認されると、企業ユーザー登録が完了します。
              </li>
            </ul>
            <ul className="notes">
              <li>
                ※「企業ユーザー登録」申請には、「TOYOTAアカウント」の取得が必要です。「TOYOTAアカウント」をお持ちでない方は、
                <Link
                  className="text-link"
                  onClick={(event) => {
                    event.preventDefault();
                    // GAタグ:ログインボタン押下
                    onGaClick('B-000-011-005');
                    // DMPビーコンタグ：ログインボタン押下
                    OnDmpClick('B-000-011-005', authInfo?.scimId);
                    eventLoginCreate();
                  }}
                  to="#title"
                >
                  こちら
                </Link>
                から取得してください。
              </li>
              <li>
                ※企業登録がお済みの場合、企業管理者が設定されています。
              </li>
              <li>
                ※「企業ユーザー登録」申請には、企業管理者のメールアドレスが必要です。予めご確認をお願いいたします。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Template>
  );
});

export default AboutPage;
