import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useCorpDelete from 'app/hooks/corporation/resign/confirm';
import useCorporationUsers, {
  CorporationUsers,
} from 'app/hooks/corporation/users/list';
import {
  ContractServiceInfo,
  useContractServiceList,
} from 'app/hooks/contract/list';
import { ColorInfo } from 'app/style/theme';
import { MessageConst } from 'app/utils/constants';
import { ButtonBack, ButtonForward } from 'app/views/components/block/button';
import {
  CheckIconChecked,
  CheckIcon,
} from 'app/views/components/block/checkbox';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import Template from 'app/views/components/template';
import { FC, memo, useEffect, useState, useContext } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import { useHistory } from 'react-router-dom';
import CorpResignCompletePage from './complete';
import CorpResignConfirmPage from './confirm';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    wrapper: {
      padding: '0 0',
    },
    title: {
      paddingLeft: 0,
    },
    subtitle: {
      marginTop: '80px',
      '& pre': {
        lineHeight: '40px',
      },
    },
    list: {
      // listStyle: 'disc',
      '& > li > ul > li': {
        listStyleType: 'none',
      },
      '& > li': {
        listStyleType: 'disc',
        paddingLeft: '12px',
        marginLeft: '20px',
        '& > *': {
          lineHeight: '35px',
        },
      },
    },
    attention: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${ColorInfo.red01}`,
      backgroundColor: ColorInfo.red02,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '15px',
      height: '180px',
      margin: '80px 0px 70px 0px',
    },
    agreeContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bold: {
      fontWeight: 'bold',
    },
    atention: {
      fontSize: '20px',
    },
    form: {
      '& .Mui-disabled': {
        color: ColorInfo.gray01,
      },
    },
  })
);

/**
 * 注意事項
 */
const ResignNoticeList: FC = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" component="h3">
        注意事項
      </Typography>
      <Box sx={{ margin: '5px 0px 5px 20px' }}>
        <ul className={classes.list}>
          <li>
            <Typography>
              ご契約（ご利用）中のサービスがある場合は、企業登録の解除（退会）ができません。
            </Typography>
            <Typography>
              事前にサービスの解約手続きを行ってください。
            </Typography>
          </li>
          <li>
            <Typography>
              退会（削除）手続きが完了した時点で、「企業情報」「企業ユーザー情報」などすべての情報が削除されます。
            </Typography>
            <Typography>
              手続き完了後に、復活させることはできません。
            </Typography>
          </li>
          <li>
            <Typography>
              企業ユーザー登録が解除されても、「TOYOTAアカウント」は引き続きご利用いただけます。
            </Typography>
          </li>
          <li>
            <Typography>
              再度、「TOYOTAアカウント」や企業名で、企業登録をされた場合も以前の情報はご確認いただけません。
            </Typography>
          </li>
        </ul>
      </Box>
    </>
  );
};

/**
 * ご契約(ご利用)中のサービス一覧
 */
const ServicesList: FC<{ list: ContractServiceInfo[] | undefined }> = ({
  list,
}) => {
  if (!list || list?.length === 0) {
    // サービス取得中 or サービス件数が0の場合は表示しない
    return <></>;
  }

  return (
    <>
      <Typography variant="h3" component="h3">
        ご契約（ご利用）中のサービス
      </Typography>
      <Box sx={{ margin: '5px 0px 5px 20px' }}>
        {list &&
          list?.map((row) => (
            <Typography variant="h4">
              {`${row.contractServiceName}${' '}${row.contractName}`}
            </Typography>
          ))}
      </Box>
    </>
  );
};

/**
 * 連携済みアカウント数 */
const CooperationAccounts: FC<{ corporationUsers: CorporationUsers }> = ({
  corporationUsers,
}) => (
  <>
    <Typography variant="h3" component="h3">
      企業に登録されている企業ユーザー
    </Typography>
    <Box sx={{ margin: '5px 0px 5px 20px' }}>
      <Typography variant="h4">{corporationUsers?.totalCount}件</Typography>
    </Box>
  </>
);

function ResignAttention() {
  const classes = useStyles();
  return (
    <Box className={classes.attention}>
      <Typography>
        現在、ご契約（ご利用）中のサービスがあるため、企業登録の解除（退会）は行えません。
      </Typography>
      <Typography style={{ marginTop: 30 }}>
        解約をご希望の場合は、「サービスに関するお問い合わせ」よりお問い合わせください。
      </Typography>
      <Typography>
        サービスをご解約後、改めて企業登録解除（退会）手続きを行ってください。
      </Typography>
    </Box>
  );
}

/**
 * 企業登録の解除 (退会)のページ
 */
const ContractResignPage: FC<{
  handleBack: () => void;
  handleNext: () => void;
  contractServiceList: ContractServiceInfo[] | undefined;
  corporationUsers: CorporationUsers;
  isDisable: boolean;
  agreeChecked: boolean;
  setAgreeChecked: (agreeChecked: boolean) => void;
}> = ({
  handleBack,
  handleNext,
  contractServiceList: services,
  corporationUsers,
  isDisable,
  agreeChecked,
  setAgreeChecked,
}) => {
  const classes = useStyles();

  // サービスの状況(undefined:取得前)
  const hasService = services === undefined ? true : services.length > 0;
  return (
    <div className={classes.wrapper}>
      <Typography align="left" className={classes.subtitle}>
        <pre>
          企業登録解除（退会）手続きを行います。
          <br />
          以下の注意事項をご確認いただき、同意のうえ「次へ」ボタンを押してください。
        </pre>
      </Typography>
      <ResignNoticeList />
      <Box sx={{ marginTop: '80px' }} />
      <CooperationAccounts corporationUsers={corporationUsers} />
      <Box sx={{ marginTop: '80px' }} />
      <ServicesList list={services} />

      {services != null && hasService && <ResignAttention />}

      <Box className={classes.agreeContainer}>
        <FormControlLabel
          className={classes.form}
          control={
            <Checkbox icon={<CheckIcon />} checkedIcon={<CheckIconChecked />} />
          }
          disabled={hasService || isDisable}
          label="すべての注意事項に同意する"
          value={agreeChecked}
          onChange={() => setAgreeChecked(!agreeChecked)}
        />
      </Box>
      <Box
        sx={{
          marginTop: '70px',
          marginBottom: '80px',
          border: 'solid',
          borderRight: 0,
          borderLeft: 0,
          borderBottom: 0,
          borderColor: ColorInfo.gray04,
        }}
      />
      <Grid
        container
        direction="row"
        style={{ marginTop: 40, marginBottom: 93 }}
      >
        {/* ボタン配置 */}
        <Grid item xs={3}>
          <ButtonBack onClick={handleBack}>キャンセル</ButtonBack>
        </Grid>

        <Grid item xs={6} style={{ padding: '0px 12px' }}>
          <ButtonForward
            onClick={handleNext}
            disabled={!agreeChecked || hasService}
            style={{ margin: 'auto' }}
          >
            次へ
          </ButtonForward>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </div>
  );
};
export const ContractResignRootPage: FC = memo(() => {
  const { status, handleNext, handleBack, globalMessage, setGlobalMessage } =
    useCorpDelete();

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  const corporationId = authInfo?.corporationId ? authInfo?.corporationId : '';

  const {
    contractServiceListAPI,
    contractServiceInfoReslt,
    contractServiceInfo,
  } = useContractServiceList();

  // サービス一覧取得
  useEffect(() => {
    // ページング無し
    void contractServiceListAPI(corporationId, 1, '999');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 法人ユーザ一覧業務Hooks(0:[条件なし])
  const { corporationUsers } = useCorporationUsers({ searchAuthType: '0' });

  useEffect(() => {
    if (!corporationUsers || !contractServiceInfoReslt) {
      setIsError(true);
      setGlobalMessage(MessageConst.NOACTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporationUsers, contractServiceInfoReslt]);

  const [agreeChecked, setAgreeChecked] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    // 確認⇒入力で戻ってきた際にagreeCheckedを初期化
    if (status === 0) {
      setAgreeChecked(false);
    }
  }, [status]);

  useEffect(() => {
    // ブラウザの進むで入力→確認に進んだ際にチェックが入っていなかったらバック
    if (status === 1) {
      if (!agreeChecked) {
        history.go(-1);
      }
    }
  }, [agreeChecked, history, status]);
  const [isError, setIsError] = useState<boolean>(false);
  return (
    <Template title="アカウント管理 - 企業登録解除（退会）">
      {status === 0 && (
        <>
          {globalMessage && <GlobalErrorMessage message={globalMessage} />}
          <ContractResignPage
            handleBack={handleBack}
            handleNext={handleNext}
            contractServiceList={contractServiceInfo}
            corporationUsers={corporationUsers}
            isDisable={isError}
            agreeChecked={agreeChecked}
            setAgreeChecked={setAgreeChecked}
          />
        </>
      )}
      {status === 1 && (
        <CorpResignConfirmPage
          handleBack={handleBack}
          handleNext={handleNext}
        />
      )}
      {status === 2 && <CorpResignCompletePage handleNext={handleNext} />}
    </Template>
  );
});
export default ContractResignRootPage;
