import Template from 'app/views/components/template';
import { Box } from '@material-ui/core';
import { FC } from 'react';
import { ButtonForward } from 'app/views/components/block/button';
import { PageInfo } from 'app/utils/constants';
import { useHistory } from 'react-router-dom';

const LinkDetachComplete: FC = () => {
  // ブラウズヒストリ
  const history = useHistory();

  // レンダリング
  return (
    // サービスの解除
    <Template title="サービスの解除">
      <Box sx={{ margin: '90px 0px 80px 0px', fontWeight: 'bold' }}>
        サービスを解除しました。
        <br />
        ご利用ありがとうございました。
      </Box>

      <ButtonForward
        style={{ margin: 'auto' }}
        onClick={() => history.push(PageInfo.TOP.path)}
      >
        トップへ
      </ButtonForward>
    </Template>
  );
};

export default LinkDetachComplete;
