import { ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  InviteDeleteRequest,
  InviteDeleteResponse,
} from 'app/service/customer/corporation/users/invite/delete';

/**
 * 招待削除業務カスタムHooks
 */
export const useInviteDelete = () => {
  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<InviteDeleteResponse>();

  // 招待削除API
  const inviteDeleteAPI = async (inviteId: string) => {
    const req: InviteDeleteRequest = {
      ...{
        inviteId,
      },
    };

    // 一覧API呼出し
    await callApi(ApiConst.CORP_USERS_INVITE_DELETE, 'POST', req);
  };

  return { response, error, inviteDeleteAPI };
};
export default useInviteDelete;
