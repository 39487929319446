import {
  FC,
  memo,
  useRef,
  useEffect,
  useState,
  useCallback,
  MouseEvent,
} from 'react';
import {
  Container,
  Toolbar,
  Typography,
  AppBar,
  Link as ALink,
  Link,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import theme, { ColorInfo } from 'app/style/theme';
import { OuterLinkInfo, PageInfo } from 'app/utils/constants';
import useDevice from 'app/hooks/device';
import onGaClick from 'app/utils/gaclick';
import { OuterBlankLink } from '../../atom/link';

const useStyles = makeStyles(() => ({
  footerArea: {
    width: '100%',
    marginTop: 105,
    padding: '70px 50px',
    backgroundColor: ColorInfo.gray05,
    position: 'relative',
    '&.simple': {
      padding: '25px 0',
    },
    '&.simple > p': {
      padding: 0,
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 40px 90px',
    },
  },
  footerInner: {
    width: '100%',
    height: 250,
    margin: '0 auto',
    padding: 0,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  footerContents: {
    width: '100%',
    padding: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  footerLogo: {
    width: 192,
    height: 'auto',
  },
  footerLink: {
    backgroundColor: 'none',
  },
  footerMenu: {
    display: 'flex',
    flexWrap: 'nowrap',
    '& ul + ul': {
      marginLeft: 90,
    },
    '& ul li': {
      padding: '0 0 8px',
      fontSize: '1.3rem',
      fontWeight: 500,
      letterSpacing: 1,
    },
    '& a:link, & a:visited': {
      color: ColorInfo.blue01,
      backgroundColor: 'transparent',
      fontSize: '1.3rem',
      textDecoration: 'none',
    },
    '& a:hover, & a:active': {
      backgroundColor: 'transparent',
      fontSize: '1.3rem',
      textDecoration: 'underline',
    },
  },
  copyContents: {
    width: '100%',
  },
  copy: {
    paddingTop: 18,
    fontSize: '1.3rem',
    color: ColorInfo.gray01,
    letterSpacing: 1,
  },
  pageTop: {
    position: 'fixed',
    right: 60,
    bottom: 50,
    textIndent: -9999,
    margin: 0,
    '& a': {
      position: 'relative',
      display: 'block',
      width: 30,
      height: 30,
      borderTop: `1px solid ${ColorInfo.blue01}`,
    },
    '& a::before': {
      position: 'absolute',
      top: 12,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'block',
      width: 20,
      height: 20,
      margin: 'auto',
      content: '""',
      transform: 'rotate(-45deg)',
      borderTop: `1px solid ${ColorInfo.blue01}`,
      borderRight: `1px solid ${ColorInfo.blue01}`,
      transition: 'top 0.2s',
    },
    '& a:hover::before': {
      top: 5,
    },
  },
  mobileLogo: {
    paddingBottom: 20,
  },
}));

const ScrollTop: FC = () => {
  const classes = useStyles();
  const [isTop, setIsTop] = useState<boolean>(true);

  const onScroll = useCallback(() => {
    setIsTop(window.scrollY === 0);
  }, []);

  const handleClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  if (isTop) {
    return <></>;
  }
  return (
    <div className={classes.pageTop}>
      <ALink href="#" onClick={handleClick}>
        Page Top
      </ALink>
    </div>
  );
};

interface FooterProps {
  isSimple?: boolean;
}

const Footer: FC<FooterProps> = memo(({ isSimple = false }) => {
  const classes = useStyles();
  const yearRef = useRef(new Date().getFullYear());
  const isMobile = useDevice();
  const contents = isSimple ? (
    <Typography className={classes.copy}>
      &copy;1995-{yearRef.current} TOYOTA MOTOR CORPORATION. ALL RIGHTS
      RESERVED.
    </Typography>
  ) : (
    <>
      <Toolbar className={classes.footerInner}>
        <Container
          className={classes.footerContents}
          style={isMobile ? { display: 'block' } : { display: 'flex' }}
        >
          <Box className={classes.footerLogo}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.svg`}
              alt="Toyota Biz Center"
              className={isMobile ? classes.mobileLogo : ''}
            />
          </Box>
          <Box className={classes.footerMenu}>
            <List>
              <ListItem>
                <OuterBlankLink
                  href={PageInfo.ATTRIBUTE.path}
                  text="サイト利用について"
                />
              </ListItem>
              <ListItem>
                <OuterBlankLink
                  href={`${OuterLinkInfo.TERMS_LINK_URL.path}${
                    process.env.REACT_APP_TERMS_BIZ_CENTER_CLIENT_ID || ''
                  }`}
                  text="Toyota Biz Center利用規約"
                  // GAタグ：フッタ-個人情報の取扱いについて押下
                  gaTagId="B-000-000-F-001"
                />
              </ListItem>
              <ListItem>
                <OuterBlankLink
                  href={OuterLinkInfo.PRIVACY_URL.path}
                  text="個人情報に関する基本方針"
                  // GAタグ：フッタ-個人情報の取扱いについて押下
                  gaTagId="B-000-000-F-002"
                />
              </ListItem>
              <ListItem>
                <OuterBlankLink
                  href={OuterLinkInfo.ID_TERM_URL.path}
                  text="TOYOTAアカウント利用規約"
                  // GAタグ：フッタ-TOYOTAアカウント利用規約押下
                  gaTagId="B-000-000-F-003"
                />
              </ListItem>
              <ListItem>
                <OuterBlankLink
                  href={OuterLinkInfo.ANTI_SOCIAL_URL.path}
                  text="反社会的勢力に対する基本方針"
                  // GAタグ：フッタ-反社会的勢力に対する基本方針押下
                  gaTagId="B-000-000-F-004"
                />
              </ListItem>
              <ListItem>
                <OuterBlankLink
                  href={OuterLinkInfo.CORP_INFO_URL.path}
                  text="企業情報"
                  // GAタグ：フッタ-企業情報押下
                  gaTagId="B-000-000-F-005"
                />
              </ListItem>
            </List>
          </Box>
        </Container>
        <Box className={classes.copyContents}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/logo_toyota.svg`}
              alt="TOYOTA"
            />
          </div>
          <Typography className={classes.copy}>
            &copy;1995-{yearRef.current} TOYOTA MOTOR CORPORATION. ALL RIGHTS
            RESERVED.
          </Typography>
        </Box>
      </Toolbar>
      <ScrollTop />
    </>
  );

  return (
    <AppBar
      className={`${classes.footerArea} ${isSimple ? 'simple' : ''}`}
      elevation={0}
      component="footer"
    >
      {contents}
    </AppBar>
  );
});

export default Footer;
