import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import theme, { ColorInfo } from 'app/style/theme';

const styles = () =>
  createStyles({
    root: {
      padding: '40px 0',
      '&:not(:first-child)': {
        borderTop: `1px solid ${ColorInfo.gray03}`,
      },
      '& a': {
        display: 'block',
        textDecoration: 'none',
        color: ColorInfo.blue01,
        transition: 'all 0.3s',
      },
      '& a:hover > div:first-of-type > div:first-child': {
        opacity: 0.7,
      },
      '& a:hover > h3': {
        color: ColorInfo.blue02,
      },
      '& a:hover  span': {
        color: ColorInfo.blue02,
        '&::before': {
          backgroundColor: ColorInfo.blue02,
        },
        '&::after': {
          borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
        },
      },
    },
    title: {
      fontSize: '2.4rem',
      lineHeight: 1.2,
      transition: 'color 0.3s',
    },
    contents: {
      marginTop: 25,
      display: 'flex',
      '& > div:first-child': {
        width: 430,
        transition: 'opacity 0.3s',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& > div:nth-child(2)': {
        flex: 1,
      },
    },
    image: {
      width: 430,
      transition: 'opacity 0.3s',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    imageSize: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    text: {
      // marginLeft: 15,
      flex: 1,
      lineHeight: 1.9,
    },
    gridStyle: {
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
      },
    },
    ar: {
      textAlign: 'right',
    },
    arrow: {
      position: 'relative',
      padding: '10px 105px 10px 0',
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      fontWeight: 500,
      verticalAlign: 'middle',
      transition: 'all 0.3s',
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
      },
      '&::before': {
        position: 'absolute',
        top: '50%',
        right: 15,
        content: "''",
        width: 70,
        height: 1,
        backgroundColor: ColorInfo.blue01,
      },
      '&::after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-6px)',
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 15px 7px 0',
        borderColor: `transparent transparent ${ColorInfo.blue01} transparent`,
        transition: 'all 0.3s',
      },
    },
  });

interface ServiceViewProps extends WithStyles<typeof styles> {
  name: string;
  href: string;
  image?: string;
  children: React.ReactNode;
}

const ServiceView = withStyles(styles)(
  ({ name, image, href, children, classes }: ServiceViewProps) => (
    <li className={classes.root}>
      <a href={href} target="_blank" rel="noreferrer">
        <h3 className={classes.title}>{name}</h3>
        <Grid container className={classes.gridStyle}>
          {/* <div className={classes.contents}> */}
          {image && (
            <Grid item xs={12} sm={6}>
              <div className={classes.image}>
                <img src={image} alt="" className={classes.imageSize} />
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography className={classes.text} component="div">
              {children}
              <p className={classes.ar} style={{ marginRight: '12px' }}>
                <span className={classes.arrow}>詳細を見る</span>
              </p>
            </Typography>
          </Grid>
          {/* </div> */}
        </Grid>
      </a>
    </li>
  )
);

export default ServiceView;
