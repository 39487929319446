import { FC, memo } from 'react';
import { Grid, InputLabel, makeStyles } from '@material-ui/core';
import { ColorInfo } from 'app/style/theme';

// インタフェース
interface InputRowGridProps {
  label: string;
  required?: boolean;
  labelList?: string[];
  className?: string;
}
// ファイルアップロード項目インタフェース
interface InputFileRowGridProps {
  labelList: string[];
}
// スタイル
const useStyles = makeStyles(() => ({
  span: {
    color: ColorInfo.red01,
  },
}));

// 入力項目レイアウト
const InputRowGrid: FC<InputRowGridProps> = memo(
  ({ label, children, required, labelList, className }) => {
    const classes = useStyles();
    return (
      <>
        <Grid container direction="row" spacing={3} className={className || ''}>
          <Grid item xs={2}>
            <InputLabel>
              {label}
              {!required && <span className={classes.span}>＊</span>}
            </InputLabel>
            {typeof labelList !== 'undefined' &&
              labelList.map((text, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <InputLabel key={index}>{text}</InputLabel>
              ))}
          </Grid>
          <Grid item xs={8}>
            {children}
          </Grid>
        </Grid>
      </>
    );
  }
);
InputRowGrid.defaultProps = { required: true };

// ファイルアップロード項目レイアウト。List形式で受け取る
const InputFileRowGrid: FC<InputFileRowGridProps> = memo(
  ({ labelList, children }) => (
    <Grid
      container
      direction="row"
      spacing={3}
      style={{ margin: 0, padding: 10 }}
    >
      <Grid item xs={3}>
        {labelList.map((label, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <InputLabel key={index}>{label}</InputLabel>
        ))}
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  )
);

// インタフェース
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ImageLinkGridProps {
  /* custom param */
}

// レイアウト
// TODO TOP画面の各サービスへのリンクなど（画面イメージが決まったら作成）
const ImageLinkGrid: FC<ImageLinkGridProps> = memo(({ children }) => (
  <>
    <Grid item xs={12} md={6}>
      {children}
    </Grid>
  </>
));

// 入力項目レイアウト 先頭スペースなし
const InputRowGridPadding: FC<InputRowGridProps> = memo(
  ({ label, children, required, labelList, className }) => {
    const classes = useStyles();
    return (
      <>
        <Grid
          container
          direction="row"
          spacing={3}
          className={className || ''}
          justifyContent="flex-start"
        >
          <Grid item xs={3}>
            <InputLabel>
              {label}
              {!required && <span className={classes.span}>＊</span>}
            </InputLabel>
            {typeof labelList !== 'undefined' &&
              labelList.map((text, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <InputLabel key={index}>{text}</InputLabel>
              ))}
          </Grid>
          <Grid item xs={8}>
            {children}
          </Grid>
        </Grid>
      </>
    );
  }
);
InputRowGrid.defaultProps = { required: true };

export { InputRowGrid, ImageLinkGrid, InputFileRowGrid, InputRowGridPadding };
