import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { ColorInfo } from 'app/style/theme';
import { FC } from 'react';
import { ButtonBack, ButtonForward } from 'app/views/components/block/button';

// スタイル
const useStyles = makeStyles(() => ({
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120px',
    marginTop: '60px',
  },
}));

const CorpResignConfirmPage: FC<{
  handleBack: () => void;
  handleNext: () => void;
}> = ({ handleBack, handleNext }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.description}>
        <Typography>
          以下の「企業登録の解除（退会）をする」ボタンを押すと、即時解除（退会）となります。
        </Typography>
        <Typography>
          ご登録いただいた情報や利用情報はすべて消去され、元に戻すことはできませんのでご注意ください。
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '40px',
          marginBottom: '80px',
          border: 'solid',
          borderRight: 0,
          borderLeft: 0,
          borderBottom: 0,
          borderColor: ColorInfo.gray04,
        }}
      />
      <Grid
        container
        direction="row"
        style={{ marginTop: 40, marginBottom: 93 }}
      >
        {/* ボタン配置 */}
        <Grid item xs={3}>
          <ButtonBack onClick={handleBack}>キャンセル</ButtonBack>
        </Grid>

        <Grid item xs={6} style={{ padding: '0px 12px' }}>
          <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
            解除（退会）をする
          </ButtonForward>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </>
  );
};

export default CorpResignConfirmPage;
