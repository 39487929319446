import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  UpdateResponse,
  UpdateRequest,
} from 'app/service/customer/corporation/users/regist';
import { ApiConst, PageInfo, UserTypeConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';

// ID連携要求情報
export interface CorpUsersInviteRegist {
  userId: string | null;
  inviteId: string;
}

/**
 * ID連携要求
 */
export const useCorpUsersInviteRegist = (inviteId: string) => {
  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<UpdateResponse>();

  // 認証コンテキスト
  const { setUserUpdate } = useContext(AuthContext);

  // hooksを一応用意しておく
  const [corpUsersInviteRegist, setCorpUsersInviteRegist] =
    useState<CorpUsersInviteRegist>({
      userId: '',
      inviteId: '',
    });

  const history = useHistory();

  // ID連携要求API
  const usersInviteRegistAPI = (inReq: UpdateRequest) => {
    // ID連携要求API呼出し
    void callApi(ApiConst.CORP_USERS_INVITE_REGIST, 'POST', inReq);
  };

  // ID連携応答処理
  useEffect(() => {
    if (response) {
      // 認証情報を更新(ID連携要求APIによる)
      setUserUpdate(response);

      // ID連携が完了した場合、マイページ画面へ遷移
      history.push(PageInfo.MYPAGE.path);
    } else if (error?.code) {
      /** 400エラーが来た場合、招待したユーザのメールアドレスとID連携したメールアドレスが異なる招待エラー画面へと遷移。処理終了 */
      history.push(
        `${PageInfo.CORP_USERS_INVITE_MAIL_ERROR.path}/${UserTypeConst.NOT_INVITED_USER}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    usersInviteRegistAPI,
    corpUsersInviteRegist,
    setCorpUsersInviteRegist,
  };
};
export default useCorpUsersInviteRegist;
