import { ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  CorpInfoEditCancelRequest,
  CorpInfoEditCancelResponse,
} from 'app/service/customer/corporation/info/edit/cancel';

/**
 * 申請取消カスタムHooks
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCorpCancel = () => {
  // API呼び出しカスタムHooks
  const {
    response: corpInfoEditCancelResponse,
    error: corpInfoEditCancelError,
    callApi,
  } = useApi<CorpInfoEditCancelResponse>();

  // 申請取消API
  const CorpInfoEditCancelAPI = async (corporationId: string) => {
    const req: CorpInfoEditCancelRequest = {
      ...{
        corporationId,
      },
    };

    // 申請取消API呼出し
    await callApi(ApiConst.CORP_USER_INFO_EDIT_CANCEL, 'POST', req);
  };

  return {
    corpInfoEditCancelResponse,
    corpInfoEditCancelError,
    CorpInfoEditCancelAPI,
  };
};
export default useCorpCancel;
