import { AccessTokenStorage } from 'app/hooks/oneId';
import { UpdateResponse } from 'app/service/customer/corporation/users/regist';
import { CorpResponse } from 'app/service/customer/corporation/apply';
import { AccessTokenResponse } from 'app/service/customer/oneId';
import { UserTypeConst } from 'app/utils/constants';
import { createContext, useState, useEffect } from 'react';
import { createHash } from 'crypto';

export interface AuthInfo {
  // 認証状態
  isAuth: boolean;
  // 申請状態（'0':申請前、'1':申請中、'2':申請済）
  applyStatus?: '0' | '1' | '2';

  // アクセストークン
  accessToken?: string;
  // ID連携状態
  userType?: string;
  // 法人/個人区分
  userTypeScim?: string;
  // 法人名 *
  corpName?: string;
  // 法人名（カナ）
  corpNameKana?: string;
  // 法人住所（郵便番号1）
  corpAddressPostalCode1?: string;
  // 法人住所（郵便番号2）
  corpAddressPostalCode2?: string;
  // 法人住所（都道府県）
  corpAddressRegion?: string;
  // 法人住所（市区町村）
  corpAddressLocality?: string;
  // 法人住所（番地）
  corpAddressStreet?: string;
  // 法人住所（建物）
  corpAddressBuilding?: string;

  // 部署名
  departmentName?: string;
  // 部署名（カナ）
  departmentNameKana?: string;

  // 法人ユーザID *
  scimId?: string;
  // 氏名 *
  name?: string;
  // 氏名（カナ）
  nameKana?: string;
  // メールアドレス
  email?: string;

  // 連絡用電話番号
  telephoneNumber1?: string;
  telephoneNumber2?: string;
  telephoneNumber3?: string;

  // 法人ID
  corporationId: string;

  // 承認権限
  authorityApproval?: string;
  // 付与権限
  authorityAssignment?: string;

  // 法人ステータス
  corporationStatus?: string;
}

export interface AuthInfoContext {
  /** アクセストークンの認証を開始前のページ描画処理を抑止するためのフラグで、`true`の時に抑止する。\
   * 初期表示時は`true`で、LocalStorageに保存したアクセストークンの認証が完了したとき、
   * またはLocalStorageにアクセストークンが設定されてない場合に `false` が設定される
   *  */
  ready: boolean;
  authInfo?: AuthInfo;
  setApplyStatus: (value: '0' | '1' | '2') => void;
  setUserInfo: (userInfo: AccessTokenResponse | null) => void;
  updateAccessToken: (accessToken: string) => void;
  setCorporationInfo: (corpInfo: CorpResponse | null, status: string) => void;
  setCorporationStatus: (status: string) => void;
  setUserUpdate: (userInfo: UpdateResponse | null) => void;
  setDepartmentName: (departmentName: string) => void;
  setAuthResign: () => void;
  setAuth: (authorityApproval: string, authorityAssignment: string) => void;
  /** アクセストークンの認証完了時にの場合に設定 */
  setReady: (ready: boolean) => void;
}

export const AuthContext = createContext<AuthInfoContext>(
  {} as AuthInfoContext
);

/**
 * AccessTokenResponseを認証情報に変換する。
 */
function fromAccessTokenResponse(
  userInfo: AccessTokenResponse | null
): AuthInfo {
  return {
    isAuth: !!userInfo,
    applyStatus: '1',
    accessToken: userInfo?.accessToken ? userInfo?.accessToken : '',
    userType: userInfo?.userType ? userInfo?.userType : '',
    userTypeScim: userInfo?.userTypeScim ? userInfo?.userTypeScim : '',
    corpName: userInfo?.corporationName ? userInfo?.corporationName : '',
    corpNameKana: userInfo?.corporationNameKana
      ? userInfo?.corporationNameKana
      : '',
    corpAddressPostalCode1: userInfo?.addressPostalCode1
      ? userInfo?.addressPostalCode1
      : '',
    corpAddressPostalCode2: userInfo?.addressPostalCode2
      ? userInfo?.addressPostalCode2
      : '',
    corpAddressRegion: userInfo?.addressRegion ? userInfo?.addressRegion : '',
    corpAddressLocality: userInfo?.addressLocality
      ? userInfo?.addressLocality
      : '',
    corpAddressStreet: userInfo?.addressStreetAddress
      ? userInfo?.addressStreetAddress
      : '',
    corpAddressBuilding: userInfo?.addressBuilding
      ? userInfo?.addressBuilding
      : '',
    departmentName: userInfo?.corporationDivision
      ? userInfo?.corporationDivision
      : '',
    departmentNameKana: userInfo?.corporationDivisionKana
      ? userInfo?.corporationDivisionKana
      : '',
    name: userInfo?.name ? userInfo?.name : '',
    nameKana: userInfo?.nameKana ? userInfo?.nameKana : '',
    email: userInfo?.email ? userInfo?.email : '',
    scimId: userInfo?.sub ? userInfo?.sub : '',
    telephoneNumber1: userInfo?.telephoneNumber1
      ? userInfo?.telephoneNumber1
      : '',
    telephoneNumber2: userInfo?.telephoneNumber2
      ? userInfo?.telephoneNumber2
      : '',
    telephoneNumber3: userInfo?.telephoneNumber3
      ? userInfo?.telephoneNumber3
      : '',
    corporationId: userInfo?.corporationId ? userInfo?.corporationId : '',
    authorityApproval: userInfo?.authorityApproval
      ? userInfo?.authorityApproval
      : '0',
    authorityAssignment: userInfo?.authorityAssignment
      ? userInfo?.authorityAssignment
      : '0',
    corporationStatus: userInfo?.corporationStatus
      ? userInfo?.corporationStatus
      : '',
  };
}

/**
 * 未認証時のAuthInfo
 */
const NO_AUTH_INFO: AuthInfo = {
  isAuth: false,
  applyStatus: '0',
  accessToken: '',
  userType: '',
  userTypeScim: '',
  corpName: '',
  corpNameKana: '',
  corpAddressPostalCode1: '',
  corpAddressPostalCode2: '',
  corpAddressRegion: '',
  corpAddressLocality: '',
  corpAddressStreet: '',
  corpAddressBuilding: '',
  departmentName: '',
  departmentNameKana: '',
  scimId: '',
  name: '',
  nameKana: '',
  email: '',
  telephoneNumber1: '',
  telephoneNumber2: '',
  telephoneNumber3: '',
  corporationId: '',
  authorityApproval: '0',
  authorityAssignment: '0',
  corporationStatus: '',
} as const;
export const AuthContextProvider: React.FC = ({ children }) => {
  const [ready, setReady] = useState<boolean>(true);
  const [authInfo, setAuthInfo] = useState<AuthInfo>(NO_AUTH_INFO);

  const setApplyStatus = (value: '0' | '1' | '2') => {
    setReady(false);
    setAuthInfo({
      ...authInfo,
      applyStatus: value,
    });
  };

  const setUserInfo = (userInfo: AccessTokenResponse | null) => {
    const info = fromAccessTokenResponse(userInfo);
    setAuthInfo({
      ...authInfo,
      ...info,
    });
    setReady(false);
    if (userInfo == null || (userInfo.accessToken ?? '') === '') {
      AccessTokenStorage.removeToken();
    }
  };

  const updateAccessToken = (accessToken: string) => {
    setAuthInfo({
      ...authInfo,
      accessToken,
    });
    setReady(false);
  };

  const setCorporationInfo = (
    corpInfo: CorpResponse | null,
    corporationStatus: string
  ) => {
    setAuthInfo({
      ...authInfo,
      corporationId: corpInfo?.corporationId ? corpInfo.corporationId : '',
      corporationStatus,
    });
    setReady(false);
  };

  const setCorporationStatus = (corporationStatus: string) => {
    setAuthInfo({
      ...authInfo,
      corporationStatus,
    });
    setReady(false);
  };

  const setUserUpdate = (userInfo: UpdateResponse | null) => {
    setAuthInfo({
      ...authInfo,
      corpName: userInfo?.corporationName ? userInfo?.corporationName : '',
      corpNameKana: userInfo?.corporationNameKana
        ? userInfo?.corporationNameKana
        : '',
      userType: userInfo?.userType ? userInfo?.userType : '',
      corporationId: userInfo?.corporationId ? userInfo?.corporationId : '',
    });
    setReady(false);
  };

  const setDepartmentName = (departmentName: string) => {
    setAuthInfo({
      ...authInfo,
      departmentName,
    });
    setReady(false);
  };

  const setAuth = (authorityApproval: string, authorityAssignment: string) => {
    setAuthInfo({
      ...authInfo,
      authorityApproval,
      authorityAssignment,
    });
    setReady(false);
  };

  // 退会時にS基盤固有の値を初期化しなおすhooks
  const setAuthResign = () => {
    if (authInfo?.userTypeScim === UserTypeConst.PERSON) {
      setAuthInfo({
        ...authInfo,
        // 個人区分の場合、法人ユーザ情報マスタにのみ「部署名」「部署名（カナ）」「会社名」「会社名（カナ）」を持つため初期化を行う
        corpName: '',
        corpNameKana: '',
        departmentName: '',
        departmentNameKana: '',
        // userTypeにはuserTypeScimを設定（corporationまたはperson）
        userType: authInfo?.userTypeScim,
        corporationId: '',
        authorityApproval: '0',
        authorityAssignment: '0',
        corporationStatus: '',
      });
    } else {
      setAuthInfo({
        ...authInfo,
        // userTypeにはuserTypeScimを設定（corporationまたはperson）
        userType: authInfo?.userTypeScim,
        corporationId: '',
        authorityApproval: '0',
        authorityAssignment: '0',
        corporationStatus: '',
      });
    }
  };
  // window.dataLayerにscimIdとclient_idを設定し、GAタグの発火時に通知する
  const onGaDataPush = (scimId: string | undefined) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      // scimIdをSHA256でハッシュ化して送信する
      luid: scimId ? createHash('sha256').update(scimId).digest('hex') : '',
      client_id: process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
        ? process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
        : '',
    });
  };

  // scimIdの変化を検知してログイン前後で発火されるuseEffect
  // ログイン前は空文字、ログイン後はscimIdをwindow.dataLayerに設定するため。
  // authInfo.scimIdをuseEffectで検知する
  useEffect(() => {
    // GAタグ
    onGaDataPush(authInfo?.scimId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo?.scimId]);

  // 下位コンポーネントへ渡す Context
  const context: AuthInfoContext = {
    ready,
    authInfo,
    setApplyStatus,
    setUserInfo,
    updateAccessToken,
    setCorporationInfo,
    setCorporationStatus,
    setUserUpdate,
    setDepartmentName,
    setAuthResign,
    setAuth,
    setReady,
  };
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
