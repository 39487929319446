import { useHistory } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { useEffect, useState } from 'react';
// import { Dimensions } from "react-native";

/**
 *デバイス判定
 */
export const useDevice = () => {
  const [isMobile, setIsMobile] = useState<boolean>();

  useEffect(() => {
    if (/iPhone|Android.+Mobile/.exec(navigator.userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return isMobile;
};
export default useDevice;
