import { ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import {
  ApprovalRequestRequest,
  ApprovalRequestResponse,
} from 'app/service/customer/corporation/users/approval/request';

/**
 * 承認リクエスト業務カスタムHooks
 */
export const useApprovalRequest = () => {
  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ApprovalRequestResponse>();

  // 承認リクエストAPI呼出し 承認の場合
  const approvalRequest = async (applyId: number) => {
    // API呼出し(許可）
    await approvalRequestAPI(applyId, '1');
  };

  // 承認リクエストAPI呼出し 拒否の場合
  const deleteRequest = async (applyId: number) => {
    // API呼出し（拒否）
    await approvalRequestAPI(applyId, '0');
  };

  // 承認リクエストAPI
  const approvalRequestAPI = async (
    applyId: number,
    permitRejectedFlg: string
  ) => {
    const req: ApprovalRequestRequest = {
      ...{
        applyId,
        permitRejectedFlg,
      },
    };

    // API呼出し
    await callApi(ApiConst.CORP_USERS_APPROVAL_REQUEST, 'POST', req);
  };

  return { response, error, approvalRequest, deleteRequest };
};
export default useApprovalRequest;
