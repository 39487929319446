import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ButtonForward } from 'app/views/components/block/button';
import { ColorInfo } from 'app/style/theme';
import useAuthDetail, { AuthData } from 'app/hooks/usage/contract/auth/detail';
import useAuthUpdate from 'app/hooks/usage/contract/auth/update';
import { MessageConst } from 'app/utils/constants';
import { AuthContext } from 'app/hooks/context/auth';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import {
  CheckIconChecked,
  CheckIcon,
} from 'app/views/components/block/checkbox';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick } from 'app/utils/dmp';
import { isTaggedTemplateExpression } from 'typescript';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 50,
    },
    firstTitle: {
      marginTop: 0,
    },
    errorMessage: {
      height: 70,
      paddingTop: 35,
    },
    buttonClose: {
      position: 'absolute',
      right: 40,
      top: 40,
    },
    iconStyle: {
      fontSize: 32,
      stroke: 'white',
      fontColor: ColorInfo.blue01,
    },
    assignCheck: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    assignCheckLabel: {
      marginTop: '16px',
    },
    assignCheckBox: {
      '& .MuiSvgIcon-root': {
        fontSize: 28,
      },
    },
    button: {
      width: 120,
      height: 42,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 10px',
      cursor: 'pointer',
      fontSize: '1.5rem',
      lineHeight: 1.4,
      letterSpacing: 2,
      color: ColorInfo.white01,
      textAlign: 'center',
      verticalAlign: 'middle',
      textDecoration: 'none',
      border: `2px solid ${ColorInfo.blue02}`,
      borderRadius: 4,
      backgroundColor: ColorInfo.blue02,
      transition: 'all 0.3s',
      '&:hover': {
        color: ColorInfo.white01,
        borderColor: ColorInfo.blue03,
        backgroundColor: ColorInfo.blue03,
      },
      '&:disabled': {
        color: ColorInfo.white01,
        borderColor: ColorInfo.gray02,
        backgroundColor: ColorInfo.gray02,
      },
    },
    buttonCancel: {
      color: ColorInfo.white01,
      borderColor: ColorInfo.gray02,
      backgroundColor: ColorInfo.gray02,
    },
    // 未選択
    buttonTransfer: {
      color: ColorInfo.gray02,
      backgroundColor: ColorInfo.gray05,
      borderColor: ColorInfo.gray05,
      '&:disabled': {
        color: ColorInfo.gray02,
        borderColor: ColorInfo.gray05,
        backgroundColor: ColorInfo.gray05,
      },
    },
    tableHead: {
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      padding: '20px 16px',
      lineHeight: 1,
      verticalAlign: 'middle',
      backgroundColor: ColorInfo.gray05,
      borderTop: `1px solid ${ColorInfo.gray03}`,
      borderBottom: `1px solid ${ColorInfo.gray03}`,
      borderLeft: `1px solid ${ColorInfo.gray03}`,
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    tableBody: {
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      padding: '10px 16px',
      borderBottom: `1px solid ${ColorInfo.gray03}`,
      borderLeft: `1px solid ${ColorInfo.gray03}`,
      '&:first-child': {
        borderLeft: 'none',
      },
    },
    tableRow: {
      height: 80,
      '& > *': {
        color: ColorInfo.blue01,
        fontSize: '1.5rem',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: `1px solid ${ColorInfo.gray03}`,
      },
      '& > *:first-child': {
        borderLeft: 'none',
      },
      '& th': {
        width: '40%',
        background: ColorInfo.gray05,
      },
    },
    tableRowList: {
      height: 70,
    },
    text: {
      margin: '0px 10px 10px 10px',
    },
  });

export interface AuthUpdateRequest {
  ownerId: string;
  licenseId: string;
  seqId: number;
  recipientId: string;
  serviceId: string;
  serviceAuthList: AuthData[];
}

interface UserInfoTableProps extends WithStyles<typeof styles> {
  email: string;
  name: string;
}

const UserInfoTable = withStyles(styles)(
  ({ name, email, classes }: UserInfoTableProps) => (
    <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell component="th">メールアドレス</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell component="th">氏名</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
);

interface UserAuthorityTableProps extends WithStyles<typeof styles> {
  serviceAuthList: AuthData[];
  scimId: string;
  administratorMail: string;
  onChange: (authList: AuthData) => void;
  onlyAssgin: boolean;
  isNowBottunDisabled: boolean;
}

const UserAuthorityTable = withStyles(styles)(
  ({
    serviceAuthList,
    scimId,
    administratorMail,
    onChange,
    onlyAssgin,
    classes,
    isNowBottunDisabled,
  }: UserAuthorityTableProps) => {
    // 認証コンテキスト
    const { authInfo } = useContext(AuthContext);
    // 自分が付与したサービスのみ表示
    const isHidden = (assignEmail: string): boolean => {
      // チェックが入っていないときは全て表示
      if (!onlyAssgin) {
        return false;
      }
      // チェックが入っていて、自分が付与していなかったら非表示
      if (authInfo?.email !== assignEmail) {
        return true;
      }
      return false;
    };

    /**
     * サービス利用トグルボタンの活性 / 非活性状態制御
     *
     * 下記の通り制御
     *
     * 1.自分が管理者 and 自分自身の利用権限....false(利用不可) ※利用権限フラグは関係なくfalse,管理者は自分自身の権限を変更できない
     * 2.自分が管理者 and 契約オプション変更フラグ=1 and 他人の利用権限....true(トグルボタン利用可、false)
     * 3.自分が管理者 and 契約オプション変更フラグ=0 and 他人の利用権限....false(利用不可)
     * 4.自分が非管理者 and 契約オプション変更フラグ=1....true(トグルボタン利用可、false)
     * 5.自分が非管理者 and 契約オプション変更フラグ=0....false(利用不可)
     * @returns boolean true=トグルボタン利用可、false=トグルボタン利用不可
     */
    const serviceUsageToggleControl = (auth: AuthData): boolean => {
      if (isNowBottunDisabled) {
        // 契約終了日を過ぎていたら利用不可
        return false;
      }
      // 自分が管理者 and 自分自身の利用権限....false(変更不可) ※利用権限フラグは関係なくfalse,管理者は自分自身の権限を変更できない
      if (
        administratorMail === authInfo?.email &&
        scimId === authInfo?.scimId
      ) {
        return false;
      }

      // 自分が管理者 and 契約オプション変更フラグ=1 and 他人の利用権限....true(変更可)
      if (
        administratorMail === authInfo?.email &&
        auth.contractOptionChangeFlag === '1' &&
        scimId !== authInfo?.scimId
      ) {
        return true;
      }

      // 自分が管理者 and 契約オプション変更フラグ=0 and 他人の利用権限....false(変更不可)
      if (
        administratorMail === authInfo?.email &&
        auth.contractOptionChangeFlag === '0' &&
        scimId !== authInfo?.scimId
      ) {
        return false;
      }

      // 自分が非管理者 and 契約オプション変更フラグ=1....true(変更可)
      if (
        administratorMail !== authInfo?.email &&
        auth.contractOptionChangeFlag === '1'
      ) {
        return true;
      }

      // 自分が非管理者 and 契約オプション変更フラグ=0....false(変更不可)
      if (
        administratorMail !== authInfo?.email &&
        auth.contractOptionChangeFlag === '0'
      ) {
        return false;
      }

      return false;
    };

    /**
     * 利用権限付与トグルボタンの活性 / 非活性状態制御
     *
     * 下記の通り制御
     *
     * 1.他人の利用権限 and 変更可否フラグ=1....true(利用可)
     * 2.上記以外....false(利用不可)
     * @returns boolean true=トグルボタン利用可、false=トグルボタン利用不可
     */
    const contractOptionUsageToggleControl = (auth: AuthData): boolean => {
      if (isNowBottunDisabled) {
        return false;
      }
      // 他人の利用権限 and 変更可否フラグ=1....true(利用可)
      if (
        scimId !== authInfo?.scimId &&
        auth.contractOptionChangeFlag === '1'
      ) {
        return true;
      }

      return false;
    };
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRowList}>
              <TableCell
                align="center"
                className={classes.tableHead}
                style={{ minWidth: '120px' }}
              >
                オプション
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHead}
                style={{ minWidth: '110px' }}
              >
                付与日
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHead}
                style={{ minWidth: '170px' }}
              >
                利用権限付与者
              </TableCell>
              <TableCell align="center" className={classes.tableHead}>
                利用権限
              </TableCell>
              <TableCell align="center" className={classes.tableHead}>
                利用権限付与機能
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceAuthList.map(
              (auth) =>
                !isHidden(auth.issuerMail) && (
                  <TableRow key={auth.indexId} className={classes.tableRowList}>
                    <TableCell className={classes.tableBody} width="100%">
                      {auth.contractOptionName}
                    </TableCell>
                    <TableCell className={classes.tableBody} width="100%">
                      {auth.licenseRegistAt}
                    </TableCell>
                    <TableCell className={classes.tableBody} width="100%">
                      {auth.issuerName}
                      <br />
                      <p style={{ whiteSpace: 'nowrap' }}>{auth.issuerMail}</p>
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      <ToggleButtonGroup
                        exclusive
                        onChange={(_, value: string) => {
                          if (value === '0')
                            onChange({
                              ...auth,
                              contractOptionUsageFlag: value,
                              authorityFlag: '0',
                            });
                          if (value === '1') {
                            if (
                              administratorMail !== authInfo?.email &&
                              scimId === authInfo?.scimId
                            ) {
                              onChange({
                                ...auth,
                                contractOptionUsageFlag: value,
                                authorityFlag: auth.authorityFlagOrigin,
                              });
                            } else {
                              onChange({
                                ...auth,
                                contractOptionUsageFlag: value,
                              });
                            }
                          }
                        }}
                      >
                        <ToggleButton
                          value="1"
                          className={`${classes.button} ${
                            auth.contractOptionUsageFlag === '0'
                              ? classes.buttonTransfer
                              : ''
                          }`}
                          disabled={!serviceUsageToggleControl(auth)}
                        >
                          有
                        </ToggleButton>
                        <ToggleButton
                          value="0"
                          className={`${classes.button} ${
                            auth.contractOptionUsageFlag !== '0'
                              ? classes.buttonTransfer
                              : ''
                          }`}
                          disabled={!serviceUsageToggleControl(auth)}
                        >
                          無
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      <ToggleButtonGroup
                        exclusive
                        onChange={(_, value: string) => {
                          if (value === '1')
                            onChange({
                              ...auth,
                              contractOptionUsageFlag: '1',
                              authorityFlag: value,
                            });
                          else onChange({ ...auth, authorityFlag: value });
                        }}
                      >
                        <ToggleButton
                          value="1"
                          className={`${classes.button} ${
                            auth.authorityFlag !== '1'
                              ? classes.buttonTransfer
                              : ''
                          }`}
                          disabled={!contractOptionUsageToggleControl(auth)}
                        >
                          有
                        </ToggleButton>
                        <ToggleButton
                          value="0"
                          className={`${classes.button} ${
                            auth.authorityFlag === '1'
                              ? classes.buttonTransfer
                              : ''
                          }`}
                          disabled={!contractOptionUsageToggleControl(auth)}
                        >
                          無
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

interface ContractDetailDialogProps extends WithStyles<typeof styles> {
  contractNo: string;
  contractMenuId: string;
  contractCourseId: string;
  scimId: string;
  email: string;
  administratorMail: string;
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setMainGlobalMessage: (mainGlobalMessage: string) => void;
  handleSearch: () => void;
  changeDisabled: boolean;
}

const ContractDetailDialog = withStyles(styles)(
  ({
    contractNo,
    contractMenuId,
    contractCourseId,
    scimId,
    email,
    administratorMail,
    name,
    open,
    setOpen,
    setMainGlobalMessage,
    classes,
    handleSearch,
    changeDisabled,
  }: ContractDetailDialogProps) => {
    const [serviceAuthList, setServiceAuthList] = useState<AuthData[]>([]);
    const { authResponse, error } = useAuthDetail(
      contractNo,
      contractMenuId,
      contractCourseId,
      scimId,
      open
    );
    // 認証コンテキスト
    const { authInfo } = useContext(AuthContext);

    const { updateAuth, authUpdateError, authUpdateResponse } = useAuthUpdate();
    // GlobalMessage情報
    const [globalMessage, setGlobalMessage] = useState<string>('');

    // 二重POST防止：OFFで初期化
    const processing = useRef(false);

    useEffect(() => {
      setMainGlobalMessage('');
      setGlobalMessage('');
      setOnlyAssign(false);
      // 二重POST防止：ダイアログ表示時にOFF
      processing.current = false;
      if (authResponse) {
        // ユニークの値として配列のindexをindexIdに設定する  , オリジンの利用可否フラグを設定する
        authResponse.map((auth, index) =>
          setServiceAuthIndexId(
            {
              ...auth,
              indexId: index,
              authorityFlagOrigin: auth.authorityFlag,
            },
            index
          )
        );
      } else if (error) {
        setMainGlobalMessage(MessageConst.ERROR_REFRESH);
        setOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, authResponse]);

    const setServiceAuthIndexId = (authData: AuthData, index: number) => {
      setServiceAuthList((prev) => {
        prev.splice(index, 1, authData);
        return [...prev];
      });
    };

    const handleServiceAuthChange = (authData: AuthData) => {
      setServiceAuthList((prev) => {
        prev.splice(
          prev.findIndex((auth) => auth.indexId === authData.indexId),
          1,
          authData
        );
        return [...prev];
      });
    };
    const handlerUpdateAuth = async () => {
      // GAタグ：サービス権限変更モーダル-権限を変更する押下
      onGaClick('B-050-002-002');
      // DMPビーコンタグ：サービス権限変更モーダル-権限を変更する押下
      OnDmpClick('B-050-002-002', authInfo?.scimId);
      if (!authResponse) return;

      // 二重POST防止：POST中なら処理せず
      if (processing.current) return;
      // 二重POST防止：POST中
      processing.current = true;

      const operatorId = authInfo?.scimId || '';
      await updateAuth({
        contractNo,
        contractMenuId,
        contractCourseId,
        scimId,
        operatorId,
        dataList: serviceAuthList,
      });
    };

    const isDisabled = (): boolean => {
      let contractOptionChangeFlag = true;
      if (changeDisabled) {
        return true;
      }
      serviceAuthList.forEach((auth) => {
        if (auth.contractOptionChangeFlag === '1') {
          contractOptionChangeFlag = false;
        }
      });
      // 全てのオプションが変更不可でも自分自身で、管理者で無ければ変更可
      if (contractOptionChangeFlag) {
        if (
          scimId === authInfo?.scimId &&
          administratorMail !== authInfo?.email
        ) {
          contractOptionChangeFlag = false;
        }
      }
      return contractOptionChangeFlag;
    };

    useEffect(() => {
      if (authUpdateResponse) {
        setGlobalMessage('');
        setOpen(false);
        // 権限変更の後は利用権限一覧情報を取得しなおす
        handleSearch();
      } else if (authUpdateError) {
        setGlobalMessage(authUpdateError.message);
        // 二重POST防止：ダイアログ表示したままのためここでOFF
        processing.current = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUpdateError, authUpdateResponse]);

    // 権限付与者のみ表示
    const [onlyAssign, setOnlyAssign] = useState(false);
    const handleAssignCheck = () => {
      setOnlyAssign(!onlyAssign);
    };
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <IconButton
          aria-label="close"
          className={classes.buttonClose}
          onClick={() => setOpen(false)}
          size="small"
        >
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
        <DialogContent className={classes.root}>
          <Box>
            <Typography variant="h3" className={classes.firstTitle}>
              利用者情報
            </Typography>
            <UserInfoTable email={email} name={name} />
            <Box className={classes.assignCheck}>
              <Typography variant="h3">利用権限</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="assgin"
                    icon={<CheckIcon />}
                    checkedIcon={<CheckIconChecked />}
                  />
                }
                label="権限を付与したサービス種別のみを表示する"
                value={onlyAssign}
                onChange={() => handleAssignCheck()}
                className={classes.assignCheckLabel}
              />
            </Box>
            <Typography variant="body1" className={classes.text}>
              利用権限：サービスを利用できる権限 ／
              利用権限付与機能：サービスの利用権限を他のユーザーに付与できる権限
              <br />
              利用権限付与者：サービスの利用権限付与者（権限の変更可能）
            </Typography>
            {authResponse && (
              <UserAuthorityTable
                serviceAuthList={serviceAuthList}
                scimId={scimId}
                administratorMail={administratorMail}
                onChange={handleServiceAuthChange}
                onlyAssgin={onlyAssign}
                isNowBottunDisabled={changeDisabled}
              />
            )}
          </Box>

          <Box className={classes.errorMessage}>
            {authUpdateError && (
              <Typography align="center" color="error">
                {globalMessage}
              </Typography>
            )}
          </Box>

          <DialogActions>
            <ButtonForward
              onClick={handlerUpdateAuth}
              style={{ margin: '0px auto -10px auto' }}
              disabled={isDisabled()}
            >
              権限を変更する
            </ButtonForward>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
);

export default ContractDetailDialog;
