import { FC } from 'react';
import Template from 'app/views/components/template';
import {
  Container,
  Typography,
  Link as ALink,
  makeStyles,
} from '@material-ui/core';
import { PageInfo } from 'app/utils/constants';
import useError from 'app/hooks/error';
import { useHistory } from 'react-router-dom';
import { ButtonForward } from 'app/views/components/block/button';

const ErrorPage: FC = () => {
  // 共通エラー業務カスタムHooks
  useError();
  const history = useHistory();

  // スタイル
  const useStyles = makeStyles(() => ({
    mainWrap: {
      marginTop: 90,
      marginBottom: 80,
    },
    textWrap: {
      marginTop: 15,
    },
  }));

  const classes = useStyles();

  // レンダリング
  return (
    <Template title="お探しのページが見つかりません。">
      <Container fixed>
        <div className={classes.mainWrap}>
          <Typography align="center">
            移動もしくは削除されたか、URLが正しく入力されていない可能性があります。
          </Typography>
          <Typography align="center" className={classes.textWrap}>
            このページをブックマーク登録されていた方は、
          </Typography>
          <Typography align="center">
            お手数ですが、ブックマークの変更をお願いいたします。
          </Typography>
          <Typography align="center" className={classes.textWrap}>
            なお、このページは5秒後にトップへ移動します。
          </Typography>
          <Typography align="center" color="error">
            自動的に移動しない場合は、以下のリンクをクリックしてください。
          </Typography>
        </div>
        <ButtonForward
          onClick={(event) => {
            event.preventDefault();
            history.push(PageInfo.TOP.path);
          }}
          style={{ margin: 'auto' }}
        >
          トップへ
        </ButtonForward>
      </Container>
    </Template>
  );
};
export default ErrorPage;
