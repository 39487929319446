import axios, { AxiosRequestConfig, Method } from 'axios';
import { useState, useContext } from 'react';
import {
  ApiConfig,
  SdpApConst,
  MessageConst,
  ErrorCodeContst,
} from 'app/utils/constants';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'app/hooks/context/auth';
import { ApiConst, PageInfo } from '../../utils/constants';

// Error Response
export interface ErrorDetailResponse {
  code: string;
  message: string;
  target: string;
}

export interface ErrorResponse {
  code: string;
  message: string;
  details?: ErrorDetailResponse[];
}

export function getAxiosRequestConfig(
  defaultConfig?: AxiosRequestConfig
): AxiosRequestConfig {
  // Request Config
  const config: AxiosRequestConfig = {
    baseURL: SdpApConst.HOST_URL,
    timeout: 70 * 1000,
    validateStatus: (status) => status <= 300 && status < 400, // 3xxは正常扱い
    ...defaultConfig,
  };
  return config;
}
const LOG_SDP_USER_ID_HEADER = 'sdp-user-id';
// API呼び出し用Hook
const useApi = <T>() => {
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState<ErrorResponse>();
  const history = useHistory();
  const { setUserInfo, authInfo, updateAccessToken } = useContext(AuthContext);

  // 最大リトライ回数
  const maxRetry = 1;
  // インターバル標準時間(ミリ秒)
  const intervalBase = 500;

  // API呼び出し
  const callApi = async (req: ApiConfig, method: Method, param: unknown) => {
    const headers = req.withoutAuth
      ? { ...req.headers, [LOG_SDP_USER_ID_HEADER]: authInfo?.scimId }
      : {
          ...req.headers,
          accessToken: authInfo?.accessToken,
          [LOG_SDP_USER_ID_HEADER]: authInfo?.scimId,
        };

    // Request Config
    const config: AxiosRequestConfig = {
      baseURL: SdpApConst.HOST_URL,
      url: req.url,
      method,
      headers,
      timeout: 70 * 1000,
      validateStatus: (status) => status <= 300 && status < 400, // 3xxは正常扱い
    };

    // GET
    if (['get', 'GET'].includes(method)) {
      config.params = param;
      // APIを投げる前にresponseとerrorを初期化する
      setResponse(undefined);
      setError(undefined);
      // 'PUT', 'POST', 'DELETE , and 'PATCH'
    } else {
      config.data = param;
      // APIを投げる前にresponseとerrorを初期化する
      setResponse(undefined);
      setError(undefined);
    }

    await execute(config, 0);
  };

  const execute = async (config: AxiosRequestConfig, count: number) => {
    // リトライ回数
    let retryCount = count;

    try {
      // Execute Request
      const res = await axios.request<T & { newAccessToken?: string }>(config);

      // eslint-disable-next-line
      const newAccessToken = res.headers['new-access-token'];
      if (newAccessToken) {
        updateAccessToken(newAccessToken);
      }

      setResponse(res.data || Object(Date.now()));

      // エラー応答
    } catch (err) {
      if (axios.isAxiosError(err)) {
        // エラー応答あり
        if (err.response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const errResponse: ErrorResponse = err.response.data;

          if (err.response.status === 400) {
            // 応答あり
            if (errResponse) {
              if (errResponse?.code) {
                // 入力値エラーのため、自画面にてメッセージ表示
                setError(errResponse);
              } else {
                // エラー電文が設定されていない予期せぬエラー
                setError({
                  code: ErrorCodeContst.NOACTION,
                  message: MessageConst.NOACTION,
                });
              }
              // 応答なし
            } else {
              setError({
                code: ErrorCodeContst.NOACTION,
                message: MessageConst.NOACTION,
              });
            }
          } else if (err.response.status === 401) {
            // 認証エラーはログアウトし、各コンソールのTOP画面に遷移
            await callApi(ApiConst.LOGOUT, 'POST', {});
            setUserInfo(null);
            history.push(PageInfo.TOP.path);
          } else if (err.response.status === 403) {
            // エラー画面遷移
            history.push(PageInfo.ERROR_500.path);
          } else if (err.response.status === 404) {
            // エラー画面遷移
            history.push(PageInfo.ERROR_404.path);
          } else if (err.response.status > 400 && err.response.status < 500) {
            // 処理を中止し、画面にエラーメッセージを表示
            setError({
              code: ErrorCodeContst.NOACTION,
              message: MessageConst.NOACTION,
            });
          } else {
            // インターバル
            const interval = 2 ** retryCount * intervalBase;
            // リトライ
            if (retryCount < maxRetry) {
              retryCount += 1;
              setTimeout(() => {
                void execute(config, retryCount);
              }, interval);
            } else {
              // リトライを実施し解消しない場合はエラー画面遷移
              history.push(PageInfo.ERROR_500.path);
            }
          }

          // エラー応答なし(レスポンスなし)
        } else {
          // 呼出し元画面上部にエラーメッセージを表示
          setError({
            code: ErrorCodeContst.NOACTION,
            message: MessageConst.NOACTION,
          });
        }
      }

      // そもそもAxiosErrorでない
      else {
        // インターバル
        const interval = 2 ** retryCount * intervalBase;
        // リトライ
        if (retryCount < maxRetry) {
          retryCount += 1;
          setTimeout(() => {
            void execute(config, retryCount);
          }, interval);
        } else {
          history.push(PageInfo.ERROR_500.path);
        }
      }
    }
  };

  return { response, error, callApi, setResponse };
};

export default useApi;
