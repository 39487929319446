import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  UpdateRequest,
  UpdateResponse,
} from 'app/service/customer/corporation/users/invite';
import {
  ApiConst,
  ErrorCodeContst,
  MessageConst,
  PageInfo,
} from 'app/utils/constants';
import useApi, { ErrorDetailResponse } from 'app/hooks/api';
import useValidate from 'app/hooks/validate';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

// ユーザ招待情報
export interface CorpUsersInvite {
  administratorId: string;
  mailAddress1: string;
  mailAddress2: string;
  mailAddress3: string;
  mailAddress4: string;
  mailAddress5: string;
}
export interface checkResponse {
  code: string;
  message: string;
  target: string;
}

/**
 * ユーザ招待業務カスタムHooks
 */
export const useCorpUsersInvite = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 申請状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);

  // ユーザ招待情報
  const [corpUsersInvite, setCorpUsersInvite] =
    // 初期値設定
    useState<CorpUsersInvite>({
      administratorId: '',
      mailAddress1: '',
      mailAddress2: '',
      mailAddress3: '',
      mailAddress4: '',
      mailAddress5: '',
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi, setResponse } = useApi<UpdateResponse>();

  // ValidateHooks
  const valid = useValidate();

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');
  // ValidationMessage情報
  const [validationMessage, setValidationMessage] = useState({
    mailsCheck: '',
    mailAddress1: '',
    mailAddress2: '',
    mailAddress3: '',
    mailAddress4: '',
    mailAddress5: '',
  });

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target?.value;
    const { name } = target;
    setCorpUsersInvite({
      ...corpUsersInvite,
      [name]: value,
    });
  };

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: PageInfo.CORP_USERS_INVITE_INPUT.path,
        confirm: PageInfo.CORP_USERS_INVITE_CONFIRM.path,
        complete: PageInfo.CORP_USERS_INVITE_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);

  useEffect(() => {
    // 初期処理

    // useState更新（企業ID登録情報をログインContextから初期設定）;
    setCorpUsersInvite({
      ...corpUsersInvite,
      // 管理者ID
      // メールアドレス1~5
      administratorId: '',
      mailAddress1: '',
      mailAddress2: '',
      mailAddress3: '',
      mailAddress4: '',
      mailAddress5: '',
    });

    // globalMessage初期化
    setGlobalMessage('');
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initValidationMessage = () => {
    // validationMessage初期化
    setValidationMessage({
      ...validationMessage,
      mailsCheck: '',
      mailAddress1: '',
      mailAddress2: '',
      mailAddress3: '',
      mailAddress4: '',
      mailAddress5: '',
    });
  };
  // 初期化
  const inicCorpUsersInvite = () => {
    setCorpUsersInvite({
      ...corpUsersInvite,
      // 管理者ID
      // メールアドレス1~5
      administratorId: '',
      mailAddress1: '',
      mailAddress2: '',
      mailAddress3: '',
      mailAddress4: '',
      mailAddress5: '',
    });
  };
  // 戻るアクション
  const history = useHistory();
  const handleBack = () => {
    // メッセージクリア
    setGlobalMessage('');

    history.go(-1);
  };

  useEffect(() => {
    if (status === 1) {
      if (inputCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, status]);
  // 次処理
  const handleNext = () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力チェック実施
        if (!inputCheck()) {
          // 入力チェックがOKだった場合に招待チェックAPIを呼び出し
          void usersInviteCheckAPI();
          // 実行後の処理はuseEffectで実施
        }
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        // 企業ID登録リクエスト呼び出し
        void usersInviteAPI();
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        nextPage();
        break;
    }
  };

  // ボタン押下時チェック
  const inputCheck = (): boolean => {
    initValidationMessage();
    let result = false;
    // メールアドレス 必須チェック
    let mailsCheckMessage = '';
    mailsCheckMessage = valid.oneOfMultipleRequiredCheck('メールアドレス', [
      corpUsersInvite?.mailAddress1,
      corpUsersInvite?.mailAddress2,
      corpUsersInvite?.mailAddress3,
      corpUsersInvite?.mailAddress4,
      corpUsersInvite?.mailAddress5,
    ]);
    result = result || !!mailsCheckMessage;

    let administratorMail1Message = '';
    // メールアドレス1バリデーションチェック
    administratorMail1Message =
      administratorMail1Message ||
      valid.mailValidation(
        { value: corpUsersInvite?.mailAddress1 },
        'メールアドレス'
      );
    result = result || !!administratorMail1Message;
    let administratorMail2Message = '';
    // メールアドレス2バリデーションチェック
    administratorMail2Message =
      administratorMail2Message ||
      valid.mailValidation(
        { value: corpUsersInvite?.mailAddress2 },
        'メールアドレス'
      );
    result = result || !!administratorMail2Message;
    let administratorMail3Message = '';
    // メールアドレス3バリデーションチェック
    administratorMail3Message =
      administratorMail3Message ||
      valid.mailValidation(
        { value: corpUsersInvite?.mailAddress3 },
        'メールアドレス'
      );
    result = result || !!administratorMail3Message;
    let administratorMail4Message = '';
    // メールアドレス4バリデーションチェック
    administratorMail4Message =
      administratorMail4Message ||
      valid.mailValidation(
        { value: corpUsersInvite?.mailAddress4 },
        'メールアドレス'
      );
    result = result || !!administratorMail4Message;
    let administratorMail5Message = '';
    // メールアドレス5バリデーションチェック
    administratorMail5Message =
      administratorMail5Message ||
      valid.mailValidation(
        { value: corpUsersInvite?.mailAddress5 },
        'メールアドレス'
      );
    result = result || !!administratorMail5Message;

    // バリデーションメッセージ設定
    setValidationMessage({
      ...validationMessage,
      mailsCheck: mailsCheckMessage,
      mailAddress1: administratorMail1Message,
      mailAddress2: administratorMail2Message,
      mailAddress3: administratorMail3Message,
      mailAddress4: administratorMail4Message,
      mailAddress5: administratorMail5Message,
    });
    if (result) {
      // グローバルメッセージ設定
      setGlobalMessage(MessageConst.GLOBAL_INOUT);
      window.scrollTo(0, 0);
    }
    return result;
  };

  // ユーザ招待API
  const usersInviteAPI = () => {
    // API Request設定
    const req: UpdateRequest = {
      applicantUserId: authInfo?.scimId || '',
    };

    if (corpUsersInvite?.mailAddress1)
      req.mailAddress1 = corpUsersInvite?.mailAddress1;
    if (corpUsersInvite?.mailAddress2)
      req.mailAddress2 = corpUsersInvite?.mailAddress2;
    if (corpUsersInvite?.mailAddress3)
      req.mailAddress3 = corpUsersInvite?.mailAddress3;
    if (corpUsersInvite?.mailAddress4)
      req.mailAddress4 = corpUsersInvite?.mailAddress4;
    if (corpUsersInvite?.mailAddress5)
      req.mailAddress5 = corpUsersInvite?.mailAddress5;

    // 登録API呼出し
    void callApi(ApiConst.CORP_USERS_INVITE, 'POST', req);
  };

  // ユーザ招待API
  const usersInviteCheckAPI = () => {
    // API Request設定
    const req: UpdateRequest = {
      applicantUserId: authInfo?.scimId || '',
    };

    if (corpUsersInvite?.mailAddress1)
      req.mailAddress1 = corpUsersInvite?.mailAddress1;
    if (corpUsersInvite?.mailAddress2)
      req.mailAddress2 = corpUsersInvite?.mailAddress2;
    if (corpUsersInvite?.mailAddress3)
      req.mailAddress3 = corpUsersInvite?.mailAddress3;
    if (corpUsersInvite?.mailAddress4)
      req.mailAddress4 = corpUsersInvite?.mailAddress4;
    if (corpUsersInvite?.mailAddress5)
      req.mailAddress5 = corpUsersInvite?.mailAddress5;

    // 登録API呼出し
    void callApi(ApiConst.CORP_USERS_INVITE_CHECK, 'POST', req);
  };

  // 画面遷移
  const nextPage = () => {
    // 次画面へ遷移（状態を加算）
    setStatus((preStatus) => preStatus + 1);
    switch (status) {
      case 0:
        // 確認画面への遷移はpush
        history.push(PageInfo.CORP_USERS_INVITE_CONFIRM.path);
        break;
      default:
        // アカウント管理ページへと遷移する。
        history.push(PageInfo.ACCOUNT_MANAGE.path);
        break;
    }
  };

  // corpRegistAPI()実行後処理 ※再レンダリング時処理
  useEffect(() => {
    if (error?.code) {
      // エラー発生により二重POST防止：OFF
      processing.current = false;
      if (status === 1) {
        // 応答電文を設定
        setGlobalMessage(error.message);
        // 確認画面に留まるか判定
        if (error?.code !== 'NOACTION') {
          // 入力画面へ戻る
          history.go(-1);
          window.scrollTo(0, 0);
        }
      } else if (status === 0) {
        // 応答電文を設定
        setGlobalMessage(error.message);
        if (error?.code === ErrorCodeContst.VALIDATION) {
          // ユーザ招待チェックAPIの結果を表示
          if (responseCheck(error?.details)) {
            /* 一つでも招待・登録済のメールアドレスが存在すればグローバルメッセージをバリデーションエラーに設定 */
            setGlobalMessage(MessageConst.GLOBAL_INOUT);
          }
        }
      }
      window.scrollTo(0, 0);
      // エラーコードを初期化する
      error.code = '';
    } else if (response) {
      // 正常
      if (status === 1) {
        // 二重POST防止：OFF
        processing.current = false;
        setStatus((preStatus) => preStatus + 1);
        // 完了画面はreplace
        history.replace(PageInfo.CORP_USERS_INVITE_COMPLETE.path);
        initValidationMessage();
        inicCorpUsersInvite();
      } else if (status === 0) {
        // メッセージクリア
        setGlobalMessage('');
        setStatus((preStatus) => preStatus + 1);
        // 確認画面はpush
        history.push(PageInfo.CORP_USERS_INVITE_CONFIRM.path);
      }
      /** responseを初期化する */
      setResponse(undefined);
    }
    // [browser]を除くため以下コメント追記
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error, history]);

  /* ユーザ招待チェックAPIの結果を表示する */
  const responseCheck = (
    details: ErrorDetailResponse[] | undefined
  ): boolean => {
    let result = false;
    if (details !== undefined) {
      let administratorMail1Message = '';
      let administratorMail2Message = '';
      let administratorMail3Message = '';
      let administratorMail4Message = '';
      let administratorMail5Message = '';

      details.forEach((detail) => {
        if (detail.target === 'mailAddress1') {
          result = true;
          administratorMail1Message = detail.message;
        } else if (detail.target === 'mailAddress2') {
          result = true;
          administratorMail2Message = detail.message;
        } else if (detail.target === 'mailAddress3') {
          result = true;
          administratorMail3Message = detail.message;
        } else if (detail.target === 'mailAddress4') {
          result = true;
          administratorMail4Message = detail.message;
        } else if (detail.target === 'mailAddress5') {
          result = true;
          administratorMail5Message = detail.message;
        }
      });
      setValidationMessage({
        ...validationMessage,
        mailAddress1: administratorMail1Message,
        mailAddress2: administratorMail2Message,
        mailAddress3: administratorMail3Message,
        mailAddress4: administratorMail4Message,
        mailAddress5: administratorMail5Message,
      });
    }
    return result;
  };
  return {
    status,
    corpUsersInvite,
    setCorpUsersInvite,
    setValidationMessage,
    handleInputEvent,
    handleNext,
    handleBack,
    globalMessage,
    validationMessage,
    setGlobalMessage,
  };
};
export default useCorpUsersInvite;
