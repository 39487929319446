/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import Template from 'app/views/components/template';
import {
  Box,
  Typography,
  Grid,
  Toolbar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SubButton,
  ButtonLowForward,
  ButtonLowBack,
  ButtonBack,
} from 'app/views/components/block/button';
import Button from '@material-ui/core/Button';
import TextInput from 'app/views/components/atom/text';
import Select from 'app/views/components/block/select';
import { PageInfo, getFaqUrl } from 'app/utils/constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid';
import { ColorInfo } from 'app/style/theme';
import ContractDetailDialog from 'app/views/components/modules/dialog/detail';
import { AuthList } from 'app/service/customer/usage/contract/auth/list';
import useContractAuthList, {
  ContractAuthList,
} from 'app/hooks/usage/contract/auth/list';
import {
  HelpIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  SortUnsortedIcon,
} from 'app/views/components/block/icon';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import onGaClick from 'app/utils/gaclick';
import useSortGrid from 'app/hooks/sortgrid';
import { OnDmpClick } from 'app/utils/dmp';
import { AuthContext } from 'app/hooks/context/auth';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 0 10px',
  },
  accTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 30,
    borderBottom: `1px solid ${ColorInfo.gray03}`,
  },
  mainText: {
    marginLeft: '22px',
    marginBottom: '25px',
  },
  tableRow: {
    height: 70,
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child > div': {
      width: 450,
    },
    '& > *:not(:first-child)': {
      marginLeft: 20,
      wordBreak: 'keep-all',
    },
  },
  listTableHead: {
    padding: '20px 16px',
    lineHeight: 1,
    verticalAlign: 'middle',
    backgroundColor: ColorInfo.gray05,
    borderTop: `1px solid ${ColorInfo.gray03}`,
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  listTableBody: {
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  listBtn: {
    width: 120,
    height: 42,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    cursor: 'pointer',
    fontSize: '1.5rem',
    lineHeight: 1.4,
    letterSpacing: 2,
    color: ColorInfo.white01,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    border: `2px solid ${ColorInfo.blue02}`,
    borderRadius: 4,
    backgroundColor: ColorInfo.blue02,
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  listBtnCancel: {
    color: ColorInfo.white01,
    borderColor: ColorInfo.gray02,
    backgroundColor: ColorInfo.gray02,
    '&:hover': {
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  listBtnTransfer: {
    color: ColorInfo.blue02,
    backgroundColor: ColorInfo.white01,
    '&:hover': {
      color: ColorInfo.white01,
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  listPager: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& button:first-child': {
      padding: '10px 0px 10px 65px',
      '&::before': {
        width: 30,
      },
    },
    '& button:last-child': {
      marginLeft: 60,
      padding: '10px 65px 10px 0px',
      '&::before': {
        width: 30,
      },
    },
    '& > *.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  contractName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '470px',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: '-10px',
    width: '500px',
  },
});

const useDataGridStyles = makeStyles({
  root: {
    marginTop: 15,
    border: 'none',
    borderTop: `1px solid ${ColorInfo.gray03}`,
    borderRadius: '0',
    '& *.MuiDataGrid-iconButtonContainer > *.MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  columnHeader: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    backgroundColor: ColorInfo.gray05,
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& *.MuiTouchRipple-root': {
      left: 10,
    },
  },
  cell: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  row: {
    height: 70,
    '&.MuiDataGrid-withBorder': {
      border: 'none',
    },
    '&.MuiDataGrid-row:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const options = [
  {
    value: '30',
    label: '30件',
  },
  {
    value: '50',
    label: '50件',
  },
  {
    value: '100',
    label: '100件',
  },
];
// インタフェース
interface AccountManageInterface {
  useContractAuth: useContractAuthListinterface;
  setMainGlobalMessage: (mainGlobalMessage: string) => void;
}
interface IdFederateInterface {
  useContractAuth: useContractAuthListinterface;
}
interface useContractAuthListinterface {
  isPageBackDisabled: boolean;
  isPageNextDisabled: boolean;
  isNowBottunDisabled: boolean;
  handleInputEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchBack: () => void;
  handleSearchNext: () => void;
  handleSearch: () => void;
  handleSearchDelete: () => void;
  contractAuthList: ContractAuthList;
  handleSearchSort: (
    sortItem: 'email' | 'name' | 'departmentName',
    sortType: 'asc' | 'desc'
  ) => void;
  apiCallStatus: number;
}

// ID連携コンポーネント
const IdFederate: FC<IdFederateInterface> = memo(({ useContractAuth }) => {
  const classes = useStyles();
  const { contractAuthList } = useContractAuth;
  return (
    <>
      <Typography variant="h3">企業管理者</Typography>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell align="center" className={classes.listTableHead}>
                <Typography variant="body1">メールアドレス</Typography>
              </TableCell>
              <TableCell align="center" className={classes.listTableHead}>
                <Typography variant="body1">氏名</Typography>
              </TableCell>
              <TableCell align="center" className={classes.listTableHead}>
                <Typography variant="body1">部署名</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.listTableBody}>
                <Typography variant="body1" align="left">
                  {contractAuthList.administratorMail}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.listTableBody}>
                <Typography variant="body1" align="center">
                  {contractAuthList.administratorName}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.listTableBody}>
                <Typography variant="body1" align="center">
                  {contractAuthList.contractDepartmentName}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

// 企業登録コンポーネント
const CorporationAccount: FC<AccountManageInterface> = memo(
  ({ useContractAuth, setMainGlobalMessage }) => {
    // 認証コンテキスト
    const { authInfo } = useContext(AuthContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<AuthList | undefined>(
      undefined
    );
    const {
      isPageBackDisabled,
      isPageNextDisabled,
      handleInputEvent,
      handleSearchBack,
      handleSearchNext,
      handleSearch,
      handleSearchDelete,
      contractAuthList,
      handleSearchSort,
      apiCallStatus,
      isNowBottunDisabled,
    } = useContractAuth;
    const { contractNo, contractMenuId, contractCourseId, rows } =
      contractAuthList;
    const classes = useStyles();
    const dataGridClasses = useDataGridStyles();
    // 次画面遷移用
    const history = useHistory();
    const userInvitePush = () => {
      history.push(
        `${PageInfo.CONTRACT_USERS_INVITE_INPUT.path}/${contractNo}/${contractMenuId}/${contractCourseId}`
      );
    };
    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'メールアドレス',
        sortable: true,
        width: 325,
        filterable: false,
        headerAlign: 'center',
      },
      {
        field: 'name',
        headerName: '氏名',
        sortable: true,
        width: 249,
        filterable: false,
        headerAlign: 'center',
      },
      {
        field: 'departmentName',
        headerName: '部署名',
        sortable: true,
        width: 249,
        filterable: false,
        headerAlign: 'center',
      },
      {
        field: 'authDetailChange',
        renderHeader: () => (
          <>
            <Typography style={{ lineHeight: '76px' }}>
              権限詳細/変更
              {/** 7月号口では非表示
              <Link
                href={getFaqUrl(4) || '/'}
                target="_blank"
                onClick={() => {
                  // GAタグ：権限詳細/変更？アイコン押下
                  onGaClick('B-050-002-004');
                  // DMPビーコンタグ：権限詳細/変更？アイコン押下
                  OnDmpClick('B-050-002-004', authInfo?.scimId);
                }}
              >
                <HelpIcon />
              </Link> */}
            </Typography>
          </>
        ),
        headerName: '権限詳細/変更',
        sortable: false,
        width: 175,
        filterable: false,
        renderCell: (params: GridCellParams) => (
          <Button
            className={classes.listBtn}
            onClick={() => {
              setSelectedUser(params.row as AuthList);
              setOpenDialog(true);
              // GAタグ：サービス権限変更-詳細ボタン押下
              onGaClick('B-050-002-001');
              // DMPビーコンタグ：サービス権限変更-詳細ボタン押下
              OnDmpClick('B-050-002-001', authInfo?.scimId);
            }}
          >
            詳細
          </Button>
        ),
        headerAlign: 'center',
        align: 'center',
      },
    ];

    // SortGrid Hooks
    const { sortModel, handleSortModelChange } = useSortGrid();

    // GridのSort変更時
    useEffect(() => {
      if (sortModel.length !== 1) {
        return;
      }

      // APIパラメータ生成 'email' | 'name' | 'departmentName'
      const sortedColumn = sortModel[0];
      let sortItem: 'email' | 'name' | 'departmentName' | null = null;
      if (sortedColumn.field === 'id') {
        // id
        sortItem = 'email';
      } else if (sortedColumn.field === 'name') {
        // 名前
        sortItem = 'name';
      } else if (sortedColumn.field === 'departmentName') {
        // 部署名
        sortItem = 'departmentName';
      }

      let sortType: 'asc' | 'desc' | null = null;
      if (sortedColumn.sort === 'asc') {
        sortType = 'asc';
      } else if (sortedColumn.sort === 'desc') {
        sortType = 'desc';
      }

      // API呼出し
      if (sortItem && sortType) handleSearchSort(sortItem, sortType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortModel]);

    return (
      <>
        <Box sx={{ marginTop: '55px' }} />
        <Box className={classes.accTitle}>
          <Typography variant="h3" style={{ margin: '25px 0 0' }}>
            サービス利用者
          </Typography>
          {contractAuthList.authorityFlag === '1' && (
            <SubButton onClick={userInvitePush} disabled={isNowBottunDisabled}>
              新規登録
            </SubButton>
          )}
        </Box>

        {apiCallStatus === 0 && (
          // API呼び出し未完了
          <></>
        )}
        {apiCallStatus === 1 && contractAuthList?.totalCount === 0 && (
          // 初期データ0件
          <div>
            <br />
            <Typography variant="body1" className={classes.mainText2}>
              現在、選択されたサービスの利用者はいません。
            </Typography>
            <br />
          </div>
        )}

        {((apiCallStatus === 1 && contractAuthList?.totalCount !== 0) ||
          apiCallStatus === 2 ||
          apiCallStatus === 3) && (
          // 初期データ1件以上 / 検索中 / 検索クリア中

          <div>
            <Box className={classes.search}>
              <Box className={classes.searchBox}>
                <TextInput
                  name="searchValue"
                  value={contractAuthList?.searchValue}
                  placeholder="入力してください（メールアドレス、氏名、部署名など）"
                  handleInputEvent={handleInputEvent}
                />
                <SubButton onClick={handleSearch}>検索</SubButton>
                <Link
                  component="button"
                  variant="body1"
                  onClick={handleSearchDelete}
                >
                  検索クリア
                </Link>
              </Box>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              style={{ marginTop: '25px', marginBottom: '15px', width: '100%' }}
            >
              <Grid>
                <Typography variant="body1" style={{ marginRight: 20 }}>
                  全{contractAuthList?.totalCount}件
                </Typography>
              </Grid>
              <Grid>
                <Select
                  name="size"
                  value={contractAuthList?.size}
                  handleInputEvent={handleInputEvent}
                  options={options}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {apiCallStatus === 2 && contractAuthList?.totalCount === 0 && (
          // 検索した結果データが0件
          <div>
            <br />
            <Typography
              variant="body1"
              className={classes.mainText2}
              style={{ marginTop: -10 }}
            >
              検索条件と一致するユーザー情報はありません。
            </Typography>
            <br />
          </div>
        )}

        {((apiCallStatus === 2 && contractAuthList?.totalCount !== 0) ||
          (apiCallStatus === 1 && contractAuthList?.totalCount !== 0) ||
          apiCallStatus === 3) &&
          rows !== undefined &&
          rows !== null && (
            <div>
              <DataGrid
                columns={columns}
                autoHeight
                hideFooter
                disableColumnMenu
                rows={contractAuthList.rows}
                headerHeight={70}
                rowHeight={70}
                components={{
                  ColumnSortedAscendingIcon: SortAscendingIcon,
                  ColumnSortedDescendingIcon: SortDescendingIcon,
                  ColumnUnsortedIcon: SortUnsortedIcon,
                }}
                classes={{
                  root: dataGridClasses.root,
                  columnHeader: dataGridClasses.columnHeader,
                  cell: dataGridClasses.cell,
                  row: dataGridClasses.row,
                }}
                sortingMode="server"
                onSortModelChange={(model) => handleSortModelChange(model)}
                sortModel={sortModel}
                sortingOrder={['asc', 'desc']}
              />
              <Toolbar
                disableGutters
                variant="dense"
                className={classes.listPager}
              >
                <ButtonLowBack
                  disabled={isPageBackDisabled}
                  onClick={handleSearchBack}
                  disableRipple
                >
                  前へ
                </ButtonLowBack>
                <ButtonLowForward
                  disabled={isPageNextDisabled}
                  onClick={handleSearchNext}
                  disableRipple
                >
                  次へ
                </ButtonLowForward>
              </Toolbar>
            </div>
          )}

        {selectedUser && (
          <ContractDetailDialog
            open={openDialog}
            setOpen={setOpenDialog}
            contractNo={contractNo}
            contractMenuId={contractMenuId}
            contractCourseId={contractCourseId}
            scimId={selectedUser.scimId}
            email={selectedUser.email}
            administratorMail={contractAuthList.administratorMail}
            name={selectedUser.name}
            setMainGlobalMessage={setMainGlobalMessage}
            handleSearch={handleSearch}
            changeDisabled={isNowBottunDisabled}
          />
        )}
      </>
    );
  }
);

// 利用権限管理コンポーネント
const ContractUsersManage: FC = memo(() => {
  const history = useHistory();
  // GlobalMessage情報
  const [mainGlobalMessage, setMainGlobalMessage] = useState<string>('');
  const useContractAuth = useContractAuthList();
  const {
    contractAuthList: { contractServiceName: serviceName, contractName: name },
  } = useContractAuth;
  const classes = useStyles();
  // 自動でブラウザバックした際に同じ位置に戻るのを防ぐ
  window.history.scrollRestoration = 'auto';

  interface params {
    contractNo: string;
    contractMenuId: string;
    contractCourseId: string;
  }

  const params = useParams<params>();

  const handleBack = () => {
    history.push(PageInfo.CONTRACT.path);
  };
  // URLの契約番号をglobalのwindowに保存しておく
  useEffect(() => {
    if (params.contractNo) window.sdpContractNo = params.contractNo;
  }, [params.contractNo]);

  useEffect(() => {
    if (mainGlobalMessage) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainGlobalMessage]);

  return (
    <Template title="契約内容照会 – 利用者管理">
      <GlobalErrorMessage message={mainGlobalMessage} />
      <Box style={{ marginTop: '90px', marginBottom: '80px' }}>
        <Typography>{serviceName}</Typography>
        <Typography style={{ fontSize: '2rem' }}>{name}</Typography>
      </Box>
      <IdFederate useContractAuth={useContractAuth} />
      <CorporationAccount
        useContractAuth={useContractAuth}
        setMainGlobalMessage={setMainGlobalMessage}
      />
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginBottom: '-30px' }}
      >
        <Grid item xs={3}>
          <ButtonBack onClick={() => handleBack()}>戻る</ButtonBack>
        </Grid>
        <Grid item xs={9} />
      </Grid>
    </Template>
  );
});

export default ContractUsersManage;
