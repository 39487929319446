import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Template from 'app/views/components/template';
import { Container, Typography, makeStyles } from '@material-ui/core';
import { PageInfo, MessageConst } from 'app/utils/constants';
import useError from 'app/hooks/error';
import { ButtonForward } from 'app/views/components/block/button';

const errorInfo: { pattern: string; message: string } = {
  pattern: 'ptn1',
  message: MessageConst.AUTH_ERROR,
};
const ErrorPage: FC = () => {
  // 共通エラー業務カスタムHooks
  useError();

  // ロケーション
  const location = useLocation();
  const { search } = location;
  const history = useHistory();

  // アクセストークンパラメータ取得
  const errorDetailMessage = () => {
    const pattern = new URLSearchParams(search).get('ptn') || '';
    switch (pattern) {
      case 'auth-error':
        return MessageConst.AUTH_ERROR;
      default:
        return '';
    }
  };

  // スタイル
  const useStyles = makeStyles(() => ({
    mainWrap: {
      marginTop: 90,
      marginBottom: 80,
    },
    textWrap: {
      marginTop: 15,
    },
  }));

  const classes = useStyles();

  // レンダリング
  return (
    <Template title="システムエラーが発生しました。">
      <Container fixed>
        <div className={classes.mainWrap}>
          <Typography
            align="center"
            color="error"
            style={{ fontSize: 20, marginBottom: '40px' }}
          >
            {errorDetailMessage()}
          </Typography>

          <Typography align="center">
            一時的に繋がりにくい状態になっています。
          </Typography>
          <Typography align="center">
            しばらく時間をおいてから再度アクセスしてください。
          </Typography>
          <Typography align="center" className={classes.textWrap}>
            なお、このページは5秒後にトップへ移動します。
          </Typography>
          <Typography align="center" color="error">
            自動的に移動しない場合は、以下のリンクをクリックしてください。
          </Typography>
        </div>
        <ButtonForward
          onClick={(event) => {
            event.preventDefault();
            history.push(PageInfo.TOP.path);
          }}
          style={{ margin: 'auto' }}
        >
          トップへ
        </ButtonForward>
      </Container>
    </Template>
  );
};

export default ErrorPage;
