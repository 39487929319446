import { AuthContext } from 'app/hooks/context/auth';
import { useEffect, useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApiConst,
  SdpApConst,
  PageInfo,
  UserTypeConst,
} from 'app/utils/constants';
import useApi, { getAxiosRequestConfig } from 'app/hooks/api';
import {
  AccessTokenRequest,
  AccessTokenResponse,
  OneIdAuthRequest,
} from 'app/service/customer/oneId';
import axios, { AxiosResponse } from 'axios';
import { initializeDMP } from 'app/utils/dmp';

/** `/api/v1/userInfo`のAPI処理 */
function fetchOneIdUserInfo(param: {
  accessToken: string;
}): Promise<AxiosResponse<AccessTokenResponse>> {
  return axios.post<AccessTokenResponse>(
    ApiConst.ONEID_USERINFO.url,
    { accessToken: param.accessToken },
    getAxiosRequestConfig()
  );
}

/** LocalStorageに保存するアクセストークン・OneIDのsub */
type UserInfoRequestParam = {
  /** アクセストークン */
  accessToken: string;
};

/** アクセストークンをLocalStorageで扱うためのクラス */
export class AccessTokenStorage {
  private static readonly ACCESS_TOKEN_STORAGE_KEY = 'tbiz';

  /**
   * LocalStorageに保存したアクセストークンを取得する。
   * 未設や不正なJSON形式だった場合は、`undefined`を返す。
   * @returns アクセストークン
   */
  static getAccessToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN_STORAGE_KEY);
  }

  /**
   * 認証の通ったアクセストークンをLocalStorageに保存する。
   * 値が空白だった場合は無効なトークンのため、保存しない
   */
  static setToken(accessToken: string): void {
    localStorage.setItem(this.ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }

  /**
   * ログアウト時や認証が無効になった時など、LocalStorageに保存したアクセストークンを削除する
   */
  static removeToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN_STORAGE_KEY);
  }
}

/**
 * ログイン関連カスタムHooks
 */
export const useOneId = () => {
  // 認証コンテキスト
  const { authInfo, setUserInfo, setReady } = useContext(AuthContext);

  // 認可エンドポイントの返却URLへのURI作成
  const createAuthEndpointURI = (
    next: string,
    prompt: string,
    visitId?: string
  ): string => {
    // 新認証基盤から本システムに戻る際のURI
    // パラメータ作成
    let param = null;
    param = `?${next}`;
    param =
      prompt && visitId
        ? `${param}&prompt=${prompt}&inviteId=${visitId}`
        : param;
    param = !prompt && visitId ? `${param}&inviteId=${visitId}` : param;
    param = prompt && !visitId ? `${param}&prompt=${prompt}` : param;

    // 認可エンドポイントの返却URLへのURI作成(エントリーポイント)
    const hostUrl = `${SdpApConst.HOST_PAGE_URL}${param}`;
    return encodeURIComponent(hostUrl);
  };

  // API呼び出しカスタムHooks
  const {
    response: responseApi,
    error,
    callApi,
  } = useApi<AccessTokenResponse>();
  const [response, setResponse] = useState<AccessTokenResponse | undefined>(
    undefined
  );

  // OneID基盤認可要求(ブラウザ GETリクエスト)
  const openIdConnectAPI = (req: OneIdAuthRequest) => {
    // axiosではなく、ブラウザGETを実施
    let path = SdpApConst.HOST_URL + ApiConst.ONEID_AUTH.url;
    path = `${path}?redirectUri=${req?.redirectUri}`;
    path = req.visitId ? `${path}&visitId=${req.visitId}` : path;
    path = req.prompt ? `${path}&prompt=${req.prompt}` : path;
    path = req.serviceId ? `${path}&serviceId=${req.serviceId}` : path;

    window.location.href = encodeURI(path);
  };

  // アクセストークン後の遷移
  // 0：なし
  // 2:取得結果より判断、3:TOP画面、4:企業申請入力画面、5：ID連携入力画面、6：XXXサービス画面
  const [afterTokenPtn, setAfterTokenPtn] = useState(0);
  const [afterTokenServiceId, setAfterTokenServiceId] = useState<string>('');
  const [afterVisitId, setAfterVisitId] = useState('');

  useEffect(() => {
    setResponse(responseApi);
  }, [responseApi]);

  // リクエスト実行(アクセストークン要求)
  const accessTokenAPI = (
    code: string,
    state: string,
    prompt?: string | null,
    visitId?: string | null,
    ptn?: number | null,
    options?: { [key: string]: string }
  ) => {
    if (ptn) setAfterTokenPtn(ptn);
    if (visitId) setAfterVisitId(visitId);
    if (options && options.serviceId) setAfterTokenServiceId(options.serviceId);

    // アクセストークン要求API Request設定
    const req: AccessTokenRequest = {
      code,
      state,
    };
    if (prompt) {
      req.prompt = prompt;
    }
    if (visitId) {
      req.visitId = visitId;
    }
    if (options?.serviceId) {
      req.serviceId = options.serviceId;
    }

    // API呼出し
    void callApi(ApiConst.ONEID_TOKEN, 'GET', req);
  };

  useEffect(() => {
    if (response?.accessToken != null) {
      AccessTokenStorage.setToken(response.accessToken);
    }
  }, [response]);

  const resAccessToken = response?.accessToken;
  const callUserInfo = useCallback((): Promise<void> => {
    if ((resAccessToken ?? '') !== '') {
      setReady(false);
      return Promise.resolve();
    }
    const token = AccessTokenStorage.getAccessToken();
    if (token == null) {
      setReady(false);
      // ・ロード時（画面リフレッシュ）に認証が存在しない場合（ログインIDが取得できない場合）のDMPビーコン発火
      initializeDMP('');
      return Promise.resolve();
    }
    return fetchOneIdUserInfo({
      accessToken: token,
    })
      .then((res) => {
        // 認証OK
        // ・ロード時（画面リフレッシュ）に認証が存在する場合
        // ・ログインして画面遷移してきた場合
        // の2パターンでのDMPビーコン発火
        initializeDMP(res.data.sub || '');
        setResponse(res.data);
      })
      .catch(() => {
        // 認証エラー
        setResponse(undefined);
        AccessTokenStorage.removeToken();
      })
      .finally(() => {
        // レンダリング待ちを解除
        setReady(false);
      });
  }, [resAccessToken, setReady]);

  // ログイン(招待)
  const eventLoginVisit = (visitId: string) => {
    const uri = createAuthEndpointURI('next=entry', 'login', visitId);
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
      prompt: 'login',
      visitId,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // ログイン(招待)（新規登録）
  const eventLoginVisitCreate = (visitId: string) => {
    const uri = createAuthEndpointURI('next=entry', 'create', visitId);
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
      prompt: 'create',
      visitId,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // ログイン(招待)（認可エンドポイントのみ実施）※招待時のID連携後の認可エンドポイント呼出し
  const eventLoginVisitOnlyCert = (visitId: string) => {
    const uri = createAuthEndpointURI('next=entry', '', visitId);
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
      prompt: '',
      visitId,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // ログイン
  const eventLogin = (prompt?: string) => {
    // 外部から呼び出す場合は、指定なし。
    // 本Hooksないから呼び出す場合、任意値を設定し、prompt=指定なしで呼び出す（ログイン系の2回目の認可エンドポイント呼び出し）
    const paraPrompt = prompt || '';
    const uri = createAuthEndpointURI('next=login', paraPrompt);
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
      prompt: paraPrompt,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // マイページ
  const eventMypage = (prompt?: string) => {
    // 外部から呼び出す場合は、指定なし。
    // 本Hooksないから呼び出す場合、任意値を設定し、prompt=指定なしで呼び出す（ログイン系の2回目の認可エンドポイント呼び出し）
    const paraPrompt = prompt || '';
    const uri = createAuthEndpointURI('next=mypage', paraPrompt);
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
      prompt: paraPrompt,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // ログイン（新規登録）
  const eventLoginCreate = () => {
    const uri = createAuthEndpointURI('next=login', 'create');
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
      prompt: 'create',
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // 企業申請（規約）
  const eventApplyTerms = () => {
    const uri = createAuthEndpointURI('next=corpApply', '');
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  // 企業ID連携（規約）
  const eventFederateTerms = () => {
    const uri = createAuthEndpointURI('next=idFederate', '');
    // OneID基盤認可要求API Request設定
    const req: OneIdAuthRequest = {
      redirectUri: uri,
    };

    // OneID基盤認可要求API
    openIdConnectAPI(req);
  };

  const eventServiceTerms = (
    serviceId: string,
    serviceUsageContractId: string
  ) => {
    // サービス利用画面API(codeが付与されていない場合、API側で500エラー画面に遷移)
    const requestUserId = authInfo?.scimId ? authInfo?.scimId : '';
    const uri = `${SdpApConst.HOST_URL}${ApiConst.SERVICE_USE.url}${requestUserId}/${serviceUsageContractId}`;

    const req: OneIdAuthRequest = {
      redirectUri: uri,
      serviceId,
    };
    openIdConnectAPI(req);
  };

  const history = useHistory();

  useEffect(() => {
    if (response) {
      // 正常
      setUserInfo(response);

      // アクセストークン後の遷移
      // 0：なし
      // 2:登録方法選択画面、3:TOP画面、4:企業申請入力画面、5：ID連携入力画面、5：ID連携入力画面、7：マイページ画面、
      // 9,10：再度、OneID基盤認可要求API呼出し（認可エンドポイントCall）
      // const [afterTokenPtn, setAfterTokenPtn] = useState(0);
      // 画面遷移
      switch (afterTokenPtn) {
        case 2:
          history.replace(PageInfo.REGISTRATION.path);
          break;
        case 3:
          if (UserTypeConst.CORP_PREMIUM === response.userType) {
            history.replace(`${PageInfo.MYPAGE.path}`);
          } else {
            history.replace(PageInfo.TOP.path);
          }
          break;
        case 4:
          history.replace(PageInfo.CORP_APPLY_INPUT.path);
          break;
        case 5:
          history.replace(PageInfo.CORP_FEDERATE_INPUT.path);
          break;
        case 6:
          history.replace(
            `${PageInfo.SERVICE_DETAIL.path}${afterTokenServiceId}`
          );
          break;
        case 7:
          history.replace(`${PageInfo.MYPAGE.path}`);
          break;
        case 9:
          if (UserTypeConst.CORP_PREMIUM === response.userType) {
            // ログイン（通常） 2回目の認可エンドポイント
            // promptパラメータを未設定にする
            eventLogin();
          } else {
            history.replace(PageInfo.TOP.path);
          }
          break;
        case 10:
          if (UserTypeConst.CORP_PREMIUM === response.userType) {
            // マイページ 2回目の認可エンドポイント
            // promptパラメータを未設定にする
            eventMypage();
          } else {
            history.replace(PageInfo.TOP.path);
          }
          break;
        default:
          break;
      }
    } else if (error?.code) {
      // API入力値エラー（アクセストークンAPI）
      // エラー画面遷移（500）
      history.push(`${PageInfo.ERROR_500.path}?ptn=auth-error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    callUserInfo,
    eventLogin,
    eventLoginCreate,
    eventLoginVisit,
    eventLoginVisitCreate,
    eventLoginVisitOnlyCert,
    eventApplyTerms,
    eventFederateTerms,
    eventServiceTerms,
    eventMypage,
    accessTokenAPI,
  };
};

export default useOneId;
