import { useState, useEffect, useContext } from 'react';
import useApi, { ErrorResponse } from 'app/hooks/api';
import { ApiConst } from 'app/utils/constants';
import { AuthContext } from 'app/hooks/context/auth';

export interface AuthData {
  contractOptionChangeFlag: string;
  contractOptionName: string;
  contractOptionUsageFlag: string;
  contractAuthorityFlag: string;
  authorityFlag: string;
  contractOptionId: string;
  contractOptionRegistFlag: string;
  seqId: string;
  // 付与日
  licenseRegistAt: string;
  // 付与者名
  issuerName: string;
  // 付与者ID
  issuerMail: string;
  // 画面表示処理用のindexId
  indexId: number;
  // 利用権限オリジナル
  authorityFlagOrigin: string;
}

const useAuthDetail = (
  contractNo: string,
  contractMenuId: string,
  contractCourseId: string,
  scimId: string,
  open: boolean
): {
  authResponse: AuthData[] | undefined;
  error: ErrorResponse | undefined;
} => {
  const { response, error, callApi } = useApi<{ dataList: AuthData[] }>();
  const { authInfo } = useContext(AuthContext);
  const [haveAuthData, setHaveAuthData] = useState<boolean>(false);

  useEffect(() => {
    if (!authInfo || !authInfo.corporationId || !authInfo.scimId) return;
    if (open) {
      void callApi(
        {
          url: `${ApiConst.CONTRACT_AUTH_DETAIL.url}/${authInfo.corporationId}/${contractNo}/${contractMenuId}/${contractCourseId}/${scimId}/${authInfo.scimId}`,
        },
        'GET',
        undefined
      );
      setHaveAuthData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (haveAuthData && response?.dataList) {
    const resDataList: AuthData[] = [];
    response?.dataList?.forEach((row) => {
      resDataList.push({
        contractOptionChangeFlag: row.contractOptionChangeFlag,
        contractOptionName: row.contractOptionName,
        contractOptionUsageFlag: row.contractOptionUsageFlag,
        contractAuthorityFlag: row.authorityFlag,
        authorityFlag: row.authorityFlag,
        contractOptionId: row.contractOptionId,
        contractOptionRegistFlag: row.contractOptionRegistFlag,
        seqId: row.seqId,
        licenseRegistAt: row.licenseRegistAt,
        issuerName: row.issuerName,
        issuerMail: row.issuerMail,
        indexId: row.indexId,
        authorityFlagOrigin: row.authorityFlagOrigin,
      });
    });

    if (response?.dataList) {
      response.dataList = resDataList;
    }

    setHaveAuthData(false);
  }

  const authResponse = response?.dataList;
  return { authResponse, error };
};

export default useAuthDetail;
