import { useEffect, useState, useContext } from 'react';
import { Request, Response } from 'app/service/contract/terms';
import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { AuthContext } from 'app/hooks/context/auth';

// サービス一覧
export interface ContractTermsItem {
  contractServiceName: string;
  clientId: string;
}
export interface ContractTerms {
  rows?: ContractTermsItem[] | null;
}

/**
 * 利用規約一覧業務カスタムHooks
 */
export const useContractTerms = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  // 法人ユーザ一覧情報
  const [contractTerms, setContractTerms] =
    // 初期値設定
    useState<ContractTerms>({
      rows: [],
    });
  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<Response>();

  // サービス一覧API
  const contractTermsAPI = () => {
    const scimId = authInfo?.scimId || '';

    // API Request設定
    const req: Request = {};

    // 一覧取得API呼出し
    const CONTRACT_TERMS: ApiConfig = {
      url: `/api/v1/usage/contract/services/terms/${scimId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };

    // サービス一覧取得API呼出し
    void callApi(CONTRACT_TERMS, 'GET', req);
  };

  useEffect(() => {
    // 初期処理
    setContractTerms({
      rows: [],
    });

    // サービス一覧取得
    contractTermsAPI();

    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // サービス一覧取得API実行後処理
  useEffect(() => {
    if (response) {
      // 正常時
      setContractTerms({
        rows: response.dataList,
      });
    } else if (error?.code) {
      // 異常時
      setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    contractTerms,
    globalMessage,
  };
};
export default useContractTerms;
