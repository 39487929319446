import React, { FC, useState, useContext } from 'react';
import Template from 'app/views/components/template';
import useOneId from 'app/hooks/oneId';
import { Grid, makeStyles, Button, Typography } from '@material-ui/core';
import { ButtonLowForward } from 'app/views/components/block/button';
import useServiceUseList from 'app/hooks/service/use';
import { ColorInfo } from 'app/style/theme';
import { useHistory } from 'react-router-dom';
import { GeneralServices, PageInfo } from 'app/utils/constants';
import { ServiceUseInfo } from 'app/service/contract/service/list';
import onGaClick from 'app/utils/gaclick';
import { AuthContext } from 'app/hooks/context/auth';
import { OnDmpClick } from 'app/utils/dmp';

// スタイル
const useStyles = makeStyles(() => ({
  twobuttonrs: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginTop: '80px',
    width: '100%',
    '& li': {
      width: '470px',
      height: '187px',
      '& button': {
        display: 'block',
        height: '100%',
        width: '100%',
        padding: '35px 40px',
        backgroundColor: ColorInfo.blue05,
        borderRadius: '4px',
        color: ColorInfo.blue02,
        textDecoration: 'none',
        transition: 'all 0.3s',
        '&:hover': {
          color: ColorInfo.white01,
          backgroundColor: ColorInfo.blue03,
        },
      },
    },
  },
  buttonrTitleText: {
    marginLeft: '15px',
    fontWeight: 600,
    fontSize: '1.8rem',
  },
  buttonrTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    position: 'relative',
    '& ::before': {
      position: 'absolute',
      top: '50%',
      right: '15px',
      content: '""',
      width: '35px',
      height: '1px',
      backgroundColor: 'currentColor',
      transition: 'all 0.3s',
    },
    '& ::after': {
      position: 'absolute',
      top: '50%',
      right: '0px',
      transform: 'translateY(-6px)',
      content: '""',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 15px 7px 0px',
      borderColor: 'transparent transparent currentColor transparent',
      transition: 'all 0.3s',
    },
    '&:hover::before': {
      color: 'currentColor',
    },
    '&:hover::after': {
      borderColor: `transparent transparent currentColor transparent`,
    },
    '& svg': {
      fill: 'currentColor',
    },
  },
  buttonText: {
    marginTop: '20px',
    letterSpacing: '1px',
    fontSize: '15px',
    textAlign: 'left',
  },
  ServiceDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
  },
  ServiceMethod: {
    width: '50%',
    marginBottom: '5px',
    color: ColorInfo.blue02,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '1.4rem',
  },
  ServiceMethodText: {
    minWidth: '80%',
    padding: '2% 10%',
    color: ColorInfo.blue02,
    border: `1px solid ${ColorInfo.blue02}`,
    textAlign: 'center',
  },
  UseDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  UseButton: {
    width: '50%',
    padding: '10px 40px 10px 0px',
    backgroundColor: 'transparent',
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    '&::before': {
      position: 'absolute',
      top: '50%',
      right: '15px',
      content: `""`,
      width: '30px',
      height: '1px',
      backgroundColor: 'currentColor',
      transition: 'all 0.3s',
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-6px)',
      content: `""`,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 15px 7px 0px',
      borderColor: `transparent transparent currentColor transparent`,
      transition: 'all 0.3s',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::before': {
      backgroundColor: 'currentColor',
    },
    '&:hover::after': {
      borderColor: `transparent transparent currentColor transparent`,
    },
  },
  service: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  mouseOver: {
    cursor: 'pointer',
    color: ColorInfo.blue02,
  },
  transition: {
    opacity: 0.7,
    transition: 'opacity 0.3s',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: '55px',
    width: '500px',
  },
  serviceUseGrid: {
    marginTop: '87px',
    marginBottom: '-57px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));
// ご利用中サービス画面
const ServiceUsePage: FC = () => {
  const { serviceUseInfoList, apiCallStatus } = useServiceUseList();
  const { rows } = serviceUseInfoList;
  const classes = useStyles();
  const history = useHistory();
  // マウスオーバー状態保持
  const [state, setState] = useState<number | undefined>(undefined);
  const mouseOverClass = (idx1: number, idx2: number) => {
    const cursole = Number(String(idx1) + String(idx2));
    return cursole;
  };
  // マウスオーバー時の処理
  const handleMouseOver = (index: number) => {
    setState(index);
  };
  const handleMouseLeave = () => {
    setState(undefined);
  };

  // 利用サービスへの転送
  const redirectToService = (url: string) => {
    window.open(url, '_blank');
  };

  // 契約内容照会アイコン
  const ContractManageIcon = () => (
    <svg
      viewBox="0 0 40 40"
      width="40"
      height="40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7.75" y="8" width="19" height="1.5" />
      <rect x="7.75" y="13" width="19" height="1.5" />
      <rect x="7.75" y="18" width="9" height="1.5" />
      <path d="M20.75,37.5h-16A2.74,2.74,0,0,1,2,34.77H2v-30A2.74,2.74,0,0,1,4.72,2h24A2.74,2.74,0,0,1,31.5,4.72v11H30v-11A1.26,1.26,0,0,0,28.76,3.5h-24A1.25,1.25,0,0,0,3.5,4.74h0v30A1.25,1.25,0,0,0,4.74,36h16Z" />
      <path d="M26.59,34.11a7.83,7.83,0,1,1,7.83-7.83A7.83,7.83,0,0,1,26.59,34.11Zm0-14.15a6.33,6.33,0,1,0,6.33,6.32A6.32,6.32,0,0,0,26.59,20Z" />
      <rect
        x="33.07"
        y="30.63"
        width="1.5"
        height="6.43"
        transform="translate(-14.02 33.83) rotate(-45)"
      />
    </svg>
  );
  // サービス紹介アイコン
  const ServiceExplainIcon = () => (
    <svg
      viewBox="0 0 40 40"
      width="40"
      height="40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.67,21a2.17,2.17,0,1,1,2.17-2.17A2.17,2.17,0,0,1,18.67,21Zm0-2.84a.67.67,0,1,0,.67.67A.67.67,0,0,0,18.67,18.18Z" />
      <path d="M18.67,26.71a2.17,2.17,0,1,1,2.17-2.17A2.17,2.17,0,0,1,18.67,26.71Zm0-2.84a.67.67,0,1,0,.67.67A.67.67,0,0,0,18.67,23.87Z" />
      <path d="M18.67,32.39a2.17,2.17,0,1,1,2.17-2.17A2.17,2.17,0,0,1,18.67,32.39Zm0-2.84a.67.67,0,1,0,.67.67A.67.67,0,0,0,18.67,29.55Z" />
      <rect x="21.75" y="18" width="10" height="1.5" />
      <rect x="21.75" y="24" width="10" height="1.5" />
      <rect x="21.75" y="29" width="10" height="1.5" />
      <path d="M36.5,39.5H11V9H36.5ZM12.5,38H35V10.5H12.5Z" />
      <polygon points="8.5 33.75 7 33.75 7 4 31.75 4 31.75 5.5 8.5 5.5 8.5 33.75" />
      <polygon points="4.5 29.75 3 29.75 3 0 27.75 0 27.75 1.5 4.5 1.5 4.5 29.75" />
    </svg>
  );
  // 改行の変換関数
  const DescriptionReplace = ({ text }: { text: string }) => {
    const texts = text.split(/(\\n)/).map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        {/\\n/.exec(item) ? <br /> : item}
      </React.Fragment>
    ));
    return <Typography>{texts}</Typography>;
  };
  // レスポンス表示関数
  const SeviceListContent = () => {
    const serviceList: (ServiceUseInfo[] | undefined)[] | undefined = [];
    // 1行表示用にサービス３つごと配列に入れる

    for (let i = 0; i < Math.ceil(Number(rows?.length) / 3); i += 1) {
      const j = i * 3;
      const p = rows?.slice(j, j + 3);
      serviceList.push(p);
    }

    const { authInfo } = useContext(AuthContext);

    if (apiCallStatus === 1) {
      if (serviceList.length === 0) {
        return (
          <Typography variant="body1" className={classes.mainText2}>
            現在、ご利用可能なサービスはありません。
          </Typography>
        );
      }
      return (
        /**
         * ・serviceListには、ServiceUseInfo[]が入っており、ServiceUseInfo[]は3つのServiceUseInfoごと入っている
         * ・サービス名と、契約名と、画像と、説明文章と、提供方式（API,Sシステム等）の行でそれぞれ別で回している
         * ・最後の行のサービスが1or2個の場合、余白を詰めるために空の要素挿入している
         *   最後の行のサービスが1個の場合、空の要素を2つ挿入
         *   最後の行のサービスが2個の場合、空の要素を1つ挿入
         */
        <Grid justifyContent="space-between" container alignItems="flex-start">
          {serviceList.map((serviceInfo, indexRow) => (
            <>
              {serviceInfo?.map((service, indexColumn) => (
                <>
                  {service.contractServiceScreenUse === '1' ? (
                    <Grid
                      item
                      container
                      xs={4}
                      className={`${classes.service} ${classes.mouseOver}`}
                      direction="column"
                      onClick={() => {
                        // GAタグ：ご利用中のサービス-各サービス押下
                        onGaClick('B-040-001-001');
                        // DMPビーコンタグ：ご利用中のサービス-各サービス押下
                        OnDmpClick('B-040-001-001', authInfo?.scimId);
                        redirectToService(service.contractServiceUri);
                      }}
                      onMouseOver={() =>
                        handleMouseOver(mouseOverClass(indexRow, indexColumn))
                      }
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={`${classes.service} ${
                            state === mouseOverClass(indexRow, indexColumn)
                              ? classes.mouseOver
                              : ''
                          }`}
                        >
                          {service.contractServiceName}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      className={classes.service}
                      item
                      container
                      xs={4}
                      direction="column"
                    >
                      <Grid item xs={12}>
                        <Typography className={classes.service}>
                          {service.contractServiceName}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
              {(serviceInfo?.length === 1 || serviceInfo?.length === 2) && (
                // 最後の行の契約が2つの場合は一つだけ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.length === 1 && (
                // 最後の行の契約が1つの場合は２つ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.map((service, indexColumn) => (
                <>
                  {service.contractServiceScreenUse === '1' ? (
                    <Grid
                      item
                      container
                      xs={4}
                      direction="column"
                      className={classes.service}
                      style={{ padding: '0px 12px' }}
                      onClick={() => {
                        // GAタグ：ご利用中のサービス-各サービス押下
                        onGaClick('B-040-001-001');
                        // DMPビーコンタグ：ご利用中のサービス-各サービス押下
                        OnDmpClick('B-040-001-001', authInfo?.scimId);
                        redirectToService(service.contractServiceUri);
                      }}
                      onMouseOver={() =>
                        handleMouseOver(mouseOverClass(indexRow, indexColumn))
                      }
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Grid item xs={12}>
                        <Typography
                          className={`${classes.service} ${
                            state === mouseOverClass(indexRow, indexColumn)
                              ? classes.mouseOver
                              : ''
                          }`}
                        >
                          {service.contractName}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      xs={4}
                      direction="column"
                      className={classes.service}
                      style={{ padding: '0px 12px' }}
                    >
                      <Grid item xs={12}>
                        <Typography className={classes.service}>
                          {service.contractName}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
              {(serviceInfo?.length === 1 || serviceInfo?.length === 2) && (
                // 最後の行の契約が2つの場合は一つだけ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.length === 1 && (
                // 最後の行の契約が1つの場合は２つ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.map((service, indexColumn) => (
                <>
                  {service.contractServiceScreenUse === '1' ? (
                    <Grid
                      item
                      container
                      xs={4}
                      direction="column"
                      className={classes.service}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        // GAタグ：ご利用中のサービス-各サービス押下
                        onGaClick('B-040-001-001');
                        // DMPビーコンタグ：ご利用中のサービス-各サービス押下
                        OnDmpClick('B-040-001-001', authInfo?.scimId);
                        redirectToService(service.contractServiceUri);
                      }}
                      onMouseOver={() =>
                        handleMouseOver(mouseOverClass(indexRow, indexColumn))
                      }
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Grid
                        item
                        xs={12}
                        className={
                          state === mouseOverClass(indexRow, indexColumn)
                            ? classes.transition
                            : ''
                        }
                      >
                        <img
                          style={{ margin: '0 auto' }}
                          src={`${GeneralServices.SERVICE_IMAGE_FILE_PATH}${service?.contractServiceImageFileName}`}
                          alt={service?.contractServiceName}
                          width="300"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      xs={4}
                      direction="column"
                      className={classes.service}
                    >
                      <Grid item xs={12}>
                        <img
                          style={{ margin: '0 auto' }}
                          src={`${GeneralServices.SERVICE_IMAGE_FILE_PATH}${service?.contractServiceImageFileName}`}
                          alt={service?.contractServiceName}
                          width="300"
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
              {(serviceInfo?.length === 1 || serviceInfo?.length === 2) && (
                // 最後の行の契約が2つの場合は一つだけ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.length === 1 && (
                // 最後の行の契約が1つの場合は２つ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.map((service, indexColumn) => (
                <>
                  {service.contractServiceScreenUse === '1' ? (
                    <Grid
                      item
                      container
                      xs={4}
                      direction="column"
                      alignItems="flex-start"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        // GAタグ：ご利用中のサービス-各サービス押下
                        onGaClick('B-040-001-001');
                        // DMPビーコンタグ：ご利用中のサービス-各サービス押下
                        OnDmpClick('B-040-001-001', authInfo?.scimId);
                        redirectToService(service.contractServiceUri);
                      }}
                      onMouseOver={() =>
                        handleMouseOver(mouseOverClass(indexRow, indexColumn))
                      }
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Grid
                        item
                        xs={12}
                        className={
                          state === mouseOverClass(indexRow, indexColumn)
                            ? classes.mouseOver
                            : ''
                        }
                      >
                        <DescriptionReplace
                          text={service?.contractServiceDescription}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      xs={4}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12}>
                        <DescriptionReplace
                          text={service?.contractServiceDescription}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
              {(serviceInfo?.length === 1 || serviceInfo?.length === 2) && (
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.length === 1 && (
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.map((service, indexColumn) => (
                <>
                  {service.contractServiceScreenUse === '1' ? (
                    <Grid
                      item
                      container
                      className={classes.ServiceDiv}
                      xs={4}
                      direction="column"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        // GAタグ：ご利用中のサービス-各サービス押下
                        onGaClick('B-040-001-001');
                        // DMPビーコンタグ：ご利用中のサービス-各サービス押下
                        OnDmpClick('B-040-001-001', authInfo?.scimId);
                        redirectToService(service.contractServiceUri);
                      }}
                      onMouseOver={() =>
                        handleMouseOver(mouseOverClass(indexRow, indexColumn))
                      }
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: '100%',
                          marginBottom: '50px',
                        }}
                      >
                        <Typography
                          className={classes.ServiceMethod}
                          style={{ float: 'left', paddingTop: 10 }}
                        >
                          <span className={classes.ServiceMethodText}>
                            {service?.contractServiceDeliveryMethod}
                          </span>
                        </Typography>
                        {service.contractServiceScreenUse === '1' && (
                          <ButtonLowForward
                            className={`${classes.UseButton} ${
                              state === mouseOverClass(indexRow, indexColumn)
                                ? classes.mouseOver
                                : ''
                            }`}
                          >
                            利用する
                          </ButtonLowForward>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      className={classes.ServiceDiv}
                      xs={4}
                      direction="column"
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: '100%',
                          marginBottom: '50px',
                        }}
                      >
                        <Typography
                          className={classes.ServiceMethod}
                          style={{ float: 'left', paddingTop: 10 }}
                        >
                          <span className={classes.ServiceMethodText}>
                            {service?.contractServiceDeliveryMethod}
                          </span>
                        </Typography>
                        {service.contractServiceScreenUse === '1' && (
                          <ButtonLowForward className={classes.UseButton}>
                            利用する
                          </ButtonLowForward>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
              {(serviceInfo?.length === 1 || serviceInfo?.length === 2) && (
                // 最後の行の契約が2つの場合は一つだけ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
              {serviceInfo?.length === 1 && (
                // 最後の行の契約が1つの場合は２つ空の要素を入れる
                <Grid item container xs={4} direction="column" />
              )}
            </>
          ))}
        </Grid>
      );
    }
    return <></>;
  };
  return (
    <Template title="ご利用可能なサービス">
      <Grid
        container
        justifyContent="flex-start"
        className={classes.serviceUseGrid}
      >
        {SeviceListContent()}
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        style={{ margin: '0 auto 0' }}
      >
        <ul className={classes.twobuttonrs}>
          <li>
            <Button
              name="contractManage"
              onClick={() => history.push(PageInfo.CONTRACT.path)}
            >
              <div className={classes.buttonrTitle}>
                <ContractManageIcon />
                <span className={classes.buttonrTitleText}>契約一覧</span>
              </div>
              <div className={classes.buttonText}>
                ご契約サービスの利用状況や契約内容の
                <br />
                ご確認はこちら。
              </div>
            </Button>
          </li>
          <li>
            <Button
              name="contractManage"
              onClick={() => history.push(PageInfo.INTRODUCE.path)}
            >
              <div className={classes.buttonrTitle}>
                <ServiceExplainIcon />
                <span className={classes.buttonrTitleText}>サービス紹介</span>
              </div>
              <div className={classes.buttonText}>
                各種サービス内容をご紹介します。
                <br />
                サービスのお申込みを検討中の方はこちら。
              </div>
            </Button>
          </li>
        </ul>
      </Grid>
    </Template>
  );
};

export default ServiceUsePage;
