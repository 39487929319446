import useApi, { ErrorResponse } from 'app/hooks/api';
import { ApiConst } from 'app/utils/constants';
import { AuthData } from 'app/hooks/usage/contract/auth/detail';

export interface ContractAuthUpdateRequest {
  contractNo: string;
  contractMenuId: string;
  contractCourseId: string;
  scimId: string;
  operatorId: string;
  dataList: AuthData[];
}

const useAuthUpdate = (): {
  updateAuth: (req: ContractAuthUpdateRequest) => Promise<void>;
  authUpdateError: ErrorResponse | undefined;
  authUpdateResponse: void | undefined;
} => {
  const {
    callApi,
    error: authUpdateError,
    response: authUpdateResponse,
  } = useApi<void>();

  const updateAuth = async (req: ContractAuthUpdateRequest) => {
    const updateDataList = req.dataList.map(
      ({
        contractOptionRegistFlag,
        seqId,
        contractOptionId,
        contractOptionUsageFlag,
        contractAuthorityFlag,
        authorityFlag,
      }) => ({
        contractOptionRegistFlag,
        seqId,
        contractOptionId,
        contractOptionUsageFlag,
        contractAuthorityFlag,
        authorityFlag,
      })
    );

    await callApi(
      {
        url: ApiConst.CONTRACT_AUTH_UPDATE.url,
      },
      'POST',
      {
        ...req,
        dataList: updateDataList,
      }
    );
  };
  return {
    updateAuth,
    authUpdateError,
    authUpdateResponse,
  };
};

export default useAuthUpdate;
