/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, forwardRef, RefObject, SetStateAction } from 'react';
import { Box, Grid, InputLabel, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SubButton } from 'app/views/components/block/button';
import { ColorInfo } from 'app/style/theme';
import useValidate from 'app/hooks/validate';

interface HiddenInputProps {
  onFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const HiddenInput = forwardRef<HTMLInputElement, HiddenInputProps>(
  ({ onFileInputChange }, inputRef) => (
    <input
      hidden
      ref={inputRef}
      type="file"
      accept="application/pdf"
      onChange={onFileInputChange}
    />
  )
);

interface FileUploadProps {
  inputRef: RefObject<HTMLInputElement>;
  file: File | undefined;
  setUploadFile: (event: SetStateAction<File | undefined>) => void;
  buttonName?: string | null;
  disabled?: boolean;
  uploadButton?: boolean;
  deleteButton?: boolean;
  fileNameList?: string[];
  setSameNameMessage?: (sameNameMessage: string) => void;
  uploadCallBack?: () => void;
  deleteCallBack?: () => void;
}

// ファイルアップロードボタン・ファイル名の表示
const FileUpload: FC<FileUploadProps> = ({
  inputRef,
  file,
  setUploadFile,
  buttonName,
  disabled,
  deleteButton,
  uploadButton,
  fileNameList,
  setSameNameMessage,
  uploadCallBack,
  deleteCallBack,
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      width: 'max-content',
    },
    label: {
      marginTop: 20,
      marginLeft: 10,
    },
    delete: {},
  }));
  const classes = useStyles();

  const valid = useValidate();

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSameNameMessageは任意なので定義されている場合のみ処理を行う
    // 初期化
    if (setSameNameMessage !== undefined) {
      setSameNameMessage('');
    }
    if (event.target && event.target.files?.length === 1) {
      // fileNameListが定義されている場合のみ重複チェック
      if (fileNameList) {
        // ファイルのリストに選択したファイル追加
        const validFileNameList = fileNameList;
        validFileNameList.push(event.target.files[0].name);

        // 重複チェック（結果はメッセージで返ってくる）
        let uploadFileMessage = '';
        uploadFileMessage =
          uploadFileMessage || valid.fileNameDuplicateCheck(validFileNameList);

        if (!uploadFileMessage) {
          setUploadFile(event.target.files[0]);
        } else {
          // 重複していた場合アップロードしないのでリストから排除
          validFileNameList.pop();
          // setSameNameMessageは任意なので定義されている場合のみ処理を行う
          if (setSameNameMessage !== undefined) {
            setSameNameMessage(uploadFileMessage);
          }
        }
      } else {
        // setSameNameMessageは任意なので定義されている場合のみ処理を行う
        if (setSameNameMessage !== undefined) {
          setSameNameMessage('');
        }
        setUploadFile(event.target.files[0]);
      }
    }
  };

  const fileUpload = () => {
    inputRef?.current?.click();

    if (typeof uploadCallBack === 'function') {
      uploadCallBack();
    }
  };

  const fileDelete = () => {
    setUploadFile(undefined);

    if (typeof deleteCallBack === 'function') {
      deleteCallBack();
    }
  };

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      alignItems="flex-start"
    >
      <Grid>
        {!disabled && uploadButton && (
          <>
            <HiddenInput onFileInputChange={onFileInputChange} ref={inputRef} />
            <SubButton onClick={fileUpload} style={{ width: 200 }}>
              {`${buttonName || '選択'}`}
            </SubButton>
          </>
        )}
      </Grid>
      {!disabled && deleteButton && file && (
        <div className={classes.delete}>
          <SubButton
            onClick={fileDelete}
            style={{
              width: 200,
              color: ColorInfo.white01,
              borderColor: ColorInfo.gray02,
              backgroundColor: ColorInfo.gray02,
            }}
          >
            {`${buttonName || '削除'}`}
          </SubButton>
        </div>
      )}
    </Grid>
  );
};
export default FileUpload;
