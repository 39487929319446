import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  UpdateRequest,
  UpdateResponse,
} from 'app/service/customer/corporation/id/federate';
import {
  ApiConst,
  PageInfo,
  MessageConst,
  ItemNameContst,
} from 'app/utils/constants';
import useApi, { ErrorDetailResponse } from 'app/hooks/api';
import useValidate from 'app/hooks/validate';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

// 企業ID登録情報
export interface CorporationIdFederate {
  administratorId: string;
  name: string;
}

/**
 * 企業ID登録業務カスタムHooks
 */
export const useCorpIdFederate = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // 申請状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);

  // 企業ID登録情報
  const [corporationIdFederate, setCorporationIdFederate] =
    // 初期値設定
    useState<CorporationIdFederate>({
      administratorId: '',
      name: '',
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<UpdateResponse>();

  // ValidateHooks
  const valid = useValidate();

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');
  // ValidationMessage情報
  const [validationMessage, setValidationMessage] = useState({
    administratorId: '',
  });

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target?.value;
    const { name } = target;
    setCorporationIdFederate({
      ...corporationIdFederate,
      [name]: value,
    });
  };

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: PageInfo.CORP_FEDERATE_INPUT.path,
        confirm: PageInfo.CORP_FEDERATE_CONFIRM.path,
        complete: PageInfo.CORP_FEDERATE_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);
  // 初期化関数
  const initCorporationIdFederate = () => {
    setCorporationIdFederate({
      ...corporationIdFederate,
      // 管理者ID
      // 名前（漢字）
      administratorId: '',
      name: authInfo?.name || '',
    });
  };
  // 初期化関数
  const initValidationMessage = () => {
    setValidationMessage({
      ...validationMessage,
      administratorId: '',
    });
  };
  useEffect(() => {
    // 初期処理

    // useState更新（企業ID登録情報をログインContextから初期設定）;
    setCorporationIdFederate({
      ...corporationIdFederate,
      // 管理者ID
      // 名前（漢字）
      administratorId: '',
      name: authInfo?.name || '',
    });

    // globalMessage初期化
    setGlobalMessage('');

    // validationMessage初期化
    setValidationMessage({
      ...validationMessage,
      administratorId: '',
    });

    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 戻るアクション
  const history = useHistory();
  const handleBack = () => {
    // メッセージクリア
    setGlobalMessage('');

    history.go(-1);
  };

  // 次処理
  const handleNext = () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力チェック実施
        if (inputCheck()) {
          // メッセージクリア
          setGlobalMessage('');
          // 遷移
          nextPage();
        }
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        // 企業ID登録リクエスト呼び出し
        void corpRegistAPI();
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        nextPage();
        break;
    }
  };

  useEffect(() => {
    if (status === 1) {
      if (!inputCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, status]);
  // 入力チェック
  const inputCheck = (): boolean => {
    initValidationMessage();
    let result = true;

    // 必須チェック
    let administratorIdMessage = '';
    administratorIdMessage = valid.requiredCheck(
      ItemNameContst.MAILADDRESS,
      corporationIdFederate?.administratorId
    );

    // 桁数チェック
    administratorIdMessage =
      administratorIdMessage ||
      valid.maxlengthCheck(
        ItemNameContst.MAILADDRESS,
        corporationIdFederate?.administratorId,
        60
      );

    // 形式チェック
    administratorIdMessage =
      administratorIdMessage ||
      valid.mailaddressCheck(corporationIdFederate?.administratorId);

    result = !administratorIdMessage;

    // バリデーションメッセージ設定
    setValidationMessage({
      ...validationMessage,
      administratorId: administratorIdMessage,
    });

    if (!result) {
      // グローバルメッセージ設定
      setGlobalMessage(MessageConst.GLOBAL_INOUT);
      window.scrollTo(0, 0);
    }

    return result;
  };

  // 入力時チェック
  const validationCheck = (): boolean => {
    let result = true;
    // メールアドレス形式チェック

    const administratorIdMessage = valid.mailaddressCheck(
      corporationIdFederate?.administratorId
    );

    result = !administratorIdMessage;

    // バリデーションメッセージ設定
    setValidationMessage({
      ...validationMessage,
      administratorId: administratorIdMessage,
    });

    return result;
  };

  // 法人ID登録API
  const corpRegistAPI = () => {
    // API Request設定
    const req: UpdateRequest = {
      applicantUserId: authInfo?.scimId || '',
      approvalEmail: corporationIdFederate?.administratorId,
      applicantEmail: authInfo?.email || '',
      applicantName: authInfo?.name || '',
      applicantDepartmentName: authInfo?.departmentName || '',
    };

    // 登録API呼出し
    void callApi(ApiConst.CORP_FEDERATE, 'POST', req);
  };

  // 画面遷移
  const nextPage = () => {
    // 次画面へ遷移（状態を加算）
    setStatus((preStatus) => preStatus + 1);
    switch (status) {
      case 0:
        // ブラウザバック対応 確認画面はpush
        history.push(PageInfo.CORP_FEDERATE_CONFIRM.path);
        break;
      default:
        history.push(PageInfo.TOP.path);
        break;
    }
  };

  // corpRegistAPI()実行後処理 ※再レンダリング時処理
  useEffect(() => {
    if (response) {
      // 正常
      setStatus((preStatus) => preStatus + 1);
      // ブラウザバック対応 完了画面はreplace
      history.replace(PageInfo.CORP_FEDERATE_COMPLETE.path);
      // 入力内容を破棄する
      initCorporationIdFederate();
      initValidationMessage();
      // ブラウザバックができるので二重POST防止：OFF
      processing.current = false;
      // API入力値エラー
    } else if (error?.code) {
      // エラー発生により二重POST防止：OFF
      processing.current = false;
      // 応答電文を設定
      setGlobalMessage(error.message);
      // エラー詳細を設定
      setErrorDetails(error?.details)

      // 確認画面に留まるか判定
      if (error?.code !== 'NOACTION') {
        // 入力画面へ戻る
        history.go(-1);
      }
      window.scrollTo(0, 0);
    }
    // [browser]を除くため以下コメント追記
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error, history]);

  const setErrorDetails = (
    details: ErrorDetailResponse[] | undefined
  ) => {
    if (details === undefined) {
      return;
    }

    let administratorIdMessage = '';
    details.forEach((detail) => {
      if (detail.target === 'administratorId') {
        administratorIdMessage = detail.message;
      }
    });
    
    setValidationMessage({
      ...validationMessage,
      administratorId: administratorIdMessage,
    });
  };

  return {
    status,
    corporationIdFederate,
    setCorporationIdFederate,
    handleInputEvent,
    handleNext,
    handleBack,
    validationCheck,
    globalMessage,
    validationMessage,
  };
};
export default useCorpIdFederate;
