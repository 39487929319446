import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  UpdateRequest,
  CorpResponse,
} from 'app/service/customer/corporation/apply';
import {
  ApiConst,
  CorporationStatusConst,
  PageInfo,
} from 'app/utils/constants';
import useApi from 'app/hooks/api';
import useValidate from 'app/hooks/validate';
import useZipCloudApi from 'app/hooks/zipcloud';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

// 企業申請情報
export interface CorporationApply {
  corporationUserId: string;
  corporationName: string;
  corporationNameKana: string;
  departmentName: string;
  departmentNameKana: string;
  corporationAddress: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  name: string;
  nameKana: string;
  emailAddress: string;
  birthday: Date | null;
  sex: string | null;
  telephoneNumber1: string;
  telephoneNumber2: string;
  telephoneNumber3: string;
  corpAddressPostalCode1: string;
  corpAddressPostalCode2: string;
  corpAddressRegion: string;
  corpAddressLocality: string;
  corpAddressStreet: string;
  corpAddressBuilding: string;
  applyReason: string;
  isEditable: boolean;
}
export interface FileData {
  filename: string | null;
  file: File | null;
}

// 企業申請メッセージ
export interface CorporationApplyMessage {
  corporationName: string;
  corporationNameKana: string;
  departmentName: string;
  departmentNameKana: string;
  corporationTelephoneNumber: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  corpAddressPostalCode: string;
  corpAddressRegion: string;
  corpAddressLocality: string;
  corpAddressStreet: string;
  corpAddressBuilding: string;
  uploadFile: string;
  applyReason: string;
}

export interface checkedItem {
  serviceId: string;
  serviceName: string;
  checked: boolean;
}
/**
 * 企業申請業務カスタムHooks
 */
export const useCorpApply = () => {
  // 認証コンテキスト
  const { authInfo, setApplyStatus, setCorporationInfo, setCorporationStatus } =
    useContext(AuthContext);

  // 申請状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);

  // 同じ名前かチェック
  const [sameFileNameMessage, setSameFileNameMessage] = useState<string>('');

  // 企業申請情報
  const [corporationApply, setCorporationApply] =
    // 初期値設定
    useState<CorporationApply>({
      corporationUserId: '',
      corporationName: '',
      corporationNameKana: '',
      departmentName: '',
      departmentNameKana: '',
      corporationAddress: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      name: '',
      nameKana: '',
      emailAddress: '',
      birthday: null,
      sex: null,
      telephoneNumber1: '',
      telephoneNumber2: '',
      telephoneNumber3: '',
      corpAddressPostalCode1: '',
      corpAddressPostalCode2: '',
      corpAddressRegion: '',
      corpAddressLocality: '',
      corpAddressStreet: '',
      corpAddressBuilding: '',
      applyReason: '',
      isEditable: false,
    });

  const initCorporationAppry = () => {
    // 住所情報結合
    const addressArray = [
      authInfo?.corpAddressRegion,
      authInfo?.corpAddressLocality,
      authInfo?.corpAddressStreet,
      authInfo?.corpAddressBuilding,
    ];

    let isCorporationUser = false;
    if (authInfo?.departmentName) {
      isCorporationUser = true;
    }
    // useState更新（企業申請情報をログインContextから初期設定）;
    setCorporationApply({
      ...corporationApply,
      // 法人ユーザID
      corporationUserId: authInfo?.scimId || '',
      // 企業名
      corporationName: authInfo?.corpName || '',
      // 企業名（カナ）
      corporationNameKana: authInfo?.corpNameKana || '',
      // 部署名
      departmentName: authInfo?.departmentName || '',
      // 部署名（カナ）
      departmentNameKana: authInfo?.departmentNameKana || '',
      // 住所
      corporationAddress: addressArray.join(''),
      // 名前（漢字）
      name: authInfo?.name || '',
      // 名前（カナ）
      nameKana: authInfo?.nameKana || '',
      // eメールアドレス
      emailAddress: authInfo?.email || '',
      // 電話番号
      telephoneNumber1: authInfo?.telephoneNumber1 || '',
      telephoneNumber2: authInfo?.telephoneNumber2 || '',
      telephoneNumber3: authInfo?.telephoneNumber3 || '',
      // 代表電話番号
      corporationTelephoneNumber1: authInfo?.telephoneNumber1 || '',
      corporationTelephoneNumber2: authInfo?.telephoneNumber2 || '',
      corporationTelephoneNumber3: authInfo?.telephoneNumber3 || '',
      // 郵便番号
      corpAddressPostalCode1: authInfo?.corpAddressPostalCode1 || '',
      corpAddressPostalCode2: authInfo?.corpAddressPostalCode2 || '',
      // 都道府県
      corpAddressRegion: authInfo?.corpAddressRegion || '',
      // 市区町村
      corpAddressLocality: authInfo?.corpAddressLocality || '',
      // 丁目番地
      corpAddressStreet: authInfo?.corpAddressStreet || '',
      // 建物名
      corpAddressBuilding: authInfo?.corpAddressBuilding || '',
      // 申請理由
      applyReason: '',
      // 部署名
      isEditable: isCorporationUser,
    });
    setUploadFile1(undefined);
    setUploadFile2(undefined);
    setUploadFile3(undefined);
    setUploadFile4(undefined);
    setUploadFile5(undefined);
  };
  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // 企業申請メッセージ
  const [corporationApplyMessage, setCorporationApplyMessage] =
    // 初期値設定
    useState<CorporationApplyMessage>({
      corporationName: '',
      corporationNameKana: '',
      departmentName: '',
      departmentNameKana: '',
      corporationTelephoneNumber: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      corpAddressPostalCode: '',
      corpAddressRegion: '',
      corpAddressLocality: '',
      corpAddressStreet: '',
      corpAddressBuilding: '',
      uploadFile: '',
      applyReason: '',
    });

  // 登記謄本ファイルアップロード情報
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const [uploadFile1, setUploadFile1] = useState<File>();
  const [uploadFile2, setUploadFile2] = useState<File>();
  const [uploadFile3, setUploadFile3] = useState<File>();
  const [uploadFile4, setUploadFile4] = useState<File>();
  const [uploadFile5, setUploadFile5] = useState<File>();

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<CorpResponse>();

  // checkedItemsは仮のサービス名をチェックした状態にしておく
  const checkedItems = [
    { serviceId: 'xxx', serviceName: 'yyy', checked: true },
  ];
  // ValidateHooks
  const valid = useValidate();

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setCorporationApply({
      ...corporationApply,
      [name]: value,
    });
  };

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: PageInfo.CORP_APPLY_INPUT.path,
        confirm: PageInfo.CORP_APPLY_CONFIRM.path,
        complete: PageInfo.CORP_APPLY_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);

  useEffect(() => {
    // 初期処理

    // 住所情報結合
    const addressArray = [
      authInfo?.corpAddressRegion,
      authInfo?.corpAddressLocality,
      authInfo?.corpAddressStreet,
      authInfo?.corpAddressBuilding,
    ];

    let isCorporationUser = false;
    if (authInfo?.departmentName) {
      isCorporationUser = true;
    }
    // useState更新（企業申請情報をログインContextから初期設定）;
    setCorporationApply({
      ...corporationApply,
      // 法人ユーザID
      corporationUserId: authInfo?.scimId || '',
      // 企業名
      corporationName: authInfo?.corpName || '',
      // 企業名（カナ）
      corporationNameKana: authInfo?.corpNameKana || '',
      // 部署名
      departmentName: authInfo?.departmentName || '',
      // 部署名（カナ）
      departmentNameKana: authInfo?.departmentNameKana || '',
      // 住所
      corporationAddress: addressArray.join(''),
      // 名前（漢字）
      name: authInfo?.name || '',
      // 名前（カナ）
      nameKana: authInfo?.nameKana || '',
      // eメールアドレス
      emailAddress: authInfo?.email || '',
      // 電話番号
      telephoneNumber1: authInfo?.telephoneNumber1 || '',
      telephoneNumber2: authInfo?.telephoneNumber2 || '',
      telephoneNumber3: authInfo?.telephoneNumber3 || '',
      // 代表電話番号
      corporationTelephoneNumber1: authInfo?.telephoneNumber1 || '',
      corporationTelephoneNumber2: authInfo?.telephoneNumber2 || '',
      corporationTelephoneNumber3: authInfo?.telephoneNumber3 || '',
      // 郵便番号
      corpAddressPostalCode1: authInfo?.corpAddressPostalCode1 || '',
      corpAddressPostalCode2: authInfo?.corpAddressPostalCode2 || '',
      // 都道府県
      corpAddressRegion: authInfo?.corpAddressRegion || '',
      // 市区町村
      corpAddressLocality: authInfo?.corpAddressLocality || '',
      // 丁目番地
      corpAddressStreet: authInfo?.corpAddressStreet || '',
      // 建物名
      corpAddressBuilding: authInfo?.corpAddressBuilding || '',
      // 部署名
      isEditable: isCorporationUser,
    });
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 戻るアクション
  const history = useHistory();
  const handleBack = () => {
    history.go(-1);
  };

  useEffect(() => {
    if (status === 1) {
      if (validateCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, status]);
  // 次処理
  const handleNext = async () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力画面の場合、入力チェック実施
        if (!validateCheck()) {
          nextPage();
        }
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        // 企業申請リクエスト呼び出し
        await corpRegistAPI();
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        nextPage();
        break;
    }
  };

  // 住所検索
  const handleGetAddress = async () => {
    if (!corporationApplyMessage?.corpAddressPostalCode) {
      // 検索API呼出し
      await getAddressAPI();
    }
  };

  // メッセージ初期化
  const initCorporationApplyMessage = () => {
    setCorporationApplyMessage({
      ...corporationApplyMessage,
      corporationName: '',
      corporationNameKana: '',
      departmentName: '',
      departmentNameKana: '',
      corporationTelephoneNumber: '',
      corpAddressPostalCode: '',
      corpAddressRegion: '',
      corpAddressLocality: '',
      corpAddressStreet: '',
      corpAddressBuilding: '',
      uploadFile: '',
      applyReason: '',
    });
  };

  // ボタン押下時入力チェック
  const validateCheck = (): boolean => {
    initCorporationApplyMessage();
    let result = false;
    // 企業名 バリデーションチェック
    const corporationNameMessage = valid.corporationNameValidation({
      value: corporationApply.corporationName,
      require: true,
    });
    result = result || !!corporationNameMessage;
    // 企業名(カナ) バリデーションチェック
    const corporationNameKanaMessage = valid.corporationNameKanaValidation({
      value: corporationApply.corporationNameKana,
      require: true,
    });
    result = result || !!corporationNameKanaMessage;
    // 部署名 バリデーションチェック
    const departmentNameMessage = valid.departmentNameValidation({
      value: corporationApply.departmentName,
      require: true,
    });
    result = result || !!departmentNameMessage;
    // 部署名(カナ) バリデーションチェック
    const departmentNameKanaMessage = valid.departmentNameValidation({
      value: corporationApply.departmentNameKana,
      require: true,
    });
    result = result || !!departmentNameKanaMessage;

    // 電話番号 バリデーションチェック
    const corporationTelephoneNumberMessageList =
      valid.corporationTelephoneNumberValidation(
        corporationApply.corporationTelephoneNumber1,
        corporationApply.corporationTelephoneNumber2,
        corporationApply.corporationTelephoneNumber3,
        true
      );
    const corporationTelephoneNumberMessage =
      corporationTelephoneNumberMessageList[0];
    const corporationTelephoneNumberMessage1 =
      corporationTelephoneNumberMessageList[1];
    const corporationTelephoneNumberMessage2 =
      corporationTelephoneNumberMessageList[2];
    const corporationTelephoneNumberMessage3 =
      corporationTelephoneNumberMessageList[3];

    result = result || !!corporationTelephoneNumberMessage;
    result = result || !!corporationTelephoneNumberMessage1;
    result = result || !!corporationTelephoneNumberMessage2;
    result = result || !!corporationTelephoneNumberMessage3;

    // 都道府県 バリデーションチェック
    const corpAddressRegionMessage = valid.corporationRegionValidation({
      value: corporationApply.corpAddressRegion,
      require: true,
    });
    result = result || !!corpAddressRegionMessage;

    // 市区町村 バリデーションチェック
    const corpAddressLocalityMessage =
      valid.corporationAddressLocalityValidation({
        value: corporationApply.corpAddressLocality,
        require: true,
      });
    result = result || !!corpAddressLocalityMessage;
    // 丁目番地 バリデーションチェック
    const corpAddressStreetMessage = valid.corporationAddressStreetValidation({
      value: corporationApply.corpAddressStreet,
      require: true,
    });
    result = result || !!corpAddressStreetMessage;
    // 建物名 バリデーションチェック
    const corpAddressBuildingMessage =
      valid.corporationAddressBuildingValidation({
        value: corporationApply.corpAddressBuilding,
      });
    result = result || !!corpAddressBuildingMessage;

    // 郵便番号 バリデーションチェック
    const corpAddressPostalCodeMessage =
      valid.corporationAddressPostalCodeValidation(
        corporationApply.corpAddressPostalCode1,
        corporationApply.corpAddressPostalCode2,
        true
      );
    result = result || !!corpAddressPostalCodeMessage;

    // アップロードファイル バリデーションチェック
    const fileNameList: (string | undefined)[] = [
      uploadFile1?.name,
      uploadFile2?.name,
      uploadFile3?.name,
      uploadFile4?.name,
      uploadFile5?.name,
    ];

    // アップロードファイル 最大サイズバリデーションチェック
    const fileSizeList: (number | undefined)[] = [
      uploadFile1?.size,
      uploadFile2?.size,
      uploadFile3?.size,
      uploadFile4?.size,
      uploadFile5?.size,
    ];

    const uploadFileMessage =
      valid.fileNameListValidation(fileNameList, true) ||
      valid.fileMaxSizeValidation(fileSizeList);

    result = result || !!uploadFileMessage;

    // 申請理由 バリデーションチェック
    const applyReasonMessage =
      valid.applyReasonValidation(
        { value: corporationApply.applyReason, require: true },
        '\r\n',
      );
    result = result || !!applyReasonMessage;

    // メッセージ設定
    setCorporationApplyMessage({
      ...corporationApplyMessage,
      corporationName: corporationNameMessage,
      corporationNameKana: corporationNameKanaMessage,
      departmentName: departmentNameMessage,
      departmentNameKana: departmentNameKanaMessage,
      corporationTelephoneNumber: corporationTelephoneNumberMessage,
      corporationTelephoneNumber1: corporationTelephoneNumberMessage1,
      corporationTelephoneNumber2: corporationTelephoneNumberMessage2,
      corporationTelephoneNumber3: corporationTelephoneNumberMessage3,
      corpAddressPostalCode: corpAddressPostalCodeMessage,
      corpAddressRegion: corpAddressRegionMessage,
      corpAddressLocality: corpAddressLocalityMessage,
      corpAddressStreet: corpAddressStreetMessage,
      corpAddressBuilding: corpAddressBuildingMessage,
      uploadFile: uploadFileMessage,
      applyReason: applyReasonMessage,
    });
    if (result) {
      // エラーメッセージ
      setGlobalMessage(
        `入力内容にエラーがあります。ご確認のうえ再度「確認」ボタンを押してください。`
      );
      window.scrollTo(0, 0);
    } else {
      setGlobalMessage('');
    }
    return result;
  };

  const fileList: FileData[] = [];
  const FileJoiin = (): FileData[] => {
    if (uploadFile1) {
      const file: FileData = {
        file: uploadFile1,
        filename: uploadFile1.name,
      };
      fileList.push(file);
    }
    if (uploadFile2) {
      const file: FileData = {
        file: uploadFile2,
        filename: uploadFile2.name,
      };
      fileList.push(file);
    }
    if (uploadFile3) {
      const file: FileData = {
        file: uploadFile3,
        filename: uploadFile3.name,
      };
      fileList.push(file);
    }
    if (uploadFile4) {
      const file: FileData = {
        file: uploadFile4,
        filename: uploadFile4.name,
      };
      fileList.push(file);
    }
    if (uploadFile5) {
      const file: FileData = {
        file: uploadFile5,
        filename: uploadFile5.name,
      };
      fileList.push(file);
    }
    return fileList;
  };
  // 法人情報登録API
  const corpRegistAPI = async () => {
    function serviceJoin() {
      const serviceList: string[] = [];
      checkedItems.forEach((item, index) => {
        if (item.checked) {
          serviceList.push(item.serviceName);
        }
      });
      return serviceList;
    }

    // API Request設定
    const req: UpdateRequest = {
      // APIを適宜設定
      ...{
        corporationUserId: corporationApply.corporationUserId,
        corporationName: corporationApply.corporationName,
        corporationNameKana: corporationApply.corporationNameKana,
        departmentName: corporationApply.departmentName,
        departmentNameKana: corporationApply.departmentNameKana,
        corporationTelephoneNumber1:
          corporationApply.corporationTelephoneNumber1,
        corporationTelephoneNumber2:
          corporationApply.corporationTelephoneNumber2,
        corporationTelephoneNumber3:
          corporationApply.corporationTelephoneNumber3,
        serviceList: serviceJoin(),
        registrationCopyFileList: FileJoiin(),
        corporationAddressPostalCode1: corporationApply.corpAddressPostalCode1,
        corporationAddressPostalCode2: corporationApply.corpAddressPostalCode2,
        corporationAddressRegion: corporationApply.corpAddressRegion,
        corporationAddressLocality: corporationApply.corpAddressLocality,
        corporationAddressStreet: corporationApply.corpAddressStreet,
        corporationAddressBuilding: corporationApply.corpAddressBuilding,
        applyReason: corporationApply.applyReason,
      },
    };
    // リクエストパラメータ生成
    const params = new FormData();
    Object.entries(req).forEach((param, index) => {
      // 添付ファイルに関しては整形する
      if (param[0] === 'registrationCopyFileList') {
        if (Array.isArray(param[1])) {
          param[1].forEach(
            (
              subParam: {
                filename: string;
                file: File;
              },
              subIndex
            ) => {
              Object.entries(subParam).forEach((sub) => {
                if (sub[1]) {
                  params.append(
                    `registrationCopyFileList[${subIndex}].${sub[0]}`,
                    sub[1]
                  );
                }
              });
            }
          );
        }
      } else if (param[1]) {
        params.append(param[0], param[1]);
      }
    });

    // 登録API呼出し
    await callApi(ApiConst.CORP_APPLY, 'POST', params);

    setApplyStatus('1');
  };

  // 画面遷移
  const nextPage = () => {
    // 次画面へ遷移（状態を加算）
    setStatus((preStatus) => preStatus + 1);
    switch (status) {
      case 0:
        // ブラウザバック対応 確認画面への遷移はpush
        history.push(PageInfo.CORP_APPLY_CONFIRM.path);
        break;
      case 1:
        // ブラウザバック対応 完了画面への遷移はreplace
        history.replace(PageInfo.CORP_APPLY_COMPLETE.path);
        break;
      default:
        history.push(PageInfo.TOP.path);
        break;
    }
  };

  const refreshCorpAddress = useRef(false); // 名前にrefをつける
  // API呼び出しカスタムHooks
  const { response: addressResponse, callZipCloudApi } = useZipCloudApi();

  // 住所検索API
  const getAddressAPI = async () => {
    await callZipCloudApi(
      corporationApply.corpAddressPostalCode1,
      corporationApply.corpAddressPostalCode2
    );
    refreshCorpAddress.current = true;
  };

  // ファイルがアップロードされた際に動くuseEffect
  // 同一ファイル名の場合にエラーメッセージを設定
  useEffect(() => {
    if (sameFileNameMessage) {
      setCorporationApplyMessage({
        ...corporationApplyMessage,
        uploadFile: sameFileNameMessage,
      });
    } else {
      setCorporationApplyMessage({
        ...corporationApplyMessage,
        uploadFile: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameFileNameMessage]);

  useEffect(() => {
    if (response) {
      // 正常
      // 法人IDと法人ステータスを更新する
      setCorporationInfo(response, CorporationStatusConst.APPLYING);

      setStatus((preStatus) => preStatus + 1);
      // ブラウザバック対応 確認画面への遷移はpush
      history.replace(PageInfo.CORP_APPLY_COMPLETE.path);
      // responseがOKの場合、入力フォームを消す
      initCorporationApplyMessage();
      initCorporationAppry();
    } else if (error?.code) {
      // API入力値エラー

      // 応答電文を設定
      setGlobalMessage(error.message);

      // 入力画面へ戻る
      // handleBack();
      history.go(-1);
      window.scrollTo(0, 0);
    }
    // [browser]を除くため以下コメント追記
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error, history]);

  useEffect(() => {
    if (addressResponse && refreshCorpAddress.current) {
      if (addressResponse.results) {
        setCorporationApply({
          ...corporationApply,
          corpAddressRegion: addressResponse.results[0].address1,
          corpAddressLocality:
            addressResponse.results[0].address2 +
            addressResponse.results[0].address3,
        });
      } else {
        setCorporationApply({
          ...corporationApply,
          corpAddressLocality: '',
        });
      }
      refreshCorpAddress.current = false;
    }
  }, [corporationApply, addressResponse]);

  return {
    status,
    setSameFileNameMessage,
    corporationApply,
    setCorporationApply,
    setCorporationApplyMessage,
    corporationApplyMessage,
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    uploadFile1,
    uploadFile2,
    uploadFile3,
    uploadFile4,
    uploadFile5,
    setUploadFile1,
    setUploadFile2,
    setUploadFile3,
    setUploadFile4,
    setUploadFile5,
    handleInputEvent,
    handleNext,
    handleBack,
    handleGetAddress,
    globalMessage,
  };
};
export default useCorpApply;
