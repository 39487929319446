import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { FC } from 'react';
import { ButtonForward } from 'app/views/components/block/button';
import { PageInfo } from 'app/utils/constants';
import { useHistory } from 'react-router-dom';
import Template from 'app/views/components/template';

// スタイル
const useStyles = makeStyles(() => ({
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    height: '120px',
    marginTop: '50px',
    textAlign: 'left',
  },
  confirmButton: {
    marginBottom: '11px',
  },
  text: {
    textAlign: 'left',
  },
}));

const CorpCancelCompletePage: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Template title="申請の取り下げ">
      <Box className={classes.description}>
        <Typography>企業情報変更の申請を取り下げました。</Typography>
        <Typography>
          お手続きに関するメールをお送り致しますので内容をご確認ください。
        </Typography>
      </Box>
      <Box sx={{ marginTop: '50px' }}>
        <Grid container direction="row" className={classes.confirmButton}>
          <Grid>
            <ButtonForward
              onClick={(event) => {
                event.preventDefault();
                history.push(PageInfo.TOP.path);
              }}
              style={{ margin: 'auto' }}
            >
              トップへ
            </ButtonForward>
          </Grid>
        </Grid>
      </Box>
    </Template>
  );
};

export default CorpCancelCompletePage;
