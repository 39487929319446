import Template from 'app/views/components/template';
import { Box } from '@material-ui/core';
import { FC } from 'react';
import { useLinkDetach } from 'app/hooks/link/detach';
import { ButtonForward } from 'app/views/components/block/button';
import { GlobalErrorMessage } from 'app/views/components/block/message';

const LinkDetach: FC = () => {
  // サービス解除業務カスタムHooks
  const { globalMessage, handleNext } = useLinkDetach();

  // レンダリング
  return (
    // サービスの解除
    <Template title="サービスの解除">
      <div
        style={{
          marginTop: '78px',
          marginBottom: globalMessage ? '77px' : '',
        }}
      >
        <GlobalErrorMessage message={globalMessage} />
      </div>
      <Box sx={{ margin: '90px 0px 0px 0px', fontWeight: 'bold' }}>
        お手続きの途中で本サービスの解除を希望される方は、[解除する]を押してください。
      </Box>
      <Box sx={{ margin: '10px 0px 80px 0px' }}>
        ※解除しても、「TOYOTAアカウント」は引き続きご利用いただけます。
      </Box>
      <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
        解除する
      </ButtonForward>
    </Template>
  );
};

export default LinkDetach;
