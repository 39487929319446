import { FC } from 'react';
import Template from 'app/views/components/template';
import { useCorpUsersInvite } from 'app/hooks/corporation/users/invite';
import { makeStyles } from '@material-ui/core/styles';
import StepperContent from 'app/views/components/block/stepper';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { ButtonBack, ButtonForward } from 'app/views/components/block/button';
import { InputRowGridPadding } from 'app/views/components/block/layout';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import TextInput from 'app/views/components/atom/text';
import { ColorInfo } from 'app/style/theme';
import useValidate from 'app/hooks/validate';

const CompanyUsersInvitePage: FC = () => {
  // 企業ID登録業務カスタムHooks
  const {
    status,
    corpUsersInvite,
    handleInputEvent,
    handleNext,
    handleBack,
    globalMessage,
    validationMessage,
    setValidationMessage,
    setGlobalMessage,
  } = useCorpUsersInvite();
  const valid = useValidate();

  // 項目の活性（入力）、非活性（入力以外）
  const isDisabled = (): boolean => {
    switch (status) {
      case 0:
        return false;
      default:
        return true;
    }
  };
  const useStyles = makeStyles(() => ({
    h2: {
      paddingLeft: '0px',
    },
    mainText: {
      marginLeft: '22px',
      marginBottom: '10px',
    },
    inputRow: {
      marginBottom: '15px',
    },
    confirm: {
      marginLeft: '-50px',
    },
    summaryText: {
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  return (
    <Template title="アカウント管理 – 企業ユーザー招待">
      <>
        <StepperContent StepNumber={status} />
        {/* ユーザ招待完了 */}
        {status === 2 && (
          <>
            <Container>
              <Grid
                container
                justifyContent="center"
                direction="column"
                style={{ margin: '0px -12px' }}
              >
                <Box
                  style={{
                    marginBottom: '80px',
                    borderBottom: `1px solid ${ColorInfo.gray04}`,
                    width: 1000,
                  }}
                >
                  <Grid style={{ marginBottom: '80px' }}>
                    <Typography align="center" style={{ marginTop: '60px' }}>
                      招待メールを送信しました。
                      <br />
                      <br />
                    </Typography>
                    <Typography align="center">
                      登録状況は、アカウント管理ページからご確認いただけます。
                    </Typography>
                  </Grid>
                </Box>
                <Grid>
                  <ButtonForward
                    onClick={handleNext}
                    style={{ paddingRight: 85, margin: 'auto' }}
                  >
                    アカウント管理ページへ
                  </ButtonForward>
                </Grid>
              </Grid>
            </Container>
          </>
        )}

        {/* 企業ID登録リクエスト：情報の入力・入力内容の確認 */}
        {status !== 2 && (
          <Container>
            <div
              style={{
                marginTop: '52px',
                marginBottom: globalMessage ? '77px' : '',
              }}
            >
              <GlobalErrorMessage message={globalMessage} />
            </div>
            {status === 1 && (
              <>
                <p className={classes.summaryText}>
                  ご入力内容をご確認のうえ、「送信」ボタンを押してください。
                </p>
              </>
            )}
            {status === 0 && (
              <>
                <p className={classes.summaryText}>
                  招待するユーザーのメールアドレスをご入力のうえ、「確認」ボタンを押してください。
                </p>
                {/* <Typography variant="body1" className={classes.mainText}>
                  招待するユーザーのメールアドレスをご入力のうえ、「確認」ボタンを押してください。
                </Typography> */}
              </>
            )}
            <Typography variant="h3" style={{ marginBottom: '15px' }}>
              招待メールアドレス
            </Typography>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding label="" required>
                {status === 0 && (
                  <Typography color="error">
                    {validationMessage?.mailsCheck}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                width: '1000px',
                marginTop: '35px',
                marginLeft: '20px',
              }}
            >
              <InputRowGridPadding
                label="メールアドレス"
                required={isDisabled()}
                className={classes.inputRow}
              />
              {status === 0 && (
                <Typography variant="body1" className={classes.mainText}>
                  ※最大５件まで
                </Typography>
              )}
            </Box>
            {(status === 0 || corpUsersInvite?.mailAddress1) && (
              <Box sx={{ margin: '5px 0px 5px 20px' }}>
                <InputRowGridPadding
                  label=""
                  className={classes.inputRow}
                  required
                >
                  <TextInput
                    className={isDisabled() ? classes.confirm : ''}
                    name="mailAddress1"
                    value={corpUsersInvite?.mailAddress1}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    fullWidth
                    required
                    onBlur={(event) => {
                      const message = valid.mailValidation(
                        { value: event.target.value },
                        'メールアドレス'
                      );
                      setValidationMessage({
                        ...validationMessage,
                        mailsCheck: '',
                        mailAddress1: message,
                      });
                    }}
                    error={!!validationMessage?.mailAddress1}
                  />
                  {status === 0 && (
                    <Typography color="error">
                      {validationMessage?.mailAddress1}
                    </Typography>
                  )}
                </InputRowGridPadding>
              </Box>
            )}
            {(status === 0 || corpUsersInvite?.mailAddress2) && (
              <Box sx={{ margin: '5px 0px 5px 20px' }}>
                <InputRowGridPadding
                  label=""
                  className={classes.inputRow}
                  required
                >
                  <TextInput
                    className={isDisabled() ? classes.confirm : ''}
                    name="mailAddress2"
                    value={corpUsersInvite?.mailAddress2}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    fullWidth
                    required
                    onBlur={(event) => {
                      const message = valid.mailValidation(
                        { value: event.target.value },
                        'メールアドレス'
                      );
                      setValidationMessage({
                        ...validationMessage,
                        mailsCheck: '',
                        mailAddress2: message,
                      });
                    }}
                    error={!!validationMessage?.mailAddress2}
                  />
                  {status === 0 && (
                    <Typography color="error">
                      {validationMessage?.mailAddress2}
                    </Typography>
                  )}
                </InputRowGridPadding>
              </Box>
            )}
            {(status === 0 || corpUsersInvite?.mailAddress3) && (
              <Box sx={{ margin: '5px 0px 5px 20px' }}>
                <InputRowGridPadding
                  label=""
                  className={classes.inputRow}
                  required
                >
                  <TextInput
                    className={isDisabled() ? classes.confirm : ''}
                    name="mailAddress3"
                    value={corpUsersInvite?.mailAddress3}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    fullWidth
                    required
                    onBlur={(event) => {
                      const message = valid.mailValidation(
                        { value: event.target.value },
                        'メールアドレス'
                      );
                      setValidationMessage({
                        ...validationMessage,
                        mailsCheck: '',
                        mailAddress3: message,
                      });
                    }}
                    error={!!validationMessage?.mailAddress3}
                  />
                  {status === 0 && (
                    <Typography color="error">
                      {validationMessage?.mailAddress3}
                    </Typography>
                  )}
                </InputRowGridPadding>
              </Box>
            )}
            {(status === 0 || corpUsersInvite?.mailAddress4) && (
              <Box sx={{ margin: '5px 0px 5px 20px' }}>
                <InputRowGridPadding
                  label=""
                  className={classes.inputRow}
                  required
                >
                  <TextInput
                    className={isDisabled() ? classes.confirm : ''}
                    name="mailAddress4"
                    value={corpUsersInvite?.mailAddress4}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    fullWidth
                    required
                    onBlur={(event) => {
                      const message = valid.mailValidation(
                        { value: event.target.value },
                        'メールアドレス'
                      );
                      setValidationMessage({
                        ...validationMessage,
                        mailsCheck: '',
                        mailAddress4: message,
                      });
                    }}
                    error={!!validationMessage?.mailAddress4}
                  />
                  {status === 0 && (
                    <Typography color="error">
                      {validationMessage?.mailAddress4}
                    </Typography>
                  )}
                </InputRowGridPadding>
              </Box>
            )}
            {(status === 0 || corpUsersInvite?.mailAddress5) && (
              <Box sx={{ margin: '5px 0px 5px 20px' }}>
                <InputRowGridPadding
                  label=""
                  className={classes.inputRow}
                  required
                >
                  <TextInput
                    className={isDisabled() ? classes.confirm : ''}
                    name="mailAddress5"
                    value={corpUsersInvite?.mailAddress5}
                    handleInputEvent={handleInputEvent}
                    disabled={isDisabled()}
                    fullWidth
                    required
                    onBlur={(event) => {
                      const message = valid.mailValidation(
                        { value: event.target.value },
                        'メールアドレス'
                      );
                      setValidationMessage({
                        ...validationMessage,
                        mailsCheck: '',
                        mailAddress5: message,
                      });
                    }}
                    error={!!validationMessage?.mailAddress5}
                  />
                  {status === 0 && (
                    <Typography color="error">
                      {validationMessage?.mailAddress5}
                    </Typography>
                  )}
                </InputRowGridPadding>
              </Box>
            )}
            <Box
              style={{
                marginTop: '70px',
                marginBottom: '60px',
                borderTop: `1px solid ${ColorInfo.gray04}`,
              }}
            />
            <Grid
              container
              direction="row"
              style={{ marginTop: 80, marginBottom: 105 }}
            >
              {/* ボタン配置 */}
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>
                  {status === 0 && 'キャンセル'}
                  {status === 1 && '戻る'}
                </ButtonBack>
              </Grid>

              <Grid item xs={6} style={{ padding: '0px 12px' }}>
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  {status === 0 && '確認'}
                  {status === 1 && '送信'}
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Container>
        )}
      </>
    </Template>
  );
};

export default CompanyUsersInvitePage;
