import { FC, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Toolbar,
  Button,
  makeStyles,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Template from 'app/views/components/template';
import { Contract, useContractInfo } from 'app/hooks/contract';
import { ButtonForward, SubButton } from 'app/views/components/block/button';
import MainAccordion from 'app/views/components/block/accordion';
import { ColorInfo } from 'app/style/theme';
import { PageInfo } from 'app/utils/constants';
import { AuthContext, AuthInfo } from 'app/hooks/context/auth';
import { ContractInfoListRequest } from 'app/service/customer/contract';
import useAdminCorpMng, { AdminCorpMng } from 'app/hooks/corporation/detail';

declare global {
  interface Window {
    sdpContractNo: string;
  }
}

// スタイル
const useStyles = makeStyles(() => ({
  ItemFont: {
    fontSize: '1.5rem',
  },
  mainText: {
    marginLeft: '22px',
    marginBottom: '25px',
  },
  tableRow: {
    '& > *': {
      borderLeft: `1px solid ${ColorInfo.gray03}`,
    },
    '& > *:first-child': {
      borderLeft: 'none',
    },
    height: 80,
    '&.is-heading': {
      background: ColorInfo.gray05,
    },
  },
  listBtn: {
    width: 120,
    height: 42,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px',
    marginLeft: 'auto',
    cursor: 'pointer',
    fontSize: '1.5rem',
    lineHeight: 1.4,
    letterSpacing: 2,
    color: ColorInfo.white01,
    textAlign: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
    border: `2px solid ${ColorInfo.blue02}`,
    borderRadius: 4,
    backgroundColor: ColorInfo.blue02,
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: ColorInfo.blue03,
      backgroundColor: ColorInfo.blue03,
    },
  },
  summaryWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 20,
  },
  summaryTitle: {
    width: '610px',
    '& > *:nth-child(2)': {
      marginLeft: 'calc(1em + 5px)',
    },
  },
  contractName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: '5px',
    width: '500px',
  },
  titleText1: {
    fontWeight: 'bold',
  },
  titleText2: {
    marginLeft: '80px',
    display: 'block',
    wordBreak: 'break-all',
    maxWidth: '860px',
    overflowX: 'clip',
    overflowY: 'auto',
    maxHeight: '120px',
  },
}));

// 契約履歴コンポーネント
const ContractHistory: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3">契約履歴</Typography>
      <Typography variant="body1" className={classes.mainText}>
        過去の契約内容のご確認はこちら。
      </Typography>
      <ButtonForward style={{ margin: 'auto' }}>契約履歴ページへ</ButtonForward>
    </>
  );
};

// 請求履歴コンポーネント
const ContractBillingInfoHistory: FC = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3">請求履歴</Typography>
      <Typography variant="body1" className={classes.mainText}>
        過去の請求金額のご確認はこちら。
      </Typography>
      <ButtonForward style={{ margin: 'auto' }}>請求履歴ページへ</ButtonForward>
    </>
  );
};

interface ContractMenuSummaryProps {
  contractName: string;
  usageDateStart: string;
  usageDateEnd: string;
}

const ContractMenuSummary: FC<ContractMenuSummaryProps> = ({
  contractName,
  usageDateStart,
  usageDateEnd,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.summaryWrapper}>
      <Box className={classes.summaryTitle}>
        <Typography variant="h4" className={classes.contractName}>
          {contractName}
        </Typography>
      </Box>
      <Box>
        <Typography>
          利用期間 {usageDateStart} 〜 {usageDateEnd}
        </Typography>
      </Box>
    </Box>
  );
};
type classesType = ReturnType<typeof useStyles>;
type historyType = ReturnType<typeof useHistory>;
interface ContractPageProps {
  usageFlag: ContractInfoListRequest['usageFlag'];
  history: historyType;
  classes: classesType;
  contract: Contract;
  apiCallStatus: number;
  adminCorpMng: AdminCorpMng;
  handleUserDetail: (
    contractNo: string,
    contractMenuId: string,
    contractCourseId: string
  ) => void;
  handleContractDetail: (contractNo: string) => void;
  handleApiKey: (
    contractNo: string,
    scimId: string,
    contractServiceName: string,
    contractName: string
  ) => void;
  authInfo: AuthInfo | undefined;
}

const ContractInfoComponent: FC<ContractPageProps> = ({
  usageFlag,
  history,
  classes,
  handleUserDetail,
  handleContractDetail,
  handleApiKey,
  authInfo,
  contract,
  apiCallStatus,
  adminCorpMng,
}) => (
  <>
    <Typography variant="h3" style={{ marginTop: '90px' }}>
      {usageFlag === '0' && '今後のご契約'}
      {usageFlag === '1' && '現在のご契約'}
      {usageFlag === '2' && '過去のご契約'}
    </Typography>
    {apiCallStatus === 0 && <></>}
    {apiCallStatus === 1 && contract.rows.length === 0 && (
      <Typography variant="body1" className={classes.mainText2}>
        {adminCorpMng?.administratorEmail === authInfo?.email && 'ご契約はありません。'}
        {adminCorpMng?.administratorEmail !== authInfo?.email && '企業管理者の方のみご確認いただけます。'}
      </Typography>
    )}
    {contract.rows.map((row) => (
      <MainAccordion
        title={row.contractServiceName}
        isPrimary
        key={row.contractServiceName}
        isExpanded
        isTitleEllipsis
      >
        {row.contractMenuList.map((subrow) => (
          <MainAccordion
            title={
              <ContractMenuSummary
                contractName={subrow.contractName}
                usageDateStart={subrow.usageDateStart}
                usageDateEnd={subrow.usageDateEnd}
              />
            }
            // 詳細画面から戻った場合は表示していた契約の詳細を開く
            isExpanded={subrow.contractNo === window.sdpContractNo}
            key={subrow.contractMenuName}
          >
            <div id={`contract-${subrow.contractNo}`}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    className={`${classes.tableRow} is-heading`}
                    style={{ borderTop: `1px solid ${ColorInfo.gray03}` }}
                  >
                    <TableCell align="left" colSpan={3}>
                      <Toolbar disableGutters variant="dense">
                        <Typography className={classes.titleText1}>
                          契約情報
                        </Typography>
                        <Button
                          className={classes.listBtn}
                          onClick={() =>
                            handleContractDetail(subrow.contractNo)
                          }
                        >
                          詳細
                        </Button>
                      </Toolbar>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell width="40%">
                      <Typography className={classes.titleText2}>
                        契約No.
                      </Typography>
                    </TableCell>
                    <TableCell width="60%">
                      <Typography>{subrow.contractNo}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell width="40%">
                      <Typography className={classes.titleText2}>
                        サービス・契約名
                      </Typography>
                    </TableCell>
                    <TableCell width="60%">
                      <Typography>
                        {row.contractServiceName}
                        <br />
                        {subrow.contractName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell width="40%">
                      <Typography className={classes.titleText2}>
                        契約期間
                      </Typography>
                    </TableCell>
                    <TableCell width="60%">
                      <Typography>
                        {subrow.contractDateStart} 〜 {subrow.contractDateEnd}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* データ使用量と請求情報は一旦非表示 ※復活の可能性あり */}
                  {/* <TableRow className={`${classes.tableRow} is-heading`}>
                      <TableCell align="left" colSpan={3}>
                        <Typography>データ使用量</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell width="40%">
                        <Typography>利用期間</Typography>
                      </TableCell>
                      <TableCell width="60%">
                        <Typography>
                          {subrow.usageDateStart} 〜 {subrow.usageDateEnd}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell>
                        <Typography>使用量</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{subrow.usageResult}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={`${classes.tableRow} is-heading`}>
                      <TableCell align="left" colSpan={3}>
                        <Typography align="left">請求情報</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={3}>
                        <Typography>お問い合わせください</Typography>
                      </TableCell>
                    </TableRow> */}
                  {(usageFlag === '0' || usageFlag === '1') && (
                    <TableRow className={`${classes.tableRow} is-heading`}>
                      <TableCell align="left" colSpan={3}>
                        <Toolbar disableGutters variant="dense">
                          <Typography className={classes.titleText1}>
                            利用者管理
                          </Typography>
                          <Button
                            className={classes.listBtn}
                            onClick={() =>
                              handleUserDetail(
                                subrow.contractNo,
                                subrow.contractMenuId,
                                subrow.contractCourseId
                              )
                            }
                          >
                            詳細
                          </Button>
                        </Toolbar>
                      </TableCell>
                    </TableRow>
                  )}
                  {subrow.ipAddress && (
                    <>
                      <TableRow className={`${classes.tableRow} is-heading`}>
                        <TableCell colSpan={3}>
                          <Typography className={classes.titleText1}>
                            IPアドレス
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.tableRow}>
                        <TableCell colSpan={3}>
                          <Typography className={classes.titleText2}>
                            {subrow.ipAddress}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {(usageFlag === '0' || usageFlag === '1') &&
                    subrow.contractServiceDeliveryMethod !== '1' && (
                      <>
                        <TableRow className={`${classes.tableRow} is-heading`}>
                          <TableCell width="40%">
                            <Typography className={classes.titleText1}>
                              APIキー
                            </Typography>
                          </TableCell>
                          <TableCell width="60%" style={{ borderLeft: 'none' }}>
                            {subrow.administratorId === authInfo?.scimId && (
                              <Button
                                className={classes.listBtn}
                                onClick={() =>
                                  handleApiKey(
                                    subrow.contractNo,
                                    authInfo?.scimId ? authInfo.scimId : '',
                                    row.contractServiceName,
                                    subrow.contractName
                                  )
                                }
                                style={{ width: '160px' }}
                              >
                                APIキーを表示
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                        {subrow.administratorId !== authInfo?.scimId && (
                          <TableRow className={classes.tableRow}>
                            <TableCell colSpan={3}>
                              <Typography>
                                企業管理者にお問い合わせください
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                </TableBody>
              </Table>
            </div>
          </MainAccordion>
        ))}
      </MainAccordion>
    ))}
  </>
);

const ContractPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { authInfo } = useContext(AuthContext);
  const { adminCorpMng } = useAdminCorpMng();
  const handleUserDetail = (
    contractNo: string,
    contractMenuId: string,
    contractCourseId: string
  ) => {
    history.push(
      `${PageInfo.CONTRACT_USERS_MANAGE.path}/${contractNo}/${contractMenuId}/${contractCourseId}`
    );
  };
  const handleContractDetail = (contractNo: string) => {
    history.push(`${PageInfo.CONTRACT_DETAIL_BASE.path}${contractNo}`);
  };
  const handleApiKey = (
    contractNo: string,
    scimId: string,
    // 前の画面から契約名とサービス名を渡す
    contractServiceName: string,
    contractName: string
  ) => {
    history.push(`${PageInfo.CONTRACT_APIKEY.path}${contractNo}/${scimId}`, {
      contractServiceName,
      contractName,
    });
  };

  const { contract: futureContract, apiCallStatus: futureApiCallStatus } =
    useContractInfo('0');
  const { contract: nowContract, apiCallStatus: nowApiCallStatus } =
    useContractInfo('1');
  const { contract: pastContract, apiCallStatus: pastApiCallStatus } =
    useContractInfo('2');
  useEffect(() => {
    // 契約詳細画面で表示した契約までスクロール
    // 契約情報が撮れてから実施する
    if (window.sdpContractNo && futureContract && nowContract && pastContract) {
      const element = document.getElementById(
        `contract-${window.sdpContractNo}`
      );
      if (element) {
        const top = Number(element.getBoundingClientRect().top);
        window.scrollTo(0, top);
      }
    }
  }, [futureContract, nowContract, pastContract]);

  return (
    <Template title="契約一覧">
      <ContractInfoComponent
        usageFlag="1"
        history={history}
        classes={classes}
        handleUserDetail={handleUserDetail}
        handleContractDetail={handleContractDetail}
        handleApiKey={handleApiKey}
        authInfo={authInfo}
        contract={nowContract}
        apiCallStatus={nowApiCallStatus}
        adminCorpMng={adminCorpMng}
      />
      <ContractInfoComponent
        usageFlag="0"
        history={history}
        classes={classes}
        handleUserDetail={handleUserDetail}
        handleContractDetail={handleContractDetail}
        handleApiKey={handleApiKey}
        authInfo={authInfo}
        contract={futureContract}
        apiCallStatus={futureApiCallStatus}
        adminCorpMng={adminCorpMng}
      />
      <ContractInfoComponent
        usageFlag="2"
        history={history}
        classes={classes}
        handleUserDetail={handleUserDetail}
        handleContractDetail={handleContractDetail}
        handleApiKey={handleApiKey}
        authInfo={authInfo}
        contract={pastContract}
        apiCallStatus={pastApiCallStatus}
        adminCorpMng={adminCorpMng}
      />
    </Template>
  );
};

export default ContractPage;
