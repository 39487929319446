import { AuthContext } from 'app/hooks/context/auth';
import { PageInfo } from 'app/utils/constants';
import { createHash } from 'crypto';
import { useContext, useEffect, FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * パスの変化を検知して処理を行うhooks
 */
const PathTracking = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  useEffect(() => {
    // DMPビーコンを発火させる処理
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (window.DMP && window.DMP.getRequest) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      window.DMP.getRequest('click', {
        document_title: document.title,
        additional_fields: {
          field01: authInfo?.scimId
            ? createHash('sha256').update(authInfo?.scimId).digest('hex')
            : '',
          field02: process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
            ? process.env.REACT_APP_ACCOUNT_INFO_CLIENT_ID
            : '',
          field03: new Date().getTime(),
          field04: `${pathname}${search}`,
          sub_type: 'virtual_pv',
        },
      });
    }

    // APIキー一覧のパス（PageInfo.CONTRACT_APIKEY.path）を含まない
    // かつ
    // 利用者管理画面のパス（PageInfo.CONTRACT_USERS_MANAGE.path）を含まない
    // かつ
    // 契約内容詳細画面のパス（PageInfo.CONTRACT_DETAIL_BASE.path）を含まない
    // かつ
    // 契約利用者招待のパス（PageInfo.CONTRACT_USERS_INVITE_BASE.path）を含まない
    // かつ
    // 契約内容照会画面のパス（PageInfo.CONTRACT.path）でない（一致）
    // パスに遷移するときにwindow.sdpContractNoを削除
    if (
      !pathname.includes(PageInfo.CONTRACT_APIKEY.path) &&
      !pathname.includes(PageInfo.CONTRACT_USERS_MANAGE.path) &&
      !pathname.includes(PageInfo.CONTRACT_USERS_INVITE_BASE.path) &&
      !pathname.includes(PageInfo.CONTRACT_DETAIL_BASE.path) &&
      pathname !== PageInfo.CONTRACT.path
    ) {
      window.sdpContractNo = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, pathname, search]);
};

const SdpTracking: FC = () => {
  PathTracking();
  return <></>;
};

export default SdpTracking;
