import { FC } from 'react';
import Template from 'app/views/components/template';
import { Typography } from '@material-ui/core';

// ダミー画面
const ServiceDetail: FC = () => (
  <>
    <Template title="XXXダミー画面">
      <Typography>ダミーです</Typography>
    </Template>
  </>
);

export default ServiceDetail;
