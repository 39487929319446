import { AuthContext } from 'app/hooks/context/auth';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApiConfig,
  ApiConst,
  CorporationStatusConst,
  MessageConst,
  PageInfo,
} from 'app/utils/constants';
import useApi from 'app/hooks/api';
import useValidate from 'app/hooks/validate';
import useZipCloudApi from 'app/hooks/zipcloud';
import {
  CorpInfoChangeRequest,
  UpdateResponse,
} from 'app/service/customer/corporation/info/edit';
import { SelectDetailResponse } from 'app/service/customer/corporation/detail';
import useBrowserBack, { UseBrowserBackProps } from 'app/hooks/browser/back';

// 法人情報変更申請PI IF
export interface CorpInfoChange {
  corporationId: string;
  corporationName: string;
  corporationNameKana: string;
  corporationAddressPostalCode1: string;
  corporationAddressPostalCode2: string;
  corporationAddressRegion: string;
  corporationAddressLocality: string;
  corporationAddressStreet: string;
  corporationAddressBuilding?: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  registrationCopyFileName?: string;
  registrationCopyFile?: File;
  applyReason: string;
}
// 企業変更申請メッセージ
export interface CorporationInfoMessage {
  corporationName: string;
  corporationNameKana: string;
  corporationTelephoneNumber: string;
  corporationTelephoneNumber1: string;
  corporationTelephoneNumber2: string;
  corporationTelephoneNumber3: string;
  serviceInfo: string;
  corporationAddressPostalCode: string;
  corporationAddressRegion: string;
  corporationAddressLocality: string;
  corporationAddressStreet: string;
  corporationAddressBuilding: string;
  uploadFile: string;
  applyReason: string;
}
/**
 * 企業申請業務カスタムHooks
 */
export const useCorpInfoEdit = () => {
  // 認証コンテキスト
  const { authInfo, setCorporationStatus } = useContext(AuthContext);

  // 申請状態（0:入力、1:確認、2:完了）
  const [status, setStatus] = useState(0);
  // 企業管理詳細API呼び出しカスタムHooks
  const {
    response: adminDetailResponse,
    error: adminDetailError,
    callApi: adminDetailApi,
  } = useApi<SelectDetailResponse>();

  // 企業情報取得API
  const adminCorpMngDetailAPI = (corporationId: string) => {
    // API呼出し
    const ADMIN_CORP_MNG_DETAIL: ApiConfig = {
      url: `/api/v1/corporation/admin/${corporationId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void adminDetailApi(ADMIN_CORP_MNG_DETAIL, 'GET', null);
  };
  // 企業申請情報
  const [corporationInfo, setCorporationInfo] =
    // 初期値設定
    useState<CorpInfoChange>({
      corporationId: '',
      corporationName: '',
      corporationNameKana: '',
      corporationAddressPostalCode1: '',
      corporationAddressPostalCode2: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      corporationAddressRegion: '',
      corporationAddressLocality: '',
      corporationAddressStreet: '',
      applyReason: '',
    });

  // 初期化関数
  const initCorporationInfo = () => {
    if (adminDetailResponse) {
      // レスポンスを設定(responseから設定)
      setCorporationInfo({
        ...corporationInfo,
        corporationId: adminDetailResponse.corporationId || '',
        corporationName: adminDetailResponse.corporationName || '',
        corporationNameKana: adminDetailResponse.corporationNameKana || '',
        corporationAddressPostalCode1:
          adminDetailResponse.corpAddressPostalCode1 || '',
        corporationAddressPostalCode2:
          adminDetailResponse.corpAddressPostalCode2 || '',
        corporationTelephoneNumber1:
          adminDetailResponse.corporationTelephoneNumber1 || '',
        corporationTelephoneNumber2:
          adminDetailResponse.corporationTelephoneNumber2 || '',
        corporationTelephoneNumber3:
          adminDetailResponse.corporationTelephoneNumber3 || '',
        corporationAddressRegion: adminDetailResponse.corpAddressRegion || '',
        corporationAddressLocality:
          adminDetailResponse.corpAddressLocality || '',
        corporationAddressStreet: adminDetailResponse.corpAddressStreet || '',
        corporationAddressBuilding:
          adminDetailResponse.corpAddressBuilding || undefined,
      });
    }
    setUploadFile(undefined);
  };

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // 企業情報取得エラー
  const [error, setError] = useState<boolean>(false);

  // 企業情報変更メッセージ
  const [corporationInfoMessage, setCorporationInfoMessage] =
    // 初期値設定
    useState<CorporationInfoMessage>({
      corporationName: '',
      corporationNameKana: '',
      corporationTelephoneNumber: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      serviceInfo: '',
      corporationAddressPostalCode: '',
      corporationAddressRegion: '',
      corporationAddressLocality: '',
      corporationAddressStreet: '',
      corporationAddressBuilding: '',
      uploadFile: '',
      applyReason: '',
    });

  // 登記謄本ファイルアップロード情報
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadFile, setUploadFile] = useState<File>();
  // API呼び出しカスタムHooks
  const {
    response: corpEditResponse,
    error: corpEditError,
    callApi: corpEditAPI,
  } = useApi<UpdateResponse>();

  // ValidateHooks
  const valid = useValidate();

  // 二重POST防止：OFFで初期化
  const processing = useRef(false);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setCorporationInfo({
      ...corporationInfo,
      [name]: value,
    });
  };
  // checkBox
  const handleCheckEvent = (name: string, value: boolean) => {
    setCorporationInfo({
      ...corporationInfo,
      [name]: value,
    });
  };

  // ブラウザバック制御Hooks
  const props: UseBrowserBackProps = {
    // APIを適宜設定
    ...{
      screen: status,
      setScreen: setStatus,
      paths: {
        input: PageInfo.CORP_INFO_EDIT_INPUT.path,
        confirm: PageInfo.CORP_INFO_EDIT_CONFIRM.path,
        complete: PageInfo.CORP_INFO_EDIT_COMPLETE.path,
      },
    },
  };
  const browser = useBrowserBack(props);

  useEffect(() => {
    setCorporationInfo({
      corporationId: '',
      corporationName: '',
      corporationNameKana: '',
      corporationAddressPostalCode1: '',
      corporationAddressPostalCode2: '',
      corporationTelephoneNumber1: '',
      corporationTelephoneNumber2: '',
      corporationTelephoneNumber3: '',
      corporationAddressRegion: '',
      corporationAddressLocality: '',
      corporationAddressStreet: '',
      applyReason: '',
    });
    // 初期処理で企業管理詳細取得をよびだす
    adminCorpMngDetailAPI(authInfo?.corporationId || '');
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adminDetailResponse) {
      // レスポンスを設定
      setCorporationInfo({
        ...corporationInfo,
        corporationId: adminDetailResponse.corporationId || '',
        corporationName: adminDetailResponse.corporationName || '',
        corporationNameKana: adminDetailResponse.corporationNameKana || '',
        corporationAddressPostalCode1:
          adminDetailResponse.corpAddressPostalCode1 || '',
        corporationAddressPostalCode2:
          adminDetailResponse.corpAddressPostalCode2 || '',
        corporationTelephoneNumber1:
          adminDetailResponse.corporationTelephoneNumber1 || '',
        corporationTelephoneNumber2:
          adminDetailResponse.corporationTelephoneNumber2 || '',
        corporationTelephoneNumber3:
          adminDetailResponse.corporationTelephoneNumber3 || '',
        corporationAddressRegion: adminDetailResponse.corpAddressRegion || '',
        corporationAddressLocality:
          adminDetailResponse.corpAddressLocality || '',
        corporationAddressStreet: adminDetailResponse.corpAddressStreet || '',
        corporationAddressBuilding:
          adminDetailResponse.corpAddressBuilding || undefined,
      });
    } else if (adminDetailError) {
      // 企業情報取得に失敗した場合には入力できないようにして再実施を促す
      setError(true);
      setGlobalMessage(MessageConst.NOACTION);
    }
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminDetailResponse, adminDetailError]);
  // 戻るアクション
  const history = useHistory();
  const handleBack = () => {
    history.go(-1);
  };

  // 次処理
  const handleNext = async () => {
    switch (status) {
      case 0:
        // 二重POST防止：OFF
        processing.current = false;
        // 入力画面の場合、入力チェック実施
        if (!validateCheck()) {
          nextPage();
        }
        break;
      case 1:
        // 二重POST防止：POST中なら処理せず
        if (processing.current) return;
        // 二重POST防止：POST中
        processing.current = true;
        // 企業変更申請リクエスト呼び出し
        await corpEditRequestAPI();
        // 実行後の処理はuseEffectで実施（再レンダリングのため）
        break;
      default:
        // 二重POST防止：OFF
        processing.current = false;
        nextPage();
        break;
    }
  };

  // 住所検索
  const handleGetAddress = async () => {
    if (!corporationInfoMessage?.corporationAddressPostalCode) {
      // 検索API呼出し
      await getAddressAPI();
    }
  };

  // メッセージ初期化
  const initcorporationInfoMessage = () => {
    setCorporationInfoMessage({
      ...corporationInfoMessage,
      corporationName: '',
      corporationNameKana: '',
      corporationTelephoneNumber: '',
      serviceInfo: '',
      corporationAddressPostalCode: '',
      corporationAddressRegion: '',
      corporationAddressLocality: '',
      corporationAddressStreet: '',
      corporationAddressBuilding: '',
      applyReason: '',
    });
  };

  useEffect(() => {
    if (status === 1) {
      if (validateCheck()) {
        history.go(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, status]);

  // ボタン押下時入力チェック
  const validateCheck = (): boolean => {
    initcorporationInfoMessage();
    let result = false;
    // 企業名 バリデーションチェック
    const corporationNameMessage = valid.corporationNameValidation({
      value: corporationInfo.corporationName,
      require: true,
    });
    result = result || !!corporationNameMessage;
    // 企業名(カナ) バリデーションチェック
    const corporationNameKanaMessage = valid.corporationNameKanaValidation({
      value: corporationInfo.corporationNameKana,
      require: true,
    });
    result = result || !!corporationNameKanaMessage;

    // 電話番号 バリデーションチェック
    const corporationTelephoneNumberMessageList =
      valid.corporationTelephoneNumberValidation(
        corporationInfo.corporationTelephoneNumber1,
        corporationInfo.corporationTelephoneNumber2,
        corporationInfo.corporationTelephoneNumber3,
        true
      );
    const corporationTelephoneNumberMessage =
      corporationTelephoneNumberMessageList[0];
    const corporationTelephoneNumberMessage1 =
      corporationTelephoneNumberMessageList[1];
    const corporationTelephoneNumberMessage2 =
      corporationTelephoneNumberMessageList[2];
    const corporationTelephoneNumberMessage3 =
      corporationTelephoneNumberMessageList[3];

    result = result || !!corporationTelephoneNumberMessage;
    result = result || !!corporationTelephoneNumberMessage1;
    result = result || !!corporationTelephoneNumberMessage2;
    result = result || !!corporationTelephoneNumberMessage3;

    // 都道府県 バリデーションチェック
    const corporationAddressRegionMessage = valid.corporationRegionValidation({
      value: corporationInfo.corporationAddressRegion,
      require: true,
    });
    result = result || !!corporationAddressRegionMessage;

    // 市区町村 バリデーションチェック
    const corporationAddressLocalityMessage =
      valid.corporationAddressLocalityValidation({
        value: corporationInfo.corporationAddressLocality,
        require: true,
      });
    result = result || !!corporationAddressLocalityMessage;
    // 丁目番地 バリデーションチェック
    const corporationAddressStreetMessage =
      valid.corporationAddressStreetValidation({
        value: corporationInfo.corporationAddressStreet,
        require: true,
      });
    result = result || !!corporationAddressStreetMessage;
    // 建物名 バリデーションチェック
    const corporationAddressBuildingMessage =
      valid.corporationAddressBuildingValidation({
        value: corporationInfo.corporationAddressBuilding || '',
      });
    result = result || !!corporationAddressBuildingMessage;

    // 郵便番号 バリデーションチェック
    const corporationAddressPostalCodeMessage =
      valid.corporationAddressPostalCodeValidation(
        corporationInfo.corporationAddressPostalCode1,
        corporationInfo.corporationAddressPostalCode2,
        true
      );
    result = result || !!corporationAddressPostalCodeMessage;

    // アップロードファイル バリデーションチェック
    const fileNameList: (string | undefined)[] = [uploadFile?.name];
    // アップロードファイル 最大サイズバリデーションチェック
    const fileSizeList: (number | undefined)[] = [uploadFile?.size];

    const uploadFileMessage =
      valid.fileNameListValidation(fileNameList, true) ||
      valid.fileMaxSizeValidation(fileSizeList);

    result = result || !!uploadFileMessage;

    // 申請理由 バリデーションチェック
    const applyReasonMessage =
      valid.applyReasonValidation(
        { value: corporationInfo.applyReason, require: true },
        '\r\n',
      );
    result = result || !!applyReasonMessage;

    // メッセージ設定
    setCorporationInfoMessage({
      ...corporationInfoMessage,
      corporationName: corporationNameMessage,
      corporationNameKana: corporationNameKanaMessage,
      corporationTelephoneNumber: corporationTelephoneNumberMessage,
      corporationTelephoneNumber1: corporationTelephoneNumberMessage1,
      corporationTelephoneNumber2: corporationTelephoneNumberMessage2,
      corporationTelephoneNumber3: corporationTelephoneNumberMessage3,
      corporationAddressPostalCode: corporationAddressPostalCodeMessage,
      corporationAddressRegion: corporationAddressRegionMessage,
      corporationAddressLocality: corporationAddressLocalityMessage,
      corporationAddressStreet: corporationAddressStreetMessage,
      corporationAddressBuilding: corporationAddressBuildingMessage,
      uploadFile: uploadFileMessage,
      applyReason: applyReasonMessage,
    });
    if (result) {
      // エラーメッセージ
      setGlobalMessage(
        `入力内容にエラーがあります。ご確認のうえ再度「確認」ボタンを押してください。`
      );
      window.scrollTo(0, 0);
    } else {
      setGlobalMessage('');
    }
    return result;
  };

  // 企業変更申請API
  const corpEditRequestAPI = async () => {
    // API Request設定
    const req: CorpInfoChangeRequest = {
      // APIを適宜設定
      ...{
        corporationId: corporationInfo.corporationId,
        corporationName: corporationInfo.corporationName,
        corporationNameKana: corporationInfo.corporationNameKana,
        corporationTelephoneNumber1:
          corporationInfo.corporationTelephoneNumber1,
        corporationTelephoneNumber2:
          corporationInfo.corporationTelephoneNumber2,
        corporationTelephoneNumber3:
          corporationInfo.corporationTelephoneNumber3,
        registrationCopyFileName: uploadFile?.name || null,
        registrationCopyFile: uploadFile || null,
        corporationAddressPostalCode1:
          corporationInfo.corporationAddressPostalCode1,
        corporationAddressPostalCode2:
          corporationInfo.corporationAddressPostalCode2,
        corporationAddressRegion: corporationInfo.corporationAddressRegion,
        corporationAddressLocality: corporationInfo.corporationAddressLocality,
        corporationAddressStreet: corporationInfo.corporationAddressStreet,
        applyReason: corporationInfo.applyReason,
      },
    };

    // 建物名は任意
    if (corporationInfo.corporationAddressBuilding) {
      req.corporationAddressBuilding =
        corporationInfo.corporationAddressBuilding;
    }

    // リクエストパラメータ生成
    const params = new FormData();
    Object.entries(req).map((param) => {
      if (param[1] !== null) {
        return params.append(param[0], param[1]);
      }
      return null;
    });
    // 登録API呼出し
    await corpEditAPI(ApiConst.CORP_USER_INFO_CHANGE, 'POST', params);
  };

  // 画面遷移
  const nextPage = () => {
    // 次画面へ遷移（状態を加算）
    setStatus((preStatus) => preStatus + 1);
    switch (status) {
      case 0:
        // ブラウザバック対応 確認画面への遷移はpush
        history.push(PageInfo.CORP_INFO_EDIT_CONFIRM.path);
        break;
      case 1:
        // ブラウザバック対応 完了画面への遷移はpush
        history.replace(PageInfo.CORP_INFO_EDIT_COMPLETE.path);
        break;
      default:
        history.push(PageInfo.TOP.path);
        break;
    }
  };

  const refreshCorpAddress = useRef(false); // 名前にrefをつける
  // API呼び出しカスタムHooks
  const { response: addressResponse, callZipCloudApi } = useZipCloudApi();

  // 住所検索API
  const getAddressAPI = async () => {
    await callZipCloudApi(
      corporationInfo.corporationAddressPostalCode1,
      corporationInfo.corporationAddressPostalCode2
    );
    refreshCorpAddress.current = true;
  };

  useEffect(() => {
    if (corpEditResponse) {
      // 正常
      // nextPage();
      setStatus((preStatus) => preStatus + 1);

      // ステータス更新
      if (
        authInfo?.corporationStatus === CorporationStatusConst.APPLYING ||
        authInfo?.corporationStatus === CorporationStatusConst.APPLY_REJECT
      ) {
        // 企業申請の場合は1(申請中)に
        setCorporationStatus(CorporationStatusConst.APPLYING);
      } else {
        // 変更申請の場合は5(変更申請中)に
        setCorporationStatus(CorporationStatusConst.CHANGE_REQUEST);
      }

      // ブラウザバック対応 完了画面への遷移はreplace
      history.replace(PageInfo.CORP_INFO_EDIT_COMPLETE.path);
      // 入力内容を初期化する
      initCorporationInfo();
      initcorporationInfoMessage();
    } else if (corpEditError?.code) {
      // API入力値エラー

      // 応答電文を設定
      setGlobalMessage(corpEditError.message);

      // 入力画面へ戻る
      // handleBack();
      history.go(-1);
      window.scrollTo(0, 0);
    }
    // [browser]を除くため以下コメント追記
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corpEditError, corpEditResponse, history]);

  useEffect(() => {
    if (addressResponse && refreshCorpAddress.current) {
      if (addressResponse.results) {
        setCorporationInfo({
          ...corporationInfo,
          corporationAddressRegion: addressResponse.results[0].address1,
          corporationAddressLocality:
            addressResponse.results[0].address2 +
            addressResponse.results[0].address3,
        });
      } else {
        setCorporationInfo({
          ...corporationInfo,
          corporationAddressLocality: '',
        });
      }
      refreshCorpAddress.current = false;
    }
  }, [corporationInfo, addressResponse]);

  return {
    status,
    error,
    corporationInfo,
    setCorporationInfo,
    setCorporationInfoMessage,
    corporationInfoMessage,
    inputRef,
    uploadFile,
    setUploadFile,
    handleInputEvent,
    handleCheckEvent,
    handleNext,
    handleBack,
    handleGetAddress,
    globalMessage,
  };
};
export default useCorpInfoEdit;
