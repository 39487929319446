import useApi from 'app/hooks/api';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import {
  NoticeDetailResponse,
  NoticeDetailRequest,
} from 'app/service/customer/notice/detail';
import { ApiConfig } from 'app/utils/constants';
import { useParams } from 'react-router-dom';

// お知らせ詳細
export interface NoticeDetail {
  notificationId: string;
  categoryType: string;
  viewDateStart: string;
  notificationTitle: string;
  notificationContents: string;
}
/**
 * お知らせ詳細取得Hooks
 */
export const useNoticeDetail = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // お知らせ詳細
  const [noticeDetail, setNoticeDetail] =
    // 初期値設定
    useState<NoticeDetail>({
      notificationId: '',
      categoryType: '',
      viewDateStart: '',
      notificationTitle: '',
      notificationContents: '',
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<NoticeDetailResponse>();

  // お知らせ詳細取得API
  const noticeDetailAPI = (notificationId: string) => {
    // API Request設定
    const req: NoticeDetailRequest = {};

    // 認証非認証確認
    if (authInfo?.isAuth) {
      // 詳細取得API呼出し
      const NOTICE_AUTH_DETAIL: ApiConfig = {
        url: `/api/v1/support/notice/detail/auth/${notificationId}`,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      };
      void callApi(NOTICE_AUTH_DETAIL, 'GET', req);
    } else {
      // 詳細取得API呼出し
      const NOTICE_DETAIL: ApiConfig = {
        url: `/api/v1/support/notice/detail/${notificationId}`,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        withoutAuth: true,
      };
      void callApi(NOTICE_DETAIL, 'GET', req);
    }
  };
  interface params {
    notificationId: string;
  }
  const params = useParams<params>();
  useEffect(() => {
    // 初期処理
    const { notificationId } = params;
    // お知らせ詳細API呼び出し
    noticeDetailAPI(notificationId);
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // お知らせ詳細設定
      setNoticeDetail({
        ...noticeDetail,
        ...response,
      });
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return { noticeDetail };
};
export default useNoticeDetail;
