/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, ChangeEvent, useEffect } from 'react';
import Template from 'app/views/components/template';
import {
  Box,
  Typography,
  Grid,
  Toolbar,
  Table,
  TableRow,
  TableCell,
  Link,
} from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBack,
  ButtonForward,
  SubButton,
  ButtonLowForward,
  ButtonLowBack,
} from 'app/views/components/block/button';
import Select from 'app/views/components/block/select';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SortUnsortedIcon,
} from 'app/views/components/block/icon';
import TextInput from 'app/views/components/atom/text';
import { ColorInfo } from 'app/style/theme';
import StepperContent from 'app/views/components/block/stepper';
import { useCorporationUsers } from 'app/hooks/corporation/users/list';
import { useCorpAdminUser } from 'app/hooks/corporation/users/admin/change';
import { SimpleRadio } from 'app/views/components/block/radio';
import SdpDivider from 'app/views/components/block/divider';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import useSortGrid from 'app/hooks/sortgrid';

const useStyles = makeStyles({
  dividerMain: {
    margin: '30px 0 30px 0',
  },
  infoArea: {},
  table: {
    margin: '10px 0 10px 0',
    borderTop: `1px solid ${ColorInfo.gray03}`,
  },
  tableService: {
    margin: '10px 0 60px 0',
  },
  tableRow: {
    alignItems: 'center',
    height: 80,
    justifyContent: 'space-between',
    paddingBottom: 18,
    '& > *': {
      border: `1px solid ${ColorInfo.gray03}`,
    },
    '& td:first-child': {
      borderLeft: '0px',
    },
    '& td:last-child': {
      borderRight: '0px',
    },
  },
  tableUserRow: {
    '& td:first-child': {
      background: ColorInfo.gray05,
    },
  },
  tableHeadRow: {
    background: ColorInfo.gray05,
    '& td': {
      textAlign: 'center',
    },
  },
  tableMarkCell: {
    textAlign: 'center',
  },
  descArea: {
    // padding: '40px 0 0px 0',
  },
  descAreaCenter: {
    // padding: '40px 0 0px 0',
    textAlign: 'center',
  },
  inputDesc: {
    textAlign: 'center',
    paddingBottom: '40px',
  },
  actionDesc: {
    textAlign: 'center',
    paddingBottom: '40px',
  },
  noticeDesc: {
    fontSize: '1.3rem',
  },

  search: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child > div': {
      width: 450,
    },
    '& > *:not(:first-child)': {
      marginLeft: 20,
      wordBreak: 'keep-all',
    },
  },
  listPager: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& button:first-child': {
      padding: '10px 0px 10px 65px',
      '&::before': {
        width: 30,
      },
    },
    '& button:last-child': {
      marginLeft: 60,
      padding: '10px 65px 10px 0px',
      '&::before': {
        width: 30,
      },
    },
    '& > *.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  confirm: {
    marginLeft: '0px',
  },
  mainText2: {
    marginLeft: '22px',
    marginBottom: '5px',
    width: '500px',
  },
});

const useDataGridStyles = makeStyles({
  root: {
    marginTop: 10,
    border: 'none',
    borderTop: `1px solid ${ColorInfo.gray03}`,
    borderRadius: '0',
    '& *.MuiDataGrid-iconButtonContainer > *.MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  columnHeader: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    backgroundColor: ColorInfo.gray05,
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& *.MuiTouchRipple-root': {
      left: 10,
    },
  },
  cell: {
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    padding: '10px 16px',
    borderBottom: `1px solid ${ColorInfo.gray03}`,
    borderLeft: `1px solid ${ColorInfo.gray03}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    '&.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  row: {
    height: 70,
    '&.MuiDataGrid-withBorder': {
      border: 'none',
    },
    '&.MuiDataGrid-row:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
  },
});

// 説明Props
interface DescriptionProps {
  screen: number;
}
// 説明
const DescriptionInfo: FC<DescriptionProps> = memo(({ screen }) => {
  const classes = useStyles();
  const Description = () => {
    switch (screen) {
      case 0: // 入力画面
        return (
          <Typography className={classes.descArea}>
            企業管理者を、指定した企業ユーザーに変更します。
            <br />
            新しい企業管理者を一覧から選択のうえ、「確認」ボタンを押してください。
            <br />
            企業管理者に付与されていたすべての権限（アカウント管理権限・サービス利用権限など）が移譲され、サービスがご利用いただけなくなりますので、ご注意ください。
            <br />
            引き続き、ご契約サービスをご利用いただくには、企業管理者またはサービス利用権限を付与できるユーザーより招待を受けてください。
          </Typography>
        );
      case 1: // 確認画面
        return (
          <Typography className={`${classes.descAreaCenter}`}>
            ご入力内容をご確認のうえ、「送信」ボタンを押してください。
          </Typography>
        );
      default:
        // 完了画面
        return (
          <Typography className={`${classes.descAreaCenter}`}>
            企業管理者の変更が完了しました。
            <br />
            新しい企業管理者に、企業管理者変更のお知らせメールを送信しました。
          </Typography>
        );
    }
  };

  return (
    <>
      <Description />
    </>
  );
});

// 操作Props
interface ActionAreaProps {
  screen: number;
  useCorpAdminUserHook: ReturnType<typeof useCorpAdminUser>;
}

// 操作
const ActionAreaInfo: FC<ActionAreaProps> = memo(
  ({ screen, useCorpAdminUserHook }) => {
    const { handleBack, handleNext } = useCorpAdminUserHook;

    return (
      <>
        <Box
          style={{
            marginTop: '80px',
            borderBottom: `1px solid ${ColorInfo.gray04}`,
            width: 1000,
          }}
        />
        {/* ボタン配置 */}
        {screen === 0 && (
          <>
            <Grid
              container
              direction="row"
              style={{ marginTop: 80, marginBottom: 105 }}
              alignItems="center"
            >
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>戻る</ButtonBack>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ flexBasis: 'auto', padding: '0px 12px' }}
              >
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  確認
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </>
        )}

        {screen === 1 && (
          <>
            <Grid
              container
              direction="row"
              style={{ marginTop: 80, marginBottom: 105 }}
              alignItems="center"
            >
              <Grid item xs={3}>
                <ButtonBack onClick={handleBack}>戻る</ButtonBack>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ flexBasis: 'auto', padding: '0px 12px' }}
              >
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  送信
                </ButtonForward>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </>
        )}

        {screen === 2 && (
          <>
            <Grid
              container
              direction="row"
              style={{ marginTop: 80, marginBottom: 105 }}
              alignItems="center"
            >
              <Grid container item xs={12} style={{ flexBasis: 'auto' }}>
                <ButtonForward
                  onClick={handleNext}
                  style={{ width: '600px', margin: 'auto' }}
                >
                  アカウント管理ページへ
                </ButtonForward>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
);

// 表示行数オプション定義
const options = [
  {
    value: '30',
    label: '30件',
  },
  {
    value: '50',
    label: '50件',
  },
  {
    value: '100',
    label: '100件',
  },
];

// 入力画面Props
interface InputAreaProps {
  useCorporationUsersHook: ReturnType<typeof useCorporationUsers>;
  useCorpAdminUserHook: ReturnType<typeof useCorpAdminUser>;
}

// 入力画面
const InputArea: FC<InputAreaProps> = memo(
  ({ useCorporationUsersHook, useCorpAdminUserHook }) => {
    const classes = useStyles();
    const dataGridClasses = useDataGridStyles();

    // 法人ユーザ一覧業務Hooks
    const {
      isPageBackDisabled,
      isPageNextDisabled,
      handleInputEvent,
      handleSearchBack,
      handleSearchNext,
      handleSearch,
      handleSearchDelete,
      corporationUsers,
      handleSearchSort,
      apiCallStatus,
    } = useCorporationUsersHook;
    const { rows } = corporationUsers;

    // 企業管理者変更Hooks
    const { nextAdminUser, setNextAdminUser } = useCorpAdminUserHook;

    // DataGrid定義
    const columns: GridColDef[] = [
      {
        field: 'select',
        headerName: '選択',
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        width: 98,
        renderCell: (params: GridCellParams) => {
          const { id, getValue } = params;
          const scimId = getValue(id, 'scimId')?.toString() || '';
          const email = getValue(id, 'id')?.toString() || '';
          const name = getValue(id, 'name')?.toString() || '';
          const nameKana = getValue(id, 'nameKana')?.toString() || '';
          const departmentName =
            getValue(id, 'departmentName')?.toString() || '';

          // ラジオ選択イベント
          const changeEvent = (value: string, check: boolean) => {
            if (check) {
              setNextAdminUser({
                scimId,
                email,
                name,
                nameKana,
                departmentName,
              });
            }
          };

          return (
            <SimpleRadio
              name="select"
              value={scimId}
              selectedValue={nextAdminUser?.scimId || ''}
              onChange={(value, check) => changeEvent(value, check)}
            />
          );
        },
      },
      {
        field: 'id',
        headerName: 'メールアドレス',
        sortable: true,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        width: 300,
      },
      {
        field: 'name',
        headerName: '氏名',
        sortable: true,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        width: 300,
      },
      {
        field: 'departmentName',
        headerName: '部署名',
        sortable: true,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        width: 300,
      },
    ];

    // SortGrid Hooks
    const { sortModel, handleSortModelChange } = useSortGrid();

    // GridのSort変更時
    useEffect(() => {
      if (sortModel.length !== 1) {
        return;
      }

      // APIパラメータ生成 'email' | 'name' | 'departmentName'
      const sortedColumn = sortModel[0];
      let sortItem: 'email' | 'name' | 'departmentName' | null = null;
      if (sortedColumn.field === 'id') {
        // id
        sortItem = 'email';
      } else if (sortedColumn.field === 'name') {
        // 名前
        sortItem = 'name';
      } else if (sortedColumn.field === 'departmentName') {
        // 部署名
        sortItem = 'departmentName';
      }

      let sortType: 'asc' | 'desc' | null = null;
      if (sortedColumn.sort === 'asc') {
        sortType = 'asc';
      } else if (sortedColumn.sort === 'desc') {
        sortType = 'desc';
      }

      // API呼出し
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (sortItem && sortType) handleSearchSort(sortItem, sortType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortModel]);

    return (
      <>
        <Typography variant="h3">企業ユーザー一覧</Typography>

        {apiCallStatus === 0 && (
          // API呼び出し未完了
          <></>
        )}
        {apiCallStatus === 1 && corporationUsers?.totalCount === 0 && (
          // 初期データ0件
          <Typography variant="body1" className={classes.mainText2}>
            現在、新しい企業管理者に指定できる企業ユーザーはいません。
          </Typography>
        )}
        {((apiCallStatus === 1 && corporationUsers?.totalCount !== 0) ||
          apiCallStatus === 2 ||
          apiCallStatus === 3) && (
          // 初期データ1件以上 / 検索中 / 検索クリア中
          <div>
            <Box className={classes.search}>
              <Box className={classes.searchBox}>
                <TextInput
                  name="searchValue"
                  value={corporationUsers?.searchValue}
                  placeholder="入力してください（メールアドレス、氏名、部署名など）"
                  handleInputEvent={handleInputEvent}
                />
                <SubButton
                  onClick={() => {
                    setNextAdminUser(null);
                    handleSearch();
                  }}
                >
                  検索
                </SubButton>
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => {
                    setNextAdminUser(null);
                    handleSearchDelete();
                  }}
                >
                  検索クリア
                </Link>
              </Box>
              {((apiCallStatus === 2 && corporationUsers?.totalCount !== 0) ||
                apiCallStatus === 1 ||
                apiCallStatus === 3) && (
                // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  style={{ margin: '36px 0 0' }}
                >
                  <Typography variant="body1" style={{ marginRight: 20 }}>
                    全{corporationUsers?.totalCount}件
                  </Typography>
                  <Select
                    name="size"
                    value={corporationUsers?.size}
                    handleInputEvent={(
                      event: ChangeEvent<HTMLInputElement>
                    ) => {
                      setNextAdminUser(null);
                      handleInputEvent(event);
                    }}
                    options={options}
                  />
                </Grid>
              )}
            </Box>

            {apiCallStatus === 2 && corporationUsers?.totalCount === 0 && (
              // 検索した結果データが0件
              <div>
                <br />
                <Typography variant="body1" className={classes.mainText2}>
                  検索条件と一致する連携済みアカウント情報はありません。
                </Typography>
              </div>
            )}
            {((apiCallStatus === 2 && corporationUsers?.totalCount !== 0) ||
              apiCallStatus === 1 ||
              apiCallStatus === 3) &&
              rows !== undefined &&
              rows !== null && (
                // 検索した結果データが1件以上 / API呼出し完了・未検索 / 検索クリア中
                <div>
                  <DataGrid
                    columns={columns}
                    autoHeight
                    hideFooter
                    disableColumnMenu
                    rows={rows.map((row) => ({
                      select: '',
                      id: row.email,
                      name: row.name,
                      departmentName: row.departmentName,
                      scimId: row.scimId,
                      nameKana: row.nameKana,
                    }))}
                    headerHeight={70}
                    rowHeight={70}
                    components={{
                      ColumnSortedAscendingIcon: SortAscendingIcon,
                      ColumnSortedDescendingIcon: SortDescendingIcon,
                      ColumnUnsortedIcon: SortUnsortedIcon,
                    }}
                    classes={{
                      root: dataGridClasses.root,
                      columnHeader: dataGridClasses.columnHeader,
                      cell: dataGridClasses.cell,
                      row: dataGridClasses.row,
                    }}
                    sortingMode="server"
                    onSortModelChange={(model) => handleSortModelChange(model)}
                    sortModel={sortModel}
                    sortingOrder={['asc', 'desc']}
                  />

                  <Toolbar
                    disableGutters
                    variant="dense"
                    className={classes.listPager}
                  >
                    <ButtonLowBack
                      disabled={isPageBackDisabled}
                      onClick={() => {
                        setNextAdminUser(null);
                        handleSearchBack();
                      }}
                      disableRipple
                    >
                      前へ
                    </ButtonLowBack>
                    <ButtonLowForward
                      disabled={isPageNextDisabled}
                      onClick={() => {
                        setNextAdminUser(null);
                        handleSearchNext();
                      }}
                      disableRipple
                    >
                      次へ
                    </ButtonLowForward>
                  </Toolbar>
                </div>
              )}
          </div>
        )}
      </>
    );
  }
);

// 確認画面Props
interface ConfirmAreaProps {
  useCorpAdminUserHook: ReturnType<typeof useCorpAdminUser>;
}
// 確認画面
const ConfirmArea: FC<ConfirmAreaProps> = memo(({ useCorpAdminUserHook }) => {
  const classes = useStyles();
  const { currentAdminUser, nextAdminUser } = useCorpAdminUserHook;
  return (
    <>
      <Box className={classes.infoArea}>
        <Typography variant="h3">変更前</Typography>

        <Table aria-label="simple table" className={classes.table}>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell style={{ width: '400px' }}>
              <Typography>メールアドレス</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.confirm}>
                {currentAdminUser.email}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>氏名</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.confirm}>
                {currentAdminUser.name}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </Box>

      <Box className={classes.infoArea}>
        <Typography variant="h3">変更後</Typography>
        <Typography>以下の企業ユーザーに企業管理者を変更します。</Typography>

        <Table aria-label="simple table" className={classes.table}>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell style={{ width: '400px' }}>
              <Typography>メールアドレス</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.confirm}>
                {nextAdminUser?.email}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>氏名</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.confirm}>
                {nextAdminUser?.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>氏名（カナ）</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.confirm}>
                {nextAdminUser?.nameKana}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={`${classes.tableRow} ${classes.tableUserRow}`}>
            <TableCell>
              <Typography>部署名</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.confirm}>
                {nextAdminUser?.departmentName}
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      </Box>
    </>
  );
});

//  企業管理者変更
export const CompanyAdminChangePage: FC = memo(() => {
  // 法人ユーザ一覧業務Hooks(1:[企業管理者を除く])
  const useCorporationUsersHook = useCorporationUsers({ searchAuthType: '1' });

  // 企業管理者変更Hooks
  const useCorpAdminUserHook = useCorpAdminUser();
  const { screen, globalMessage } = useCorpAdminUserHook;

  // stepper文言
  const steps = ['入力', '確認', '完了'];

  return (
    <Template title="アカウント管理 - 企業管理者変更">
      <StepperContent StepNumber={screen} Steps={steps} />

      <div
        style={{
          marginTop: '52px',
          marginBottom: globalMessage ? '77px' : '',
        }}
      >
        <GlobalErrorMessage message={globalMessage} />
      </div>

      <DescriptionInfo screen={screen} />

      {screen === 0 && (
        <>
          <InputArea
            useCorporationUsersHook={useCorporationUsersHook}
            useCorpAdminUserHook={useCorpAdminUserHook}
          />
        </>
      )}
      {screen === 1 && (
        <>
          <ConfirmArea useCorpAdminUserHook={useCorpAdminUserHook} />
        </>
      )}
      <ActionAreaInfo
        screen={screen}
        useCorpAdminUserHook={useCorpAdminUserHook}
      />
    </Template>
  );
});

export default CompanyAdminChangePage;
