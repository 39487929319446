import { FC, memo, useContext } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInfo } from 'app/style/theme';
import onGaClick from 'app/utils/gaclick';
import { OnOutDmpClick } from 'app/utils/dmp';
import { AuthContext } from 'app/hooks/context/auth';

const useStyles = makeStyles(() => ({
  // テキストリンク
  textLink: {
    fontSize: '1.5rem',
    color: ColorInfo.blue02,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
  },
  btnArrow: {
    position: 'relative',
    padding: '10px 105px 10px 0',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: ColorInfo.blue01,
    fontSize: '1.5rem',
    fontWeight: 500,
    border: 0,
    verticalAlign: 'middle',
    textDecoration: 'none',
    transition: 'all 0.3s',
    '&::before': {
      position: 'absolute',
      top: '50%',
      right: 15,
      content: "''",
      width: 70,
      height: 1,
      backgroundColor: ColorInfo.blue01,
      transition: 'all 0.3s',
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-6px)',
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 15px 7px 0',
      borderColor: `transparent transparent ${ColorInfo.gray01} transparent`,
      transition: 'all 0.3s',
    },
    '&:hover': {
      color: ColorInfo.blue02,
      '&::before': {
        backgroundColor: ColorInfo.blue02,
      },
      '&::after': {
        borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
      },
    },
  },
}));
// インタフェース
interface LinkProps {
  href: string;
  text: string;
  gaTagId?: string;
}
const doNothing = () => {
  // 何もしない関数
};
export const OuterBlankLink: FC<LinkProps> = memo(({ href, text, gaTagId }) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  const classes = useStyles();
  return (
    <>
      <Link
        href={href}
        className={classes.textLink}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          if (gaTagId) {
            // GAタグ
            onGaClick(gaTagId);
            // DMPビーコン
            OnOutDmpClick(href, authInfo?.scimId);
          } else {
            doNothing();
          }
        }}
      >
        {text}
      </Link>
    </>
  );
});

export const OuterBlankLinkArrow: FC<LinkProps> = memo(
  ({ href, text, gaTagId }) => {
    // 認証コンテキスト
    const { authInfo } = useContext(AuthContext);
    const classes = useStyles();
    return (
      <>
        <Link
          href={href}
          className={classes.btnArrow}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            if (gaTagId) {
              onGaClick(gaTagId);
              // DMPビーコン
              OnOutDmpClick(href, authInfo?.scimId);
            } else {
              doNothing();
            }
          }}
        >
          {text}
        </Link>
      </>
    );
  }
);

export const TextLink: FC<LinkProps> = memo(({ href, text }) => {
  const classes = useStyles();
  return (
    <>
      <Link href={href} className={classes.textLink}>
        {text}
      </Link>
    </>
  );
});
