import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { FC } from 'react';
import { ButtonForward } from 'app/views/components/block/button';

// スタイル
const useStyles = makeStyles(() => ({
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120px',
    marginTop: '50px',
  },
  confirmButton: {
    marginBottom: '11px',
  },
}));

const CorpResignCompletePage: FC<{
  handleNext: () => void;
}> = ({ handleNext }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.description}>
        <Typography>企業登録の解除（退会）が完了しました。</Typography>
        <Typography>ご利用ありがとうございました。</Typography>
      </Box>
      <>
        <Box sx={{ marginTop: '50px' }}>
          <Grid container direction="row" className={classes.confirmButton}>
            <Grid>
              <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                トップへ
              </ButtonForward>
            </Grid>
          </Grid>
        </Box>
      </>
    </>
  );
};

export default CorpResignCompletePage;
