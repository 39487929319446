import Template from 'app/views/components/template';

import './page_terms.css';
import { Link } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { FC, memo, useContext } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import onGaClick from 'app/utils/gaclick';
import { OnOutDmpClick } from 'app/utils/dmp';

// サイト利用について - サイト利用規約 | Toyota Biz Center

const AttributePage: FC = memo(() => {
  const { authInfo } = useContext(AuthContext);
  return (
    <Template title="サイト利用について - サイト利用規約">
      <div className="main-wrap">
        <p>
          当ウェブサイトは、トヨタ自動車株式会社（以下、当社といいます）によって運営されています。
          <br />
          本利用規約記載の諸条件に同意された場合のみ、当ウェブサイトをご利用ください。
        </p>

        <h2 className="title-h3">免責事項、責任の限定</h2>
        <p className="main-text">
          当社は当ウェブサイトの情報の正確性、適切性、確実性またはご利用になるお客様の特定の目的への適合性について、いかなる保証をするものではありません。当社は、当ウェブサイトの内容に誤りがあった場合でも、一切の責任を負わないものとします。また、当ウェブサイトのご利用によって生じたいかなる損害、およびその損害の可能性について当社が通知されていた場合も含めて、一切の責任を負わないものとします。
          <br />
          本項は準拠法の範囲内においてのみ適用されます。
          <br />
          <br />
          当社は当ウェブサイトのコンテンツやURLを予告なしに変更、修正および中止することがあります。あらかじめご了承ください。当社は、当ウェブサイトのコンテンツやURLの変更、修正および中止によって生じるいかなる損害に対し、一切の責任を負わないものとします。
        </p>

        <h2 className="title-h3">著作権、商標権</h2>
        <p className="main-text">
          当ウェブサイトで公開されている文章、画像、図画、アニメーション、映像、音声などの一連の情報（以下、コンテンツといいます）は全て、当社の著作権およびその他知的所有権の対象となります。当ウェブサイトのコンテンツのご利用は個人使用（非商用）のみに限ります。また、目的の如何を問わず、当社の書面による承諾を得ずにコンテンツの全部または一部を複製、転用、改変、販売など二次利用することはできません。トヨタの名称やロゴは当社の商標です。また、いかなる方法においてもトヨタのロゴの使用、ダウンロード、コピーおよび配布を禁じます。その他商標および商標名は各々の所有者に属します。
        </p>

        <h2 className="title-h3">当ウェブサイトへのリンク</h2>
        <p className="main-text">
          当ウェブサイトにリンクされる場合、当サイトのトップページ（
          <Link className="text-link" to={PageInfo.TOP.path}>
            https://biz.toyota
          </Link>
          ）に設定してください。
          <br />
          また、以下のいずれかに該当するようなリンクは固くお断りいたします。
        </p>
        <ul className="list-link">
          <li>
            公序良俗や法律に反するまたはその可能性のあるコンテンツを含むウェブサイトからのリンク。
          </li>
          <li>
            フレーム機能を使用したリンクや当社のコンテンツであることを不明にするなど、当社の著作権を侵害するその他形式のリンク。
          </li>
          <li>
            当社やその製品、サービスを誹謗中傷、信用を毀損するコンテンツを含むウェブサイトからのリンク。
          </li>
          <li>営利目的のリンク（利益を得る目的で張られたリンク）。</li>
        </ul>
        <p>
          当ウェブサイトにリンクされたサイトについて、第三者から損害に対する賠償を求められたり、その他苦情や請求を受けたとしても、当社はいかなる場合もそれらに対して一切の責任を負わないものとします。
          <br />
          当ウェブサイトにリンクする場合、第三者からの苦情や請求に関して、リンク先である当社にいかなる損害も与えないようにしてください。
          <br />
          理由の如何を問わず当ウェブサイトへのリンクを解除するように当社が求めた場合、ただちにリンク解除を行う必要があります。
          <br />
          当ウェブサイトにリンクされた第三者のウェブサイトは各運営担当者の責任の下で管理されており、当該ウェブサイトおよびそのコンテンツについて、当社は一切の責任を負いません。
        </p>

        <h2 className="title-h3">外部サイトへのリンク</h2>
        <p className="main-text">
          当ウェブサイトは、当ウェブサイトから完全に独立した他サイトへのリンクが設定されている場合があります。
          <br />
          当社は当該リンク先のウェブサイトに含まれる情報の正確性、確実性、信頼性に関して一切の保証をいたしません。また、他サイトへのリンクは、その形式を問わず当該第三者や第三者機関のウェブサイト、製品、サービスに対し当社が推奨をするものではありません。
        </p>

        <h2 className="title-h3">環境</h2>
        <h3 className="title-h3_sub">1. 推奨動作環境</h3>
        <h4 className="title-h4">ブラウザ</h4>
        <dl className="terms-tbl">
          <dt>Microsoft Edge</dt>
          <dd>最新版（Windows）</dd>
          <dt>Mozilla Firefox</dt>
          <dd>最新版（Windows/Macintosh/iOS/Android）</dd>
          <dt>Google Chrome</dt>
          <dd>最新版（Windows/Macintosh/iOS/Android）</dd>
          <dt>Safari</dt>
          <dd>最新版（Windows/Macintosh/iOS）</dd>
        </dl>
        <ul className="notes">
          <li>
            ※ご利用のブラウザのバージョンが古い場合、一部のコンテンツで正しく表示されない場合があります。
            <br />
            ご覧になる際には、ご利用のブラウザバージョンをご確認のうえ、最新のものにアップデートしていただきますようお願いします。
          </li>
          <li>
            ※各コンテンツの表示における体感速度は、ご利用の端末のスペックや通信環境に依存する場合があります。
            <br />
            CPUやメモリのスペックが満足でなかった場合、スムーズな操作ができない場合がありますことを予めご了承ください。
          </li>
        </ul>

        <h4 className="title-h4">推奨画面サイズ</h4>
        <dl className="terms-tbl">
          <dt>PC</dt>
          <dd>横幅 768pixel～1200pixel</dd>
        </dl>
        <ul className="notes">
          <li>
            ※当サイトでは、使用しているブラウザの画面サイズによってレイアウトが崩れる場合があります。
          </li>
        </ul>

        <h3 className="title-h3_sub">2. JavaScriptおよびCookie</h3>
        <p className="main-text">
          当ウェブサイトではJavaScriptを使用しています。お使いのブラウザがJavaScriptに対応していない場合やお使いのブラウザの機能が無効になっている場合、当ウェブサイトが閲覧できなかったり、コンテンツが正しく表示されないことがあります。当ウェブサイトを閲覧できるように、JavaScriptを有効にしてください。
          <br />
          当ウェブサイトではCookieを使用しております。これは当ウェブサイトの利用状況を把握することにより、当ウェブサイトのコンテンツの充実や利便性の向上を目的としたものです。Cookieにより、個人情報が取得されることは一切ありません。お使いのブラウザの設定により、Cookieの受信、発行時の通知、および受信拒否の設定などが可能です。
          <br />
          当社によるCookieの利用やプライバシー保護について不明な点がある場合は、
          <a
            className="text-link"
            href="https://global.toyota/jp/privacy-notice/"
            target="_blank"
            rel="noreferrer"
            onClick={(event) => {
              event.preventDefault();
              // GAタグ:個人情報保護に関わる基本方針押下
              onGaClick('B-000-010-001');
              // DMPビーコンタグ：個人情報保護に関わる基本方針押下
              OnOutDmpClick(
                'https://global.toyota/jp/privacy-notice/',
                authInfo?.scimId
              );
              window.open('https://global.toyota/jp/privacy-notice/', '_blank');
            }}
          >
            個人情報保護に関する基本方針
          </a>
          をご参照ください。
        </p>

        <h3 className="title-h3_sub">3. プラグイン</h3>
        <p className="main-text">
          当ウェブサイトでは、文章や映像、音声のご利用にあたり、各種プラグインソフトウェアが必要となる場合があります。
          <br />
          ご利用の際は以下のプラグインアプリケーションを各社のウェブサイトよりダウンロードしてください。
          <br />
          <a
            className="text-link"
            href="https://www.adobe.com/jp/acrobat/pdf-reader.html"
            target="_blank"
            rel="noreferrer"
            onClick={(event) => {
              event.preventDefault();
              // GAタグ:AdobeReaderのダウンロード押下
              onGaClick('B-000-010-002');
              // DMPビーコンタグ：個人情報保護に関わる基本方針押下
              OnOutDmpClick(
                'https://www.adobe.com/jp/acrobat/pdf-reader.html',
                authInfo?.scimId
              );
              window.open(
                'https://www.adobe.com/jp/acrobat/pdf-reader.html',
                '_blank'
              );
            }}
          >
            Adobe Readerのダウンロード
          </a>
        </p>

        <h3 className="title-h3_sub">
          4. カスケーディングスタイルシート（CSS）を有効にする
        </h3>
        <p className="main-text">
          当ウェブサイトでは、視覚効果のためにCSSを採用しております。お使いのブラウザでCSSを無効にされている場合、ウェブページ閲覧が行えなくなったり、ページが正しく表示されないことがあります。ご覧になる際にはブラウザ設定でCSSを有効にしてください。
        </p>

        <h2 className="title-h3">準拠法および管轄裁判所</h2>
        <p className="main-text">
          本利用規約記載のこれら諸条件の解釈および適用は、他に別段の定めのない限り、日本国法に準拠するものとします。また、これら諸条件にかかわる全ての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属管轄裁判所とするものとします。
        </p>

        <div className="back-btn">
          <Link className="btn-base pattern1" to={PageInfo.TOP.path}>
            トップへ
          </Link>
        </div>
      </div>
    </Template>
  );
});

export default AttributePage;
