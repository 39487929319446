import { AuthContext } from 'app/hooks/context/auth';
import { PageInfo, UserTypeConst } from 'app/utils/constants';
import { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

// ログイン済みであれば遷移可能
export const AuthenticatedRoute: FC<RouteProps> = ({
  component,
  path,
  exact,
}) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  return authInfo?.isAuth ? (
    <Route component={component} path={path} exact={exact} />
  ) : (
    <Redirect to={PageInfo.TOP.path} />
  );
};

export const AuthExcludePersonRoute: FC<RouteProps> = ({
  component,
  path,
  exact,
}) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  return authInfo?.isAuth && authInfo?.userType !== UserTypeConst.PERSON ? (
    <Route component={component} path={path} exact={exact} />
  ) : (
    <Redirect to={PageInfo.TOP.path} />
  );
};

// ログイン済み、かつID連携済みユーザ(corporationIdを保持)のみ遷移可能
export const AuthIdFederateRoute: FC<RouteProps> = ({
  component,
  path,
  exact,
}) => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  return authInfo?.isAuth && authInfo?.corporationId ? (
    <Route component={component} path={path} exact={exact} />
  ) : (
    <Redirect to={PageInfo.TOP.path} />
  );
};
