import { FC, memo, useState } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core';
import { ColorInfo } from 'app/style/theme';

// スタイル
const useStyles = makeStyles(() => ({
  icon: {
    color: ColorInfo.blue02,
    fontSize: '3rem',
  },
  summary: {
    background: 'white',
    '&.is-primary': {
      background: ColorInfo.gray05,
      borderTop: `1px solid ${ColorInfo.gray03}`,
      borderBottom: `1px solid ${ColorInfo.gray03}`,
    },
  },
  summaryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px',
    '&.is-primary': {
      padding: 0,
    },
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  summaryTitle: {
    '&.is-primary': {
      fontSize: '2.4rem',
    },
  },
  primaryAccordion: {
    '&.MuiAccordion-root': {
      marginBottom: '16px',
    },
    '&::before': {
      top: '0',
    },
  },
  accordion: {
    '&::before': {
      top: '0',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  titleEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '735px',
  },
}));

// インタフェース
interface AccordionProps {
  title: string | React.ReactNode;
  isExpanded: boolean;
  disabled?: boolean;
  isPrimary?: boolean;
  isTitleEllipsis?: boolean;
}

const MainAccordion: FC<AccordionProps> = memo(
  ({ children, title, disabled, isPrimary, isExpanded, isTitleEllipsis }) => {
    const classes = useStyles();
    // アコーディオン
    const [expanded, setExpanded] = useState<boolean>(isExpanded);
    const primaryClassName = isPrimary ? 'is-primary' : '';

    const titleEllipsisClass = (): string => {
      if (isTitleEllipsis) {
        return classes.titleEllipsis;
      }
      return '';
    };

    return (
      <Accordion
        expanded={expanded}
        elevation={0}
        className={isPrimary ? classes.primaryAccordion : classes.accordion}
      >
        <AccordionSummary
          classes={{ root: classes.summary, content: classes.summaryContent }}
          className={primaryClassName}
          style={
            !isPrimary && !expanded
              ? { borderBottom: `1px solid ${ColorInfo.gray03}` }
              : {}
          }
        >
          {typeof title === 'string' ? (
            <Typography
              className={`${
                classes.summaryTitle
              } ${primaryClassName} ${titleEllipsisClass()}`}
            >
              {title}
            </Typography>
          ) : (
            title
          )}

          {!disabled && expanded && (
            <IndeterminateCheckBoxIcon
              className={classes.icon}
              onClick={() => setExpanded(!expanded)}
            />
          )}
          {!disabled && !expanded && (
            <AddBox
              className={classes.icon}
              onClick={() => setExpanded(!expanded)}
            />
          )}
        </AccordionSummary>
        <AccordionDetails className={`${classes.detail} ${primaryClassName}`}>
          {children}
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default MainAccordion;
