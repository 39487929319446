import { useState, useCallback } from 'react';
import { GridSortModel } from '@material-ui/data-grid';

// DataGrid Sort hooks
const useSortGrid = () => {
  // sortModel
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const handleSortModelChange = useCallback(
    (newModel: GridSortModel) => {
      if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
        setSortModel(newModel);
      }
    },
    [sortModel]
  );

  return { sortModel, handleSortModelChange };
};

export default useSortGrid;
