import { useContext } from 'react';
import Template from 'app/views/components/template';
import {
  Typography,
  Link,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { useContractTerms } from 'app/hooks/contract/terms';
import { OuterLinkInfo } from 'app/utils/constants';
import { AuthContext } from 'app/hooks/context/auth';
import { OnOutDmpClick } from 'app/utils/dmp';
import onGaClick from 'app/utils/gaclick';
import { ColorInfo } from 'app/style/theme';
import { OuterBlankLink } from 'app/views/components/atom/link';

const styles = () =>
  createStyles({
    guide: {
      marginTop: 30,
      '& ul': {
        padding: 0,
        borderBottom: `1px solid ${ColorInfo.gray03}`,
        borderTop: `1px solid ${ColorInfo.gray03}`,
      },
      '& li': {
        padding: '0 25px',
      },
      '& li a': {
        display: 'block',
        padding: '22px 0',
        textDecoration: 'none',
        color: ColorInfo.blue01,
        fontSize: '1.5rem',
        '&:hover': {
          color: ColorInfo.blue02,
          textDecoration: 'underline',
        },
      },
    },
  });
const ContractTermsPage = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    // 利用規約一覧業務カスタムHooks
    const { contractTerms } = useContractTerms();
    const { rows } = contractTerms;
    // 認証コンテキスト
    const { authInfo } = useContext(AuthContext);
    // レンダリング
    return (
      <>
        <Template title="規約一覧">
          <div className={classes.guide}>
            <Typography variant="h3" style={{ marginTop: '90px' }}>
              利用規約
            </Typography>
            <ul>
              {rows !== undefined &&
                rows !== null &&
                rows.map((row, index) => (
                  <li
                    style={{
                      borderTop: `${
                        index !== 0 ? `1px solid ${ColorInfo.gray03}` : ''
                      }`,
                    }}
                  >
                    <OuterBlankLink
                      href={`${OuterLinkInfo.TERMS_LINK_URL.path}${row.clientId}`}
                      text={row.contractServiceName}
                      gaTagId="B-050-006-001"
                    />
                  </li>
                ))}
            </ul>
          </div>
        </Template>
      </>
    );
  }
);
export default ContractTermsPage;
