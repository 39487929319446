import { Box } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { SubButton } from 'app/views/components/block/button';
import { messageConv } from 'app/utils/constants';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    contentsMainArea: {
      display: 'block',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '25px',
      textAlign: 'center',
    },
    buttonArea: {
      display: 'flex',
      height: '60px',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 18,
    },
    dialogActionArea: {
      justifyContent: 'center',
      marginTop: '45px',
      marginBottom: '20px',
    },
  });

interface ConfirmDialogProps extends WithStyles<typeof styles> {
  msg: string;
  open: boolean;
  doYes: any;
  setOpen: (open: boolean) => void;
  onlyOk?: boolean;
}

const ConfirmDialog = withStyles(styles)(
  ({
    msg,
    open,
    doYes,
    setOpen,
    onlyOk = false,
    classes,
  }: ConfirmDialogProps) => {
    // YES押下処理
    const yesEvent = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      await doYes();
      setOpen(false);
    };

    // No押下処理
    const noEvent = () => {
      setOpen(false);
    };

    return (
      <>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Box className={classes.contentsMainArea}>
              <Typography>{messageConv(msg)}</Typography>
            </Box>

            <DialogActions className={classes.dialogActionArea}>
              <Box className={classes.buttonArea}>
                {!onlyOk && (
                  <SubButton
                    onClick={() => {
                      noEvent();
                    }}
                    style={{ margin: '10px 30px' }}
                  >
                    キャンセル
                  </SubButton>
                )}
                <SubButton
                  onClick={() => {
                    void yesEvent();
                  }}
                  style={{ margin: '10px 30px' }}
                >
                  OK
                </SubButton>
              </Box>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

export default ConfirmDialog;
