import { FC, useContext } from 'react';
import { Link as ALink } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import Template from 'app/views/components/template';
import {
  PageInfo,
  OuterLinkInfo,
  CorporationStatusConst,
} from 'app/utils/constants';
import { ColorInfo } from 'app/style/theme';
import { ButtonLowForward } from 'app/views/components/block/button';
import {
  GlobalErrorMessage,
  GlobalErrorHtml,
} from 'app/views/components/block/message';
import useServiceUseList from 'app/hooks/service/use';
import useRequestCount from 'app/hooks/corporation/users/approval/requestCount';
import { AuthContext } from 'app/hooks/context/auth';
import onGaClick from 'app/utils/gaclick';
import { OnDmpClick, OnOutDmpClick } from 'app/utils/dmp';
import { useAdminCorpMng } from 'app/hooks/corporation/detail';

const requestInfoStyles = () =>
  createStyles({
    spacer: {
      height: '75px',
    },
    infoArea: {
      marginTop: '15px',
      '& div': {
        marginTop: '15px',
      },
    },
    infoLink: {
      textDecoration: 'none',
      color: ColorInfo.red01,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });

const RequestInfo = withStyles(requestInfoStyles)(
  ({ classes }: WithStyles<typeof requestInfoStyles>) => {
    // Hooks
    const { authInfo } = useContext(AuthContext);
    const { count } = useRequestCount();
    const history = useHistory();
    const { adminCorpMng } = useAdminCorpMng();

    // 表示非表示フラグ定義
    const counterFlag = authInfo?.authorityApproval === '1' && count > 0;
    const changeRequestingFlag =
      authInfo?.corporationStatus === CorporationStatusConst.CHANGE_REQUEST;
    const changeRequestRejectFlag =
      authInfo?.corporationStatus === CorporationStatusConst.CHANGE_REJECT;
    const spacerFlag =
      changeRequestingFlag || changeRequestRejectFlag || counterFlag;
    const adminCorpMngFlag =
      adminCorpMng.administratorEmail === authInfo?.email;

    return (
      <>
        {spacerFlag && adminCorpMngFlag && <div className={classes.spacer} />}
        {counterFlag && (
          <div className={classes.infoArea}>
            <Link
              className={classes.infoLink}
              to={{
                pathname: PageInfo.ACCOUNT_MANAGE.path,
                state: { scrollTo: 'idRequest' },
              }}
            >
              <GlobalErrorMessage
                message={`企業ユーザー登録の申請が${count}件あります。`}
              />
            </Link>
          </div>
        )}
        {changeRequestRejectFlag && adminCorpMngFlag && (
          <div className={classes.infoArea}>
            <GlobalErrorHtml>
              企業情報の変更申請が差し戻されました。
              <br />
              申請内容の確認・修正、または取り下げを希望される方は
              <ALink
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.CORP_INFO_EDIT_INPUT.path);
                }}
              >
                <span>こちら</span>
              </ALink>
              からお手続きいただけます。
            </GlobalErrorHtml>
          </div>
        )}
        {changeRequestingFlag && adminCorpMngFlag && (
          <div className={classes.infoArea}>
            <GlobalErrorHtml>
              企業情報の変更申請を受け付けました。
              <br />
              申請内容の確認・修正、または取り下げは
              <ALink
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(PageInfo.CORP_INFO_EDIT_INPUT.path);
                }}
              >
                <span>こちら</span>
              </ALink>
              からお手続きいただけます。
            </GlobalErrorHtml>
          </div>
        )}
      </>
    );
  }
);

const serviceUseStyles = () =>
  createStyles({
    mypageUsing: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > h3': {
        marginTop: '80px',
      },
    },
    btnArrow: {
      position: 'relative',
      padding: '10px 105px 10px 0',
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: ColorInfo.blue01,
      fontSize: '1.5rem',
      fontWeight: 500,
      border: 0,
      verticalAlign: 'middle',
      textDecoration: 'none',
      transition: 'all 0.3s',
      '&::before': {
        position: 'absolute',
        top: '50%',
        right: '15px',
        content: '',
        width: '70px',
        height: '1px',
        backgroundColor: ColorInfo.blue01,
        transition: 'all 0.3s',
      },
      '&::after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-6px)',
        content: '',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 15px 7px 0px',
        borderColor: `transparent transparent ${ColorInfo.blue01} transparent`,
        transition: 'all 0.3s',
      },
      '&:hover': {
        color: ColorInfo.blue02,
      },
      '&:hover::before': {
        backgroundColor: ColorInfo.blue02,
      },
      '&:hover::after': {
        borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
      },
    },
    mypageUsingList: {
      borderTop: `1px solid ${ColorInfo.gray03}`,
      '& + ul': {
        borderTop: 'none',
      },
      '& li': {
        borderBottom: `1px solid ${ColorInfo.gray03}`,
      },
      '& li a': {
        display: 'block',
        textDecoration: 'none',
      },
    },
    mypageService: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      textAlign: 'left',
      color: ColorInfo.blue01,
      '& + dl': {
        borderTop: 'none',
      },
      '& dt': {
        width: '340px',
        padding: '20px 25px',
        fontSize: '1.6rem',
        lineHeight: 1.4,
        backgroundColor: ColorInfo.gray05,
        borderRight: `1px solid ${ColorInfo.gray03}`,
      },
      '& dt span': {
        fontSize: '1.4rem',
      },
    },
    company: {
      width: '330px',
      lineHeight: 1.4,
      padding: '0 0 0 25px',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    period: {
      width: '330px',
      lineHeight: 1.4,
      padding: '0 0 0 25px',
    },
    mypageServiceLink: {
      width: '100%',
      '&:hover': {
        color: ColorInfo.blue01,
      },
    },
    mypageServiceNoLink: {
      '& dd': {
        fontSize: '1.5rem',
      },
      '&:hover': {
        color: ColorInfo.blue01,
        textDecoration: 'none',
      },
    },
    serviceEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
    mainText2: {
      marginLeft: '22px',
      marginBottom: '5px',
      width: '500px',
    },
  });

const ServiceUse = withStyles(serviceUseStyles)(
  ({ classes }: WithStyles<typeof serviceUseStyles>) => {
    // Hooks
    // const { eventServiceTerms } = useOneId();
    const { serviceUseInfoList, apiCallStatus } = useServiceUseList('2');
    const { rows } = serviceUseInfoList;
    const { authInfo } = useContext(AuthContext);

    // 画面遷移
    const history = useHistory();
    const handleUseService = () => {
      history.push(PageInfo.SERVICE_USE.path);
    };

    // 利用サービスへの転送
    const redirectToService = (url: string) => {
      window.open(url, '_blank');
    };

    // サービスリスト表示
    const SeviceListContent = () =>
      rows?.map((serviceInfo) => (
        <>
          <ul className={classes.mypageUsingList}>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {serviceInfo.contractServiceScreenUse === '1' ? (
                <ALink
                  component="button"
                  variant="body1"
                  className={classes.mypageServiceLink}
                  onClick={() => {
                    // GAタグ:マイページ-ご利用中のサービス-各サービス押下
                    onGaClick('B-000-004-001');
                    // DMPビーコンタグ：マイページ-ご利用中のサービス-各サービス押下
                    OnDmpClick('B-000-004-001', authInfo?.scimId);
                    redirectToService(serviceInfo.contractServiceUri);
                  }}
                >
                  <dl className={classes.mypageService}>
                    <dt>
                      <Typography className={classes.serviceEllipsis}>
                        {serviceInfo.contractServiceName}
                      </Typography>
                      <Typography className={classes.serviceEllipsis}>
                        {serviceInfo.contractName}
                      </Typography>
                    </dt>
                    <dd className={classes.company}>
                      {serviceInfo.contractCorporationName}
                    </dd>
                    <dd className={classes.period}>
                      契約期間：{serviceInfo.contractDateStart}〜
                      {serviceInfo.contractDateEnd}
                    </dd>
                  </dl>
                </ALink>
              ) : (
                <ALink className={classes.mypageServiceNoLink}>
                  <dl className={classes.mypageService}>
                    <dt>
                      <Typography className={classes.serviceEllipsis}>
                        {serviceInfo.contractServiceName}
                      </Typography>
                      <Typography className={classes.serviceEllipsis}>
                        {serviceInfo.contractName}
                      </Typography>
                    </dt>
                    <dd className={classes.company}>
                      {serviceInfo.contractCorporationName}
                    </dd>
                    <dd className={classes.period}>
                      契約期間：{serviceInfo.contractDateStart}〜
                      {serviceInfo.contractDateEnd}
                    </dd>
                  </dl>
                </ALink>
              )}
            </li>
          </ul>
        </>
      ));

    return (
      <>
        {apiCallStatus === 0 && (
          // Api呼出し未完了
          <></>
        )}

        {apiCallStatus === 1 && rows && rows?.length !== 0 ? (
          <div className={classes.mypageUsing}>
            <Typography variant="h3">ご利用可能なサービス</Typography>
            <ButtonLowForward
              onClick={handleUseService}
              style={{ marginTop: '70px' }}
            >
              ご利用可能なサービス一覧
            </ButtonLowForward>
          </div>
        ) : (
          apiCallStatus === 1 && (
            <div>
              <div className={classes.mypageUsing}>
                <Typography variant="h3">ご利用可能なサービス</Typography>
              </div>
              <Typography variant="body1" className={classes.mainText2}>
                現在、ご利用可能なサービスはありません。
              </Typography>
            </div>
          )
        )}
        {SeviceListContent()}
      </>
    );
  }
);

const bunnerStyles = () =>
  createStyles({
    list: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      marginTop: 80,
      '& li': {
        width: 470,
      },
      '& li a': {
        display: 'block',
        height: '100%',
        padding: '35px 40px',
        backgroundColor: ColorInfo.blue05,
        borderRadius: 4,
        color: ColorInfo.blue02,
        textDecoration: 'none',
        transition: 'all 0.3s',
      },
      '& li a:hover': {
        color: ColorInfo.white01,
        backgroundColor: ColorInfo.blue03,
        '& > div:first-child::before': {
          backgroundColor: ColorInfo.white01,
        },
        '& > div:first-child::after': {
          borderColor: `transparent transparent ${ColorInfo.white01} transparent`,
        },
      },
    },
    title: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      position: 'relative',
      '&::before': {
        position: 'absolute',
        top: '50%',
        right: 15,
        content: "''",
        width: 35,
        height: 1,
        backgroundColor: ColorInfo.blue02,
        transition: 'all 0.3s',
      },
      '&::after': {
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-6px)',
        content: "''",
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 15px 7px 0',
        borderColor: `transparent transparent ${ColorInfo.blue02} transparent`,
        transition: 'all 0.3s',
      },
      '& svg': {
        fill: 'currentColor',
      },
    },
    inner: {
      marginLeft: 15,
      fontSize: '1.8rem',
      fontWeight: 600,
    },
    text: {
      marginTop: 20,
      letterSpacing: 1,
      color: 'inherit',
    },
  });

const Banners = withStyles(bunnerStyles)(
  ({ classes }: WithStyles<typeof bunnerStyles>) => (
    <ul className={classes.list}>
      <li>
        <Link to={PageInfo.CONTRACT.path}>
          <div className={classes.title}>
            <svg
              viewBox="0 0 40 40"
              width="40"
              height="40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="7.75" y="8" width="19" height="1.5" />
              <rect x="7.75" y="13" width="19" height="1.5" />
              <rect x="7.75" y="18" width="9" height="1.5" />
              <path d="M20.75,37.5h-16A2.74,2.74,0,0,1,2,34.77H2v-30A2.74,2.74,0,0,1,4.72,2h24A2.74,2.74,0,0,1,31.5,4.72v11H30v-11A1.26,1.26,0,0,0,28.76,3.5h-24A1.25,1.25,0,0,0,3.5,4.74h0v30A1.25,1.25,0,0,0,4.74,36h16Z" />
              <path d="M26.59,34.11a7.83,7.83,0,1,1,7.83-7.83A7.83,7.83,0,0,1,26.59,34.11Zm0-14.15a6.33,6.33,0,1,0,6.33,6.32A6.32,6.32,0,0,0,26.59,20Z" />
              <rect
                x="33.07"
                y="30.63"
                width="1.5"
                height="6.43"
                transform="translate(-14.02 33.83) rotate(-45)"
              />
            </svg>
            <span className={classes.inner}>契約一覧</span>
          </div>
          <Typography className={classes.text}>
            ご契約サービスの利用状況や契約内容の
            <br />
            ご確認はこちら。
          </Typography>
        </Link>
      </li>
      <li>
        <Link to={PageInfo.INTRODUCE.path}>
          <div className={classes.title}>
            <svg
              viewBox="0 0 40 40"
              width="40"
              height="40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18.67,21a2.17,2.17,0,1,1,2.17-2.17A2.17,2.17,0,0,1,18.67,21Zm0-2.84a.67.67,0,1,0,.67.67A.67.67,0,0,0,18.67,18.18Z" />
              <path d="M18.67,26.71a2.17,2.17,0,1,1,2.17-2.17A2.17,2.17,0,0,1,18.67,26.71Zm0-2.84a.67.67,0,1,0,.67.67A.67.67,0,0,0,18.67,23.87Z" />
              <path d="M18.67,32.39a2.17,2.17,0,1,1,2.17-2.17A2.17,2.17,0,0,1,18.67,32.39Zm0-2.84a.67.67,0,1,0,.67.67A.67.67,0,0,0,18.67,29.55Z" />
              <rect x="21.75" y="18" width="10" height="1.5" />
              <rect x="21.75" y="24" width="10" height="1.5" />
              <rect x="21.75" y="29" width="10" height="1.5" />
              <path d="M36.5,39.5H11V9H36.5ZM12.5,38H35V10.5H12.5Z" />
              <polygon points="8.5 33.75 7 33.75 7 4 31.75 4 31.75 5.5 8.5 5.5 8.5 33.75" />
              <polygon points="4.5 29.75 3 29.75 3 0 27.75 0 27.75 1.5 4.5 1.5 4.5 29.75" />
            </svg>
            <span className={classes.inner}>サービス紹介</span>
          </div>
          <Typography className={classes.text}>
            各種サービス内容をご紹介します。
            <br />
            サービスのお申込みを検討中の方はこちら。
          </Typography>
        </Link>
      </li>
    </ul>
  )
);

const otherButtonStyles = () =>
  createStyles({
    list: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      marginTop: 30,
      '& li': {
        width: 300,
        height: 210,
      },
      '& li a': {
        display: 'block',
        height: '100%',
        padding: '30px 0',
        backgroundColor: ColorInfo.blue05,
        color: ColorInfo.blue02,
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'all 0.3s',
      },
      '& li a:hover': {
        color: ColorInfo.white01,
        backgroundColor: ColorInfo.blue03,
      },
      '& li a svg': {
        display: 'inline-block',
        fill: 'currentColor',
      },
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 60,
      marginBottom: 20,
      color: 'inherit',
      fontSize: '1.8rem',
      lineHeight: 1.4,
      fontWeight: 600,
    },
  });

const OtherButtons = withStyles(otherButtonStyles)(
  ({ classes }: WithStyles<typeof otherButtonStyles>) => {
    const history = useHistory();
    const { authInfo } = useContext(AuthContext);
    return (
      <ul className={classes.list}>
        <li>
          <Link to={PageInfo.ACCOUNT_MANAGE.path}>
            <Typography className={classes.inner}>アカウント管理</Typography>
            <svg
              viewBox="0 0 48 48"
              width="48"
              height="48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M44.92,46.5H36.75V45h8.17a.09.09,0,0,0,.08-.08V36.75h1.5v8.17A1.58,1.58,0,0,1,44.92,46.5Z" />
              <path d="M46.5,11.75H45V3.58a.09.09,0,0,0-.08-.08H36.75V2h8.17A1.58,1.58,0,0,1,46.5,3.58Z" />
              <path d="M3.5,11.75H2V3.58A1.58,1.58,0,0,1,3.58,2h8.17V3.5H3.58a.09.09,0,0,0-.08.08Z" />
              <path d="M11.75,46.5H3.58A1.58,1.58,0,0,1,2,44.92V36.75H3.5v8.17a.09.09,0,0,0,.08.08h8.17Z" />
              <path d="M24.08,25.58a7.77,7.77,0,1,1,7.77-7.77A7.78,7.78,0,0,1,24.08,25.58Zm0-14a6.27,6.27,0,1,0,6.27,6.26A6.28,6.28,0,0,0,24.08,11.55Z" />
              <path d="M35.54,38.28a12.15,12.15,0,0,0-22.91,0l-1.42-.5a13.66,13.66,0,0,1,25.75,0Z" />
            </svg>
          </Link>
        </li>
        <li>
          <Link to={PageInfo.HOW_TO_GUIDE.path}>
            <Typography className={classes.inner}>ご利用ガイド</Typography>
            <svg
              viewBox="0 0 48 48"
              width="48"
              height="48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M29.68,42.56H18.33v-8.3a2.08,2.08,0,0,0-1.09-1.81A14.07,14.07,0,0,1,9.89,19.4,14.36,14.36,0,0,1,23.58,5.94a14.13,14.13,0,0,1,7.2,26.51,2.08,2.08,0,0,0-1.1,1.82Zm-9.85-1.5h8.35V34.27a3.6,3.6,0,0,1,1.88-3.14A12.62,12.62,0,0,0,23.62,7.44a12.82,12.82,0,0,0-12.23,12A12.54,12.54,0,0,0,18,31.13a3.6,3.6,0,0,1,1.87,3.13Z" />
              <path d="M24,46.79a4.28,4.28,0,0,1-4.27-4.27h1.5a2.77,2.77,0,0,0,5.54,0h1.5A4.28,4.28,0,0,1,24,46.79Z" />
              <rect x="19.07" y="34.03" width="9.85" height="1.5" />
              <rect x="19.07" y="37.55" width="9.85" height="1.5" />
              <rect x="23.25" y="1" width="1.5" height="2.81" />
              <rect
                x="14.45"
                y="3.36"
                width="1.5"
                height="2.81"
                transform="translate(-0.35 8.24) rotate(-30)"
              />
              <rect
                x="8.01"
                y="9.8"
                width="1.5"
                height="2.82"
                transform="translate(-5.32 13.19) rotate(-60)"
              />
              <rect x="5" y="19.25" width="2.81" height="1.5" />
              <rect
                x="7.36"
                y="28.05"
                width="2.81"
                height="1.5"
                transform="translate(-13.23 8.24) rotate(-30)"
              />
              <rect
                x="38.49"
                y="27.39"
                width="1.5"
                height="2.81"
                transform="translate(-5.32 48.38) rotate(-60.01)"
              />
              <rect x="40.19" y="19.25" width="2.81" height="1.5" />
              <rect
                x="37.83"
                y="10.45"
                width="2.82"
                height="1.5"
                transform="translate(-0.34 21.12) rotate(-30)"
              />
              <rect
                x="31.39"
                y="4.01"
                width="2.81"
                height="1.5"
                transform="translate(12.27 30.78) rotate(-60)"
              />
            </svg>
          </Link>
        </li>
        <li>
          {/* TODO 7月号口時点ではfaqへのリンクをクローズ。faq urlがかたまったのち、復活させる */}
          <a
            href={OuterLinkInfo.FAQ_LINK_URL.path}
            target="_blank"
            rel="noreferrer"
            onClick={(event) => {
              onGaClick('B-000-004-002');
              // DMPビーコン
              OnOutDmpClick(OuterLinkInfo.FAQ_LINK_URL.path, authInfo?.scimId);
            }}
            // href="/"
            // onClick={(event) => {
            //   event.preventDefault();
            //   history.push(PageInfo.FAQ.path);
            // }}
          >
            <Typography className={classes.inner}>よくあるご質問</Typography>
            <svg
              viewBox="0 0 48 48"
              width="48"
              height="48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="37.41"
                y="18.56"
                width="1.5"
                height="3.72"
                transform="translate(-1.63 37.45) rotate(-51.32)"
              />
              <path d="M36.31,21.44H34.52a3.1,3.1,0,0,1-3.1-3.1V14.7a3.1,3.1,0,0,1,3.1-3.1h1.79a3.1,3.1,0,0,1,3.1,3.1v3.64A3.1,3.1,0,0,1,36.31,21.44ZM34.52,13.1a1.6,1.6,0,0,0-1.6,1.6v3.64a1.6,1.6,0,0,0,1.6,1.6h1.79a1.6,1.6,0,0,0,1.6-1.6V14.7a1.6,1.6,0,0,0-1.6-1.6Z" />
              <rect x="23.99" y="18.37" width="4.63" height="1.5" />
              <rect x="16.56" y="12.4" width="1.5" height="8.32" />
              <rect x="17.31" y="11.65" width="4.25" height="1.5" />
              <polygon points="28.27 20.91 26.34 13.16 26.27 13.16 24.34 20.91 22.88 20.55 25.13 11.52 25.84 11.67 27.51 11.65 29.73 20.55 28.27 20.91" />
              <rect x="17.31" y="15.81" width="3.36" height="1.5" />
              <path d="M10.75,47.14v-10H6.62A5.62,5.62,0,0,1,1,31.56v-20A5.64,5.64,0,0,1,5.49,6L5.8,7.47a4.14,4.14,0,0,0-3.3,4v20a4.13,4.13,0,0,0,4.12,4.12h5.63v8l8.29-8h8.13v1.5H21.14Z" />
              <path d="M38.52,42.26l-10.4-10H14.39a5.63,5.63,0,0,1-5.63-5.62V6.62A5.63,5.63,0,0,1,14.39,1H40.83a5.62,5.62,0,0,1,5.62,5.62V26.68a5.62,5.62,0,0,1-5.62,5.62H38.52ZM14.39,2.5a4.13,4.13,0,0,0-4.13,4.12V26.68a4.13,4.13,0,0,0,4.13,4.12H28.73l8.29,8V30.8h3.81A4.13,4.13,0,0,0,45,26.68V6.62A4.13,4.13,0,0,0,40.83,2.5Z" />
            </svg>
          </a>
        </li>
      </ul>
    );
  }
);

// マイページ画面
const MyPage: FC = () => (
  <Template title="マイページ">
    <RequestInfo />
    <ServiceUse />
    <Banners />
    <Typography variant="h3">その他</Typography>
    <OtherButtons />
  </Template>
);

export default MyPage;
