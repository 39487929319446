import { PageInfo } from 'app/utils/constants';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export interface UseBrowserBackProps {
  screen: number | null;
  setScreen: (screen: number) => void;
  popstateFunc?: any;
  paths: paths;
  // パスにパラメータを持つ場合、指定のパスへ飛ばす
  haveParam?: string;
}
export interface paths {
  input: string;
  confirm: string;
  complete: string;
}
/**
 * ブラウザ制御カスタムHooks
 */
export const useBrowserBack = (props: UseBrowserBackProps) => {
  // ブラウザバック制御変数（ブラウザバック処理ステップ）
  const stepRef = useRef<null | number>(null);
  // ブラウザバック制御変数（ブラウザバック実施時画面[screen]）
  const beforeScreenRef = useRef<null | number>(null);
  const history = useHistory();
  // 自動でブラウザバックした際に同じ位置に戻るのを防ぐ
  window.history.scrollRestoration = 'manual';

  /**
   * デフォルトpopState処理
   * 入力画面(screen:0)→確認画面(screen:1)→完了画面(screen:2)
   * 履歴は「入力」or「確認、完了」の2パターンのみ
   * popStateイベントが発火されて、検知したパスが「確認」「完了」の場合は必ず、「進むボタン」
   * 検知したパスが「入力」の場合には必ず、「戻るボタン」
   * 入力画面：〇●：入力画面表示時に履歴●を作成することで、ブラウザフォワードを制御（ボタンからの確認画面遷移のみ許可）
   * 確認画面：〇 ：戻る操作時に入力画面の履歴の意識が必要（上記よりブラウザフォワードが有効にならないように制御）
   * 完了画面：〇●：完了画面表示時に履歴●を作成することで、ブラウザバックを制御（ブラウザバックしても〇に戻る状態になる。再度●を作成）
   */
  // popState関数（ブラウザバック時処理）
  const popstateFunc = () => {
    if (
      history.location.pathname !== props.paths.input &&
      history.location.pathname !== props.paths.confirm &&
      history.location.pathname !== props.paths.complete
    ) {
      // input/confirm/complete以外のページに遷移する場合はそのまま遷移させる
      return;
    }
    // 確認画面でブラウザバックをした後に進むボタンを押した際の処理
    if (
      history.location.pathname === props.paths.confirm &&
      stepRef.current === 0
    ) {
      // history.replace(props.paths.confirm);
      props.setScreen(1); // status設定：確認画面
      return;
    }
    // 完了画面でブラウザバックをした後に進むボタンを押した際の処理
    if (
      history.location.pathname === props.paths.complete &&
      stepRef.current === 0
    ) {
      props.setScreen(0); // status設定：入力画面
      // 完了には進めないようにする
      history.replace(props.paths.input);
      return;
    }
    // popstateFuncメイン処理
    if (stepRef.current === 0) {
      // 入力画面
      // 入力画面でのブラウザバックは特に処理を行わない
    } else if (stepRef.current === 1) {
      // 確認画面
      // 確認→入力へのブラウザバック
      // 確認→完了でエラーが発生した場合もこのケース
      props.setScreen(0); // status設定：入力画面
    } else if (stepRef.current === 2) {
      // 退会した後にブラウザバックされた後はTOP画面に遷移
      // replaceするのでinputには戻らない
      if (PageInfo.CORP_RESIGN_BASE.path === history.location.pathname) {
        history.replace(PageInfo.TOP.path);
        return;
      }
      if (PageInfo.CORP_ADMIN_CHANGE_INPUT.path === history.location.pathname) {
        // 操作者は企業管理者ではなくなっているため、企業管理者変更後にブラウザバックされた後はアカウント管理画面へ遷移
        // replaceするのでinputには戻らない
        history.replace(PageInfo.ACCOUNT_MANAGE.path);
        return;
      }
      if (PageInfo.CORP_INFO_EDIT_INPUT.path === history.location.pathname) {
        // 企業情報変更申請完了後にブラウザバックされた場合はアカウント管理画面へ遷移
        // replaceするのでinputには戻らない
        history.replace(PageInfo.ACCOUNT_MANAGE.path);
        return;
      }
      // 完了画面でブラウザバック
      props.setScreen(0); // status設定：入力画面
    }
  };
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', refreshStateEvent());
    // イベント クリーンアップ
    return function cleanup() {
      // イベントの設定解除
      window.removeEventListener('beforeunload', refreshStateEvent());
    };
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // リンク直打ち、または、更新を書けた際に走るイベント
  // ・確認画面でリロード：入力画面に戻る（入力内容は破棄されている）さらに戻ると元の画面
  // ・完了画面でリロード：完了画面に戻る（入力内容は破棄されている）さらに戻ると入力画面
  // ・確認画面を直打ち：元の画面に戻す
  // ・完了画面を直打ち：完了画面のまま、さらに戻ると元の画面
  const refreshStateEvent = (): any => {
    // inputの場合には素直に表示
    if (history.location.pathname === props.paths.input) {
      return;
    }
    if (history.location.pathname === props.paths.complete) {
      if (props.haveParam) {
        // 契約内容照会画面-利用者招待画面でリロードされると画面遷移できないため、契約内容照会へ飛ばす
        history.replace(props.haveParam);
        return;
      }
      // 完了画面での更新の場合、完了画面のままにするためにscreen=2
      props.setScreen(2);
    } else if (history.location.pathname === props.paths.confirm) {
      if (props.haveParam) {
        // 契約内容照会画面-利用者招待画面でリロードされると画面遷移できないため、契約内容照会へ飛ばす
        history.replace(props.haveParam);
        return;
      }
      // 確認画面での更新の場合、現在のパスを入力に書き換えて確認画面に遷移できないようにする
      // そのあとバックして入力画面に遷移
      // また、リンクを直打ちした際もbeforeunloadが走る。
      // 直打ちの場合にはinputに書き換えてconfirmに行けないようにしてから-1をして、
      // もとの画面に飛ばす
      history.replace(props.paths.input);
      history.go(-1);
    }
  };
  // popStateイベント設定（利用側から定義がない場合は、定義されているpopstateFuncを利用）
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const popStateEvent = props.popstateFunc ? props.popstateFunc : popstateFunc;

  // ブラウザバック制御useRef設定
  useEffect(() => {
    // ブラウザバック制御処理状態（初期：status）、完了時：-1
    stepRef.current = props.screen;
    // ブラウザバック前画面（status）
    beforeScreenRef.current = props.screen;
  }, [props.screen]);

  useEffect(() => {
    // イベント設定（戻るイベント）
    window.addEventListener('popstate', popStateEvent, false);
    // イベント クリーンアップ
    return function cleanup() {
      window.removeEventListener('popstate', popStateEvent, false);
    };
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default useBrowserBack;
