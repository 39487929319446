import { FC, Fragment, memo } from 'react';
import Template from 'app/views/components/template';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ButtonBack } from 'app/views/components/block/button';
import { useHistory } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import useNoticeDetail from 'app/hooks/notice/detail';
import useNoticeModule from 'app/views/components/modules/notice';

const useStyles = makeStyles({
  backButton: {
    marginBottom: '-10px',
  },
});

// お知らせ詳細
const NoticeDetail: FC = memo(() => {
  const history = useHistory();
  const { noticeDetail } = useNoticeDetail();
  const { GetCategoryIcon } = useNoticeModule();
  const classes = useStyles();

  // 改行の変換関数
  const DescriptionReplace = ({ text }: { text: string }) => {
    const texts = text.split(/(\n)/).map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>{/\n/.exec(item) ? <br /> : item}</Fragment>
    ));
    return <Typography>{texts}</Typography>;
  };
  return (
    <>
      <Template title="お知らせ">
        <Typography variant="h3" style={{ marginTop: '90px' }}>
          お知らせ
        </Typography>
        <Box sx={{ display: 'flex', marginBottom: '50px' }}>
          {GetCategoryIcon(noticeDetail.categoryType)}
          <Typography style={{ marginLeft: '10px', fontSize: '1.8rem' }}>
            {noticeDetail.viewDateStart}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: '10px' }}>
          <Typography variant="h4" style={{ marginBottom: '50px' }}>
            {noticeDetail.notificationTitle}
          </Typography>
          <Box sx={{ marginBottom: '100px' }}>
            <DescriptionReplace text={noticeDetail.notificationContents} />
          </Box>
        </Box>
        <div className={classes.backButton}>
          <ButtonBack onClick={() => history.push(PageInfo.NOTICE_BASE.path)}>
            戻る
          </ButtonBack>
        </div>
      </Template>
    </>
  );
});

export default NoticeDetail;
