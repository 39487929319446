import { FC } from 'react';
import Template from 'app/views/components/template';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useCorpIdFederate from 'app/hooks/corporation/id/federate';
import { ButtonForward, ButtonBack } from 'app/views/components/block/button';
import TextInput from 'app/views/components/atom/text';
import { InputRowGridPadding } from 'app/views/components/block/layout';
import StepperContent from 'app/views/components/block/stepper';
import { GlobalErrorMessage } from 'app/views/components/block/message';
import { ColorInfo } from 'app/style/theme';

const CompanyIdFederatePage: FC = () => {
  // 企業ID登録業務カスタムHooks
  const {
    status,
    corporationIdFederate,
    handleInputEvent,
    handleNext,
    handleBack,
    validationCheck,
    globalMessage,
    validationMessage,
  } = useCorpIdFederate();

  // 項目の活性（入力）、非活性（入力以外）
  const isDisabled = (): boolean => {
    switch (status) {
      case 0:
        return false;
      default:
        return true;
    }
  };
  const useStyles = makeStyles(() => ({
    h2: {
      paddingLeft: '0px',
    },
    confirm: {
      marginLeft: '-50px',
    },
    summaryText: {
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  // レンダリング
  return (
    <Template title="企業ユーザー登録">
      <>
        <StepperContent StepNumber={status} />

        {/* 企業ID登録リクエスト：リクエスト完了 */}
        {status === 2 && (
          <>
            <Grid style={{ marginBottom: '80px' }}>
              <Box
                sx={{
                  marginBottom: '30px',
                  marginTop: 50,
                  textAlign: 'center',
                }}
              >
                企業ユーザー登録の申請を受け付けました。
              </Box>
              <Box sx={{ marginTop: '5px', textAlign: 'center' }}>
                申請が承認されると「企業ユーザー登録申請承認のお知らせ」メールが届きます。
              </Box>
              <Box
                sx={{
                  marginTop: '5px',
                  marginBottom: '75px',
                  textAlign: 'center',
                }}
              >
                ※承認状況は企業管理者またはアカウント管理権限保有者に直接お問い合わせください。
              </Box>
              <Grid>
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  トップへ
                </ButtonForward>
              </Grid>
            </Grid>
          </>
        )}

        {/* 企業ID登録リクエスト：情報の入力・入力内容の確認 */}
        {status !== 2 && (
          <Container>
            <div
              style={{
                marginTop: '52px',
                marginBottom: globalMessage ? '77px' : '',
              }}
            >
              <GlobalErrorMessage message={globalMessage} />
            </div>

            {status === 0 ? (
              <p className={classes.summaryText}>
                企業管理者に企業ユーザー登録の申請を行います。
                <br />
                企業管理者のメールアドレスをご入力のうえ、「確認」ボタンを押してください。
              </p>
            ) : (
              <p className={classes.summaryText}>
                ご入力内容をご確認のうえ、「この内容で申請する」ボタンを押してください。
              </p>
            )}

            <Typography
              variant="h3"
              style={{
                marginTop: 75,
                marginBottom: 24,
                display: 'inline-block',
              }}
            >
              企業管理者情報
            </Typography>
            {status === 0 && (
              <Typography
                color="error"
                style={{ display: 'inline-block', marginLeft: '25px' }}
              >
                ※企業管理者のメールアドレスは、貴社内でご確認ください。
              </Typography>
            )}
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding
                label="メールアドレス"
                required={isDisabled()}
              >
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="administratorId"
                  value={corporationIdFederate?.administratorId}
                  handleInputEvent={handleInputEvent}
                  disabled={isDisabled()}
                  fullWidth
                  required
                  onBlur={validationCheck}
                  error={!!validationMessage?.administratorId}
                />
                {status === 0 && (
                  <Typography color="error">
                    {validationMessage?.administratorId}
                  </Typography>
                )}
              </InputRowGridPadding>
            </Box>

            <Typography variant="h3">お客さま情報</Typography>
            <Box sx={{ margin: '5px 0px 5px 20px' }}>
              <InputRowGridPadding label="氏名" required>
                <TextInput
                  className={isDisabled() ? classes.confirm : ''}
                  name="name"
                  value={corporationIdFederate?.name}
                  disabled
                  fullWidth
                />
              </InputRowGridPadding>
            </Box>
            <Box
              style={{
                marginTop: '80px',
                marginBottom: '60px',
                borderTop: `1px solid ${ColorInfo.gray04}`,
              }}
            />
            <Grid
              container
              direction="row"
              style={{ marginTop: 80, marginBottom: 105 }}
            >
              {/* ボタン配置 */}
              {status === 1 && (
                <Grid item xs={3}>
                  <ButtonBack onClick={handleBack}>戻る</ButtonBack>
                </Grid>
              )}

              <Grid container item xs={status === 1 ? 6 : 12}>
                <ButtonForward onClick={handleNext} style={{ margin: 'auto' }}>
                  {status === 0 && '確認'}
                  {status === 1 && 'この内容で申請する'}
                </ButtonForward>
              </Grid>
              {status === 1 && <Grid item xs={3} />}
            </Grid>
          </Container>
        )}
      </>
    </Template>
  );
};

export default CompanyIdFederatePage;
