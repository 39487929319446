import { useHistory } from 'react-router-dom';
import { PageInfo } from 'app/utils/constants';
import { useEffect } from 'react';
/**
 * 共通エラー業務カスタムHooks
 */
export const useError = () => {
  const history = useHistory();

  useEffect(() => {
    // 初期処理
    setTimeout(() => {
      history.push(PageInfo.TOP.path);
    }, 5 * 1000);
  });

  return 0;
};
export default useError;
