import { AuthContext } from 'app/hooks/context/auth';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiConst, PageInfo } from 'app/utils/constants';
import useApi from 'app/hooks/api';

const useLogout = (): (() => void) => {
  const { setUserInfo } = useContext(AuthContext);
  const history = useHistory();
  const { callApi } = useApi<void>();

  const logout = async () => {
    await callApi(ApiConst.LOGOUT, 'POST', {});
    setUserInfo(null);
    history.push(PageInfo.TOP.path);
  };

  return logout;
};

export default useLogout;
