import { FC } from 'react';
import Template from 'app/views/components/template';
import { Typography } from '@material-ui/core';
import IntroduceServiceList from 'app/views/components/block/introduce';

const IntroduceServicePage: FC = () => (
  <>
    <Template title="サービス紹介">
      <div style={{ marginBottom: '150px' }}>
        <Typography
          variant="h3"
          style={{ marginTop: '90px', marginBottom: '-10px' }}
        >
          サービス紹介
        </Typography>
        <IntroduceServiceList />
      </div>
    </Template>
  </>
);

export default IntroduceServicePage;
