import { ApiConfig, ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import { RegistService } from 'app/service/contract/regist';

// 登録済サービス情報
export interface RegistServiceList {
  dataList?: RegistService[] | null;
}

/**
 * ご利用中のサービス一覧取得Hooks
 */
export const useRegistServiceList = () => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // ご利用中のサービス情報
  const [registServiceList, setRegistServiceList] =
    // 初期値設定
    useState<RegistServiceList>({
      dataList: [],
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<RegistServiceList>();

  const contractRegistServiceListAPI = () => {
    void callApi(ApiConst.REGIST_SERVICE_LIST, 'GET', null);
  };

  useEffect(() => {
    // 初期処理
    setRegistServiceList({
      dataList: [],
    });
    // ご利用中のサービス情報取得API呼び出し
    contractRegistServiceListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (response) {
      // ご利用中のサービス情報設定
      setRegistServiceList({
        dataList: response.dataList,
      });
    } else if (error?.code) {
      // API入力値エラー
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return { registServiceList };
};
export default useRegistServiceList;
