import { useEffect, useState, useContext } from 'react';
import {
  Request,
  ContractDetailRequest,
  ContractDetailResponse,
  ApiKeyInfo,
  ApiKeyResponseList,
} from 'app/service/contract/detail/apiKey';
import { ApiConfig, ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'app/hooks/context/auth';

// 画面表示IF-APIKey一覧
export interface ApiKeyList {
  rows: ApiKeyInfo[];
}

/**
 * 契約内容照会－契約詳細業務カスタムHooks
 */
export const useApiKey = () => {
  // APIキー一覧
  const [apiKeyList, setApiKeyList] =
    // 初期値設定
    useState<ApiKeyList>({
      rows: [],
    });

  // GlobalMessage情報
  const [globalMessage, setGlobalMessage] = useState<string>('');

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ApiKeyResponseList>();

  interface params {
    contractNo: string;
    scimId: string;
  }
  const params = useParams<params>();

  // APIキー一覧取得
  const apiKeyListAPI = () => {
    // API Request設定
    const req: Request = {};

    const serviceUsageContractId = params.contractNo;
    const serviceUsageScimId = params.scimId;
    // APIキー一覧取得API呼出し
    const APIKEY_LIST: ApiConfig = {
      url: `${ApiConst.APIKEY_LIST.url}${serviceUsageContractId}/${serviceUsageScimId}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };

    // APIキー一覧取得取得API呼出し
    void callApi(APIKEY_LIST, 'GET', null);
  };

  useEffect(() => {
    // 初期処理
    // APIキー一覧取得
    apiKeyListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 契約情報詳細取得API実行後処理
  useEffect(() => {
    if (response) {
      // 正常時
      // 契約情報詳細をセット
      setApiKeyList({
        rows: response?.apiKeyList,
      });
    } else if (error?.code) {
      // 異常時
      setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    apiKeyList,
    globalMessage,
  };
};
export default useApiKey;
