import { ApiConfig, ApiConst } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'app/hooks/context/auth';
import {
  ServiceUseInfoListResponse,
  ServiceUseInfo,
} from 'app/service/contract/service/list';

// ご利用中のサービス情報
export interface ServiceUseInfoList {
  rows?: ServiceUseInfo[] | null;
}

/**
 * ご利用中のサービス一覧取得Hooks
 */
export const useServiceUseList = (serviceUseSort = '1') => {
  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);

  // ご利用中のサービス情報
  const [serviceUseInfoList, setServiceUseInfoList] =
    // 初期値設定
    useState<ServiceUseInfoList>({
      rows: [],
    });

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ServiceUseInfoListResponse>();

  const contractServiceUseListAPI = (scimId: string) => {
    // ご利用中のサービス情報取得API呼び出し
    // sortFlagを1とする
    const CONTRACT_SERVICE_USE_LIST: ApiConfig = {
      url: `${ApiConst.CONTRACT_SERVICE_USE_LIST.url}/${scimId}/${serviceUseSort}`,
      headers: ApiConst.CONTRACT_SERVICE_USE_LIST.headers,
    };
    void callApi(CONTRACT_SERVICE_USE_LIST, 'GET', null);
  };

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  useEffect(() => {
    // 初期処理
    setServiceUseInfoList({
      rows: [],
    });
    setApiCallStatus(0);

    // ご利用中のサービス情報取得API呼び出し
    contractServiceUseListAPI(authInfo?.scimId || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (response) {
      setApiCallStatus(1);

      // ご利用中のサービス情報設定
      setServiceUseInfoList({
        rows: response.dataList,
      });
    } else if (error?.code) {
      // API入力値エラー
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return { serviceUseInfoList, apiCallStatus };
};
export default useServiceUseList;
