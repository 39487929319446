import axios from 'axios';
import { useState } from 'react';
import { MessageConst, ErrorCodeContst } from 'app/utils/constants';
import { AddressResponse } from 'app/service/customer/corporation/apply';

export interface ErrorResponse {
  code: string;
  message: string;
}

// API呼び出し用Hook
const useZipCloudApi = () => {
  const [response, setResponse] = useState<AddressResponse>();
  const [error, setError] = useState<ErrorResponse>();

  // API呼び出し
  const callZipCloudApi = async (code1: string, code2: string) => {
    await axios
      .get('https://zipcloud.ibsnet.co.jp/api/search', {
        params: { zipcode: code1 + code2 },
      })
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response) {
          // 応答あり
          if (err.response?.data) {
            setError(err.response.data);
            return;
          }
        }
        setError({
          code: ErrorCodeContst.NOACTION,
          message: MessageConst.NOACTION,
        });
      });
  };

  return { response, error, callZipCloudApi, setResponse };
};

export default useZipCloudApi;
