/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useState } from 'react';
import { ApiConfig } from 'app/utils/constants';
import useApi from 'app/hooks/api';
import { AuthContext } from 'app/hooks/context/auth';
import { useParams } from 'react-router-dom';
import { parse } from 'date-fns';
import {
  AuthList,
  ContractAuthListResponse,
  ContractAuthListRequest,
} from 'app/service/customer/usage/contract/auth/list';

// 利用権限一覧
export interface ContractAuthList
  extends Omit<ContractAuthListResponse, 'dataList'> {
  contractNo: string;
  contractMenuId: string;
  contractCourseId: string;
  status: string;
  contractDateStart: string;
  contractDateEnd: string;
  searchValue: string; // 検索条件
  page: number; // 取得対象ページ番号
  size: string; // 表示対象件数
  rows: (AuthList & { id: string })[];
  sortItem?: 'email' | 'name' | 'departmentName' | null; // ソート項目
  sortType?: 'asc' | 'desc' | null; // ソート順
}

/**
 * 利用権限一覧業務カスタムHooks
 */
export const useContractAuthList = () => {
  // 利用権限一覧情報
  const [contractAuthList, setContractAuthList] =
    // 初期値設定
    useState<ContractAuthList>({
      contractNo: '',
      contractMenuId: '',
      contractCourseId: '',
      totalPage: 0,
      totalCount: 0,
      contractServiceName: '',
      contractMenuName: '',
      contractCourseName: '',
      contractOptionName: '',
      contractName: '',
      status: '',
      administratorMail: '',
      administratorName: '',
      contractDepartmentName: '',
      authorityFlag: '',
      searchValue: '',
      contractDateStart: '',
      contractDateEnd: '',
      page: 0,
      size: '',
      rows: [],
    });

  // 認証コンテキスト
  const { authInfo } = useContext(AuthContext);
  // 新規登録ボタン制御（true：非活性、false：活性）
  const [isNowBottunDisabled, setIsNowBottunDisabled] =
    useState<boolean>(false);
  // 前へボタン制御（true：非活性、false：活性）
  const [isPageBackDisabled, setIsPageBackDisabled] = useState<boolean>(true);
  // 次へボタン制御（true：非活性、false：活性）
  const [isPageNextDisabled, setIsPageNextDisabled] = useState<boolean>(true);

  /** 画面状態を保持する
   * 画面ロード中：0
   * 画面ロード完了：1
   * 検索中：2
   * 検索クリア：3
   */
  const [apiCallStatus, setApiCallStatus] = useState(0);

  // 入力値 onChange
  const handleInputEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    // プルダウンの場合はイベント発火
    if (name === 'size' && target.type !== 'checkbox') {
      handleSelect(target.value);
    } else {
      setContractAuthList({
        ...contractAuthList,
        [name]: value,
      });
    }
  };

  // 表示件数変更アクション
  const handleSelect = (sizeIndex: string) => {
    // 1ページ目に設定
    const pageIndex = 1;

    setContractAuthList({
      ...contractAuthList,
      page: pageIndex,
      size: sizeIndex,
    });
    // IF-SV-003利用権限一覧情報リクエスト呼び出し
    contractAuthListAPI(
      contractAuthList.contractNo,
      contractAuthList.contractMenuId,
      contractAuthList.contractCourseId,
      pageIndex,
      sizeIndex,
      contractAuthList.searchValue
    );
  };

  // 前へアクション
  const handleSearchBack = () => {
    // ページ減算
    const pageIndex = contractAuthList.page - 1;

    setContractAuthList({
      ...contractAuthList,
      page: pageIndex,
      size: contractAuthList.size,
    });
    // 利用権限一覧情報リクエスト呼び出し
    contractAuthListAPI(
      contractAuthList.contractNo,
      contractAuthList.contractMenuId,
      contractAuthList.contractCourseId,
      pageIndex,
      contractAuthList.size,
      contractAuthList.searchValue
    );
  };

  // 次へアクション
  const handleSearchNext = () => {
    // ページ加算
    const pageIndex = contractAuthList.page + 1;

    setContractAuthList({
      ...contractAuthList,
      page: pageIndex,
      size: contractAuthList.size,
    });
    // 利用権限一覧情報リクエスト呼び出し
    contractAuthListAPI(
      contractAuthList.contractNo,
      contractAuthList.contractMenuId,
      contractAuthList.contractCourseId,
      pageIndex,
      contractAuthList.size,
      contractAuthList.searchValue
    );
  };

  // 検索アクション
  const handleSearch = () => {
    // 1ページ目に設定
    const pageIndex = 1;

    setContractAuthList({
      ...contractAuthList,
      searchValue: contractAuthList.searchValue,
      page: pageIndex,
      size: contractAuthList.size,
    });

    // 利用権限一覧情報リクエスト呼び出し
    contractAuthListAPI(
      contractAuthList.contractNo,
      contractAuthList.contractMenuId,
      contractAuthList.contractCourseId,
      pageIndex,
      contractAuthList.size,
      contractAuthList.searchValue
    );
  };

  // 検索条件削除
  const handleSearchDelete = () => {
    // 1ページ目に設定
    const pageIndex = 1;
    // 検索状態のときは、ステータスを3に設定
    if (apiCallStatus === 2) setApiCallStatus(3);

    setContractAuthList({
      ...contractAuthList,
      searchValue: '',
    });

    // 利用権限一覧情報リクエスト呼び出し
    contractAuthListAPI(
      contractAuthList.contractNo,
      contractAuthList.contractMenuId,
      contractAuthList.contractCourseId,
      pageIndex,
      contractAuthList.size
    );
  };

  // 並び替え
  const handleSearchSort = (
    sortItem: 'email' | 'name' | 'departmentName' | null | undefined,
    sortType: 'asc' | 'desc' | null | undefined
  ) => {
    // 並び替え情報設定
    setContractAuthList({
      ...contractAuthList,
      sortItem,
      sortType,
    });

    // 利用権限一覧情報リクエスト呼び出し
    contractAuthListAPI(
      contractAuthList.contractNo,
      contractAuthList.contractMenuId,
      contractAuthList.contractCourseId,
      contractAuthList.page,
      contractAuthList.size,
      contractAuthList.searchValue,
      sortItem,
      sortType
    );
  };

  // API呼び出しカスタムHooks
  const { response, error, callApi } = useApi<ContractAuthListResponse>();

  // 利用権限一覧API
  const contractAuthListAPI = (
    contractNo: string,
    menuId: string,
    courseId: string,
    pageIndex = 1,
    pageSize = '30',
    searchValue?: string,
    sortItem?: 'email' | 'name' | 'departmentName' | null, // ソート項目
    sortType?: 'asc' | 'desc' | null // ソート順
  ) => {
    // API Request設定
    const corporationId = authInfo?.corporationId || '';
    const operatorId = authInfo?.scimId || '';

    const req: ContractAuthListRequest = {};
    if (searchValue) req.searchValue = searchValue;
    if (searchValue) setApiCallStatus(2); // 画面の状態を検索中＝２に設定

    // ソートを設定
    if (contractAuthList.sortItem) req.sortItem = contractAuthList.sortItem;
    if (sortItem) req.sortItem = sortItem;
    if (contractAuthList.sortType) req.sortType = contractAuthList.sortType;
    if (sortType) req.sortType = sortType;

    // 一覧取得API呼出し
    const CONTRACT_AUTH_LIST: ApiConfig = {
      url: `/api/v1/usage/contract/auth/list/${corporationId}/${contractNo}/${operatorId}/${pageIndex}/${pageSize}`,
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    };
    void callApi(CONTRACT_AUTH_LIST, 'GET', req);
  };

  interface params {
    contractNo: string;
    contractMenuId: string;
    contractCourseId: string;
  }

  const params = useParams<params>();
  useEffect(() => {
    // 初期処理
    const pageIndex = 1;
    const pageSize = '30';

    // Api呼出しステータスを未完了(0)に設定
    setApiCallStatus(0);

    const { contractNo, contractMenuId, contractCourseId } = params;

    setContractAuthList({
      contractNo,
      contractMenuId,
      contractCourseId,
      totalPage: 0,
      totalCount: 1,
      contractServiceName: '',
      contractMenuName: '',
      contractCourseName: '',
      contractOptionName: '',
      contractName: '',
      status: '',
      administratorMail: '',
      administratorName: '',
      contractDepartmentName: '',
      authorityFlag: '',
      searchValue: '',
      contractDateStart: '',
      contractDateEnd: '',
      page: pageIndex,
      size: pageSize,
      rows: [],
    });

    // 利用権限一覧API呼び出し
    contractAuthListAPI(
      contractNo,
      contractMenuId,
      contractCourseId,
      pageIndex,
      pageSize
    );
    // マウント時のため、第2引数は[]（以下のeslintコメント記載）
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      // Api呼び出しステータスが 0(初期状態) もしくは 3(検索クリア)の場合はステータスを1に設定
      if (apiCallStatus === 0 || apiCallStatus === 3) setApiCallStatus(1);
      // 利用権限一覧設定
      setContractAuthList({
        ...contractAuthList,
        ...response,
        rows: response.dataList.map((row) => ({ ...row, id: row.email })),
      });
      // ステータスで比較を行う、ステータスが5(利用終了)の場合（終了している契約）に非活性とする
      if (
        response.status === '5'
      ) {
        setIsNowBottunDisabled(true);
      }
      // 次へボタン制御
      if (response.totalPage > contractAuthList.page) {
        // 活性
        setIsPageNextDisabled(false);
      } else {
        // 非活性
        setIsPageNextDisabled(true);
      }

      // 前へボタン制御
      if (contractAuthList.page > 1) {
        // 活性
        setIsPageBackDisabled(false);
      } else {
        // 非活性
        setIsPageBackDisabled(true);
      }
    } else if (error?.code) {
      // API入力値エラー
      // 応答電文を設定
      // setGlobalMessage(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return {
    isPageBackDisabled,
    isPageNextDisabled,
    handleInputEvent,
    handleSearchBack,
    handleSearchNext,
    handleSearch,
    handleSearchDelete,
    contractAuthList,
    handleSearchSort,
    apiCallStatus,
    isNowBottunDisabled,
  };
};
export default useContractAuthList;
