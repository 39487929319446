import ReactDOM from 'react-dom';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const styles = () =>
  createStyles({
    video: {
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: '50%',
      transform: 'translateX(-30%)',
      width: 1020,
      height: 530,
    },
  });

interface MainVisualProps extends WithStyles<typeof styles> {
  src: string;
}

const MainVisual = withStyles(styles)(({ src, classes }: MainVisualProps) =>
  ReactDOM.createPortal(
    <CardMedia
      component="video"
      src={src}
      className={classes.video}
      playsInline
      muted
      autoPlay
      loop
    >
      <Typography>動画を再生できる環境ではありません。</Typography>
    </CardMedia>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('video-area')!
  )
);

export default MainVisual;
